'use strict'

import { Pipe, PipeTransform } from '@angular/core';

@Pipe({name: 'transformjson'})
export class TransformJsonPipe implements PipeTransform {
    transform(value: string) : any {
        return JSON.parse(value);
    }
}
