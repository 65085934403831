import { Component, Inject } from '@angular/core';

import { RoleReportPagenationModel, RoleReportModel } from '../../models';
import { Reports } from '../../reports';
import { TIERAPICalls } from '../../../services/';
import { http2Error } from 'src/tier/tier.utils';

@Component({
    selector: 'tier-reports-rolereport',
    templateUrl: './rolereport.template.html'
})
export class RoleReportComponent extends Reports {
    public report : RoleReportModel[] = [];
    public total : number = 0;
    public search : string | undefined = undefined;

    constructor(
        @Inject(TIERAPICalls) private apicall : TIERAPICalls
    ) { super(); };

    public get() {
        this.apicall.post<RoleReportPagenationModel>('api/reports/RolesReport',
            this.state2Options()
        ).subscribe({
            next: (response : RoleReportPagenationModel) => {
                this.report = response.ObjectList;
                this.total = response.Total;
            },
            error: (error) => {
                this.alert.error(http2Error(error));
            }
        });
    }

    public setState(state : boolean) {
        this.report.forEach((record) => {
            record.Teammembers?.forEach((teammember) => {
                teammember.Collapse = state;
            });
        })
    }
}
