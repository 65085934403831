<tierspinner name="root" [fullscreen]="true" size="large" type="ball-atom"><p style="color: white" > Loading... </p></tierspinner>
<div>
    <div class="header container-fluid d-print-none">
    </div>
    <div class="menu nopadding container-fluid d-print-none mx-0 px-0">
      <tiermenu></tiermenu>
    </div>
    <div class="container-fluid d-print-none mx-0 px-0">
        <tiertitle></tiertitle>
    </div>
    <tiernews></tiernews>
    <div class="content container-fluid mx-0 m-1">
        <ui-view></ui-view>
    </div>
    <div class="footer container-fluid d-print-none px-2 py-1">
      <div class="row">
        <div class="col text-start ">
            Version: {{clientVersion}}
        </div>
        <div class="col text-center">
            <a uiSref="privacy">Privacy Notice</a> |
            <a uiSref="tandc">Terms and Conditions</a>
        </div>
        <div class="col text-end">
            © Caradox LTD 2024
        </div>
      </div>
    </div>
</div>
