import { Component, Inject, OnInit, ChangeDetectionStrategy } from '@angular/core';
import {
  CalendarEvent,
  CalendarView,
  CalendarMonthViewDay
} from 'angular-calendar';
import { Subject } from 'rxjs';
import { parse, isSameMonth, isSameDay } from "date-fns";
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

import { http2Error } from 'src/tier/tier.utils';
import { TIERAPICalls, TIERToast } from '../../services';
import { ScheduleModel } from '../../models/';
import { ScheduleDetailsComponent } from './';

@Component({
    selector: 'tier-training-schedule-calendar',
    templateUrl: './schedulecalendar.template.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ScheduleCalendarComponent implements OnInit {
    public view: CalendarView = CalendarView.Month;
    public viewDate: Date = new Date();
    public events: CalendarEvent[] = [];
    public activeDayIsOpen : boolean = false;
    public refresh = new Subject<void>();
    public CalendarView = CalendarView;

    constructor(
        @Inject(TIERAPICalls) private apicall : TIERAPICalls,
        @Inject(TIERToast) private alert : TIERToast,
        @Inject(NgbModal) private modalService : NgbModal
    ) { }

    ngOnInit(): void {
        this.getEvents();
    }

    public getEvents() {
        this.apicall.get<ScheduleModel[]>('api/schedule/').subscribe({
            next: (response : ScheduleModel[]) => {
                this.addEvents(response);
                this.refresh.next();
            },
            error: (error) => {
                this.alert.error(http2Error(error));
            }
        });
    }

    public addEvents(events: any[]) : void {
        this.events = [];

        events.forEach((value) => {
            this.events.push({
                title: value.Title,
                start: parse(value.StartDate, 'dd-MM-yyyy HH:mm', new Date()),
                end: parse(value.EndDate, 'dd-MM-yyyy HH:mm', new Date()),
                draggable: false,
                meta: {
                    session: value
                }
            });
        });
    }

    public setView(view: CalendarView) {
        this.view = view;
    }

    public dayClicked({ date, events }: { date: Date; events: CalendarEvent[] }) {
        if (isSameMonth(date, this.viewDate)) {
            if (
                (isSameDay(this.viewDate, date) && this.activeDayIsOpen === true) ||
                events.length === 0
            ) {
                this.activeDayIsOpen = false;
            } else {
                this.activeDayIsOpen = true;
            }
            this.viewDate = date;
        }
    }

    beforeMonthViewRender({ body }: { body: CalendarMonthViewDay[] }): void {
        body.forEach((day) => {
            if (day.events.some((ele) => ele.meta?.session?.IsAttending)) {
                day.cssClass = "bg-warning-subtle";
            }
        });
    }

    public eventClicked(event : any) {
        const modalRef = this.modalService.open(ScheduleDetailsComponent, { size: 'lg' });

        modalRef.componentInstance.session = event?.meta?.session;

        modalRef.result.then(() => {
            this.getEvents();
        }).catch(() => {});
    }
}
