<div>
    <tierfilter class="mb-3" [config]="filterConfig" [(data)]="values" (fetch)="get()"></tierfilter>
</div>

<div class="card">
    <div class="card-header">
        Templates
    </div>
    <div class="card-body">
        <div class="row">
            <div class="col-md-6 col-lg-4 col-xxl-3 mb-2" *ngIf="cancelLink">
                <a class="link-underline link-underline-opacity-0" [uiSref]="cancelLink" [uiParams]="cancelOpts">
                    <div class="card border-danger">
                        <div class="card-body d-flex">
                            <div class="hstack">
                                <div class="ps-4">
                                    <h1 class="m-0 p-0"><i class="fa-solid fa-ban text-danger"></i></h1>
                                </div>
                                <div class="ps-4">
                                    <h2 class="m-0 p-0 text-primary">Cancel</h2>
                                </div>
                            </div>
                        </div>
                    </div>
                </a>
            </div>
            <div class="col-md-6 col-lg-4 col-xxl-3 mb-2" *ngIf="newLink">
                <a class="link-underline link-underline-opacity-0" [uiSref]="newLink">
                    <div class="card border-info">
                        <div class="card-body d-flex">
                            <div class="hstack">
                                <div class="ps-4">
                                    <h1 class="m-0 p-0"><i class="fa-solid fa-plus text-success"></i></h1>
                                </div>
                                <div class="ps-4">
                                    <h2 class="m-0 p-0 text-primary">New Template</h2>
                                </div>
                            </div>
                        </div>
                    </div>
                </a>
            </div>
            <div class="col-md-6 col-lg-4 col-xxl-3 mb-2" *ngFor="let template of templates">
                <a class="link-underline link-underline-opacity-0" [uiSref]="selectLink" [uiParams]="getParams(template)">
                    <div class="card" [ngClass]="{ 'border-danger': !template.IsActive, 'border-success': template.IsActive }">
                        <div class="card-body d-flex">
                            <div class="hstack">
                                <div class="">
                                    <div class="ps-4">
                                        <h1 class="m-0 p-0">
                                            <i class="fa-solid fa-hard-hat text-danger" *ngIf="template.IsContainer === false"></i>
                                            <i class="fa-solid fa-box text-danger" *ngIf="template.IsContainer === true"></i>
                                        </h1>
                                    </div>
                                </div>
                                <div class="ps-4 text-primary">
                                    <div>
                                        <b>Name: </b>{{template.Name}}
                                    </div>
                                    <div>
                                        <b>Allowed Roles: </b>
                                        <span *ngIf="template.AllowedRoles === null || template.AllowedRoles?.length! <= 0">Everyone Allowed</span>
                                        <span *ngFor="let ar of template.AllowedRoles; last as l">
                                            {{ar.Name}}<span *ngIf="!l">,</span>
                                        </span><br>
                                        <b>Expiry Period: </b>{{template.ExpiryPeriodInMonths || '0'}} Months<br>
                                        <b>Check Period: </b>{{template.CheckPeriodInMonths || '0'}} Months<br>
                                        <b>Allocatable: </b> {{template.IsAllocatable === true ? 'Yes' : 'No'}}<br>
                                        <b>Container: </b> {{template.IsContainer === true ? 'Yes' : 'No'}}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </a>
            </div>
        </div>
    </div>
</div>
<div class="d-flex justify-content-center my-3" *ngIf="templates?.length">
    <ngb-pagination [collectionSize]="total" [(page)]="page" [pageSize]="values.amount" [maxSize]="15" [rotate]="true" [ellipses]="false" [boundaryLinks]="true" (pageChange)="get()" />
</div>
