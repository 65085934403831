<div>
    <div class="hstack">
        <div class="ms-auto">
            <button [disabled]="!contacts.length" type="button" class="btn btn-sm btn-secondary me-2" (click)="showAll(false)">Collapse All</button>
            <button [disabled]="!contacts.length" type="button" class="btn btn-sm btn-secondary me-2" (click)="showAll(true)">Expand All</button>
        </div>
        <div>
            <input [disabled]="!contacts.length"
                    type="text"
                    class="form-control"
                    placeholder="Search..."
                    [(ngModel)]="searchText">
        </div>
    </div>
    <br />
    <div class="table-responsive">
        <table class="table" [hidden]="!contacts.length">
            <thead>
                <tr>
                    <th></th>
                    <th></th>
                    <th>Name</th>
                    <th>Equipment Validity</th>
                    <th>Home</th>
                    <th>Mobile</th>
                    <th>Team Callsign</th>
                    <th>Airwave Callsign</th>
                </tr>
            </thead>
            <tbody *ngFor="let contact of contacts | search: searchText">
                <tr>
                    <td role="button" (click)="toggleContact(contact)">
                        <i class="me-2 fa-solid pe-auto" [ngClass]="{'fa-caret-down': contact.showModules, 'fa-caret-right': !contact.showModules}"></i>
                    </td>
                    <td>
                        <img class="img-thumbnail medium-image" [src]="contact.profilePictureURL">
                    </td>
                    <td><a href="mailto:{{contact.EmailAddress}}">{{contact.Name}}</a></td>
                    <td>
                        <span class="badge" (click)="showInvalidEquipment(contact.Equipment)" [attr.role]="contact.Equipment.length ? 'button' : undefined"
                            [ngClass]="{ 'text-bg-success': !contact.Equipment.length, 'text-bg-danger': contact.Equipment.length }"
                            [textContent]="!contact.Equipment.length ? 'Equipment Valid' : contact.Equipment.length + ' invalid items'">
                        </span>
                    </td>
                    <td *ngIf="contact.HomeNo"><a href="tel:{{contact.HomeNo}}">{{contact.HomeNo || 'N/A'}}</a></td>
                    <td *ngIf="!contact.HomeNo">N/A</td>
                    <td *ngIf="contact.MobileNo"><a href="tel:{{contact.MobileNo}}">{{contact.MobileNo || 'N/A'}}</a></td>
                    <td *ngIf="!contact.MobileNo">N/A</td>
                    <td>{{contact.TeamCallsign || 'N/A'}}</td>
                    <td>{{contact.AirwaveCallsign || 'N/A'}}</td>
                </tr>
                <tr [hidden]="!contact.showModules" >
                    <td colspan="7">
                        <div class="mt-2 mb-2">
                            <span *ngFor="let module of contact.Modules">
                                <span class="badge me-2" (click)="showCriticalModulesNotMet(module)"
                                    [attr.role]="module.CriticalModulesNotMet.length ? 'button' : undefined"
                                    [ngClass]="{
                                        'text-bg-success':! module.CriticalModulesNotMet.length,
                                        'text-bg-danger': module.CriticalModulesNotMet.length }"
                                    [textContent]="module.Name">
                                </span>
                            </span>
                            <span class="badge text-bg-secondary" *ngIf="!contact.Modules.length">User has no roles</span>
                        </div>
                    </td>
                </tr>
            </tbody>
        </table>
    </div>

    <div class="alert alert-info" role="alert" [hidden]="contacts.length">
        <i class="fa-solid fa-exclamation-circle"></i>  0 results found.
    </div>
</div>
