'use strict'

import { Component, Inject } from '@angular/core';
import { parse, isValid, differenceInYears } from "date-fns";

import { IncidentInformationReportModel, IncidentInformationPagenationModel } from '../../models';
import { Reports } from '../../reports';
import { TIERAPICalls } from '../../../services/';
import { http2Error } from 'src/tier/tier.utils';

@Component({
    selector: 'tier-reports-incidentinformationereport',
    templateUrl: './incidentinformationreport.template.html'
})
export class IncidentInformationReportComponent extends Reports {
    public report : IncidentInformationReportModel[] = [];
    public total : number = 0;

    constructor(
        @Inject(TIERAPICalls) private apicall : TIERAPICalls
    ) { super(); };

    public get() {
        this.apicall.post<IncidentInformationPagenationModel>('api/reports/IncidentInformationReport',
            this.state2Options()
        ).subscribe({
            next: (response : IncidentInformationPagenationModel) => {
                this.report = response.ObjectList;
                this.total = response.Total;
            },
            error: (error) => {
                this.alert.error(http2Error(error));
            }
        });
    }

    public dob2age(dateOfBirth : string | undefined | null) : number | null {
        let eventDate : Date | null = null;

        try {
            eventDate = parse(dateOfBirth!, 'dd-MM-yyyy HH:mm', new Date());
        } catch(e) {
            return null;
        }

        if (!isValid(eventDate))
            return null;

        return differenceInYears(new Date(), eventDate);
    };

    public setState(state : boolean) {}
}
