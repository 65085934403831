import { Component, Inject, OnInit } from '@angular/core';
import { StateService } from '@uirouter/core';
import { merge } from 'rxjs';

import { TIERAPICalls, TIERProfilePicture, TIERAuth, TIERConfig, TIERToast, TIERMenuSignals } from '../../../services';
import { MenuModel } from '../../../models'
import { http2Error } from 'src/tier/tier.utils';

@Component({
    selector: 'tiermenu',
    template: `
      <nav class="navbar navbar-expand-lg bg-body-tertiary">
          <div class="container-fluid">
            <a routerLink="dashboard" class="navbar-brand">{{title}}</a>
            <button class="navbar-toggler" type="button" (click)="isNavCollapsed = !isNavCollapsed">
			    <span class="navbar-toggler-icon"></span>
		    </button>
            <div class="navbar-collapse" [class.collapse]="isNavCollapsed">
             <ul class="navbar-nav">
              <tiermenuchild class="dropdown-item" *ngFor="let menu of menu" [entry]="menu"></tiermenuchild>
            </ul>
            <ul class="navbar-nav ms-auto">
                <li class="nav-item" ngbDropdown display="dynamic">
                  <a class="nav-link" ngbDropdownToggle role="button">
                    <i class="fa-solid fa-user-circle fa-lg root-menu-icon"></i> <span class="root-menu-text">Account</span>
                  </a>
                  <div class="dropdown-menu" ngbDropdownMenu>
                    <div [hidden]="!isLoggedIn">
                        <div class="dropdown-item-text" [hidden]="!isLoggedIn">
                            <div class="d-flex align-items-center">
                                <div class="menu-avatar">
                                    <img [src]="avatar" class="img-thumbnail">
                                </div>
                                <div class="menu-user-label ms-2">
                                    <h6 class="m-0 text-truncate">{{fullname}}</h6>
                                    <p class="card-text text-body text-muted org-text text-truncate">{{organization}}</p>
                                </div>
                            </div>
                        </div>
                        <li ><hr class="dropdown-divider"></li>
                    </div>
                    <tiermenuchild *ngFor="let menu of sideMenu" [entry]="menu"></tiermenuchild>
                    <a class="dropdown-item child-menu-entry" [hidden]="isLoggedIn" uiSref="login" uiSrefActive="active"><i class="fa-solid fa-sign-in-alt fa-lg child-menu-icon d-flex justify-content-center"></i><span class="child-menu-text">Login</span></a>
                    <a class="dropdown-item child-menu-entry" [hidden]="!isLoggedIn" (click)="logOut()"><i class="fa-solid fa-sign-out-alt fa-lg child-menu-icon d-flex justify-content-center"></i><span class="child-menu-text">Logout</span></a>
                </div>
                </li>
              </ul>
            </div>
          </div>
        </nav>`,
      styleUrls: [ "./TIERMenu.component.scss" ]
})
export class TIERMenuComponent implements OnInit {
  public isNavCollapsed : boolean = true;
  public isLoggedIn : boolean = this.auth.isLoggedIn();
  public menu : MenuModel[] = [];
  public sideMenu : MenuModel[] = [];
  public title : string | null = null;
  public fullname : string | null = null;
  public organization : string | null = null;
  public avatar : string = this.pp.getDefaultPic();

  constructor(
    @Inject(TIERAPICalls) private apicall : TIERAPICalls,
    @Inject(TIERConfig) private config : TIERConfig,
    @Inject(TIERAuth) private auth : TIERAuth,
    @Inject(StateService) private router : StateService,
    @Inject(TIERToast) private alert : TIERToast,
    @Inject(TIERProfilePicture) private pp : TIERProfilePicture,
    @Inject(TIERMenuSignals) private menusig : TIERMenuSignals
  ) {
    this.title = this.config.get('siteDefaultTitle');

    merge(this.menusig.RefreshEvent, this.auth.LoginEvent).subscribe({
        next: () => {
            this.refresh();
        }
    })

    this.auth.LogoutEvent.subscribe({
        next: () => {
            this.clean();
        }
    })
  };

  ngOnInit(): void {
      if (!this.auth.isLoggedIn()) {
        this.clean();
        return;
      }

      this.makeMenu();
  }

  public logOut() : void {
      this.auth.logout();
      this.router.go('login');
  }

  private clean() : void {
      this.isLoggedIn = this.auth.isLoggedIn();
      this.avatar = this.pp.getDefaultPic();
      this.menu = [];
      this.sideMenu = [];
  }

  public refresh() : void {
    this.clean();
    this.makeMenu();
  }

  private removeMenuEntries(menu : MenuModel[], faciliatorCount : number) : MenuModel[] {
    let entries = [];

    for(let i=0; i < menu.length; i++) {
        if(menu[i].permissions === null || this.auth.hasClaim(menu[i].permissions) || (menu[i].showOnFaciliator && faciliatorCount > 0)) {
            if(menu[i].children !== null && menu[i].children.length > 0)
                menu[i].children = this.removeMenuEntries(menu[i].children, faciliatorCount);
            entries.push(menu[i]);
        }
    }
    return entries;
  }

  private makeMenu() : void {
    if(this.auth.isLoggedIn()) {
        let tokenInfo = this.auth.getTokenInfo();

        if(tokenInfo !== null) {
            this.fullname = tokenInfo.fullname;
            this.organization = tokenInfo.organizationName;

            this.pp.getMine().subscribe({
                next: (response) => {
                    this.avatar = response;
                },
                error: (error) => {
                    this.alert.error(http2Error(error));
                }
            });
        }

        this.apicall.get<number>("api/teammembers/faciliatorCount").subscribe({
            next: (response : number) => {
                this.menu = this.removeMenuEntries(this.config.get('menu'), response);
            },
            error: (error) => {
                this.alert.error(http2Error(error));
            }
        });
    }
    this.sideMenu = this.removeMenuEntries(this.config.get('sideMenu'), 0);
}
}

