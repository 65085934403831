import { Component } from '@angular/core';
import { WidgetBase } from '../../Widget.base';

@Component({
    selector: 'tier-widget-testwidget',
    template: '<b>HERE</b>',
    styleUrls: [ '../../_widgets.scss' ]
})
export class TestWidgetComponent extends WidgetBase{
    static readonly widgetName = 'TestWidgetComponent';
}
