'use strict'

import { Component, Input } from '@angular/core';

@Component({
    selector: 'tier-reportpane',
    template: `
        <div class="card mb-4 mt-2">
            <div class="card-header" (click)="isHidden = !isHidden" [ngClass]="{ 'd-print-none': isHidden }">
                <div class="hstack">
                    <div [textContent]="title"></div>
                    <div class="ms-auto d-print-none">
                        Click header to
                        <b [hidden]="!isHidden">Open</b>
                        <b [hidden]="isHidden">Close</b>
                        <i class="fa fa-arrow-down ms-1" [ngClass]="{'fa-arrow-down': isHidden, 'fa-arrow-up': !isHidden}"></i>
                    </div>
                </div>
            </div>

            <div class="card-body" #collapse="ngbCollapse" [(ngbCollapse)]="isHidden">
                <ng-content />
            </div>
        </div>`,
})
export class TIERReportPaneComponent {
    @Input() title : string | null = null;
    @Input() isHidden : boolean = false;
}
