'use strict'

import { Component, Inject } from '@angular/core';
import { TIERAPICalls, TIERToast } from '../../services';
import { http2Error, isNorU } from '../../tier.utils';

import { RoleModuleModel } from '../../models/';

@Component({
    selector: 'tier-training-rolelayout',
    templateUrl: "./rolelayout.template.html"
  })
  export class RoleLayoutComponent {
    public selectedItemId : number | null = null;
    public modules : RoleModuleModel[] = [];
    public searchText : string | undefined = undefined;

    constructor(
      @Inject(TIERAPICalls) private apicall : TIERAPICalls,
      @Inject(TIERToast) private alert : TIERToast
    ) { };

    public getModules(id : number | null) : void {
      if(isNorU(id))
        return;

      this.apicall.get<RoleModuleModel[]>('api/roles/%s/modules/'.formUri(id)).subscribe({
        next: (response) => {
            this.modules = response;
        },
        error: (error) => {
          this.alert.error(http2Error(error));
        }
      });
    }

    public onClear() : void {
        this.modules = [];
    }
  }
