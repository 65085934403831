<div class="col-md-8 offset-md-2 mt-3">
    <div class="row" *ngFor="let attendees of session.Attendees">
        <div class="hstack">
            <div class="w-100">
                <strong>Name:</strong> {{attendees.FullName}}
            </div>
            <div class="ms-auto">
                <div class="p-3 border"></div>
            </div>
        </div>
        <div class="row mb-4">
            <strong>Comments: </strong>
        </div>
        <hr class="mb-3" />
    </div>
    <div class="row mb-3">
        <div class="text-end p-0">
            <button type="button" class="btn btn-info" (click)="print()">Print Attendence</button>
        </div>
    </div>
</div>
