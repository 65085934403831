
import {
    authResolver
} from '../tier.routes';

import {
    IncidentReportComponent,
    IncidentAttendenceReportComponent,
    IncidentInformationReportComponent,
    ModuleTimelineReportComponent,
    RoleReportComponent,
    TrainingReportComponent,
    EquipmentReportComponent,
    UserReportComponent,
    EquipmentUsageReportComponent
} from './components';

export const routes : any = [
    {
        'name': 'reports@incident',
        'url': '/reports/incident/?{IsAsc:bool}&{Column:string}&{Amount:int}&{Page}&{Teammembers}',
        'component': IncidentReportComponent,
        'resolve': [
            authResolver
        ],
        'data': {
            'label': 'Reports/Incidents'
        },
        'params': {
            'Teammembers': {
                'type': 'string',
                'array': true,
                'value': []
            },
            'Page': {
                'type': 'int',
                'dynamic': true
            }
        }
    },
    {
        'name': 'reports@incidentattendence',
        'url': '/reports/incidentattendence/?{IsAsc:bool}&{Column:string}&{Amount:int}&{Page}&{From:string}&{To:string}&{Teammembers}',
        'component': IncidentAttendenceReportComponent,
        'resolve': [
            authResolver
        ],
        'data': {
            'label': 'Reports/Incident Attendence'
        },
        'params': {
            'Teammembers': {
                'type': 'string',
                'array': true,
                'value': []
            },
            'Page': {
                'type': 'int',
                'dynamic': true
            }
        }
    },
    {
        'name': 'reports@incidentinformation',
        'url': '/reports/incidentinformation/?{IsAsc:bool}&{Column:string}&{Amount:int}&{Page}&{IncidentId:int}',
        'component': IncidentInformationReportComponent,
        'resolve': [
            authResolver
        ],
        'data': {
            'label': 'Reports/Incident Information'
        },
        'params': {
            'Page': {
                'type': 'int',
                'dynamic': true
            }
        }
    },
    {
        'name': 'reports@moduletimeline',
        'url': '/reports/moduletimeline/?{IsAsc:bool}&{Column:string}&{Amount:int}&{Page}&{CriticalOnly:bool}&{Teammembers}&{Skills}',
        'component': ModuleTimelineReportComponent,
        'resolve': [
            authResolver
        ],
        'data': {
            'label': 'Reports/Module Timeline'
        },
        'params': {
            'Teammembers': {
                'type': 'string',
                'array': true,
                'value': []
            },
            'Skills': {
                'type': 'int',
                'array': true,
                'value': []
            },
            'Page': {
                'type': 'int',
                'dynamic': true
            }
        }
    },
    {
        'name': 'reports@roles',
        'url': '/reports/roles/?{IsAsc:bool}&{Column:string}&{Amount:int}&{Page}&{CriticalOnly:bool}&{Teammembers}&{Roles}',
        'component': RoleReportComponent,
        'resolve': [
            authResolver
        ],
        'data': {
            'label': 'Reports/Roles'
        },
        'params': {
            'Teammembers': {
                'type': 'string',
                'array': true,
                'value': []
            },
            'Roles': {
                'type': 'string',
                'array': true,
                'value': []
            },
            'Page': {
                'type': 'int',
                'dynamic': true
            }
        }
    },
    {
        'name': 'reports@training',
        'url': '/reports/training/?{IsAsc:bool}&{Column:string}&{Amount:int}&{Page}&{CriticalOnly:bool}&{Show:string}&{Teammembers}&{Skills}&{Roles}',
        'component': TrainingReportComponent,
        'resolve': [
            authResolver
        ],
        'data': {
            'label': 'Reports/Training'
        },
        'params': {
            'Teammembers': {
                'type': 'string',
                'array': true,
                'value': [],

            },
            'Skills': {
                'type': 'int',
                'array': true,
                'value': [],

            },
            'Roles': {
                'type': 'string',
                'array': true,
                'value': [],

            },
            'Page': {
                'type': 'int',
                'dynamic': true
            }
        }
    },
    {
        'name': 'reports@equipment',
        'url': '/reports/equipment/?{IsAsc:bool}&{Column:string}&{Amount:int}&{Page}&{Show:string}&{Teammembers}',
        'component': EquipmentReportComponent,
        'resolve': [
            authResolver
        ],
        'data': {
            'label': 'Reports/Equipment'
        },
        'params': {
            'Teammembers': {
                'type': 'string',
                'array': true,
                'value': []
            },
            'Page': {
                'type': 'int',
                'dynamic': true
            }
        }
    },
    {
        'name': 'reports@equipmentusage',
        'url': '/reports/equipmentusage/?{IsAsc:bool}&{Column:string}&{Amount:int}&{Page}&{Teammembers}&{EqFrom:string}&{EqTo:string}',
        'component': EquipmentUsageReportComponent,
        'resolve': [
            authResolver
        ],
        'data': {
            'label': 'Reports/Equipment Usage'
        },
        'params': {
            'Teammembers': {
                'type': 'string',
                'array': true,
                'value': []
            },
            'Page': {
                'type': 'int',
                'dynamic': true
            }
        }
    },
    {
        'name': 'reports@users',
        'url': '/reports/users/{Type:string}/?{IsAsc:bool}&{Column:string}&{Amount:int}&{Page}&{AnonHomeNo:bool}&{AnonMobileNo:bool}&{EqFrom:string}&{Teammembers}',
        'component': UserReportComponent,
        'resolve': [
            authResolver
        ],
        'data': {
            'label': 'Reports/Team Members'
        },
        'params': {
            'Teammembers': {
                'type': 'string',
                'array': true,
                'value': []
            },
            'Page': {
                'type': 'int',
                'dynamic': true
            }
        }
    }
]
