import { Component, EventEmitter, Output } from '@angular/core';

@Component({
    selector: 'TIERReportButtons',
    template: `
        <div class="rlbt-container d-print-none">
            <div class="rlbt-button rlbt-iconbutton" (click)="isClosed = !isClosed">
                <i class="fa-solid fa-plus"></i>
            </div>
            <ul class="rlbt-options" [hidden]="isClosed">
                <li (click)="exportEvent()">
                    <span class="rlbt-btn-label">Export</span>
                    <div class="rlbt-iconbutton">
                        <i class="fa-solid fa-file-export"></i>
                    </div>
                </li>
                <li (click)="printEvent()">
                    <span class="rlbt-btn-label">Print</span>
                    <div class="rlbt-iconbutton">
                        <i class="fa-solid fa-print"></i>
                    </div>
                </li>
            </ul>
        </div>`,
    styleUrl: './TIERReportButtons.component.scss'
})
export class TIERReportButtonsComponent {
    public isClosed : boolean = true;

    @Output() print : EventEmitter<void> = new EventEmitter();
    @Output() export : EventEmitter<void> = new EventEmitter();

    public printEvent() {
        this.isClosed = true;
        this.print.emit();
    }

    public exportEvent() {
        this.isClosed = true;
        this.export.emit()
    }
}
