import { Component, Inject, ViewChild, Input, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { NgForm } from '@angular/forms';
import { HttpHeaders } from '@angular/common/http';
import { StateService } from '@uirouter/angular';
import {
    Observable,
    OperatorFunction,
    debounceTime,
    distinctUntilChanged,
    switchMap,
    tap,
    catchError,
    of
} from 'rxjs';

import { SessionModel, VenueModel, EquipmentResourceModel } from '../../models/';
import { TIERAPICalls, TIERAuth } from '../../services';
import { isNorU } from 'src/tier/tier.utils';

@Component({
    selector: 'tier-training-sessions-edit',
    templateUrl: "./editsessions.template.html"
})
export class EditSessionsComponent implements OnInit {
    @ViewChild('sessionForm') sessionForm!: NgForm;

    @Input() session : SessionModel = {};

    constructor(
        @Inject(TIERAPICalls) private apicall : TIERAPICalls,
        @Inject(NgbActiveModal) private activeModal : NgbActiveModal,
        @Inject(StateService) private state : StateService,
        @Inject(TIERAuth) private auth : TIERAuth
      ) { }

    public searchFailed : boolean = false;

    getNamedLocation : OperatorFunction<string, readonly string[]> =
        (text$: Observable<string>) =>
            text$.pipe(
            debounceTime(200),
            distinctUntilChanged(),
            switchMap(term =>
                term.length < 2 ? [] : this.apicall.post<readonly string[]>('api/namedlocations/filter/', JSON.stringify(term), undefined, new HttpHeaders({ 'Content-Type': 'application/json; charset=UTF-8' })).pipe(
                tap(() => (this.searchFailed = false)),
					catchError(() => {
						this.searchFailed = true;
						return of([]);
					}),
                )
                )
            )

    ngOnInit(): void {
        this.checkForNonAdminIsComplete();
    }

    public ok() : void  {
        this.activeModal.close({ redirect: false, session: this.session });
    }

    public CreateVenueIfNull(path : keyof Pick<SessionModel, "Venue">, ref : keyof VenueModel, value : any) : void {
        this.session[path] = {};

        if(isNorU(value)) {
            this.session[path] = undefined;
            this.searchFailed = false;
        } else {
            this.session[path]![ref] = value;
        }
    }

    public dismiss() : void  {
        this.activeModal.dismiss();
    }

    public complete() : void  {
        this.activeModal.close({ redirect: true, session: this.session });
    }

    public delete() : void  {
        this.session.IsActive = !this.session.IsActive;
        this.ok();
    }

    private checkForNonAdminIsComplete() {
        if(!this.auth.hasClaim(['admin']) && this.session.IsComplete)
            setTimeout(() => {
                if(!this.auth.hasClaim(['admin']) && this.session.IsComplete)
                    this.sessionForm.control.setErrors({ ...this.sessionForm.control.errors || {}, 'nonadminiscomplete': true });
            });
    }

    public openAttendencePage(id : number | undefined) : void  {
        if(!id)
            return;

        let url = this.state.href('training@sessionsattendence', { 'id': id })
        window.open(url, '_blank');
    }
}
