'use strict';

import { Injectable, Inject } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { take } from 'rxjs';

import { TIERConfig } from './';

@Injectable({
    providedIn: 'root'
})
export class TIERToast
{
    private isDebug : boolean = false;

    constructor(
        @Inject(ToastrService) private toast : ToastrService,
        @Inject(TIERConfig) private config : TIERConfig) {
            this.isDebug = !this.config.get("production");
        }

    public success(message: string | null, title: string = 'Success', timeout: number = 3600) : void {
        if(message === null)
            return;

        if(this.isDebug)
            console.log(message);

        this.toast.success(message, title, { timeOut: timeout })
    }

    public warning(message: string | null, title: string = 'Warning', timeout: number = 5000) : void {
        if(message === null)
            return;

        if(this.isDebug)
            console.warn(message);

        this.toast.warning(message, title, { timeOut: timeout })
    }

    public error(message: string | null, title: string = 'Error') : void {
        if(message === null)
            return;

        if(this.isDebug)
            console.error(message);

        this.toast.error(message, title, { disableTimeOut: true }).onTap
            .pipe(take(1))
            .subscribe(() => this.copyToClipboard(message));
    }

    private copyToClipboard(message : string) {
        navigator.clipboard.writeText(message).catch(null);
    }

    public remove(id : number) {
        this.toast.remove(id);
    }

    public removeAll() : void {
        this.toast.clear();
    }

}
