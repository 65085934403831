'use strict'

import { Component, OnInit } from '@angular/core';
import { WidgetBase } from '../../Widget.base';
import { http2Error } from 'src/tier/tier.utils';

@Component({
    selector: 'tier-widget-teamadmin-teammembersnotinroleswidget',
    template: `
    <tierspinner [name]="getUUID()"></tierspinner>
    <div>
        <table class="table table-borderless table-hover table-condensed">
            <thead>
                <tr>
                    <th>Team Member</th>
                </tr>
            </thead>
            <tbody>
                <tr *ngFor="let teammember of teammembers">
                    <td>{{teammember.Name}}</td>
                </tr>
            </tbody>
        </table>
    </div>`,
    styleUrls: [ '../../_widgets.scss' ]
})
export class TeamMembersNotInRoleWidgetComponent extends WidgetBase implements OnInit {
    static readonly widgetName = 'TeamMembersNotInRoleWidgetComponent';
    public teammembers : any[] = [];

    ngOnInit(): void {
        this.callAPI<any[]>('api/dashboard/usersnotinroles', this.getUUID()).subscribe({
            next: (response : any[]) => {
              this.teammembers = response;
              if(this.teammembers.length <= 0) {
                this.switchToNoDataWidget();
              }
            },
            error: (error) => {
                this.alert.error(http2Error(error));
            }
        });
    }
}
