import { Component } from '@angular/core';
import { WidgetBase } from '../../Widget.base';

@Component({
    selector: 'tier-widget-notfoundwidget',
    template: `
        <div class="systemwidget">
            <br/>
            <i style="font-size: 48px;" class="fa-solid fa-bomb systemwidgeticon"></i>
            <b class="systemwidgettext">Widget not found within library.</b>
        </div>`,
    styleUrls: [ '../../_widgets.scss' ]
})
export class NotFoundWidgetComponent extends WidgetBase {
    static readonly widgetName = 'NotFoundWidgetComponent';
 }
