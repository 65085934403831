import { Component, OnInit, Inject, Input } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { StateService, Transition } from '@uirouter/angular';

import { CompleteSessionResponseModal, CompleteSessionNotInRoleModal, CompleteSessionModuleModal } from '../../models/';
import { TIERAPICalls, TIERToast, TIERMenuSignals, TIERDialog } from '../../services';
import { http2Error, isNorU } from 'src/tier/tier.utils';
import { SessionsCompleteCommentsComponent } from './';

interface ImatrixHeaders {
    id? : number,
    name? : string
}

interface Imatrix {
    count : number
    data : any
    headers : ImatrixHeaders[]
}

@Component({
    selector: 'tier-training-sessionscomplete',
    templateUrl: "./sessionscomplete.template.html"
})
export class SessionsCompleteComponent implements OnInit {
    private id : number = this.trans.params()?.id;

    public filterFunction = (value : any) => value.assessmentBased === true;
    public matrix : Imatrix = { headers: [], data: {}, count: 0 };
    public completeSession : CompleteSessionResponseModal = {};
    public completeDate : string | null = null;
    public badSkills : CompleteSessionNotInRoleModal[] = [];
    public selectColumn : any = {};
    private isSaved : boolean = false;

    constructor(
        @Inject(TIERAPICalls) private apicall : TIERAPICalls,
        @Inject(TIERToast) private alert : TIERToast,
        @Inject(NgbModal) private modalService : NgbModal,
        @Inject(StateService) private state : StateService,
        @Inject(Transition) private trans : Transition,
        @Inject(TIERMenuSignals) private menusig : TIERMenuSignals,
        @Inject(TIERDialog) private dialog : TIERDialog) {}

    ngOnInit(): void {
        if(!this.id)
            return;

        this.apicall.get<CompleteSessionResponseModal>('api/complete/%s'.formUri(this.id)).subscribe({
            next: (response : CompleteSessionResponseModal) => {
                this.completeDate = response.CompleteDate!;
                this.badSkills = response.MembersNotInRoleForSkills!;
                this.generateMatrix(response.CompleteSessionModules!);
            },
            error: (error) => {
                this.alert.error(http2Error(error));
            }
        });
    }

    public generateMatrix(modules : CompleteSessionModuleModal[]) {
        modules.forEach((module) => {
            if (isNorU(module.FullName)) {
                this.alert.error("Unable to get users fullname.");
                return;
            }

            if (isNorU(this.matrix.data[module.FullName!]))
                this.matrix.data[module.FullName!] = [];

            this.matrix.data[module.FullName!].push({
                id: module.Id,
                skillModuleId: module.SkillModuleId,
                skillName: module.SkillName,
                assessmentBased: module.AssessmentBased,
                status: module.Status,
                comments: module.Comments
            });
        });

        let assessmentBasedEntries : any = (Object.values(this.matrix.data)[0] as any).filter((value : any) => value.assessmentBased === true);
        if(!isNorU(assessmentBasedEntries))
             assessmentBasedEntries.forEach((entry : any) => this.matrix.headers.push({ id: entry.skillModuleId, name: entry.skillName }));
    };

    public markColumn(id : number | undefined, event : any) {
        if(!id)
            return;

        Object.values(this.matrix.data).forEach((module : any) =>
            module.filter((value : any) => value.assessmentBased === true && value.skillModuleId === id).forEach((entry : any) => entry.status = event.target.checked)
        );
    }

    public markAll(event : any) {
       Object.values(this.matrix.data).forEach((module : any) =>
            module.filter((value : any) => value.assessmentBased === true).forEach((entry : any) => entry.status = event.target.checked)
        );
    }

    public openCommentModal(skill : any) {
        const modalRef = this.modalService.open(SessionsCompleteCommentsComponent);

        modalRef.componentInstance.commentText = skill.comments;

        modalRef.result.then((result) => {
            skill.comments = result;
        }).catch(() => {});
    }

    private showUnloadWarning() : Promise<boolean> {
        return this.dialog.showConfirmDialog("Are you sure?", "Assessable modules need to be ticked on this screen if the assessed person has passed. " +
                        "Failure to do this means their assessment has been classed as failed. Attendence based skills still need " +
                        "to be submitted by clicking confirm. Are you sure you want to navigate away from this page?").then(
            () => { return Promise.resolve(true); }
        ).catch(
            () => { return Promise.resolve(false) }
        );
    }

    public save() {
        if(this.matrix.headers.length > 0) {
            this.showUnloadWarning().then((result : boolean) => {
                if(result)
                    this._save();
            })
        } else {
            this._save();
        }
    }

    private _save() {
        let toBeSavedModules : any = [];

        if(!this.id)
            return;

        Object.values(this.matrix.data).forEach((modules : any) =>
            modules.forEach((module : any) =>
                toBeSavedModules.push({
                    Id: module.id,
                    Obtained: this.completeDate,
                    Status: module.assessmentBased === true ? module.status === null ? false : module.status : null,
                    Comments: module.comments
                })
            )
        );

        this.apicall.put<CompleteSessionResponseModal>('api/complete/', {
            Id: this.id,
            CompleteSessionModules: toBeSavedModules,
            IsComplete: true
        }).subscribe({
            next: () => {
                this.menusig.refresh();
                this.isSaved = true;
                this.state.go('dashboard');
            },
            error: (error) => {
                this.alert.error(http2Error(error));
            }
        });
    };

    public uiCanExit() {
        if(!this.isSaved)
            return this.showUnloadWarning();

        return true;
    }
}

