import { Component, Input, OnInit, forwardRef } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from "@angular/forms";

import { isNorU } from 'src/tier/tier.utils';

@Component({
    selector: 'tierfilterdropdown',
    template: `
        <div class="row">
            <label for="search" class="col-sm-3 col-form-label fw-bold" [textContent]="label"></label>
            <div class="col-sm-9">
                <tierdropdown
                    [source]="source"
                    [placeholder]="placeholder"
                    (clear)="reset()"
                    [(ngModel)]="selectedId"
                    (ngModelChange)="updateChanges($event)"
                    [bindId]="bindId"
                    [bindValue]="bindValue"
                    [clearable]="clearable"
                    [httpparams]="httpparams"
                    [objectList]="objectList">
                </tierdropdown>
            </div>
        </div>`,
    providers: [{
        provide: NG_VALUE_ACCESSOR,
        useExisting: forwardRef(() => TIERFilterDropdownComponent),
        multi: true
    }]
})
export class TIERFilterDropdownComponent implements ControlValueAccessor, OnInit {
    @Input() label : string | null = null;
    @Input() source : any = null;
    @Input() placeholder : string = '';
    @Input() httpparams : any = null;
    @Input() objectList : boolean = false;

    @Input() bindId : string = 'Id';
    @Input() bindValue : string = 'Name';
    @Input() clearable : boolean = false;

    public selectedId : any = null;

    onChange: (_: any) => void = (_: any) => {};
    onTouched: () => void = () => {};

    ngOnInit(): void {
        this.bindValue = this.bindValue ?? 'Name';
    }

    public reset() : void {
        this.updateChanges();
    }

    updateChanges(selected : string | null = null) : void {
        this.onChange(selected);
    }

    writeValue(selected: any): void {
        this.selectedId = isNorU(selected) ? null : selected;
    }

    registerOnChange(fn: any): void {
        this.onChange = fn;
    }

    registerOnTouched(fn: any): void {
        this.onTouched = fn;
    }
}
