'use strict'

import { Component, Input, Output, EventEmitter, OnInit, Inject, forwardRef } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from "@angular/forms";

import { TIERAPICalls, TIERToast } from '../../services';
import { RoleModel } from '../../models/';
import { http2Error } from 'src/tier/tier.utils';

@Component({
    selector: 'tier-roleselector',
    template: ` <div class="row col-sm-12 form-text mb-3" *ngIf="!readonly">Please select roles</div>
                <div class="row col-sm-12">
                    <div class="col-sm-6 form-check" *ngFor="let role of roles;">
                        <input class="form-check-input" (click)="tickBoxClicked(role)" [checked]="state[role.Id!]" [disabled]="readonly" type="checkbox">
                        <label class="form-check-label">
                            {{role.Name}}
                        </label>
                    </div>
                </div>`,
    providers: [{
        provide: NG_VALUE_ACCESSOR,
        useExisting: forwardRef(() => RoleSelectorComponent),
        multi: true
    }],
})
export class RoleSelectorComponent implements OnInit, ControlValueAccessor {
    @Input() readonly : boolean = false;
    @Output() clean : EventEmitter<any> = new EventEmitter();

    @Input() bindId : string = "RoleId";
    @Input() returnObject : boolean = false;

    public roles : RoleModel[] = [];
    public state : any = {};

    private userRoles : any[] = [];

    onChange: (_: any) => void = (_: any) => {};
    onTouched: () => void = () => {};

    constructor(
        @Inject(TIERAPICalls) private apicall : TIERAPICalls,
        @Inject(TIERToast) private alert : TIERToast) {}

    ngOnInit(): void {
        this.apicall.get<RoleModel[]>('/api/roles').subscribe({
            next: (response : RoleModel[]) => {
                this.roles = response;
            },
            error: (error) => {
                this.alert.error(http2Error(error));
            }
        });

        this.clean.subscribe({
            next: () => {
                this.blankAll();
            }
        })

        this.createState();
    }

    updateChanges() : void {
        this.onChange(this.userRoles);
        this.createState();
    }

    writeValue(selected : any): void {
        this.userRoles = selected ?? [];
        this.createState();
    }

    registerOnChange(fn: any): void {
        this.onChange = fn;
    }

    registerOnTouched(fn: any): void {
        this.onTouched = fn;
    }

    public tickBoxClicked(role : RoleModel) {
        if(role?.Id) {
            if(this.state[role.Id] === true) {
                let index = this.userRoles.findIndex((state) => state[this.bindId] === role.Id);
                if(index === -1) return;

                this.userRoles.splice(index, 1);
            } else {
                this.state[role.Id] = true;
                this.userRoles.push(this.returnObject === true ? role : { [this.bindId]: role.Id });
            }
        }

        this.updateChanges();
    }

    private createState() {
        this.blankAll();

        this.userRoles.forEach((item) => {
            this.state[item[this.bindId]] = true;
        })
    }

    private blankAll() {
        this.state = {};
    }
}
