'use strict'

import { Component, Input, Inject, OnInit } from '@angular/core';
import { TeammembersModel } from 'src/tier/models';
import { TIERProfilePicture, TIERToast } from '../../../services';

import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { isNorU, http2Error } from 'src/tier/tier.utils';

@Component({
    selector: 'tier-teammemberslisting',
    template: `
        <div class="modal-body card m-0 p-0" (click)="activeModal.dismiss()">
            <div class="card-body">
                <ul class="list-group">
                    <li class="list-group-item" *ngFor="let teammember of teammembers">
                        <img class="small-image img-thumbnail me-2" [src]="ppUrls[teammember.Id!]"/>
                        <span [textContent]="teammember[bindname]"></span>
                    </li>
                </ul>
            </div>
        </div>`
})
export class TIERTeammembersListingComponent implements OnInit {
    @Input() teammembers : TeammembersModel[] = [];
    @Input() bindname : keyof TeammembersModel = "FullName";

    public ppUrls : { [key : string] : string  } = {};

    constructor(@Inject(NgbActiveModal) public activeModal : NgbActiveModal,
                @Inject(TIERProfilePicture) private pp : TIERProfilePicture,
                @Inject(TIERToast) private alert : TIERToast)
        {}

    ngOnInit(): void {
        this.teammembers.forEach((teammember) => {
            if(!isNorU(teammember.ProfilePicture)) {
                this.pp.get(teammember.Id ?? null).subscribe({
                    next: (url : string) => {
                        this.ppUrls[teammember.Id!] = url;
                    },
                    error: (error) => {
                        this.alert.error(http2Error(error));
                    }
                });
            } else {
                this.ppUrls[teammember.Id!] = this.pp.getDefaultPic();
            }
        })
    }
}
