import { Directive, ElementRef, Inject } from '@angular/core';
import { DOCUMENT } from '@angular/common';

@Directive({
    selector: '[TIERMaximize]',
    exportAs: 'TIERMaximize'
})
export class TIERMaximizeDirective {
    constructor(
        @Inject(ElementRef) private element: ElementRef,
        @Inject(DOCUMENT) private doc: Document) { }

    get isFullscreen(): boolean {
        return this.doc.fullscreenElement !== null;
    }

    public toggle() : void {
        if(this.isFullscreen) {
            this.exit();
            return;
        }
        this.enter();
    }

    public async enter() {
        try {
            await this.element.nativeElement.requestFullscreen();
        } catch (e: any) {
            console.log(e);
        }
    };

    public async exit() {
        try {
            await this.doc.exitFullscreen();
        } catch(e: any) {
            console.log(e);
        }
    };
}
