'use strict'

import { Component, Inject, Input } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { TIERDialog } from '../../services/';
import { IncidentModel } from 'src/tier/models';

@Component({
    selector: 'tier-concludeincident',
    template: `<div class="modal-header">
        <h3 class="modal-title">Incident Completion</h3>
    </div>
    <div class="modal-body">
        <form #conclusionForm="ngForm" name="conclusionForm" novalidate>
            <div class="form-group">
                <label class="form-label" for="description">Incident Completion</label>
                <textarea id="description" #description="ngModel" name="description" class="form-control" rows="3" [class.is-invalid]="description.invalid" [(ngModel)]="incident.Conclusion" minlength="6" required></textarea>
                <p [hidden]="description.valid" class="form-text text-danger">Incident Completion must be over 6 characters</p>
            </div>
        </form>
    </div>
    <div class="modal-footer">
        <div>
            <button type="button" class="btn btn-secondary me-2"
                    (click)="this.activeModal.dismiss()">
                Cancel
            </button>
            <button type="button" class="btn btn-primary"
                    (click)="confirm()"
                    [disabled]="conclusionForm.invalid">
                Confirm
            </button>
        </div>
    </div>`
})
export class ConcludeIncidentComponent {
    @Input() incident : IncidentModel = {};

    constructor(
        @Inject(NgbActiveModal) public activeModal : NgbActiveModal,
        @Inject(TIERDialog) private dialog : TIERDialog) {}

    public confirm() {
        let close = (cs : boolean) => this.activeModal.close({ incident: this.incident, status: cs });

        if(!this.incident.HasConclusion) {
            this.dialog.showConfirmDialog("Print Conclusion", "This is the first time concluding this incident. Do you wish to print the conclusion form?").then(() => close(true), () => close(false)).catch(() => {});
        } else {
            close(false);
        }
    }
}
