import { Input, forwardRef, Directive, OnChanges, SimpleChanges } from '@angular/core';
import { NG_VALIDATORS, Validator, AbstractControl, ValidationErrors } from '@angular/forms';

import { isNorU } from 'src/tier/tier.utils';

@Directive({
  selector: '[DuplicateEntryValidation]',
  providers: [
    {
      provide: NG_VALIDATORS,
      useExisting: forwardRef(() => ContainsDuplicateEntryDirective),
      multi: true,
    },
  ]
})
export class ContainsDuplicateEntryDirective implements Validator, OnChanges {
    @Input('DuplicateEntryValidation') source : any[] | null | undefined  = [];
    @Input('BindId') bindId : string = 'Id';

    onChange: () => void = () => {};

    ngOnChanges(changes: SimpleChanges): void {
        if(!isNorU(changes['source']?.currentValue) && !changes['source'].isFirstChange())
            this.source = changes['source'].currentValue;

        this.onChange();
    }

    validate(control: AbstractControl): ValidationErrors | null {
        if(isNorU(control.value) || isNorU(this.source))
            return null;

        let errors : any[] = [];
        control.value.forEach((entry : any) => {
            this.source!.forEach(source => {
                if(source[this.bindId] === entry[this.bindId])
                    errors.push({ 'DuplicateEntryValidation': { value: this.source } });
            });
        });

        if(errors.length > 0)
            return errors;

        return null;
    }

    registerOnValidatorChange(fn: () => void): void {
        this.onChange = fn;
    }
}
