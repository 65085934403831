'use strict'

import { Component, OnInit } from '@angular/core';
import { WidgetBase } from '../../Widget.base';
import { http2Error } from 'src/tier/tier.utils';

@Component({
    selector: 'tier-widget-teamadmin-teammemberbyrolewidget',
    template: `
    <tierspinner [name]="getUUID()"></tierspinner>
    <div>
        <table [hidden]="!mainTable" class="table table-borderless table-hover table-condensed">
            <thead>
                <tr>
                    <th>Role</th>
                    <th>Count</th>
                </tr>
            </thead>
            <tbody>
                <tr *ngFor="let role of roles">
                    <td><a class="link-primary link-offset-2 link-underline-opacity-25 link-underline-opacity-100-hover" role="button" (click)="showTeamMembers(role.RoleId, role.RoleName)">{{role.RoleName}}</a></td>
                    <td>{{role.UserCount}}</td>
                </tr>
            </tbody>
        </table>
        <div [hidden]="mainTable" (click)="fetchRoles()">
            <div class="widgetchildheader">
                <span>{{selectedRoleName}}</span>
            </div>
            <table class="table table-borderless table-hover table-condensed">
                <thead>
                    <tr>
                        <th>Team Member</th>
                        <th>Status</th>
                    </tr>
                </thead>
                <tbody>
                    <tr *ngFor="let teamMember of roleTeamMembers"
                        [ngClass]="{
                            'table-success': teamMember.IsExpired === false,
                            'table-danger': teamMember.IsExpired === true || teamMember.Expires === null}">
                        <td>{{teamMember.FullName}}</td>
                        <td *ngIf="teamMember.IsExpired === false">All skills are valid</td>
                        <td *ngIf="teamMember.IsExpired === true">Role contains expired critical skills</td>
                    </tr>
                </tbody>
            </table>
        </div>
    </div>`,
    styleUrls: [ '../../_widgets.scss' ]
})
export class TeamMembersByRoleWidgetComponent extends WidgetBase implements OnInit {
  static readonly widgetName = 'TeamMembersByRoleWidgetComponent';
    public roles : any[] = [];
    public roleTeamMembers : any[] = [];
    public selectedRoleName : string | null = null;
    public mainTable: boolean = false;

    ngOnInit(): void {
      this.fetchRoles();
    }

    public fetchRoles() : void {
      this.callAPI<any[]>('api/dashboard/teammembersbyrole', this.getUUID()).subscribe({
        next: (response : any[]) => {
          this.roles = response;

          if(this.roles.length <= 0)
            this.switchToNoDataWidget();

          this.mainTable = true;
        },
        error: (error) => {
            this.alert.error(http2Error(error));
        }
      });
    }

    public showTeamMembers(id : Number, roleName: string) : void {
      this.callAPI('api/dashboard/teammembersbyroledetail/%s'.formUri(id), this.getUUID()).subscribe({
            next: (response) => {
              this.roleTeamMembers = response as Array<object>;
              this.selectedRoleName = roleName;
              this.mainTable = false;
            },
            error: (error) => {
                this.alert.error(http2Error(error));
            }
        });
    }
}
