import { Component, OnInit, Inject, Input, EventEmitter, Output } from '@angular/core';
import { HttpParams } from '@angular/common/http';
import { Observable, forkJoin } from 'rxjs';
import { StateService, Transition } from '@uirouter/angular';

import { TIERAPICalls, TIERToast, TIERAuth, TIEREquipmentStack } from '../../services';
import { EquipmentResourceListingModel, EquipmentResourceModel } from '../../models';
import { http2Error, calcUUID, isNorU } from 'src/tier/tier.utils';

@Component({
    selector: 'tier-equipment-resources',
    templateUrl: "./equipmentresources.template.html",
    styleUrl: './equipmentresources.component.scss'
})
export class EquipmentResourcesComponent implements OnInit {
    @Input({ required: true }) viewLink! : string;
    @Input() selectLink : string | null = null;
    @Input() checkout : boolean = false;
    @Input() newLink : string  | null = null;
    @Input() filterConfig : object[] | null = null;
    @Input() refresh : EventEmitter<any> = new EventEmitter();
    @Input() containerCheckInText : string = 'Checkin Container';
    @Input() contrainerCheckOutText : string = 'Checkout Container'

    @Output() itemChoosen : EventEmitter<EquipmentResourceModel> = new EventEmitter();

    private defaultFilterConfig = [
        {
            name: calcUUID(),
            formControl: "search",
            label: "Search Text",
            value: "search"
        },
        {
            name: calcUUID(),
            label: "Allocated To",
            formControl: "dropdown",
            placeHolder: "Select team member",
            source: 'api/teammembers',
            value: "teammemberId",
            bindValue: 'FullName'
        },
        {
            name: calcUUID(),
            label: "Categories",
            formControl: "dropdown",
            placeHolder: "Select Category",
            source: 'api/equipment/template/category',
            value: "categoryId"
        },
        {
            name: calcUUID(),
            label: "Condition",
            formControl: "dropdown",
            placeHolder: "Select Condition",
            source: 'api/equipment/resources/conditiontypes',
            value: "conditionId"
        },
        {
            name: calcUUID(),
            label: "Template",
            formControl: "dropdown",
            placeHolder: "Select Template",
            source: 'api/equipment/templates',
            httpparams: { skip: 0, amount: 100 },
            isObjectList: true,
            value: "templateId"
        },
        {
            name: calcUUID(),
            label: "Check Due",
            formControl: "dropdown",
            placeHolder: "Select Check Due Status",
            source: [{ Id: "all", Name: "All" }, { Id: "valid", Name: "Valid" }, { Id: "expiring", Name: "Expiring" }, { Id: "expired", Name: "Expired" }, { Id: "notchecked", Name: "Not Checked" }],
            value: "lastChecked"
        },
        {
            name: calcUUID(),
            label: "Expiry",
            formControl: "dropdown",
            placeHolder: "Select Expiry",
            source: [{ Id: "all", Name: "All" }, { Id: "valid", Name: "Valid" }, { Id: "expiring", Name: "Expiring" }, { Id: "expired", Name: "Expired" }, { Id: "noexpiry", Name: "No Expiry" }],
            value: "expiry"
        },
        {
            name: calcUUID(),
            label: "Allocated",
            formControl: "dropdown",
            placeHolder: "Select Allocation",
            source: [{ Id: 0, Name: "All" }, { Id: 1, Name: "Allocated" }, { Id: 2, Name: "Not allocated" }],
            value: "allocated"
        },
        {
            name: calcUUID(),
            formControl: "tickbox",
            label: "Show Inactive",
            value: "isActive"
        },
        {
            name: calcUUID(),
            formControl: "tickbox",
            label: "Filter Recursively",
            value: "recursive"
        }
    ];

    public resources : { children: any[], parent: any } = {
        children: [],
        parent: null
    };

    public total : number = 0;
    public page : number = 1;
    public userId : string | undefined = this.auth.getTokenInfo()?.userId ?? undefined;

    public values : any = {
        skip: 0,
        amount: 19,
        parentId: this.trans.params()?.parentId,
        search: this.trans.params()?.search,
        teammemberId: this.trans.params()?.teammemberId,
        categoryId: this.trans.params()?.categoryId,
        conditionId: this.trans.params()?.conditionId,
        templateId: this.trans.params()?.templateId,
        lastChecked: this.trans.params()?.lastChecked,
        expiry: this.trans.params()?.expiry,
        isActive: this.trans.params()?.isActive,
        recursive: this.trans.params()?.recursive,
        allocated: this.trans.params()?.allocated
    };

    public genFooter = (resource : EquipmentResourceModel) => {
        let footer = [];
        let checks = [
            {
                value: resource.AllocatedToId !== null && resource.AllocatedToId !== this.userId,
                icon: "fa-shipping-fast",
                message: "Resource is allocated to " + (resource.AllocatedTo !== null ? resource.AllocatedTo?.FullName : "someone else.")
            },
            {
                value: resource.AllocatedToId === this.userId,
                icon: "fa-shipping-fast",
                message: "Resource is allocated to you."
            },
            {
                value: !resource.CheckedById,
                icon: "fa-ban",
                message: "Item has not been checked."
            },
            {
                value: !resource.IsAllowedToCheckout && this.checkout && resource.EquipmentTemplate?.IsAllocatable,
                icon: "fa-ban",
                message: "Cannot checkout resource."
            },
            {
                value: !resource.EquipmentTemplate?.IsAllocatable,
                icon: "fa-ban",
                message: "Resource is non-allocatable."
            },
            {
                value: resource.CheckDueExpired && resource.CheckedById,
                icon: "fa-shipping-fast",
                message: "Resource Check period has expired."
            },
            {
                value: resource.CheckDueExpiring && !resource.CheckDueExpired,
                icon: "fa-shipping-fast",
                message: "Resource check period is expiring."
            },
            {
                value: resource.ResourceExpired,
                icon: "fa-shipping-fast",
                message: "Resource has expired."
            },
            {
                value: resource.ResourceExpiring && !resource.ResourceExpired,
                icon: "fa-shipping-fast",
                message: "Resource is expiring."
            }];

        checks.forEach((check) => {
            if (check.value)
                footer.push(check);
        });

        if (!footer.length)
            footer.push({ icon: "fa-check", message: "Item is ready to be allocated." });

        return footer;
    };

    constructor(
        @Inject(TIERAPICalls) private apicall : TIERAPICalls,
        @Inject(TIERToast) private alert : TIERToast,
        @Inject(TIERAuth) private auth : TIERAuth,
        @Inject(StateService) private state : StateService,
        @Inject(Transition) private trans : Transition,
        @Inject(TIEREquipmentStack) public moveStack : TIEREquipmentStack,
    ) {}

    ngOnInit(): void {
        this.filterConfig = this.filterConfig ?? this.defaultFilterConfig;

        this.refresh.subscribe({
            next: () => {
                this.get();
            }
        })

        this.get();
    }

    public get() {
        this.values.skip = (this.page - 1) * this.values.amount;

        let request : Observable<any>[] = [
            this.apicall.get<EquipmentResourceListingModel>('api/equipment/resources/layer', new HttpParams({'fromObject': { ...this.values, checkout: this.checkout }})) ];

        if(!isNorU(this.values.parentId))
            request.push(this.apicall.get<EquipmentResourceModel>("api/equipment/resource/%s".formUri(this.values.parentId), new HttpParams({'fromObject': { ...this.values, checkout: this.checkout }})));

        forkJoin(request).subscribe({
            next: (response) => {
                this.resources.children = response[0].ObjectList;
                this.total = response[0].Total;
                this.resources.parent = !isNorU(response[1]) ? response[1] : null;
            },
            error: (error) => {
                this.alert.error(http2Error(error));
            }
        });
    }

    public whenContainerChoosen(resource : EquipmentResourceModel) {
        if(this.viewLink)
            this.state.go( this.viewLink, { 'parentId': resource.Id } );
    }

    public whenItemChoosen(resource : EquipmentResourceModel) {
        if (!this.itemChoosen.observed && this.selectLink) {
            this.state.go(this.selectLink, { 'id': resource.Id });
        } else {
            this.itemChoosen.emit(resource);
        }
    }

    public whenLongPressed(event : any, resource : EquipmentResourceModel) {
        if(this.values.checkout)
            return

        if(event.stopPropagation)
            event.stopPropagation();

        if(event.preventDefault)
            event.preventDefault();

        resource.Selected = !resource.Selected;

        if(resource.Selected) {
            this.moveStack.add(resource);
        } else {
            this.moveStack.remove(resource);
        }
    }

    public clearMoveStack() {
        this.resources.children.forEach((resource) => resource.Selected = false);
        this.moveStack.clear();
    }

    public commitMove() {
        let commit = this.moveStack.commit(this.resources.parent);

        if(commit)
            commit.subscribe({
                next: () => {
                    this.clearMoveStack();
                    this.get();
                },
                error: (error) => {
                    this.alert.error(http2Error(error));
                }
            });
    }
}
