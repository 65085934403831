import { Injectable, Inject, ErrorHandler } from '@angular/core';
import { HttpErrorResponse } from '@angular/common/http';

import { TIERAppInsights, TIERConfig } from './';
import { isNorU } from 'src/tier/tier.utils';

@Injectable({
  providedIn: 'root'
})
export class TIERErrorHandler extends ErrorHandler {

    private isDebug : boolean = false;

    constructor(
        @Inject(TIERAppInsights) private appinsights: TIERAppInsights,
        @Inject(TIERConfig) private config : TIERConfig)
    {
        super();
        this.isDebug = !this.config.get("production");
    }

    handleError(error: any) : void {
        let errorMessage : Error;

        if(isNorU(error))
            return;

        if(error instanceof Error) {
            errorMessage = error;
        } else if(error instanceof HttpErrorResponse && error?.error instanceof Error) {
            errorMessage = error.error;
        } else if(error instanceof HttpErrorResponse && !isNorU(error?.error)) {
            errorMessage = Error('Unknown Error from HTTPErrorResponse: ' + JSON.stringify(error.error));
        } else {
            errorMessage = Error('Unknown Error: ' + JSON.stringify(error));
        }

        if(this.isDebug)
            console.error(errorMessage);

        this.appinsights.logException(errorMessage);
    }
}
