'use strict'

import { Component, OnInit } from '@angular/core';
import { WidgetBase } from '../../Widget.base';
import { http2Error } from 'src/tier/tier.utils';

@Component({
    selector: 'tier-widget-equipmentmanager-equipmentallocationwidget',
    template: `
    <tierspinner [name]="getUUID()"></tierspinner>
    <div *ngFor="let entry of data">
        <b>{{entry.FullName}}</b>
        <table class="table table-borderless table-hover table-condensed">
            <thead>
                <tr>
                    <th>Type</th>
                    <th>Date</th>
                    <th>Reference</th>
                    <th>Condition</th>
                </tr>
            </thead>
            <tbody>
                <tr *ngFor="let details of entry.Entries">
                    <td>
                        <i class="fa-solid fa-arrow-left" *ngIf="contains(details.Type, 'UNALLOCATED')"></i>
                        <i class="fa-solid fa-arrow-right" *ngIf="contains(details.Type, 'ALLOCATED')"></i>
                    </td>
                    <td>{{details.Date | transformdate: 'dd-MM-yyyy'}}</td>
                    <td>{{details.Reference}}</td>
                    <td ng-class="{ 'text-success': details.Condition.IsDefault, 'text-danger': !details.Condition.IsDefault }">{{details.Condition.Name}}</td>
                </tr>
            </tbody>
        </table>
    </div>`,
    styleUrls: [ '../../_widgets.scss' ]
})
export class EquipmentAllocationWidgetComponent extends WidgetBase implements OnInit {
    static readonly widgetName = 'EquipmentAllocationWidgetComponent';
    public data : any[] = [];

    ngOnInit(): void {
        this.callAPI<any[]>('api/dashboard/equipmentallocation', this.getUUID()).subscribe({
            next: (response : any[]) => {
              this.data = response;
              if(this.data.length <= 0)
                this.switchToNoDataWidget();
            },
            error: (error) => {
                this.alert.error(http2Error(error));
            }
        });
    }

    public contains(type : string, match : string) : boolean {
        let ret : boolean = false;

        type.split(',').forEach((iter) => {
            if(iter === match) {
                ret = true;
                return;
            }
        });

        return ret;
    }
}
