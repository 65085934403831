'use strict'

import { Component, Inject } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
    selector: 'tier-management-teammembers-passwordconfirm',
    template: `
            <form #passwordForm="ngForm" role="form" name="passwordForm" (ngSubmit)="confirm()" novalidate autocomplete="none">
                <div class="modal-header">
                    <h4 class="modal-title">Change password</h4>
                </div>
                <div class="modal-body">
                    <div class="row mb-3">
                        <label for="pass" class="col-sm-3 col-form-label fw-bold">Password</label>
                        <div class="col-sm-9">
                            <input #pass="ngModel" minlength="6" type="password" id="pass" name="pass" [ngClass]="{ 'is-invalid': pass.invalid, 'is-valid': pass.valid && !pass.pristine }" class="form-control" [(ngModel)]="password" placeholder="Password" required>
                            <p [hidden]="pass.valid" class="form-text text-danger">Password is required to be over 6 characters.</p>
                        </div>
                    </div>
                    <div class="row mb-3">
                        <label for="con" class="col-sm-3 col-form-label fw-bold">Confirm</label>
                        <div class="col-sm-9">
                            <input #con="ngModel" minlength="6" type="password" id="con" name="con" [ngClass]="{ 'is-invalid': con.invalid, 'is-valid': con.valid && !con.pristine }" class="form-control" [DuplicateField]="pass.value" [(ngModel)]="confirmpassword" placeholder="Confirm Password" required>
                            <p [hidden]="pass.pristine || con.valid" class="form-text text-danger">Please confirm password.</p>
                        </div>
                    </div>
                </div>
            <div class="modal-footer">
                    <button class="btn btn-warning"
                            type="button"
                            (click)="activeModal.dismiss()">
                        Cancel
                    </button>
                    <button class="btn btn-primary"
                            type="submit"
                            [disabled]="passwordForm.invalid">
                        Confirm
                    </button>
                </div>
            </form>`
})
export class TeammembersPasswordConfirmComponent {

    public password : string | null = null;
    public confirmpassword : string | null = null;

    constructor(@Inject(NgbActiveModal) public activeModal : NgbActiveModal) {}

    public confirm() {
        this.activeModal.close(this.password);
    }
}
