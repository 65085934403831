import { Inject, Input, Component } from '@angular/core';
import { GridsterItem } from 'angular-gridster2';
import { WidgetLibrary } from '../shared/decorators/WidgetsLibrary.decorator';
import { ChartType, ChartData } from 'chart.js';
import { NgxSpinnerService } from 'ngx-spinner';
import { Observable } from 'rxjs';
import { finalize } from 'rxjs/operators';

import { TIERAPICalls, TIERHelpers, TIEROptions, TIERToast, SKIP_ROOT_SPINNER } from '../services';
import { HttpContext } from '@angular/common/http';

@Component({
  selector: 'tier-widget-widgetbase',
  template: ''
})
export abstract class WidgetBase {
    @Input() widget!: GridsterItem;

    constructor(
        @Inject(TIERAPICalls) protected apicall : TIERAPICalls,
        @Inject(TIERHelpers) protected help : TIERHelpers,
        @Inject(TIEROptions) protected options : TIEROptions,
        @Inject(TIERToast) protected alert : TIERToast,
        @Inject(NgxSpinnerService) protected spinner: NgxSpinnerService
      ) { }

      protected doughnutChartType : ChartType = 'doughnut';
      protected barChartType : ChartType = 'bar';
      protected pieChartType : ChartType = 'pie';

      protected switchToNoDataWidget() : void {
        this.widget.component = WidgetLibrary.Get('NoDataWidgetComponent');
      }

      protected getUUID() : string {
        return this.widget.UUID;
      }

      protected callAPI<T>(uri : string, name : string) : Observable<T> {
        this.spinner.show(name);
        return this.apicall.get<T>(uri, undefined, undefined, new HttpContext().set(SKIP_ROOT_SPINNER, true)).pipe(
          finalize(() => { this.spinner.hide(name) })
       );
      }

      protected isEmpty(data : ChartData | undefined | null) : boolean {
        if(!data)
            return true;

        if(data.datasets === null)
          return true;

        let aboveone : number = 0;
        data.datasets.forEach((value) => {
          if(value.data !== null) {
            value.data.forEach((innervalue) => {
              if(innervalue as number > 0)
                aboveone++;
            });
          }
        })

        return aboveone <= 0;
      }
}
