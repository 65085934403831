<div>
    <form #roleForm="ngForm" (ngSubmit)="save()" novalidate autocomplete="none" class="col-md-8 offset-md-2">
        <div class="row">
            <label class="col-sm-3 col-form-label fw-bold">Role</label>
            <div class="col-sm-9">
                <tierdropdown
                    name="roledd"
                    source="api/roles"
                    placeholder="Select Role Or Create New"
                    [refresh]="refreshEmitter"
                    [(ngModel)]="selectedRoleId"
                    (ngModelChange)="fetchRole($event)"
                    [allowNew]=true
                    bindId="Id"
                    bindValue="Name"
                    newLabel="Create New Role"
                    [httpparams]="{ ShowInactive: true }">
                </tierdropdown>
            </div>
        </div>
        <hr />
        <div class="row mb-3">
            <label for="name" class="col-sm-3 col-form-label fw-bold">Role Name</label>
            <div class="col-sm-9">
                <input type="text" #name="ngModel" id="name" name="name" class="form-control" placeholder="Role Name" [(ngModel)]="role.Name" [ngClass]="{ 'is-invalid': name.invalid, 'is-valid': name.valid && !name.pristine }" required>
                <p [hidden]="name.valid" class="form-text text-danger">Role name is required.</p>
            </div>
        </div>
        <div class="row mb-3">
            <label class="col-sm-3 col-form-label fw-bold">Skill Modules</label>
            <div class="col-sm-9">
                <tierdropdown
                    name="skilldd"
                    source="api/modules"
                    placeholder="Select Module"
                    (clear)="resetSkill()"
                    [(ngModel)]="role.Skills"
                    [multi]=true
                    bindValue="Name">
                </tierdropdown>
            </div>
        </div>
        <div class="row mb-3">
            <label class="col-sm-3 col-form-label pt-0 fw-bold">Permission Group</label>
            <div class="col-sm-9">
                <div class="form-check" *ngFor="let claim of claims">
                    <input class="form-check-input" type="checkbox" [name]="claim.ClaimName" [(ngModel)]="claim.Checked" (change)="claimClicked(claim)">
                    <label class="form-check-label">
                      {{claim.PresentationName}}
                    </label>
                  </div>
            </div>
        </div>
        <div class="row mb-3">
            <label class="col-sm-3 col-form-label pt-0 fw-bold">Flags</label>
            <div class="col-sm-9">
                <div class="form-check">
                    <input class="form-check-input" name="OperationalFlag" type="checkbox" [(ngModel)]="role.OperationalFlag">
                    <label class="form-check-label">
                        Operational
                    </label>
                </div>
                <div class="form-check">
                    <input class="form-check-input" name="IncidentFlag" type="checkbox" [(ngModel)]="role.IncidentFlag">
                    <label class="form-check-label">
                        Incident
                    </label>
                </div>
            </div>
        </div>
        <div class="row mb-4">
            <div class="offset-sm-3 text-end col-sm-9">
                    <button
                        type="button"
                        class="btn btn-success me-2"
                        (click)="delete()"
                        [hidden]="!role.Id"
                        [ngClass]="{'btn-success': role.IsActive === true, 'btn-danger': role.IsActive === false}"
                        [textContent]="role.IsActive ? 'Active' : 'Inactive'"
                        [disabled]="roleForm.invalid">
                    </button>
                    <button type="submit" class="btn btn-primary" [disabled]="roleForm.invalid || roleForm.pristine">
                        Save
                    </button>
            </div>
        </div>
    </form>
</div>
