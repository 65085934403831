
import { Component, Input, Output, EventEmitter, Inject } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

import { VenueModel } from 'src/tier/models';

@Component({
    selector: 'tier-mapping-modal',
    template: `
        <div class="modal-body">
            <tier-mapping [(location)]="location" [zoom]="this.zoom" [permission]="permission" [tooltip]="true"></tier-mapping>
        </div>
        <div class="modal-footer">
            <div>
                <button type="button"
                        class="btn btn-primary me-2"
                        (click)="this.activeModal.close()"
                        ng-disabled="!marker()"
                        ng-hide="isReadOnly === true">
                    Ok
                </button>
                <button type="button"
                        class="btn btn-warning"
                        (click)="this.activeModal.dismiss()">
                    Close
                </button>
            </div>
        </div>`,
})
export class TIERMappingModalComponent {
    @Input() zoom : number | string | undefined = undefined;

    @Input() location : VenueModel | null | undefined = null;
    @Output() locationChange : EventEmitter<VenueModel | null | undefined > = new EventEmitter<VenueModel | null | undefined>();
    @Input() permission : string = 'r';

    constructor(@Inject(NgbActiveModal) public activeModal : NgbActiveModal) {}
}
