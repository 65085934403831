import { Component, Input, Inject } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
    selector: 'tiertypedropdownmodal',
    template: `
        <div class="modal-header">
            <h3 class="modal-title" [textContent]="label"></h3>
        </div>
        <div class="modal-body">
            <div class="row mb-3">
                <label for="typename" class="col-sm-3 col-form-label fw-bold">Name</label>
                <div class="col-sm-9">
                    <input type="text" #mname="ngModel" id="typename" name="typename" class="form-control" placeholder="Type Name" [(ngModel)]="typename" [class.is-invalid]="mname.value?.length < 3">
                    <p [hidden]="mname.value?.length >= 3" class="form-text text-danger">Valid name is required and must be over 3 characters.</p>
                </div>
            </div>
        </div>
        <div class="modal-footer">
            <button class="btn btn-secondary"
                    type="button"
                    (click)="this.activeModal.dismiss()">
                Cancel
            </button>
            <button class="btn btn-primary"
                    type="button"
                    [disabled]="mname.value?.length < 3"
                    (click)="confirm()">
                Confirm
            </button>
        </div>`,
})
export class TIERTypeDropdownModalComponent {
    @Input() label : string = "None";
    public typename : string | null = null;

    constructor(@Inject(NgbActiveModal) public activeModal : NgbActiveModal) {}

    public confirm() {
        this.activeModal.close(this.typename);
    }
}
