<div class="privacy">
    <h1>T.I.E.R Privacy Notice</h1>
    <h2>Who are we</h2>
    <p>
        T.I.E.R is a training, incident, equipment and reporting service (“service”) available through <a href="https://sartier.co.uk">https://sartier.co.uk</a>. It has been designed in conjunction with emergency and humanitarian agencies, developed by Caradox Ltd. Registered Company Number 12387641 (“us”, “we”, “our”).
        This Privacy Policy sets out the basis on which the personal data collected from you, or that you provide to us will be processed by us. Please read the following carefully to understand our views and practices regarding your personal data and how we will treat it.
        We are registered with the UK ICO, registration number <a href="https://ico.org.uk/ESDWebPages/Entry/ZA221691" target="_blank">ZA221691</a>
    </p>
    <h2>What we record</h2>
    <p>We store the information required by your organisation to manage and record your training records. Most of the information below is optional regarding using the service.</p>
    <p>Your name and email address is the only information required by the system in order to support your account. Your organisation may require other information to be held about you and that is the organisations decision about how it operates.</p>
    <p>When your organisation signs you up to the service, we also capture a set of dates around when you join and leave as well as other milestone dates. Once a leave date it entered, you will be locked out of the system and all your personal data, except for your name, will be deleted from the system after 90 days.</p>
    <p>We also store some information about your device used to access this service such as browser and client information and country, county and city of where you are accessing the service from. This is only used to ensure the service works correctly across the many different devices and the service is being provided in a timely manner from different locations. This data will also be used to allow us to replicate errors and slowness in the TIER service.</p>
    <h2>What we will do with your information</h2>
    We store the organisations information about you around what roles you perform within the organisation as well as your training record. This is to enable the organisation to assure your readiness to perform your roles.
    <h2>Where we will store your information</h2>
    TIER stores the data in a datacentre in the United Kingdom. We will not sell your information with any third parties, at all, ever.
    The data is protected by advanced threat detection capabilities and is encrypted at rest and in transit.
    <h2>Disclosure of information</h2>
    We may disclose your information to third parties where we are required to do so to comply with the law and any legal obligation we have under those laws. We may also disclose your information for providing third party services to you through our service, such as sending a text message to your mobile number via a third party.
    <h2>Access to information</h2>
    You have a right to access the information we hold on you; your personal details and current training record is held in the service and is available to you at any time from your profile page. Your training history records are held in the service and are accessible by your organisation. Your organisation can provide these to you upon request. You also have a right to request this information from us and you can do so by sending an email to <a href="mailto:support@sartier.co.uk">support&#64;sartier.co.uk</a>, please note and access requests received by us may be subject to a processing fee of no more than £10.
    <h2>Updates</h2>
    We may modify this notice at any time. Any changes we make will be made available through our service.
    If you have any queries, please contact us via <a href="mailto:enquiries@sartier.co.uk">enquiries&#64;sartier.co.uk</a>
</div>
