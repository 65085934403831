'use strict'

import { Component, Inject, OnInit } from '@angular/core';
import { Observable } from 'rxjs';

import { ScheduleModel } from 'src/tier/models';
import { TIERAPICalls, TIERToast } from '../../services';
import { http2Error } from 'src/tier/tier.utils';

@Component({
    selector: 'tier-training-schedule-list',
    templateUrl: './schedulelist.template.html'
})
export class ScheduleListComponent implements OnInit{
    public sessions : ScheduleModel[] = [];
    public searchText : string | undefined = undefined;

    constructor(
        @Inject(TIERAPICalls) private apicall : TIERAPICalls,
        @Inject(TIERToast) private alert : TIERToast
    ) { }

    ngOnInit(): void {
        this.getEvents();
    }

    public getEvents() {
        this.apicall.get<ScheduleModel[]>('api/schedule/').subscribe({
            next: (response : ScheduleModel[]) => {
                this.sessions = response;
            },
            error: (error) => {
                this.alert.error(http2Error(error));
            }
        });
    }

    public attend(id : number, isAttending : boolean = false) {
        let call : Observable<object> = this.apicall.put<object | null>('api/schedule/%s/attend'.formUri(id), null);

        if(isAttending)
            call = this.apicall.delete<object>('api/schedule/%s/attend'.formUri(id));

        call.subscribe({
            next: () => {
                this.getEvents();
            },
            error: (error) => {
                this.alert.error(http2Error(error));
            }
        });
    }

}
