import { Component, Input } from '@angular/core';
import { animate, state, style, transition, trigger } from '@angular/animations';

@Component({
    selector: 'tierpanelslider',
    template: ` <div class="panelslider" [@slide]="isOpen">
                <ng-content></ng-content>
                </div>`,
    styleUrls: [ "./TIERPanelSlider.component.scss" ],
    animations: [
        trigger('slide', [
          state('false', style({
            transform: 'translateX(-350px)'
          })),
          state('true', style({
            transform: 'translateX(0)'
          })),
          transition('false => true', [
            animate('300ms ease-out')
          ])
        ])
      ]
})
export class TIERPanelSliderComponent {
    @Input({required: true}) public isOpen : boolean = false;
}
