<div class="d-print-none mb-3 d-flex justify-content-end">
    <div>
        <div class="form-group">
            <input type="text" class="form-control"
                placeholder="Search..."
                [(ngModel)]="search"
                minlength="2"
                maxlength="20">
        </div>
    </div>
</div>
<div *ngIf="report.length">
    <table class="table">
        <thead TIERCustomTableHeader [format]="[
                                    { id: 'Name', name: 'Team Member' },
                                    { id: 'SkillName', name: 'Module' },
                                    { id: 'AssessmentBased', name: 'Type' },
                                    { id: 'IsCritical', name: 'Critical?' },
                                    { id: 'Assessed', name: 'Assessment Date' },
                                    { id: 'Expires', name: 'Assessment Expires' },
                                    { id: 'Status', name: 'Passed?' }]"
               [(table)]="report">
        </thead>
        <tbody>
            <tr *ngFor="let assess of report | search: search" [ngClass]="{ 'table-danger': assess.IsExpired || (assess.Status === false && assess.AssessmentBased === true), 'table-warning': assess.IsExpiring && !assess.IsExpired }">
                <td>{{assess.Name}}</td>
                <td>{{assess.SkillName}}</td>
                <td *ngIf="assess.AssessmentBased === true"><span class="badge text-bg-primary">Assessment</span></td>
                <td *ngIf="assess.AssessmentBased === false"><span class="badge text-bg-primary">Attendence</span></td>
                <td><i class="fa-solid" [ngClass]="{ 'fa-check': assess.IsCritical, 'fa-times': !assess.IsCritical }"></i></td>
                <td>{{(assess.Assessed | transformdate: 'dd-MM-yyyy')}}</td>
                <td>{{(assess.Expires | transformdate: 'dd-MM-yyyy') || 'N/A'}}</td>
                <td><i class="fa-solid" [ngClass]="{ 'fa-check': assess.Status === true, 'fa-times': assess.Status === false && assess.Type === true }"></i></td>
            </tr>
        </tbody>
    </table>
    <div class="d-flex justify-content-center my-3 d-print-none" *ngIf="params.Amount">
        <ngb-pagination [collectionSize]="total" [(page)]="params.Page" [pageSize]="params.Amount" [maxSize]="5" [rotate]="true" [ellipses]="false" [boundaryLinks]="true" (pageChange)="reload()" />
    </div>
</div>
<div class="alert alert-warning" role="alert" [hidden]="report.length">
    <i class="fa-solid fa-exclamation-circle"></i> 0 results found.
</div>

<TIERReportButtons (print)="print()" (export)="export('TrainingReport')"></TIERReportButtons>
