<div>
    <tierfilter class="mb-3" [config]="filterConfig" [(data)]="values" (fetch)="get()"></tierfilter>
</div>

<div class="hstack mb-3">
    <div class="ms-auto">
        <button type="button" class="btn btn-info" (click)="new()" [disabled]="!isAdminOrTrainingAdmin">Create New Session</button>
    </div>
</div>

<div [hidden]="!sessions.length">

    <div class="mb-4">
        <div class="mb-1 fw-bold">
            Colour Key:
        </div>
        <div class="hstack ms-4">
            <div class="me-2 hstack">
                <div class="text-bg-danger rounded-2 p-2 me-2">
                </div>
                <div>
                    <span>Session out-of-sync</span>
                </div>
            </div>
            <div class="me-2 hstack">
                <div class="text-bg-secondary rounded-2 p-2 me-2">
                </div>
                <div>
                    <span>Suggestion</span>
                </div>
            </div>
            <div class="me-2 hstack">
                <div class="text-bg-info rounded-2 p-2 me-2">
                </div>
                <div>
                    <span>Completed session</span>
                </div>
            </div>
        </div>
    </div>

    <div class="table-responsive">
        <table class="table">
            <thead>
                <tr>
                    <th></th>
                    <th *ngFor="let header of headers" (click)="changeOrder(header)" [ngClass]="{ 'text-center': header.centered === true, 'cursor-link' : header.orderable === true }">
                        {{header.name}}
                        <i class="fa-solid" [ngClass]="
                        {
                            'fa-caret-down': values.column === header.name && values.direction === 'asc',
                            'fa-caret-up': values.column === header.name && values.direction === 'desc',
                            'cursor-link' : header.orderable === true
                        }">
                        </i>
                    </th>
                </tr>
            </thead>
            <tbody>
                <tr *ngFor="let session of sessions" [ngClass]="{ 'table-info': session.IsComplete === true, 'table-active': session.Type === 'SUGGESTION', 'table-danger': session.hasUnmarkedSkills === true }">
                    <td class="text-center">
                        <button  type="button" *ngIf="session.Type === 'SESSION'" (click)="edit(session.Id)" class="btn btn-primary btn-sm">
                            <i class="fa-solid fa-pencil-alt"></i>
                        </button>
                        <button type="button" *ngIf="session.Type === 'SUGGESTION'" (click)="newSuggestion(session)" class="btn btn-info btn-sm" [disabled]="session.IsComplete === true && isAdmin === false">
                            <i class="fa-solid fa-plus"></i>
                        </button>
                    </td>
                    <td>
                        <tier-telltime [from]="session.StartDate" [to]="session.EndDate"></tier-telltime>
                    </td>
                    <td *ngIf="session.URL"><a [href]="session.URL" target="_blank" [textContent]="session.Title"></a></td>
                    <td *ngIf="!session.URL" [textContent]="session.Title"></td>
                    <td>{{session.Venue || 'N/A'}}</td>
                    <td>
                        <div *ngFor="let skillModule of session.SkillModules">
                            <tier-skilltooltip [skill]="skillModule"></tier-skilltooltip>
                        </div>
                        <span class="badge text-bg-secondary" *ngIf="session.SkillModules?.length === 0">
                            No Modules
                        </span>
                    </td>
                    <td>
                        <tier-teammembersbadge [teammembers]="session.Attendees" bindname="FullName"></tier-teammembersbadge>
                    </td>
                    <td class="text-center">
                        <i class="fa-solid" [ngClass]="{ 'fa-check': session.IsActive, 'fa-times': !session.IsActive }"></i>
                    </td>
                </tr>
            </tbody>
        </table>
    </div>

    <div class="d-flex justify-content-center my-3">
        <ngb-pagination [collectionSize]="total" [(page)]="page" [pageSize]="values.amount" [maxSize]="15" [rotate]="true" [ellipses]="false" [boundaryLinks]="true" (pageChange)="get()" />
    </div>

</div>

<div class="alert alert-info" role="alert" [hidden]="sessions.length">
    <i class="fa-solid fa-exclamation-circle"></i> 0 results found.
</div>
