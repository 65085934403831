<div class="modal-header">
    <h3 class="modal-title">Add Incident Subject</h3>
</div>
<div class="modal-body">

    <div class="alert alert-warning" role="alert" [hidden]="entry.NoInjuries || bodyPartLength() || entry.IncidentGlobalInjuries?.length">
        You must select a global injury, an injury or select the No Injuries checkbox.
    </div>

    <form name="subjectAddForm" #subjectAddForm="ngForm" novalidate autocomplete="none">
        <div class="row mb-3">
            <label for="incidentsubjecttype" class="col-sm-3 col-form-label fw-bold">Subject Type</label>
            <div class="col-sm-9">
                <tiertypedropdown
                    id="incidentsubjecttype"
                    name="incidentsubjecttype"
                    placeholder="Select Subject Type"
                    url="api/incidentsubjecttype"
                    label="Subject Type"
                    deleteId="Id"
                    [(ngModel)]="entry.IncidentSubjectType"
                    (ngModelChange)="bindId($event)">
                </tiertypedropdown>
            </div>
        </div>
        <div class="row mb-3">
            <label for="firstname" class="col-sm-3 col-form-label fw-bold">First Name</label>
            <div class="col-sm-9">
                <input type="text" id="firstname" name="firstname" class="form-control" placeholder="First Name" [(ngModel)]="entry.FirstName">
            </div>
        </div>
        <div class="row mb-3">
            <label for="lastname" class="col-sm-3 col-form-label fw-bold">Last Name</label>
            <div class="col-sm-9">
                <input type="text" id="lastname" name="lastname" class="form-control" placeholder="Last Name" [(ngModel)]="entry.LastName">
            </div>
        </div>
        <div class="row mb-3">
            <label class="col-sm-3 col-form-label fw-bold">Gender</label>
            <div class="col-sm-9">
                <div class="radio">
                    <label>
                        <input type="radio" name="sex" [(ngModel)]="entry.Sex" id="male" value="Male">
                        Male
                    </label>
                </div>
                <div class="radio">
                    <label>
                        <input type="radio" name="sex" [(ngModel)]="entry.Sex" id="female" value="Female">
                        Female
                    </label>
                </div>
                <div class="radio">
                    <label>
                        <input type="radio" name="other" [(ngModel)]="entry.Sex" id="other" value="Other">
                        Other
                    </label>
                </div>
            </div>
        </div>
        <div class="row mb-3">
            <label for="nationaldd" class="col-sm-3 col-form-label fw-bold">Nationality</label>
            <div class="col-sm-9">
                <tierdropdown
                        id="nationaldd"
                        name="nationaldd"
                        source="api/nationalities"
                        [(ngModel)]="entry.NationalityId"
                        [allowNew]=false
                        bindId="Id"
                        bindValue="Country"
                        placeholder="Select Nationality"
                        [isNumber]="true">
                    </tierdropdown>
            </div>
        </div>
        <div class="row mb-3">
            <label for="dateofbirth" class="col-sm-3 col-form-label fw-bold">Subject DoB</label>
            <div class="col-sm-9">
                <tiertimepicker id="dateOfBirth" name="dateOfBirth" #dateOfBirth="ngModel" [(ngModel)]="entry.DateOfBirth" (timechanged)="dob2age($event)" format="d-m-Y H:i" altFormat="d-m-Y" [altInput]="true"></tiertimepicker>
            </div>
        </div>
        <div class="row mb-3">
            <div class="col-sm-9 offset-sm-3 text-center">
                <i class="fa-solid fa-arrows-alt-v" style="font-size: 24px;"></i>
            </div>
        </div>
        <div class="row mb-3">
            <label for="age" class="col-sm-3 col-form-label fw-bold">Age</label>
            <div class="col-sm-9">
                <input type="number" id="age" name="age" class="form-control" [(ngModel)]="entry.Age" (input)="age2dob()">
            </div>
        </div>
        <div class="row mb-3">
            <label for="globalinjuries" class="col-sm-3 col-form-label fw-bold">Global Injuries</label>
            <div class="col-sm-9">
                <tiertypedropdown
                    id="globalinjuries"
                    name="globalinjuries"
                    [multi]="true"
                    placeholder="Select Global Injuries"
                    url="api/incidentglobalinjuries"
                    label="Global Injuries"
                    deleteId="Id"
                    [(ngModel)]="entry.IncidentGlobalInjuries"
                    [disabled]="entry.NoInjuries ?? false">
                </tiertypedropdown>
            </div>
        </div>
        <div class="row mb-3">
            <label for="bodymap" class="col-sm-3 col-form-label fw-bold">Injuries</label>
            <div class="col-sm-9">
                <div [ngClass]="{ 'bodydisabled': entry.NoInjuries }">
                <div class="bodypartoverlay" [hidden]="hideBodyPartWindow">
                    <div class="card bodypartwindow">
                        <div class="card-header"><b>{{bodyPart.bodyPartName}}</b></div>
                        <div class="card-body">
                            <textarea class="bodyparttextarea form-control" name="bodypartdescription" [(ngModel)]="bodyPart.description"></textarea>
                        </div>
                        <div class="card-footer">
                            <div class="hstack">
                                <div class="ms-auto">
                                    <button type="button" class="btn btn-danger me-1" (click)="removeSubjectInjury()" [hidden]="!bodyPartInjuries[bodyPart.bodyPartId!]">Delete</button>
                                    <button type="button" class="btn btn-secondary me-1" (click)="closeBodyPartWindow()">Cancel</button>
                                    <button type="button" class="btn btn-primary" (click)="confirmSubjectInjury()" [disabled]="bodyPart.description?.length! < 1">Confirm</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                    <div class="row">
                        <div class="hstack">
                            <div class="card text-center w-50">
                                <div class="card-header">Front of subject</div>
                                <div class="card-body">
                                    <svg xmlns="http://www.w3.org/2000/svg" xmlns:se="http://svg-edit.googlecode.com" xmlns:xlink="http://www.w3.org/1999/xlink" xmlns:dc="http://purl.org/dc/elements/1.1/" xmlns:cc="http://creativecommons.org/ns#" xmlns:rdf="http://www.w3.org/1999/02/22-rdf-syntax-ns#" xmlns:inkscape="http://www.inkscape.org/namespaces/inkscape" width="110" height="265" (click)="bodyPartClicked($event)">

                                        <g name="Front of head" id="fronthead">
                                            <rect fill="#000000" stroke="#222222" style="color: rgb(0, 0, 0);" stroke-width="2" stroke-linejoin="round" stroke-dashoffset="" fill-rule="nonzero" x="28.701297760009766" y="14.298709869384766" width="52" height="35" class="" [ngClass]="{ 'bodypartselected': bodyPartInjuries['fronthead'] }" />
                                            <rect fill="#ffffff" stroke="#222222" style="color: rgb(0, 0, 0);" stroke-width="2" stroke-linejoin="round" stroke-dashoffset="" fill-rule="nonzero" x="35" y="22" width="6" height="7" class="" />
                                            <rect fill="#ffffff" stroke="#222222" style="color: rgb(0, 0, 0);" stroke-width="2" stroke-linejoin="round" stroke-dashoffset="" fill-rule="nonzero" x="69.28571367263794" y="22.51948118209839" width="6" height="7" class="" />
                                            <rect fill="#ffffff" stroke="#222222" style="color: rgb(0, 0, 0);" stroke-width="2" stroke-linejoin="round" stroke-dashoffset="" fill-rule="nonzero" x="32" y="35.64935064315796" width="45" height="8" class="" />
                                            <rect fill="#ffffff" stroke="#222222" style="color: rgb(0, 0, 0);" stroke-width="2" stroke-linejoin="round" stroke-dashoffset="" fill-rule="nonzero" x="61" y="40" width="2" height="0" />
                                        </g>

                                        <rect fill="#000000" stroke="#222222" style="color: rgb(0, 0, 0);" stroke-width="2" stroke-linejoin="round" stroke-dashoffset="" fill-rule="nonzero" id="chest" x="39.701297760009766" y="60.03896236419678" width="29" height="99" class="" name="Chest" [ngClass]="{ 'bodypartselected': bodyPartInjuries['chest'] }" />
                                        <rect fill="#000000" stroke="#222222" style="color: rgb(0, 0, 0);" stroke-width="2" stroke-linejoin="round" stroke-dashoffset="" fill-rule="nonzero" x="17.095233917236328" y="65.4285717010498" width="14" height="58" class="" id="frontrightarm" name="Front of right arm" [ngClass]="{ 'bodypartselected': bodyPartInjuries['frontrightarm'] }" />
                                        <rect fill="#000000" stroke="#222222" style="color: rgb(0, 0, 0);" stroke-width="2" stroke-linejoin="round" stroke-dashoffset="" fill-rule="nonzero" x="77.70129776000977" y="65.4285717010498" width="14" height="58" class="" id="frontleftarm" name="Front of left arm" [ngClass]="{ 'bodypartselected': bodyPartInjuries['frontleftarm'] }" />
                                        <rect fill="#000000" stroke="#222222" style="color: rgb(0, 0, 0);" stroke-width="2" stroke-linejoin="round" stroke-dashoffset="" fill-rule="nonzero" x="40.73160171508789" y="167.3419895172119" width="11" height="82" class="" id="frontrightleg" name="Front of right leg" [ngClass]="{ 'bodypartselected': bodyPartInjuries['frontrightleg'] }" />
                                        <rect fill="#000000" stroke="#222222" style="color: rgb(0, 0, 0);" stroke-width="2" stroke-linejoin="round" stroke-dashoffset="" fill-rule="nonzero" x="57.09523391723633" y="167.3419895172119" width="11" height="82" class="" id="frontleftleg" name="Front of left leg" [ngClass]="{ 'bodypartselected': bodyPartInjuries['frontleftleg'] }" />

                                    </svg>
                                </div>
                            </div>
                            <div class="card text-center w-50">
                                <div class="card-header">Back of subject</div>
                                <div class="card-body">
                                    <svg xmlns="http://www.w3.org/2000/svg" xmlns:se="http://svg-edit.googlecode.com" xmlns:xlink="http://www.w3.org/1999/xlink" xmlns:dc="http://purl.org/dc/elements/1.1/" xmlns:cc="http://creativecommons.org/ns#" xmlns:rdf="http://www.w3.org/1999/02/22-rdf-syntax-ns#" xmlns:inkscape="http://www.inkscape.org/namespaces/inkscape" width="90" height="265" (click)="bodyPartClicked($event)">
                                        <rect fill="#000000" stroke="#222222" style="color: rgb(0, 0, 0);" stroke-width="2" stroke-linejoin="round" stroke-dashoffset="" fill-rule="nonzero" x="17.53245639801025" y="14.298709869384766" width="52" height="35" class="" id="backhead" name="Back of head" [ngClass]="{ 'bodypartselected': bodyPartInjuries['backhead'] }" />
                                        <rect fill="#000000" stroke="#222222" style="color: rgb(0, 0, 0);" stroke-width="2" stroke-linejoin="round" stroke-dashoffset="" fill-rule="nonzero" x="30.2207727432251" y="60.03896236419678" width="29" height="99" class="" id="back" name="Back" [ngClass]="{ 'bodypartselected': bodyPartInjuries['back'] }" />
                                        <rect fill="#000000" stroke="#222222" style="color: rgb(0, 0, 0);" stroke-width="2" stroke-linejoin="round" stroke-dashoffset="" fill-rule="nonzero" x="10.08224582672119" y="65.60172891616821" width="14" height="58" class="" id="backleftarm" name="Back of left arm" [ngClass]="{ 'bodypartselected': bodyPartInjuries['backleftarm'] }" />
                                        <rect fill="#000000" stroke="#222222" style="color: rgb(0, 0, 0);" stroke-width="2" stroke-linejoin="round" stroke-dashoffset="" fill-rule="nonzero" x="66.0129861831665" y="66.07792282104492" width="14" height="58" class="" id="backrightarm" name="Back of right arm" [ngClass]="{ 'bodypartselected': bodyPartInjuries['backrightarm'] }" />
                                        <rect fill="#000000" stroke="#222222" style="color: rgb(0, 0, 0);" stroke-width="2" stroke-linejoin="round" stroke-dashoffset="" fill-rule="nonzero" x="31.64068698883057" y="167.3419895172119" width="11" height="82" class="" id="backleftleg" name="Back of left leg" [ngClass]="{ 'bodypartselected': bodyPartInjuries['backleftleg'] }" />
                                        <rect fill="#000000" stroke="#222222" style="color: rgb(0, 0, 0);" stroke-width="2" stroke-linejoin="round" stroke-dashoffset="" fill-rule="nonzero" x="48.2640643119812" y="167.3419895172119" width="11" height="82" class="" id="backrightleg" name="Back of right leg" [ngClass]="{ 'bodypartselected': bodyPartInjuries['backrightleg'] }" />
                                    </svg>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="row mb-3">
            <label for="city" class="col-sm-3 col-form-label fw-bold" [textContent]="isCare ? 'Address' : 'City/Town'"></label>
            <div class="col-sm-9">
                <input type="text" id="city" [placeholder]="isCare ? 'Address' : 'City/Town'" name="city" class="form-control" [(ngModel)]="entry.City">
            </div>
        </div>
        <div class="row mb-3">
            <label for="notes" class="col-sm-3 col-form-label fw-bold">Notes</label>
            <div class="col-sm-9">
                <textarea class="form-control" placeholder="Notes" name="notes" id="notes" rows="5" [(ngModel)]="entry.Notes"></textarea>
            </div>
        </div>
        <div class="row mb-3">
            <label class="col-sm-3 col-form-label fw-bold pt-0">Options</label>
            <div class="col-sm-9">
                <div class="form-check">
                    <input class="form-check-input" id="noinjuries" name="noinjuries" type="checkbox" #noinjuriesref [(ngModel)]="entry.NoInjuries" (click)="noInjuriesClicked(noinjuriesref.checked)">
                    <label class="form-check-label">
                        No Injuries
                    </label>
                </div>
            </div>
        </div>
    </form>
</div>
<div class="modal-footer">
    <button class="btn btn-secondary"
            type="button"
            (click)="this.activeModal.dismiss()">
        Cancel
    </button>
    <button class="btn btn-primary"
            type="button"
            (click)="confirm()"
            [disabled]="subjectAddForm.invalid || (!entry.NoInjuries && !bodyPartLength() && !entry.IncidentGlobalInjuries?.length)">
        Confirm
    </button>
</div>
