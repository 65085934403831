import { Component, OnInit, inject } from '@angular/core';
import { Transition, StateService, UIRouterGlobals } from '@uirouter/angular';

import { TIERDownload, TIERToast } from '../services/';

import { clone, http2Error } from '../tier.utils';

@Component({ template: '' })
export abstract class Reports implements OnInit {
    protected alert : TIERToast = inject(TIERToast);
    protected down : TIERDownload = inject(TIERDownload);
    protected global : UIRouterGlobals = inject(UIRouterGlobals);
    protected state : StateService = inject(StateService);
    protected trans : Transition = inject(Transition);

    public abstract total : number;

    public params : any = clone(this.global.params);

    constructor() {}

    ngOnInit(): void {
        this.get();
    }

    public abstract get() : void;

    public extendAll() {
        this.setState(false);
    };

    public collapseAll() {
        this.setState(true);
    };

    public abstract setState(state : boolean) : void;

    protected state2Options() : any {
        return {
            IsAsc: this.params.IsAsc,
            Column: this.params.Column,
            Amount: this.params.Amount,
            Page: this.params.Page,
            Parameters: this.params
        };
    };

    public reload() {
        this.state.go(this.global.current.name!, this.params);
        this.get();
    }

    public print() {
        window.print();
    }

    public export(report : string) {
        this.down.downloadLinkFromServer('api/reports/' + report + '/export/excel', 'POST',
            undefined,
            this.state2Options()
        ).subscribe({
            next: (link : HTMLAnchorElement) => {
                link.dispatchEvent(new MouseEvent(`click`, { bubbles: true, cancelable: true, view: window }));
            },
            error: (err) => { this.alert.error(http2Error(err)); }
        })
    }
}
