'use strict'

import { Component, Input, OnInit, Inject } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
    selector: 'tier-listing',
    template: `
        <div class="modal-body card m-0 p-0" (click)="activeModal.dismiss()">
            <div class="card-header text-bg-primary" [textContent]="title"></div>
            <div class="card-body">
                <ul class="list-group">
                    <li class="list-group-item" *ngFor="let entry of entries">
                        <i class="fa-solid me-2" *ngIf="icon" [ngClass]="icon"></i>
                        <b [textContent]="id ? entry[id] : entry "></b>
                    </li>
                </ul>
            </div>
        </div>`
})
export class ListingComponent {
    @Input() title : string = "None";
    @Input() entries : any[] = [];
    @Input() id : string | null = null;

    @Input() icon : string | null = null;

    constructor(@Inject(NgbActiveModal) public activeModal : NgbActiveModal) {}
}
