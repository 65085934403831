<div class="mb-2">
    <div class="text-end">
        <blockquote>
            <p class="fw-bold">Assessments will be marked with the session end date: {{completeDate | transformdate: 'dd-MM-yyyy'}}</p>
        </blockquote>
    </div>
    <form #sessioncompleteform="ngForm" role="form" name="sessioncompleteform" novalidate autocomplete="none" (ngSubmit)="save()">
        <div class="table-responsive" *ngIf="matrix.headers.length > 0">
            <table class="table">
                <thead>
                    <tr>
                        <th></th>
                        <th *ngFor="let header of matrix.headers"><div><span>{{header.name}}</span></div></th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td>
                            <input #markallref name="markall" type="checkbox" id="markall" class="btn-check" autocomplete="off" (click)="markAll($event)">
                            <label class="btn btn-info btn-sm" for="markall" [textContent]="markallref.checked ? 'Select None' : 'Select All'"></label>
                        </td>
                        <td *ngFor="let header of matrix.headers">
                            <input type="checkbox" [id]="header.id" class="btn-check" autocomplete="off" (click)="markColumn(header.id, $event)">
                            <label class="btn btn-info btn-sm" [for]="header.id"><i class="fa-solid fa-angle-down"></i></label>
                        </td>
                    </tr>
                    <tr *ngFor="let entry of matrix.data | keyvalue">
                        <td>{{entry.key}}</td>
                        <td *ngFor="let skill of entry.value | filterbyobjectprop:filterFunction">
                            <input type="checkbox" [id]="skill.id" [name]="skill.id" class="btn-check" autocomplete="off" [(ngModel)]="skill.status">
                            <label class="btn btn-info btn-sm me-2" [for]="skill.id"><i class="fa-solid" [ngClass]="{ 'fa-check': skill.status, 'fa-times': !skill.status }"></i></label>
                            <button class="btn btn-secondary btn-sm" type="button" (click)="openCommentModal(skill)"><i class="fa-solid fa-comments"></i></button>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
        <div class="mb-3 text-end">
            <button type="submit" class="btn btn-primary">Commit</button>
        </div>
    </form>
    <div>
        <div class="card text-bg-warning mb-2 p-2">
            <span class="fw-bold">Note: </span>Once commited this session will only be able to be updated by an admin.
        </div>
    </div>
    <div>
        <div class="card text-bg-warning mb-2 p-2">
            <span class="fw-bold">Note: </span>Attendence based skill modules are not shown here but will automatically be marked attended when committed.
        </div>
    </div>
</div>
<div class="alert alert-warning" role="alert" *ngIf="badSkills.length">
    The following team members are not in a role that has the skills you are assessing:<br><br>
    <span *ngFor="let badSkill of badSkills">
        Team member <b>&#34;{{badSkill.Name}}&#34;</b> is not in a role that contains the skill <b>&#34;{{badSkill.Skill}}&#34;.</b><br>
    </span>
    <br>
    While the skill will be marked it will not appear in the assessments screen or the team members training screen until the teammember is added to a role with that skill.
</div>
