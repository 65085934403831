'use strict'

import { Component, Input, Inject } from '@angular/core';
import { TeammembersModel } from 'src/tier/models';

import { TIERTeammembersListingComponent } from './';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
    selector: 'tier-teammembersbadge',
    template: `<span role="button" class="badge text-bg-secondary" [textContent]="(!teammembers || !teammembers.length ? 0 : teammembers.length) + ' Attendees'" (click)="showModal()"></span>`
})
export class TIERTeammembersBadgeComponent {
    @Input({ required: true }) teammembers : TeammembersModel[] | undefined | null = [];
    @Input() bindname : string = "name";

    constructor(@Inject(NgbModal) private modalService : NgbModal)
        {}

    public showModal() {
        if(!this.teammembers?.length)
            return;

        const modalRef = this.modalService.open(TIERTeammembersListingComponent, { size: 'sm' });

        modalRef.componentInstance.teammembers = this.teammembers ?? [];
        modalRef.componentInstance.bindname = this.bindname;

        modalRef.result.then(() => {}).catch(() => {});
    }
}
