import { Component, Inject, OnInit } from '@angular/core';
import { format, subMonths, setHours, setMinutes } from "date-fns";
import { HttpParams } from '@angular/common/http';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { StateService } from '@uirouter/angular';

import { SessionListingModel, SessionEntryModel, SessionModel, OptionModel } from '../../models/';
import { TIERAPICalls, TIERToast, TIERAuth, TIEROptions } from '../../services';
import { EditSessionsComponent } from '../';
import { http2Error, calcUUID } from 'src/tier/tier.utils';

@Component({
    selector: 'tier-training-sessions',
    templateUrl: "./sessions.template.html"
})
export class SessionsComponent implements OnInit {
    public sessions : SessionEntryModel[] = [];
    public isAdmin = this.auth.hasClaim(['admin']);
    public isAdminOrTrainingAdmin = this.auth.hasClaim(['admin', 'trainingadmin']);
    public total : number = 0;
    public page : number = 1;

    public headers = [
        { name: 'Scheduled', centered: false, orderable: true },
        { name: 'Title', centered: false, orderable: true },
        { name: 'Venue', centered: false, orderable: true },
        { name: 'Modules', centered: false, orderable: false },
        { name: 'Attendees', centered: false, orderable: false },
        { name: 'Active', centered: true, orderable: true }
    ];

    public filterConfig = [
        {
            name: calcUUID(),
            formControl: "search",
            label: "Search Text",
            value: "searchText"
        },
        {
            name: calcUUID(),
            label: "Always Show Last",
            formControl: "dropdown",
            placeHolder: "Select Last",
            source: [{ Id: 0, Name: "None" }, { Id: 3, Name: "3 Entries" }, { Id: 5, Name: "5 Entries" }, { Id: 10, Name: "10 Entries" }],
            value: "alwaysShowLast"
        },
        {
            name: calcUUID(),
            label: "Date",
            formControl: "date",
            equality: 'dateEquality',
            format: "dd-MM-yyyy",
            value: "date"
        },
        {
            name: calcUUID(),
            label: "Show Uncompleted (Ignores Date Ranges)",
            formControl: "tickbox",
            value: "showUncompleted"
        },
        {
            name: calcUUID(),
            label: "Show Inactive",
            formControl: "tickbox",
            value: "showInactive"
        },
        {
            name: calcUUID(),
            label: "Show Session Forcasting (Ignores Date Ranges)",
            formControl: "tickbox",
            value: "showSuggestions"
        }
    ];

    public values = {
        skip: 0,
        amount: 10,
        date: format(subMonths(new Date(), 1), 'dd-MM-yyyy'),
        dateEquality: 'morethan',
        alwaysShowLast: 0,
        searchText: '',
        showUncompleted: true,
        showInactive: false,
        showSuggestions: true,
        column: 'Scheduled',
        direction: 'asc'
    };

    constructor(
        @Inject(TIERAPICalls) private apicall : TIERAPICalls,
        @Inject(TIERToast) private alert : TIERToast,
        @Inject(TIERAuth) private auth : TIERAuth,
        @Inject(NgbModal) private modalService : NgbModal,
        @Inject(StateService) private state : StateService,
        @Inject(TIEROptions) private options : TIEROptions
    ) { }

    ngOnInit(): void {
        this.get();
    }

    public get() : void {
        this.values.skip = (this.page - 1) * this.values.amount;

        this.apicall.get<SessionListingModel>('api/sessions', new HttpParams({'fromObject': { ...this.values }})).subscribe({
            next: (response : SessionListingModel) => {
                this.sessions = response.ObjectList;
                this.total = response.Total;
            },
            error: (error) => {
                this.alert.error(http2Error(error));
            }
        });
    }

    public changeOrder(header : any) : void {
        if (header.orderable === false)
            return;

        if (header.name === this.values.column) {
            if (this.values.direction === 'asc') {
                this.values.direction = 'desc';
            } else {
                this.values.direction = 'asc';
            }
        } else {
            this.values.column = header.name;
            this.values.direction = 'asc';
        }

        this.get();
    };

    private save(session : SessionModel, redirect : boolean = false) {
        this.apicall.save<SessionModel>('api/sessions/%s'.formUri(session.Id), session).subscribe({
            next: () => {
                if(redirect) {
                    this.state.go('training@sessionscomplete', { id: session.Id });
                } else {
                    this.alert.success("Session saved.");
                    this.get();
                }
            },
            error: (err) => { this.alert.error(http2Error(err)); }
        });
    }

    private openModal(session : SessionModel = { Attendees: [], Faciliators: [], SkillModules: [], Venue: null, IsActive: true }) : void {
        const modalRef = this.modalService.open(EditSessionsComponent, { size: 'xl' });

        modalRef.componentInstance.session = session;

        modalRef.result.then((results : any) => {
            this.save(results.session, results.redirect);
        }).catch(() => {});
    }

    public new() : void  {
        this.openModal({ StartDate: this.getDefaultDateTime('org-session-starttime'), EndDate: this.getDefaultDateTime('org-session-endtime'), Attendees: [], Faciliators: [], SkillModules: [], Venue: null, IsActive: true });
    }

    public newSuggestion(sessionEntry : SessionEntryModel) : void {
        this.openModal({ Attendees: sessionEntry.Attendees, SkillModules: sessionEntry.SkillModules, Faciliators: [], StartDate: sessionEntry.StartDate, EndDate: sessionEntry.EndDate, IsActive: true, Venue: null });
    }

    private getDefaultDateTime(optionStr : keyof OptionModel) : string {
        let option : string | undefined = this.options.get(optionStr);
        let date : Date = new Date();

        if(option) {
            let split : string[] = option.split(':');
            if(split?.length > 1) {
                date = setHours(date, +split[0]);
                date = setMinutes(date, +split[1]);
            }
        }

        return format(date, 'dd-MM-yyyy HH:mm');
    }

    public edit(id : number | null = null) : void {
        if(!id)
            return;

        this.apicall.get<SessionModel>('api/sessions/%s'.formUri(id)).subscribe({
            next: (response : SessionModel) => {
                this.openModal(response);
            },
            error: (error) => {
                this.alert.error(http2Error(error));
            }
        });
    }
}
