'use strict'

import { Component, Inject } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

import { DrivingLicenceCategoryEntitlementModel, DrivingLicenceCategoryModel } from '../../models';

@Component({
    selector: 'tier-management-teammembers-categories',
    template: `
        <form #dleForm="ngForm" role="form" name="dleForm" (ngSubmit)="confirm()" novalidate autocomplete="none">
            <div class="modal-header">
                <h4 class="modal-title">Add Entitlement</h4>
            </div>
            <div class="modal-body">
                <div class="row mb-3">
                    <label class="col-sm-3 col-form-label fw-bold">Entitlement</label>
                    <div class="col-sm-9">
                        <tierdropdown
                            name="dledd"
                            source="api/drivinglicencecategoryentitlements"
                            placeholder="Select Entitlement"
                            (ngModelChange)="setEntitlementId($event)"
                            [(ngModel)]="entry.DrivingLicenceCategoryEntitlement"
                            [allowNew]=false
                            bindValue="Name"
                            #dledd="ngModel"
                            required>
                        </tierdropdown>
                        <p [hidden]="dledd.valid" class="form-text text-danger">Entitlement is required.</p>
                    </div>
                </div>
                <div class="row mb-3">
                    <label for="validfrom" class="col-sm-3 col-form-label fw-bold">Valid From</label>
                    <div class="col-sm-9">
                        <tiertimepicker name="validfrom" id="validfrom" #validfrom="ngModel" [model]="validfrom" [(ngModel)]="entry.ValidFrom" required></tiertimepicker>
                        <p [hidden]="validfrom.valid" class="form-text text-danger">Valid from date is required.</p>
                    </div>

                </div>
                <div class="row mb-3">
                    <label for="validto" class="col-sm-3 col-form-label fw-bold">Valid To</label>
                    <div class="col-sm-9">
                        <tiertimepicker name="validto" id="validto" #validto="ngModel" [model]="validto" [DateValidation]="[ { value: validfrom.value, equality: 'morethan', ident: 'validfrom' } ]" [(ngModel)]="entry.ValidTo" required></tiertimepicker>
                        <p [hidden]="validto.valid" class="form-text text-danger">Valid to date is required and must be after valid from date.</p>
                    </div>
                </div>
                <div class="row mb-3">
                    <label for="informationCodes" class="col-sm-3 col-form-label fw-bold">Information Codes</label>
                    <div class="col-sm-9">
                        <input type="text" id="informationCodes" name="informationCodes" class="form-control" [(ngModel)]="entry.InformationCodes" placeholder="Information Codes">
                    </div>
                </div>
            </div>
            <div class="modal-footer">
                <button class="btn btn-secondary"
                        type="button"
                        (click)="activeModal.dismiss()">
                    Cancel
                </button>
                <button class="btn btn-primary"
                        type="submit"
                        [disabled]="dleForm.invalid">
                    Confirm
                </button>
            </div>
        </form>`,
})
export class TeammembersEntitlementsComponent {

    public entry : DrivingLicenceCategoryModel = {};

    constructor(@Inject(NgbActiveModal) public activeModal : NgbActiveModal) {}

    public setEntitlementId(obj : DrivingLicenceCategoryEntitlementModel) {
        this.entry.DrivingLicenceCategoryEntitlementId = obj.Id;
    }

    public confirm() {
        this.activeModal.close(this.entry);
    }
}
