'use strict'

import { Component, OnInit } from '@angular/core';
import { ChartData, ChartConfiguration } from 'chart.js';
import { WidgetBase } from '../../Widget.base';
import { http2Error } from 'src/tier/tier.utils';

@Component({
    selector: 'tier-widget-equipmentmanager-equipmentconditionwidget',
    template: `
        <tierspinner [name]="getUUID()"></tierspinner>
        <div class="chart-container">
            <canvas baseChart class="chart" [type]="pieChartType" [data]="data" [options]="chartOptions"></canvas>
        </div>`,
    styleUrls: [ '../../_widgets.scss' ]
})
export class EquipmentConditionWidgetComponent extends WidgetBase implements OnInit {
  static readonly widgetName = 'EquipmentConditionWidgetComponent';
    public data : ChartData<'pie'> | undefined = undefined;

    public chartOptions : ChartConfiguration['options']= {
      responsive: true,
      maintainAspectRatio: false,
    };

    ngOnInit(): void {
        this.callAPI<ChartData<'pie'> | null>('api/dashboard/equipmentcondition', this.getUUID()).subscribe({
            next: (response : ChartData<'pie'> | null) => {
              this.data = response ?? undefined;
              if(this.isEmpty(this.data))
                this.switchToNoDataWidget();
            },
            error: (error) => {
              this.alert.error(http2Error(error));
            }
        });
    }
}
