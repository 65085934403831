import { Input, forwardRef, Directive, OnChanges, SimpleChanges } from '@angular/core';
import { NG_VALIDATORS, Validator, AbstractControl, ValidationErrors } from '@angular/forms';
import { parse, isValid, compareAsc } from "date-fns";

import { isNorU } from 'src/tier/tier.utils';

@Directive({
  selector: '[DateValidation]',
  providers: [
    {
      provide: NG_VALIDATORS,
      useExisting: forwardRef(() => DateValidationDirective),
      multi: true,
    },
  ]
})
export class DateValidationDirective implements Validator, OnChanges {
    @Input({ alias: 'DateValidation', required: true }) source : { ident: string, value: string | null | undefined, equality: "morethan" | "lessthan" | "equal" }[] = [];
    @Input('ValidationFormat') format : string = 'dd-MM-yyyy';

    onChange: () => void = () => {};

    ngOnChanges(changes: SimpleChanges): void {
        if(!isNorU(changes['source']?.currentValue) && !changes['source'].isFirstChange())
            this.source = changes['source'].currentValue;

        this.onChange();
    }

    validate(control: AbstractControl): ValidationErrors | null {
        let controlDate = null;

        try {
            controlDate = parse(control.value, this.format, new Date());
        } catch(e) {
            return null;
        }

        for(let i=0; i < this.source.length; i++) {
            let sourceDate = null;

            try {
                if(!this.source[i].value)
                    throw Error("Null error in datevalidation directive");

                sourceDate = parse(this.source[i].value!, this.format, new Date());
            } catch(e) {
                continue;
            }

            if(!isValid(sourceDate) && !isValid(controlDate))
                continue;

            let comparison : number = 0;
            switch(this.source[i].equality) {
                case 'lessthan':
                    comparison = -1;
                    break;
                case 'equal':
                    comparison = 0;
                    break;
                case 'morethan':
                    comparison = 1;
                    break;
            }

            if(compareAsc(sourceDate, controlDate) === comparison)
                return { 'DateValidation': { value: this.source[i].ident } };
        };

        return null;
    }

    registerOnValidatorChange(fn: () => void): void {
        this.onChange = fn;
    }
}
