<div class="row">
    <div class="col-md-6 mb-2">
        <div class="card">
            <div class="card-header">TIER Login</div>
            <div class="card-body">
                <form class="row g-3" #loginForm="ngForm" (ngSubmit)="login()">
                    <div class="form-floating">
                        <input type="text" id="email" name="email" class="form-control" placeholder="Email Address" [(ngModel)]="loginInfo.email" required>
                        <label for="email" class="ms-2">Email Address</label>
                    </div>
                    <div class="form-floating col-12">
                        <input type="password" name="password" id="password" class="form-control" placeholder="Password" [(ngModel)]="loginInfo.password" required>
                        <label for="password" class="ms-2">Password</label>
                    </div>
                    <div class="form-check col-12 ms-2">
                        <input type="checkbox" class="form-check-input" name="rememberme" id="rememberme" [(ngModel)]="loginInfo.remember">
                        <label class="form-check-label" for="rememberme">Keep yourself logged in</label>
                    </div>
                    <div class="d-grid gap-2">
                        <button type="submit" class="btn btn-default" [disabled]="!loginForm.form.valid">Submit</button>
                    </div>
                </form>
            </div>
        </div>
    </div>
    <div class="col-md-6">
        <div class="card">
            <div class="card-header">3rd Party Login</div>
            <div class="card-body">
                <div class="d-grid gap-2">
                    <button class="btn btn-google" type="button" (click)="authExternalProvider('Google')">
                        <i class="fa-brands fa-google"></i> | Connect with Google account
                    </button>
                    <button class="btn btn-microsoft" type="button" (click)="authExternalProvider('Microsoft')">
                        <i class="fa-brands fa-microsoft"></i> | Connect with Microsoft account
                    </button>
                </div>
            </div>
        </div>
    </div>
</div>
