<div class="d-print-none mb-3 d-flex justify-content-end">
    <TIERExtendButtons class="me-3" (extend)="extendAll()" (collapse)="collapseAll()"></TIERExtendButtons>
    <div>
        <div class="form-group">
            <input type="text" class="form-control"
                placeholder="Search..."
                [(ngModel)]="search"
                minlength="2"
                maxlength="20">
        </div>
    </div>
</div>
<div *ngIf="report.length">
    <div *ngFor="let inner of report | search: search">
        <div class="card text-bg-info mb-2">
            <div class="card-body fw-bold">
                {{inner.Name}}
                <span *ngIf="inner.ExternalRef">({{inner.ExternalRef}})</span>
                 - {{inner.Start | transformdate: 'dd-MM-yyyy'}} to {{inner.End | transformdate: 'dd-MM-yyyy'}}
            </div>
        </div>
        <div class="alert alert-warning" role="alert" *ngIf="!inner.Teammembers.length">
            <i class="fa-solid fa-exclamation-circle"></i> No selected team members attended this incident.
        </div>
        <table class="table" *ngIf="inner.Teammembers.length">
            <thead TIERCustomTableHeader name="TMHeader" [format]="[
                                    { id: 'FullName', name: 'Name' },
                                    { id: 'Start', name: 'Start Date' },
                                    { id: 'End', name: 'End Date' },
                                    { id: 'Time', name: 'Time At Incident' }]"
                   [(table)]="inner.Teammembers">
            </thead>
            <tbody>
            @for (tm of inner.Teammembers; track tm) {
                <tr [ngClass]="{ 'danger': !tm.End }">
                    <td>{{tm.FullName}}</td>
                    <td>{{tm.Start}}</td>
                    <td>{{tm.End || "Not Finished"}}</td>
                    <td *ngIf="tm.End">{{tm.Time}} Mins</td>
                    <td *ngIf="!tm.End">N/A</td>
                </tr>
                <tr *ngIf="tm.Skills.length">
                    <td colspan="4">
                        <tier-reportpane title="Modules" class="nomargin" [isHidden]="tm.CollapseSkills">
                            <table class="table">
                                <thead TIERCustomTableHeader [format]="[
                                        { id: 'Name', name: 'Name' },
                                        { id: 'Description', name: 'Description' },
                                        { id: 'AssessmentBased', name: 'Type' },
                                        { id: 'Critical', name: 'Critical' },
                                        { id: 'MonthsValidFor', name: 'Months Valid For' }]"
                                    [(table)]="tm.Skills">
                                </thead>
                                <tbody>
                                    <tr *ngFor="let sk of tm.Skills">
                                        <td>{{sk.Name}}</td>
                                        <td>{{sk.Description}}</td>
                                        <td *ngIf="sk.AssessmentBased === true"><span class="badge text-bg-primary">Assessment</span></td>
                                        <td *ngIf="sk.AssessmentBased === false"><span class="badge text-bg-primary">Attendence</span></td>
                                        <td *ngIf="sk.IsCritical === true"><i class="fa-solid fa-check"></i></td>
                                        <td *ngIf="sk.IsCritical === false"><i class="fa-solid fa-times"></i></td>
                                        <td>{{sk.MonthsValidFor}}</td>
                                    </tr>
                                </tbody>
                            </table>
                        </tier-reportpane>
                    </td>
                </tr>
            }
        </tbody>
        <tfoot>
            <tr>
                <td colspan="4" class="text-end fw-bold" [ngClass]="{ 'table-warning': !inner.IsConcluded, 'table-success': inner.IsConcluded }">
                    Incident is <span>{{inner.IsConcluded ? 'concluded' : 'not concluded'}}</span>
                </td>
            </tr>
        </tfoot>
        </table>
    </div>
    <div class="d-flex justify-content-center my-3 d-print-none" *ngIf="params.Amount">
        <ngb-pagination [collectionSize]="total" [(page)]="params.Page" [pageSize]="params.Amount" [maxSize]="5" [rotate]="true" [ellipses]="false" [boundaryLinks]="true" (pageChange)="reload()" />
    </div>
</div>
<div class="alert alert-warning" role="alert" [hidden]="report.length">
    <i class="fa-solid fa-exclamation-circle"></i> 0 results found.
</div>

<TIERReportButtons (print)="print()" (export)="export('IncidentReport')"></TIERReportButtons>
