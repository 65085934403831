'use strict'

import { Component, OnInit } from '@angular/core';
import { WidgetBase } from '../../Widget.base';
import { http2Error } from 'src/tier/tier.utils';
import { EquipmentResourceModel } from 'src/tier/models';

@Component({
    selector: 'tier-widget-all-invalidequipmentstatus',
    template: `<tierspinner [name]="getUUID()"></tierspinner>
    <table class="table table-borderless table-hover table-condensed">
        <thead>
            <th></th>
            <th>Template</th>
            <th>Reference</th>
            <th>Status</th>
        </thead>
        <tbody>
            <tr *ngFor="let equip of equipment"
                [ngClass]="{
                    'table-danger': equip.CheckDueExpired || equip.ResourceExpired || equip.EquipmentResourceConditionType?.IsDefault !== true ,
                    'table-warning': equip.CheckDueExpiring || equip.ResourceExpiring
                }">
                <td><i class="fa-solid fa-box me-1"></i></td>
                <td>{{equip.EquipmentTemplate?.Name}}</td>
                <td>{{equip.Reference}}</td>
                <td><ul class="comma-seperated-list">
                    @if (equip.CheckDueExpiring && equip.CheckedById && !equip.CheckDueExpired) {
                        <li>Check Expiring</li>
                    }
                    @if (equip.CheckDueExpired && equip.CheckedById) {
                        <li>Check Expired</li>
                    }
                    @if (equip.ResourceExpired) {
                        <li>Expired</li>
                    }
                    @if (equip.ResourceExpiring && !equip.ResourceExpired) {
                        <li>Expiring</li>
                    }
                    @if (!equip.CheckedById) {
                        <li>Not Checked</li>
                    }
                    @if(equip.EquipmentResourceConditionType?.IsDefault !== true) {
                        <li>{{equip.EquipmentResourceConditionType?.Name}}</li>
                    }
                </ul></td>
            </tr>
        </tbody>
    </table>`,
    styleUrls: [ '../../_widgets.scss' ]
})
export class InvalidEquipmentStatusWidgetComponent extends WidgetBase implements OnInit {
    static readonly widgetName = 'InvalidEquipmentStatusWidgetComponent';
    public equipment : EquipmentResourceModel[] = [];

    ngOnInit(): void {
        this.callAPI<any[]>('api/dashboard/invalidequipmentstatus', this.getUUID()).subscribe({
            next: (response : any[]) => {
              this.equipment = response;
              if(this.equipment.length <= 0) {
                this.switchToNoDataWidget();
              }
            },
            error: (error) => {
                this.alert.error(http2Error(error));
            }
        });
    }
}
