import { Component, Input, forwardRef } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from "@angular/forms";

import { EquipmentResourcePropertiesModel, EquipmentTemplateAttributeModel } from '../../models';
import { isNorU } from 'src/tier/tier.utils';

@Component({
    selector: 'tier-equipment-properties',
    templateUrl: "./equipmentproperties.template.html",
    providers: [{
        provide: NG_VALUE_ACCESSOR,
        useExisting: forwardRef(() => EquipmentPropertiesComponent),
        multi: true
    }]
})
export class EquipmentPropertiesComponent implements ControlValueAccessor {
    @Input() attributes : EquipmentTemplateAttributeModel[] = [];

    public properties : EquipmentResourcePropertiesModel[] = [];

    index = (id : number) => { let moo = this.properties.findIndex(o => o?.EquipmentTemplateAttributeId === id); return moo === -1 ? null : moo; }
    onChange: (_: any) => void = (_: any) => {};
    onTouched: () => void = () => {};

    writeValue(properties : EquipmentResourcePropertiesModel[]): void {
        this.properties = properties ?? [];
    }

    registerOnChange(fn: any): void {
        this.onChange = fn;
    }

    registerOnTouched(fn: any): void {
        this.onTouched = fn;
    }

    public getIndexValue(id : number) : any {
        let index = this.properties.findIndex(o => o?.EquipmentTemplateAttributeId === id);
        if(index === -1)
            return null;

        return this.properties[index]?.Value;
    }

    public propertyChange(event : any, attribute : EquipmentResourcePropertiesModel) {
        let index = this.properties.findIndex(o => o.EquipmentTemplateAttributeId === attribute.Id);

        if(index !== -1) {
            if(isNorU(event)) {
                this.properties.splice(index, 1);
            } else {
                this.properties[index].Value = event;
            }
        } else {
            if(isNorU(event))
                return;

            this.properties.push({
                'EquipmentTemplateAttributeId': attribute.Id,
                'Value': String(event)
            });
        }

        this.onChange(this.properties);
    }
}
