'use strict';

import { Injectable, Input, Inject, Component } from '@angular/core';
import { NgbModal, NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Injectable()
export class TIERDialog
{
    constructor(@Inject(NgbModal) private modal : NgbModal) { }

    public showAlertDialog(title : string, message : string, confirmButton : string = "Confirm") : Promise<any>{
        const modalRef = this.modal.open(AlertDialog, { backdrop: 'static', keyboard: false });

		modalRef.componentInstance.title = title;
        modalRef.componentInstance.message = message;
        modalRef.componentInstance.confirmButton = confirmButton;

        return modalRef.result;
    }

    public showConfirmDialog(title : string, message : string, confirmButton : string = "Confirm", cancelButton : string = "Cancel") : Promise<any> {
        const modalRef = this.modal.open(ConfirmDialog, { backdrop: 'static', keyboard: false });

		modalRef.componentInstance.title = title;
        modalRef.componentInstance.message = message;
        modalRef.componentInstance.confirmButton = confirmButton;
        modalRef.componentInstance.cancelButton = cancelButton;

        return modalRef.result;
    }
}

@Component({
    selector: 'alertdialog',
    template: `
        <div class="modal-header">
            <h3 class="modal-title" [textContent]="title"></h3>
        </div>
        <div class="modal-body">
            <div [textContent]="message"></div>
        </div>
        <div class="modal-footer">
            <button class="btn btn-primary"
                    type="button"
                    (click)="this.modal.close()" [textContent]="confirmButton">
                Confirm
            </button>
        </div>`,
})
class AlertDialog {
    @Input() title : string | null = null;
    @Input() message : string | null = null;
    @Input() confirmButton : string | null = null;

    constructor(@Inject(NgbActiveModal) public modal : NgbActiveModal) { }
}

@Component({
    selector: 'confirmdialog',
    template: `
        <div class="modal-header">
            <h3 class="modal-title" [textContent]="title"></h3>
        </div>
        <div class="modal-body">
            <div [textContent]="message"></div>
        </div>
        <div class="modal-footer">
            <button class="btn btn-secondary"
                    type="button"
                    (click)="this.modal.dismiss()" [textContent]="cancelButton">
                Cancel
            </button>
            <button class="btn btn-primary"
                    type="button"
                    (click)="this.modal.close()" [textContent]="confirmButton">
                Confirm
            </button>
        </div>`,
})
class ConfirmDialog {
    @Input() title : string | null = null;
    @Input() message : string | null = null;
    @Input() confirmButton : string | null = null;
    @Input() cancelButton : string | null = null;

    constructor(@Inject(NgbActiveModal) public modal : NgbActiveModal) { }
}
