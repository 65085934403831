export { FacilitatedSessionsWidgetComponent } from './all/facilitatedsessionswidget/FacilitatedSessionsWidget.component';
export { IncidentAttendanceWidgetComponent } from './all/incidentattendancewidget/IncidentAttendanceWidget.component';
export { MyExpiredModulesWidgetComponent } from './all/myexpiredmoduleswidget/MyExpiredModulesWidget.component';
export { MyExpiringModulesWidgetComponent } from './all/myexpiringmoduleswidget/MyExpiringModulesWidget.component';
export { MyFailedModulesWidgetComponent } from './all/myfailedmoduleswidget/MyFailedModulesWidget.component';
export { MyModulesWidgetComponent } from './all/mymoduleswidget/MyModulesWidget.component';
export { SessionsAttendedWidgetComponent } from './all/sessionsattendedwidget/SessionsAttendedWidget.component';
export { TimeSpentTrainingWidgetComponent } from './all/timespenttrainingwidget/TimeSpentTrainingWidget.component';
export { InvalidEquipmentStatusWidgetComponent } from './all/invalidequipmentstatuswidget/invalidequipmentstatuswidget.component';

export { EquipmentAllocationWidgetComponent } from './equipmentmanager/equipmentallocationwidget/EquipmentAllocationWidget.component';
export { EquipmentConditionWidgetComponent } from './equipmentmanager/equipmentconditionwidget/EquipmentConditionWidget.component';
export { EquipmentValidityWidgetComponent } from './equipmentmanager/equipmentvaliditywidget/EquipmentValidityWidget.component';

export { NoDataWidgetComponent } from './system/nodatawidget/NoDataWidget.component';
export { NotFoundWidgetComponent } from './system/notfoundwidget/NotFoundWidget.component';
export { TestWidgetComponent } from './system/testwidget/TestWidget.component';

export { IncidentPerTypeWidgetComponent } from './teamadmin/incidentpertypewidget/IncidentPerTypeWidget.component';
export { TeamMembersByRoleWidgetComponent } from './teamadmin/teammembersbyrolewidget/TeamMembersByRoleWidget.component';
export { TeamMembersNotInRoleWidgetComponent } from './teamadmin/teammembersnotinroleswidget/TeamMembersNotInRolesWidget.component';

export { NonCriticalExpiredModulesWidgetComponent } from './trainingadmin/noncriticalexpiredmoduleswidget/NonCriticalExpiredModulesWidget.component';
export { NonCriticalExpiringModulesWidgetComponent } from './trainingadmin/noncriticalexpiringmoduleswidget/NonCriticalExpiringModulesWidget.component';
export { TeamExpiredModulesWidgetComponent } from './trainingadmin/teamexpiredmoduleswidget/TeamExpiredModulesWidget.component';
export { TeamExpiringModulesWidgetComponent } from './trainingadmin/teamexpiringmoduleswidget/TeamExpiringModulesWidget.component';
export { TeamFailedModulesWidgetComponent } from './trainingadmin/teamfailedmoduleswidget/TeamFailedModulesWidget.component';
