<div class="modal-header">
    <div class="hstack">
        <h3 class="modal-title">
            Choose Equipment
        </h3>
    </div>
    <div class="ms-auto">
        <input class="form-control"
            type="text"
            placeholder="Search..."
            [(ngModel)]="searchText">
    </div>
</div>
<div class="modal-body">
    <div class="row mb-3" [hidden]="!resources.length">
        <div *ngIf="enableButtons">
            <button class="btn btn-primary me-2" role="button" (click)="expandToggle(false)">Expand</button>
            <button class="btn btn-primary me-2" role="button" (click)="expandToggle(true)">Collapse</button>
            <button class="btn btn-primary me-2" role="button" (click)="selectToggle(true)">Select All</button>
            <button class="btn btn-primary" role="button" (click)="selectToggle(false)">Unselect All</button>
        </div>
    </div>
    <tier-equipment-treeselector-node [showAllocated]="showAllocated" [search]="searchText" [(nodes)]="resources"></tier-equipment-treeselector-node>
    <div class="alert alert-info" role="alert" [hidden]="resources.length">
        <i class="fa-solid fa-exclamation-circle"></i> 0 results found.
    </div>
</div>
<div class="modal-footer">
    <div>
        <button type="button" class="btn btn-secondary me-2" (click)="this.activeModal.dismiss()">
            Cancel
        </button>
        <button type="button" class="btn btn-primary" (click)="confirm()">
            Confirm
        </button>
    </div>
</div>
