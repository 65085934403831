import { Injectable, Inject } from '@angular/core';
import { EquipmentResourceModel } from '../models';

import { TIERAPICalls } from './';
import { Observable } from 'rxjs';

interface EquipmentStack {
    id : number | undefined,
    ref : string | null | undefined,
    template : string | undefined,
    from : number | null | undefined
}

@Injectable()
export class TIEREquipmentStack
{
    private readonly _stack : EquipmentStack[] = [];
    get stack() { return this._stack; }

    public count = () : number => this._stack.length;

    constructor(@Inject(TIERAPICalls) protected apicall : TIERAPICalls) {}

    public add(resource : EquipmentResourceModel) : void {
       this._stack.push({ 'id': resource.Id, 'ref': resource.Reference, 'template': resource.EquipmentTemplate?.Name, 'from': resource?.EquipmentResourceParentId });
    }

    public remove(resource : EquipmentResourceModel) : void {
        let index = this._stack.findIndex((elm) => {
            return elm.id === resource.Id;
        });

        if(index === -1) {
            console.log("Unable to find resourceId to remove from moveStack. ResourceId: ", resource.Id);
            return;
        }

        this._stack.splice(index, 1);
    }

    public clear() : void {
        this._stack.length = 0;
    }

    public commit(to : EquipmentResourceModel) : Observable<object> | null {
        let convertedStack : { 'Id': number, 'To': number }[] = [];

        this._stack.forEach((resource) => {
            if(resource.id && to.Id)
                convertedStack.push({ 'Id': resource.id, 'To': to.Id });
        })

        if(!convertedStack.length)
            return null;

        return this.apicall.put('api/equipment/resources/move', convertedStack);
    }
}
