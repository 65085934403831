<div class="d-print-none mb-3 d-flex justify-content-end">
    <TIERExtendButtons class="me-3" (extend)="extendAll()" (collapse)="collapseAll()"></TIERExtendButtons>
</div>

<div class="mb-4">
    <div class="mb-1 fw-bold">
        Colour Key:
    </div>
    <div class="hstack ms-4">
        <div class="me-2 hstack">
            <div class="text-bg-danger rounded-2 p-2 me-2">
            </div>
            <div>
                <span>Equipment has expired</span>
            </div>
        </div>
        <div class="me-2 hstack">
            <div class="text-bg-warning rounded-2 p-2 me-2">
            </div>
            <div>
                <span>Equipment is lost/damaged/empty</span>
            </div>
        </div>
        <div class="me-2 hstack">
            <div class="text-bg-info rounded-2 p-2 me-2">
            </div>
            <div>
                <span>Equipment has not been checked</span>
            </div>
        </div>
    </div>
</div>

<div *ngIf="report.length">
    <table class="table">
        <thead TIERCustomTableHeader [format]="[
                    { id: 'TemplateName', name: 'Resource' },
                    { id: 'ExpiryDate', name: 'Expiry Date' },
                    { id: 'AllocatedTo', name: 'Allocated To' },
                    { id: 'CheckedBy', name: 'Checked By' },
                    { id: 'CheckDueDate', name: 'Check Due On' },
                    { id: 'Condition', name: 'Condition' },
                    { id: 'Cost', name: 'Cost' },
                    { id: 'Total', name: 'Total' }]"
            [(table)]="rawReport" (tableChange)="refresh($event)">
        </thead>
        <tbody>
            <tr *ngFor="let r of report" [hidden]="r.IsCollapsed" [ngClass]="
            {
                'table-danger': r.IsExpired === true,
                'table-warning': r.ConditionBad === true,
                'table-info': r.CheckDueDate === null
            }">
                <td (click)="collapse([r.Id])">
                    <span class="me-3" *ngFor="let x of [].constructor(r.Level)"></span>
                    <span class="fa-solid me-2"
                        [ngClass]="{ 'fa-folder-open': r.IsContainer === true && !r.IsClosed, 'fa-folder': r.IsContainer === true && r.IsClosed, 'fa-barcode': !r.IsContainer }">
                    </span> {{r.TemplateName}} ({{r.Reference}})
                </td>
                <td>{{(r.ExpiryDate | transformdate: 'dd-MM-yyyy') || 'N/A'}}</td>
                <td>{{r.AllocatedTo || 'Unallocated'}}</td>
                <td>{{r.CheckedByName || 'Not Checked'}}</td>
                <td>{{(r.CheckDueDate | transformdate: 'dd-MM-yyyy') || 'Not Checked'}}</td>
                <td>{{r.ConditionName || 'Unknown'}}</td>
                <td>£ {{(r.Cost || 0) | number : '1.0-2'}}</td>
                <td><span *ngIf="r.IsContainer">£ {{(r.Total || 0) | number : '1.0-2'}}</span></td>
            </tr>
        </tbody>
        <tfoot>
            <tr>
                <td colspan="7" class="text-end fw-bold">Page Resources Total: </td>
                <td>£ {{(totalCost() || 0) | number : '1.0-2'}}</td>
            </tr>
            <tr>
                <td colspan="7" class="text-end fw-bold">All Resources Total: </td>
                <td>£ {{(report[0].CompleteTotal || 0) | number : '1.0-2'}}</td>
            </tr>
        </tfoot>
    </table>
    <div class="d-flex justify-content-center my-3 d-print-none" *ngIf="params.Amount">
        <ngb-pagination [collectionSize]="total" [(page)]="params.Page" [pageSize]="params.Amount" [maxSize]="5" [rotate]="true" [ellipses]="false" [boundaryLinks]="true" (pageChange)="reload()" />
    </div>
</div>
<div class="alert alert-warning" role="alert" [hidden]="report.length">
    <i class="fa-solid fa-exclamation-circle"></i> 0 results found.
</div>

<TIERReportButtons (print)="print()" (export)="export('EquipmentReport')"></TIERReportButtons>
