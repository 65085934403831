'use strict'

import { Pipe, PipeTransform } from '@angular/core';
import { parse, isValid, format } from "date-fns";

@Pipe({name: 'transformdate'})
export class TransformDatePipe implements PipeTransform {
    transform(value: any, pattern : string) : any {
        if (!value || !pattern)
            return value;

        let d = parse(value, 'dd-MM-yyyy HH:mm', new Date());
        if (!isValid(d))
            return value;

        return format(d, pattern).toString();
    }
}
