import { Component, Input, Output, EventEmitter, OnInit, Inject } from '@angular/core';
import { StateService, UIRouterGlobals } from '@uirouter/angular';

import { clone } from 'src/tier/tier.utils';

@Component({
    selector: 'tierfilter',
    template: `
        <div class="card mb-4 mt-2">
            <div class="card-header" (click)="isHidden = !isHidden">
                <div class="hstack">
                    <div class="">
                        Query Filter
                    </div>
                    <div class="ms-auto">
                        Click header to
                        <b [hidden]="!isHidden">Open</b>
                        <b [hidden]="isHidden">Close</b>
                        <i class="fa fa-arrow-down ms-1" [ngClass]="{'fa-arrow-down': isHidden, 'fa-arrow-up': !isHidden}"></i>
                    </div>
                </div>
            </div>

            <div class="card-body" #collapse="ngbCollapse" [(ngbCollapse)]="isHidden">
                <form name="filterForm" #filterForm="ngForm" novalidate autocomplete="none" (ngSubmit)="apply()">
                    <div class="col-sm-12 row">
                        <div *ngFor="let control of config" class="col-sm-6 col-lg-4 mb-3">
                            <div *ngIf="control.formControl === 'search'"><tierfiltersearch [name]="control.name" [label]="control.label" [(ngModel)]="data[control.value]"></tierfiltersearch></div>
                            <div *ngIf="control.formControl === 'dropdown'"><tierfilterdropdown [name]="control.name" [source]="control.source" [placeholder]="control.placeHolder" [label]="control.label" [(ngModel)]="data[control.value]" [httpparams]="control.httpparams" [objectList]="control.isObjectList" [bindValue]="control.bindValue"></tierfilterdropdown></div>
                            <div *ngIf="control.formControl === 'date'"><tierfilterdate [name]="control.name" [label]="control.label" [(ngModel)]="data[control.value]" [(equality)]="data[control.equality]"></tierfilterdate></div>
                            <div *ngIf="control.formControl === 'tickbox'"><tierfiltertickbox [name]="control.name" [label]="control.label" [(ngModel)]="data[control.value]"></tierfiltertickbox></div>
                        </div>
                        <div class="hstack ms-2">
                            <div class="ms-auto">
                                <button class="btn btn-secondary me-2" type="reset" (click)="reset($event)">Reset</button>
                                <button class="btn btn-primary" type="submit" [disabled]="filterForm.invalid">Apply Filter</button>
                            </div>
                        </div>
                    </div>
                </form>
            </div>
        </div>`,
})
export class TIERFilterComponent implements OnInit {
    @Input() config : Array<any> | null = [];
    @Input() isPageFilter : boolean = false;

    @Input() data : any = {};
    @Output() dataChange : EventEmitter<any> = new EventEmitter<any>();

    @Output() fetch : EventEmitter<object> = new EventEmitter();

    public isHidden : boolean = true;
    private defaultValues : any = null;

    constructor(
        @Inject(StateService) private state : StateService,
        @Inject(UIRouterGlobals) private global : UIRouterGlobals
    ) {};

    ngOnInit(): void {
        this.defaultValues = clone(this.data);
    }

    public reset(event : Event) {
        event.preventDefault();
        event.stopPropagation();

        if(this.isPageFilter) {
            this.state.go(this.global.current, { 'parentId': this.data?.parentId }, { inherit: false });
        } else {
            this.data = clone(this.defaultValues);
            this.dataChange.emit(this.data);
            this.apply();
        }
    }

    public apply() {
        if(this.isPageFilter) {
            this.state.go(this.global.current, this.data);
        } else {
            this.fetch.emit(this.data);
        }
    }
}
