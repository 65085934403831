'use strict'

import { Component, Inject, EventEmitter, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';

import { TIERAPICalls, TIERToast, TIERMessages } from '../../services';
import { MessageModel } from '../../models/';
import { isNorU, http2Error } from 'src/tier/tier.utils';

@Component({
    selector: 'tier-messages',
    templateUrl: "./messages.template.html"
})
export class MessagesComponent {
    @ViewChild('messageForm') messageForm!: NgForm;

    public message : MessageModel = { };
    public selectedMessageId : number = -1;
    public refreshEmitter : EventEmitter<string | number> = new EventEmitter();

    constructor(
        @Inject(TIERAPICalls) private apicall : TIERAPICalls,
        @Inject(TIERMessages) private messages : TIERMessages,
        @Inject(TIERToast) private alert : TIERToast
        ) {}

    public get(id : number) : void {
            if(id === -1) {
                this.clean();
                return;
            }

            this.apicall.get<MessageModel>('api/messages/%s'.formUri(id)).subscribe({
                next: (response : MessageModel) => {
                    this.message = response;
                    this.messageForm.form.markAsPristine();
                },
                error: (error) => {
                    this.alert.error(http2Error(error));
                }
            });
    }

    public clean() : void {
        this.message = {};
        this.messageForm.form.markAsPristine();
    }

    private finish(id : number | undefined, message : string) {

        if(id) {
            this.refreshEmitter.emit(id);
            this.get(id);
        }

        this.alert.success(message);
        this.messages.RefreshEvent.emit();
    }

    public save() : void {
            this.apicall.save<MessageModel>('api/messages/%s'.formUri(this.message.Id), this.message).subscribe({
                next: (response : MessageModel) => {
                    let id = isNorU(response) ? this.message?.Id : response.Id;

                    this.finish(id, 'Message Saved');
                },
                error: (error) => {
                    this.alert.error(http2Error(error));
                }
            });
    }

    public delete() : void {
            this.apicall.delete<MessageModel>('api/messages/%s'.formUri(this.message.Id)).subscribe({
                next: () => {
                    this.finish(-1, 'Message deleted.')
                },
                error: (error) => {
                    this.alert.error(http2Error(error));
                }
            });
    }

    public sendAgain() : void {
            this.apicall.delete<MessageModel>('api/messages/resend/%s'.formUri(this.message.Id)).subscribe({
                next: () => {
                    this.finish(this.message.Id, 'Message resent.')
                },
                error: (error) => {
                    this.alert.error(http2Error(error));
                }
            });
    }
}
