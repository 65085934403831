'use strict'

import { Component, Input, Inject, ViewChild, AfterContentInit } from '@angular/core';
import { NgForm } from '@angular/forms';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { HttpHeaders } from '@angular/common/http';
import {
    Observable,
    OperatorFunction,
    debounceTime,
    distinctUntilChanged,
    switchMap,
    tap,
    catchError,
    of
} from 'rxjs';
import { parse, isValid, compareAsc } from "date-fns";
import { NgModel } from '@angular/forms';

import { IncidentAttendeeModel, IncidentModel, VenueModel } from '../../models/';
import { TIERAPICalls, TIEROptions, TIERConfig } from '../../services';
import { isNorU } from 'src/tier/tier.utils';
import { ConcludeIncidentComponent } from './';

@Component({
    selector: 'tier-editincindents',
    templateUrl: "./editincidents.template.html"
})
export class EditIncidentsComponent implements AfterContentInit {
    @ViewChild('incidentForm') incidentForm!: NgForm;
    @ViewChild('startDate') startDate!: NgModel;
    @ViewChild('endDate') endDate!: NgModel;

    @Input() incident : IncidentModel = { IncidentSubjects: [], IncidentAttendees: [], IncidentMessages: [] };

    public isCare : boolean = false;
    public noTrackTimeOption : boolean = false;
    public searchFailed : boolean = false;
    public hasDuplicateAttendees : boolean = false;

    getNamedLocation : OperatorFunction<string, readonly string[]> =
        (text$: Observable<string>) =>
            text$.pipe(
            debounceTime(200),
            distinctUntilChanged(),
            switchMap(term =>
                term.length < 2 ? [] : this.apicall.post<readonly string[]>('api/namedlocations/filter/', JSON.stringify(term), undefined, new HttpHeaders({ 'Content-Type': 'application/json; charset=UTF-8' })).pipe(
                tap(() => (this.searchFailed = false)),
					catchError(() => {
						this.searchFailed = true;
						return of([]);
					}),
                )
                )
            )

    constructor(
        @Inject(TIERAPICalls) private apicall : TIERAPICalls,
        @Inject(NgbActiveModal) public activeModal : NgbActiveModal,
        @Inject(TIERConfig) private config : TIERConfig,
        @Inject(TIEROptions) private options : TIEROptions,
        @Inject(NgbModal) private modalService : NgbModal
      ) {
            this.isCare = this.config.get('variant') === 'care';
            this.noTrackTimeOption = this.options.get('org-incident-no-track-time') === "true";
        }

    ngAfterContentInit(): void {
        this.checkForDuplicates();
    }

    public CreateVenueIfNull(path : keyof Pick<IncidentModel, "RvLocation" | "ActivityLocation">, ref : keyof VenueModel, value : any) : void {
        this.incident[path] = {};

        if(isNorU(value)) {
            this.incident[path] = null;
            this.searchFailed = false;
        } else {
            this.incident[path]![ref] = value;
        }
    }

    public save(status : boolean = false) : void {
        this.activeModal.close({ 'incident': this.incident, 'status': status });
    }

    public delete() : void {
        this.incident.IsActive = !this.incident.IsActive;
        this.save();
    }

    public complete() : void {
        const modalRef = this.modalService.open(ConcludeIncidentComponent);

        modalRef.componentInstance.incident = this.incident;

        modalRef.result.then((result : { incident: IncidentModel, status: boolean }) => {
            this.incident = result.incident;
            this.incident.HasConclusion = true;

            this.save(result.status);
        }).catch(() => {});
    }

    public timeHasChanged(ele : any, event : any) : void {
        let cDate, sDate, eDate : Date | null = null;

        if(isNorU(event) || this.noTrackTimeOption)
            return;

        try {
            cDate = parse(event, 'dd-MM-yyyy HH:mm', new Date());
        } catch(e) {
            return;
        }

        if(!Array.isArray(this.incident.IncidentAttendees) || !isValid(cDate))
            return;

        let errors : string[] = [];
        this.incident.IncidentAttendees.forEach((attendee : IncidentAttendeeModel) => {
            try {
                sDate = parse(attendee.Start!, 'dd-MM-yyyy HH:mm', new Date());
                eDate = parse(attendee.End!, 'dd-MM-yyyy HH:mm', new Date());
            } catch(e) {
                return;
            }

            if(!isValid(sDate) || !isValid(eDate))
                return;

            if(compareAsc(cDate, sDate) === -1 || compareAsc(cDate, eDate) === 1)
                 errors.push(attendee.TeamMember?.FullName!)
        })

        if(errors.length > 0 && ele.control) {
            ele.control.setErrors({ ...ele.control.errors || {}, 'outofbounds': errors });
        } else {
            ele.control.setErrors(null);
        }
    }

    public checkForDuplicates() : void {
        if(!this.noTrackTimeOption)
            return;

        let duplicates :{ [name : string]: number } = {};

        if(!Array.isArray(this.incident.IncidentAttendees))
            return;

        this.incident.IncidentAttendees.forEach((attendee) => {
            if(attendee.TeamMember?.FullName && isNorU(duplicates[attendee.TeamMember.FullName])) {
                duplicates[attendee.TeamMember.FullName] = 1;
            } else {
                if(attendee.TeamMember?.FullName)
                    duplicates[attendee.TeamMember.FullName]++;
            }
        });

        this.hasDuplicateAttendees = false;
        Object.keys(duplicates).forEach((dup) => {
            if(duplicates[dup] > 1)
                this.hasDuplicateAttendees = true;
        })

        setTimeout(() => {
            if(this.hasDuplicateAttendees && this.incidentForm?.control) {
                this.incidentForm.control.setErrors({ ...this.incidentForm.control.errors || {}, 'duplicateattendees': true });
            } else {
                if(this.incidentForm.control.hasError('duplicateattendees'))
                    this.incidentForm.control.setErrors(null);
            }}
        );
    }
}
