import { Component, OnInit, Inject, Input } from '@angular/core';
import { StateService, Transition } from '@uirouter/angular';

import { TIERAPICalls, TIERToast, TIERDialog } from '../../services';
import { EquipmentTemplateModel } from '../../models';
import { http2Error } from 'src/tier/tier.utils';
import { isNorU } from 'src/tier/tier.utils';

@Component({
    selector: 'tier-equipment-template',
    templateUrl: "./equipmenttemplate.template.html"
})
export class EquipmentTemplateComponent implements OnInit {
    private templateId : number = this.trans.params()?.templateId;

    public template : EquipmentTemplateModel = { AllowedRoles: [], EquipmentTemplateAttributes: [], EquipmentTemplateCategories: [], IsActive: true, IsContainer: false, IsAllocatable: true, IsAllowedRecursiveCheck: false };

    constructor(
        @Inject(TIERAPICalls) private apicall : TIERAPICalls,
        @Inject(TIERToast) private alert : TIERToast,
        @Inject(StateService) private state : StateService,
        @Inject(TIERDialog) private dialog : TIERDialog,
        @Inject(Transition) private trans : Transition
    ) { }

    ngOnInit(): void {
        if(!this.templateId)
            return;

        this.apicall.get<EquipmentTemplateModel>('api/equipment/template/%s'.formUri(this.templateId)).subscribe({
            next: (response : EquipmentTemplateModel) => {
                this.template = response;
            },
            error: (error) => {
                this.alert.error(http2Error(error));
            }
        });
    }

    public cancel() {
        this.state.go('equipment@templates@view');
    }

    private _save(id : number | undefined, flip : boolean) {
        if(flip)
            this.template.IsActive = !this.template.IsActive;

        this.apicall.save<EquipmentTemplateModel>('api/equipment/template/%s'.formUri(id), this.template).subscribe({
            next: () => {
                this.cancel();
                if(isNorU(id)) {
                    this.alert.success("Equipment template added.")
                } else {
                    this.alert.success("Equipment template edited.")
                }
            },
            error: (error) => {
                this.alert.error(http2Error(error));
            }
        });
    }

    public save(id : number | undefined, flip : boolean = false) {
        if(flip && this.template.IsActive) {
            this.dialog.showConfirmDialog(
                "Making template inactive",
                "Are you sure you wish make to make this template inactive? You will no longer be able to create new resources from this template but current resource will be uneffected."
            ).then(() => this._save(id, flip)).catch(() => {});
        } else {
            this._save(id, flip);
        }
    }
}
