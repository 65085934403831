
import {
    DashboardComponent,
    EmergencyContactsComponent,
    RoleLayoutComponent,
    LoginComponent,
    PrivacyComponent,
    TandcComponent,
    RolesComponent,
    TeammembersComponent,
    MessagesComponent,
    ContactsComponent,
    AuditLogComponent,
    OrgSettingsComponent,
    ModulesComponent,
    AssessmentsComponent,
    SessionsComponent,
    SessionsAttendenceComponent,
    SessionsCompleteComponent,
    ScheduleComponent,
    ScheduleCalendarComponent,
    ScheduleListComponent,
    IncidentsComponent,
    RespondingComponent,
    EquipmentTemplatesComponent,
    EquipmentTemplateComponent,
    EquipmentResourcesComponent,
    EquipmentResourceEditComponent,
    EquipmentResourceAddComponent,
    EquipmentResourceHistoryComponent,
    EquipmentCheckoutComponent,
    ReportBuilderComponent,
    ReportsComponent } from './components';

import { TIERAuth, TIEROptions } from './services';
import { StateService, Transition } from '@uirouter/angular';
import { environment } from '../environments/environment';

const isCare : boolean = environment.variant === "care";

export const optionsResolver = {
    token: 'options',
    deps: [TIEROptions],
    resolveFn: (options : TIEROptions) => options.resolve()
};

export const authResolver = {
    token: 'auth',
    deps: [TIERAuth, StateService],
    resolveFn: (auth : TIERAuth, state : StateService) => {
            if(auth.isLoggedIn()) {
                return Promise.resolve();
            } else {
                state.go('login');
                return Promise.reject("Not Authenticated");
            }
        }
    };

export const routes : any = [
    {
        'name': 'dashboard',
        'url': '/dashboard',
        'component': DashboardComponent,
        'resolve': [
            authResolver,
            optionsResolver
        ],
        'data': {
            'label': 'Dashboard'
        }
    },
    {
        'name': 'privacy',
        'path': '/privacy',
        'component': PrivacyComponent,
        'data': {
            'label': 'Privacy Notice'
        }
    },
    {
        'name': 'tandc',
        'url': '/tandc',
        'component': TandcComponent,
        'data': {
            'label': 'Terms & Conditions'
        }
    },
    {
        'name': 'management@roles',
        'url': '/management/roles',
        'component': RolesComponent,
        'resolve': [
            authResolver
        ],
        'data': {
            'label': 'Management/Roles'
        }
    },
    {
        'name': 'management@teammembers',
        'url': '/management/teammembers',
        'component': TeammembersComponent,
        'resolve': [
            authResolver,
            optionsResolver,
            {
                token: 'state',
                resolveFn: () => 'admin'
            }
        ],
        'data': {
            'label': 'Management/Team Members',
        }
    },
    {
        'name': 'management@messages',
        'url': '/management/messages',
        'component': MessagesComponent,
        'resolve': [
            authResolver
        ],
        'data': {
            'label': 'Management/Messages'
        }
    },
    {
        'name': 'management@contacts',
        'url': '/management/contacts',
        'component': ContactsComponent,
        'resolve': [
            authResolver
        ],
        'data': {
            'label': 'Management/Contacts'
        }
    },
    {
        'name': 'management@auditlog',
        'url': '/management/auditlog',
        'component': AuditLogComponent,
        'resolve': [
            authResolver
        ],
        'data': {
            'label': 'Management/Audit Log'
        }
    },
    {
        'name': 'management@settings',
        'url': '/management/settings',
        'component': OrgSettingsComponent,
        'resolve': [
            authResolver,
            optionsResolver
        ],
        'data': {
            'label': 'Management/Settings'
        }
    },
    {
        'name': 'account@profile',
        'url': '/account/profile',
        'component': TeammembersComponent,
        'resolve': [
            authResolver,
            optionsResolver,
            {
                'token': 'state',
                'resolveFn': () => 'user'
            }
        ],
        'data': {
            'label': 'Account/Profile',
        }
    },
    {
        'name': 'account@equipmentcheckout',
        'url': '/account/checkout/?{parentId}&{search}&{teammemberId}&{categoryid}&{conditionId}&{templateId}&{lastChecked}&{expiry}&{recursive}&{allocated}',
        'component': EquipmentCheckoutComponent,
        'resolve': [
            authResolver
        ],
        'data': {
            'label': 'Account/Equipment Checkout'
        },
        'params': {
            parentId: {
                type: 'int',
                value: null,
                squash: true,
                ignore: true
            },
            search: {
                type: 'string',
                value: '',
                squash: true
            },
            teammemberId: {
                type: 'string',
                value: '',
                squash: true
            },
            categoryId: {
                type: 'int',
                value: null,
                squash: true
            },
            conditionId: {
                type: 'int',
                value: null,
                squash: true
            },
            templateId: {
                type: 'int',
                value: null,
                squash: true
            },
            lastChecked: {
                type: 'string',
                value: 'all',
                squash: true
            },
            expiry: {
                type: 'string',
                value: 'all',
                squash: true
            },
            recursive: {
                type: 'bool',
                value: false,
                squash: true
            },
            allocated: {
                type: 'int',
                value: 0,
                squash: true
            }
        }
    },
    {
        'name': 'management@emergencycontacts',
        'url': 'management/emergencycontacts',
        'component': EmergencyContactsComponent,
        'resolve': [
            authResolver
        ],
        'data': {
            'label': 'Management/Emergency Contacts for Team Members'
        }
    },
    {
        'name': 'reports@reportbuilder',
        'url': '/reports/reportbuilder',
        'component': ReportBuilderComponent,
        'resolve': [
            authResolver
        ],
        'data': {
            'label': 'Reports/Custom'
        }
    },
    {
        'name': 'reports@index',
        'url': '/reports/{report}',
        'component': ReportsComponent,
        'resolve': [
            authResolver
        ],
        'data': {
            'label': 'Reports/Supplied'
        },
        'params': {
            'report': {
                'type': "string",
                'isOptional': true,
                'value': '',
                'squash': true
            }
        }
    },
    {
        'name': 'training@rolelayout',
        'url': '/training/rolelayout',
        'component': RoleLayoutComponent,
        'resolve': [
            authResolver
        ],
        'data': {
            'label': 'Training/Role Layout'
        }
    },
    {
        'name': 'training@modules',
        'url': '/training/modules',
        'component': ModulesComponent,
        'resolve': [
            authResolver
        ],
        'data': {
            'label': 'Training/Modules'
        }
    },
    {
        'name': 'training@assessment',
        'url': '/training/assessment',
        'component': AssessmentsComponent,
        'resolve': [
            authResolver,
            optionsResolver
        ],
        'data': {
            'label': 'Training/Assessments'
        }
    },
    {
        'name': 'training@sessions',
        'url': '/training/sessions',
        'component': SessionsComponent,
        'resolve': [
            authResolver,
            optionsResolver
        ],
        'data': {
            'label': 'Training/Sessions'
        }
    },
    {
        'name': 'training@sessionsattendence',
        'url': '/training/sessionattendence/{id:int}',
        'component': SessionsAttendenceComponent,
        'resolve': [
            authResolver
        ],
        'data': {
            'label': 'Training/Session Attendence'
        }
    },
    {
        'name': 'training@sessionscomplete',
        'url': '/training/sessionscomplete/{id:int}',
        'component': SessionsCompleteComponent,
        'resolve': [
            authResolver
        ],
        'data': {
            'label': 'Training/Complete Session'
        }
    },
    {
        'name': 'training@schedule',
        'url': '/training/schedule',
        'component': ScheduleComponent,
        'resolve': [
            authResolver
        ],
        'data': {
            'label': 'Training/Schedule',
        }
    },
    {
        'name': 'training@schedule.list',
        'url': '/list',
        'component': ScheduleListComponent,
        'data': {
            'label': 'Training/Schedule/List',
        }
    },
    {
        'name': 'training@schedule.calendar',
        'url': '/calendar',
        'component': ScheduleCalendarComponent,
        'data': {
            'label': 'Training/Schedule/Calendar',
        }
    },
    {
        'name': 'incidents@incidents',
        'url': '/incidents/incidents',
        'component': IncidentsComponent,
        'resolve': [
            authResolver,
            optionsResolver
        ],
        'data': {
            'label': isCare ? 'Incident Control' : 'Incidents/Incidents'
        }
    },
    {
        'name': 'incidents@responding',
        'url': '/incidents/responding',
        'component': RespondingComponent,
        'resolve': [
            authResolver,
            optionsResolver
        ],
        'data': {
            'label': isCare ? 'Active Incidents' : 'Incidents/Responding'
        }
    },
    {
        'name': 'equipment@templates@view',
        'url': '/equipment/templates',
        'component': EquipmentTemplatesComponent,
        'resolve': [
            authResolver,
            {
                'token': 'newLink',
                'resolveFn': () => 'equipment@templates@add'
            },
            {
                'token': 'selectLink',
                'resolveFn': () => 'equipment@templates@edit'
            }
        ],
        'data': {
            'label': 'Equipment/Templates'
        }
    },
    {
        'name': 'equipment@templates@add',
        'url': '/equipment/templates/add',
        'component': EquipmentTemplateComponent,
        'resolve': [
            authResolver
        ],
        'data': {
            'label': 'Equipment/Template/Add'
        }
    },
    {
        'name': 'equipment@templates@edit',
        'url': '/equipment/templates/edit/{templateId}',
        'component': EquipmentTemplateComponent,
        'resolve': [
            authResolver
        ],
        'data': {
            'label': 'Equipment/Template/Edit'
        },
        params: {
            templateId: {
                type: 'int'
            }
        }
    },
    {
        'name': 'equipment@resources@view',
        'url': '/equipment/resources/?{parentId}&{search}&{teammemberId}&{conditionId}&{templateId}&{categoryid}&{lastChecked}&{expiry}&{recursive}&{isActive}&{allocated}',
        'component': EquipmentResourcesComponent,
        'resolve': [
            authResolver,
            {
                'token': 'newLink',
                'resolveFn': () => 'equipment@resources@templateselect'
            },
            {
                'token': 'viewLink',
                'resolveFn': () => 'equipment@resources@view'
            },
            {
                'token': 'selectLink',
                'resolveFn': () => 'equipment@resources@edit'
            }
        ],
        'data': {
            'label': 'Equipment/Resources'
        },
        params: {
            parentId: {
                type: 'int',
                value: null,
                squash: true,
                ignore: true
            },
            search: {
                type: 'string',
                value: '',
                squash: true
            },
            categoryId: {
                type: 'int',
                value: null,
                squash: true
            },
            teammemberId: {
                type: 'string',
                value: '',
                squash: true
            },
            conditionId: {
                type: 'int',
                value: null,
                squash: true
            },
            templateId: {
                type: 'int',
                value: null,
                squash: true
            },
            lastChecked: {
                type: 'string',
                value: 'all',
                squash: true
            },
            expiry: {
                type: 'string',
                value: 'all',
                squash: true
            },
            isActive: {
                type: 'bool',
                value: false,
                squash: true
            },
            recursive: {
                type: 'bool',
                value: false,
                squash: true
            },
            allocated: {
                type: 'int',
                value: 0,
                squash: true
            }
        }
    },
    {
        'name': 'equipment@resources@templateselect',
        'url': '/equipment/templates/select/{parentId}',
        'component': EquipmentTemplatesComponent,
        'resolve': [
            authResolver,
            {
                'token': 'selectLink',
                'resolveFn': () => 'equipment@resources@add'
            },
            {
                'token': 'cancelLink',
                'resolveFn': () => 'equipment@resources@view'
            },
            {
                'token': 'selectOpts',
                'deps': [ Transition ],
                'resolveFn': (trans : Transition) => {
                    return { parentId: trans.params()?.parentId };
                }
            },
            {
                'token': 'cancelOpts',
                'deps': [ Transition ],
                'resolveFn': (trans : Transition) => {
                    return { parentId: trans.params()?.parentId };
                }
            }
        ],
        'data': {
            'label': 'Equipment/Resources/Template Select'
        },
        params: {
            parentId: {
                type: 'int',
                value: null,
                squash: true
            }
        }
    },
    {
        'name': 'equipment@resources@add',
        'url': '/equipment/resources/add/{parentId}/template/{templateId}',
        'component': EquipmentResourceAddComponent,
        'resolve': [
            authResolver
        ],
        'data': {
            'label': 'Equipment/Resources/Add'
        },
        'params': {
            'parentId': {
                type: 'int',
                value: null,
                squash: true
            },
            'templateId': {
                type: 'int'
            }
        }
    },
    {
        'name': 'equipment@resources@edit',
        'url': '/equipment/resources/edit/{id}',
        'component': EquipmentResourceEditComponent,
        'resolve': [
            authResolver
        ],
        'data': {
            'label': 'Equipment/Resources/Edit'
        },
        'params': {
            'id': {
                type: 'int',
                value: null,
                squash: true
            }
        }
    },
    {
        'name': 'equipment@resources@history',
        'url': '/equipment/resource/history/{id}',
        'component': EquipmentResourceHistoryComponent,
        'resolve': [
            authResolver
        ],
        'data': {
            'label': 'Equipment/Resource History'
        },
        'params': {
            'id': {
                type: 'int',
                value: null,
                squash: true
            }
        }
    },
    {
        'name': 'account@training',
        'url': '/account/training',
        'component': AssessmentsComponent,
        'resolve': [
            authResolver,
            optionsResolver,
            {
                'token': 'state',
                'resolveFn': () => 'user'
            }
        ],
        'data': {
            'label': 'Account/Training Assessments'
        }
    },
    {
        'name': 'login',
        'url': '/login',
        'component': LoginComponent,
        'data': {
            'label': 'Login'
        }
    }
];
