<div class="d-print-none mb-3 d-flex justify-content-end">
    <TIERExtendButtons class="me-3" (extend)="extendAll()" (collapse)="collapseAll()"></TIERExtendButtons>
    <div>
        <div class="form-group">
            <input type="text" class="form-control"
                placeholder="Search..."
                [(ngModel)]="search"
                minlength="2"
                maxlength="20">
        </div>
    </div>
</div>
<div *ngIf="report.length">
    <div *ngFor="let r of report">
        <div class="card text-bg-info mb-2">
            <div class="card-body fw-bold">
                {{r.Name}}
            </div>
        </div>
        <div class="alert alert-warning" role="alert" *ngIf="!r.Teammembers">
            <i class="fa-solid fa-exclamation-circle"></i> No active team members in role with assessed skills.
        </div>
        <div *ngFor="let tm of r.Teammembers">
            <tier-reportpane title="{{tm.FullName}}" *ngIf="tm.Skills" [isHidden]="tm.Collapse">
                <table class="table">
                    <thead TIERCustomTableHeader [format]="[
                                        { id: 'Name', name: 'Module' },
                                        { id: 'IsCritical', name: 'Critical' },
                                        { id: 'Obtained', name: 'Assess Date' },
                                        { id: 'Expires', name: 'Expiry Date' },
                                        { id: 'AssessmentBased', name: 'Type' },
                                        { id: 'IsPassed', name: 'Passed' },
                                        { id: 'IsValid', name: 'Valid' } ]"
                        [(table)]="tm.Skills">
                    </thead>
                    <tbody>
                        <tr *ngFor="let sk of tm.Skills | search: search" [ngClass]="{ 'table-danger': !sk.IsValid, 'table-success': sk.IsValid }">
                            <td>{{sk.Name}}</td>
                            <td *ngIf="sk.IsCritical === true"><i class="fa-solid fa-check"></i></td>
                            <td *ngIf="sk.IsCritical === false"><i class="fa-solid fa-times"></i></td>
                            <td>{{sk.Obtained | transformdate: 'dd-MM-yyyy'}}</td>
                            <td>{{(sk.Expires | transformdate: 'dd-MM-yyyy') || 'N/A'}}</td>
                            <td *ngIf="sk.AssessmentBased === true"><span class="badge text-bg-primary">Assessment</span></td>
                            <td *ngIf="sk.AssessmentBased === false"><span class="badge text-bg-primary">Attendence</span></td>
                            <td *ngIf="sk.IsPassed === true && sk.AssessmentBased === true"><i class="fa-solid fa-check"></i></td>
                            <td *ngIf="sk.IsPassed === false && sk.AssessmentBased === true"><i class="fa-solid fa-times"></i></td>
                            <td *ngIf="sk.AssessmentBased === false">N/A</td>
                            <td *ngIf="sk.IsValid === true"><i class="fa-solid fa-check"></i></td>
                            <td *ngIf="sk.IsValid === false"><i class="fa-solid fa-times"></i></td>
                        </tr>
                    </tbody>
                </table>
            </tier-reportpane>
        </div>
    </div>
    <div class="d-flex justify-content-center my-3 d-print-none" *ngIf="params.Amount">
        <ngb-pagination [collectionSize]="total" [(page)]="params.Page" [pageSize]="params.Amount" [maxSize]="5" [rotate]="true" [ellipses]="false" [boundaryLinks]="true" (pageChange)="reload()" />
    </div>
</div>

<div class="alert alert-warning" role="alert" [hidden]="report.length">
    <i class="fa-solid fa-exclamation-circle"></i> Search produced no results
</div>

<TIERReportButtons (print)="print()" (export)="export('RolesReport')"></TIERReportButtons>
