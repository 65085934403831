<div id="dashboard-content">
    <div class="row hidden-print mb-2">
        <div class="hstack">
            <div class="d-none d-md-flex" *ngIf="!layout.isEditing">
                <div class="pe-2">
                    <label class="col-form-label fw-bold">Refresh every:</label>
                </div>
                <div class="flex-grow-1">
                    <tierdropdown
                        [source]="refreshTimes"
                        [(ngModel)]="selectedRefreshTime"
                        (ngModelChange)="setRefresh($event)"
                        placeholder="Choose refresh rate"
                        [allowNew] = false
                        bindId="id"
                        (clear)="clearRefresh()"
                    ></tierdropdown>
                </div>
            </div>
            <div class="ms-auto px-0">
                <div>
                    <button *ngIf="!layout.isEditing" type="button" class="btn btn-info me-1 d-none d-md-inline" (click)="toggleFullscreen()">
                        Go Fullscreen
                    </button>
                    <button *ngIf="layout.isEditing" type="button" class="btn btn-success me-1" (click)="toggleWidgetMenu()">
                        <b [hidden]="sideMenuToggle">Open </b>
                        <b [hidden]="!sideMenuToggle">Close </b>
                        Widget Menu
                    </button>
                    <button *ngIf="layout.isEditing" type="button" class="btn btn-danger me-1" (click)="cancel()">
                        Cancel
                    </button>
                    <button type="button" class="btn btn-primary" (click)="changeLayout(false)">
                        {{layout.buttonText}}
                    </button>
                </div>
            </div>
        </div>
    </div>

    <div id="dashboard-window">
        <tierpanelslider [isOpen]="sideMenuToggle">
            <div class="widget-menu">
                <div class="container-fluid">
                    <i class="fa fa-times grid-close-button" (click)="sideMenuToggle = false"></i>
                </div>
                <div class="widget-title-container"><h1 class="widget-title">Widgets</h1></div>
                <div class="widgetmenu-panel">
                    <div *ngFor="let section of widgets">
                        <div class="section-title-container"><h2 class="widget-section">{{section.Category}}</h2></div>
                        <div *ngFor="let widget of section.Items">
                            <div class="card border-success mb-2">
                                <div class="card-header">{{widget.Title}}</div>
                                <div class="card-body">
                                    <div>
                                        {{widget.Description}}
                                    </div>
                                    <hr />
                                    <div class="float-end">
                                        <button *ngIf="alreadyDeployed(widget) === false" type="button" (click)="addWidgetItem(widget)" class="btn btn-primary btn-sm">Add to dashboard</button>
                                        <button *ngIf="alreadyDeployed(widget) === true" type="button" (click)="removeAllWidgetOfType(widget)" class="btn btn-danger btn-sm">Remove from dashboard</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </tierpanelslider>
        <gridster class="grid" [options]="gridster2Options" TIERMaximize #MaximizeFrame="TIERMaximize">
            <gridster-item class="grid-item" [item]="item" *ngFor="let item of dashboard">
                <div class="grid-header">
                    <div class="grid-handle">
                        <div class="textoverflow">{{item.title}}</div>
                    </div>

                    <i *ngIf="layout.isEditing" class="fa fa-times grid-close-button" (click)="delWidget(item)"></i>
                </div>
                <div class="grid-body widgetoverflow">
                    <ng-container *ngComponentOutlet="item.component; inputs: { widget: item };"></ng-container>
                </div>
            </gridster-item>
        </gridster>
    </div>
</div>
