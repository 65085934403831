<div class="d-print-none mb-3 d-flex justify-content-end" autocomplete="none">
    <div class="form-group">
        <input type="text" class="form-control"
               placeholder="Search..."
               [(ngModel)]="search"
               minlength="2"
               maxlength="20">
    </div>
</div>
<div *ngIf="report.length > 0">
    <table class="table">
        <thead TIERCustomTableHeader [format]="[
                                    { id: 'TeamMemberName', name: 'Team Member' },
                                    { id: 'IncidentCount', name: 'Incident Amount' },
                                    { id: 'IncidentTotalHours', name: 'Incident Total Hours' },
                                    { id: 'IncidentPercentageAgainstOrg', name: 'Incident Percentage' }]"
               [(table)]="report">
        </thead>
        <tbody>
            <tr *ngFor="let at of report | search: search">
                <td>{{at.TeamMemberName}}</td>
                <td>{{at.IncidentCount}}</td>
                <td>{{at.IncidentTotalHours}} Hrs</td>
                <td>{{at.IncidentPercentageAgainstOrg | number: '1.0-2'}} %</td>
            </tr>
        </tbody>
    </table>
    <div class="d-flex justify-content-center my-3 d-print-none" *ngIf="params.Amount">
        <ngb-pagination [collectionSize]="total" [(page)]="params.Page" [pageSize]="params.Amount" [maxSize]="5" [rotate]="true" [ellipses]="false" [boundaryLinks]="true" (pageChange)="reload()" />
    </div>
</div>

<div class="alert alert-warning" role="alert" [hidden]="report.length">
    <i class="fa-solid fa-exclamation-circle"></i> 0 results found.
</div>

<TIERReportButtons (print)="print()" (export)="export('IncidentAttendenceReport')"></TIERReportButtons>
