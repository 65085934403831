'use strict';

import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';

import { clone } from '../tier.utils';

@Injectable({
    providedIn: 'root'
})
export class TIERConfig
{
    private config = environment;

    public get(...args : (keyof typeof this.config)[]) : any {
        let entry : any = this.config;
        for(let i = 0; i < args.length; i++) {
            entry = entry[args[i]];
        }
        return clone(entry);
    }
}
