'use strict'

import { Component, OnInit } from '@angular/core';
import { parse, addMonths, differenceInMonths } from "date-fns";
import { WidgetBase } from '../../Widget.base';
import { http2Error } from 'src/tier/tier.utils';

@Component({
    selector: 'tier-widget-all-mymodules',
    template: `
    <tierspinner [name]="getUUID()"></tierspinner>
    <table class="table table-borderless table-hover table-condensed">
        <thead>
            <tr>
                <th>Module</th>
                <th>Critical</th>
                <th>Obtained</th>
                <th>Expires</th>
                <th>Next</th>
            </tr>
        </thead>
        <tbody>
            <tr *ngFor="let module of modules" [ngClass]="module.Class">
                <td>{{module.Module}}</td>
                <td>
                    <i class="fa fa-check" *ngIf="module.Critical === true"></i>
                    <i class="fa fa-times" *ngIf="module.Critical === false"></i>
                </td>
                <td>{{module.Obtained | transformdate: 'dd-MM-yyyy'}}</td>
                <td>{{module.Expires | transformdate: 'dd-MM-yyyy'}}</td>
                <td>{{module.NextSession | transformdate: 'dd-MM-yyyy'}}</td>
            </tr>
        </tbody>
    </table>`,
    styleUrls: [ '../../_widgets.scss' ]
})
export class MyModulesWidgetComponent extends WidgetBase implements OnInit {
    static readonly widgetName = 'MyModulesWidgetComponent';
    public modules : any[] = [];

    ngOnInit(): void {
        this.callAPI<any[]>('api/dashboard/mymodules', this.getUUID()).subscribe({
            next: (response : any[]) => {
              this.modules = this.augument(response);
              if(this.modules.length <= 0) {
                this.switchToNoDataWidget();
              }
            },
            error: (error) => {
                this.alert.error(http2Error(error));
            }
        });
    }

    private augument(response : any) : any {
        response.forEach((value : any) => {
            if(value.AssessmentBasedFlag === true && value.Status === false) {
                value.Class = 'text-danger';
            };

            let diffFromToday = null;
            try {
                diffFromToday = differenceInMonths(addMonths(parse(value?.Obtained, "dd-MM-yyyy", new Date()), value.MonthsValidFor), new Date());
            } catch {
                return;
            }

            if(diffFromToday && diffFromToday <= 0) {
                value.Class = 'danger';
            } else if(diffFromToday && diffFromToday > 0 && diffFromToday <= 3) {
                value.Class = 'warning';
            } else if(diffFromToday && diffFromToday > 3) {
                value.Class = 'success';
            } else {
                value.Class = 'bg-info text-white';
            }
        });

        return response;
    }
}
