'use strict'

export class WidgetLibrary {
    static readonly WidgetRegistry : Map<string, any> = new Map();

    static RegisterWidget = (key : any): void => {
        WidgetLibrary.WidgetRegistry.set(key.widgetName, key);
    }

    static Get(key : string) : any {
        return WidgetLibrary.WidgetRegistry.get(key);
    }
}
