<div>
    <form #messageForm="ngForm" (ngSubmit)="save()" novalidate autocomplete="none" class="col-md-8 offset-md-2">
        <div class="row">
            <label class="col-sm-3 col-form-label fw-bold">Message</label>
            <div class="col-sm-9">
                  <tierdropdown
                        name="mdd"
                        source="api/messages"
                        placeholder="Select Message or Create New"
                        newLabel="Create New Message"
                        (ngModelChange)="get($event)"
                        (clear)="clean()"
                        [(ngModel)]="selectedMessageId"
                        [allowNew]=true
                        bindId="Id"
                        bindValue="Name"
                        [refresh]="refreshEmitter">
                    </tierdropdown>
            </div>
        </div>
        <hr>
        <div class="row mb-3">
            <label class="col-sm-3 col-form-label fw-bold">Message Type</label>
            <div class="col-sm-9">
                  <tierdropdown
                        name="mtdd"
                        #messageType="ngModel"
                        source="api/messagetypes"
                        placeholder="Select Message Type"
                        [(ngModel)]="message.MessageTypeId"
                        [allowNew]=false
                        bindId="Id"
                        bindValue="Name"
                        required>
                    </tierdropdown>
                    <p [hidden]="messageType.valid" class="form-text text-danger">Message type is required.</p>
            </div>
        </div>
        <div class="row mb-3">
            <label class="col-sm-3 col-form-label fw-bold">Message Text</label>
            <div class="col-sm-9">
                <textarea id="message" #messageText="ngModel" name="message" [ngClass]="{ 'is-invalid': messageText.invalid, 'is-valid': messageText.valid && !messageText.pristine }" class="form-control" rows="8" minlength="4" [(ngModel)]="message.Text" required></textarea>
                <p [hidden]="messageText.valid" class="form-text text-danger">Message name is required and must be more than 4 characters.</p>
            </div>
        </div>
        <div class="row mb-4">
            <div class="offset-sm-3 text-end col-sm-9">
                <button type="button" class="btn btn-secondary me-2"
                        (click)="sendAgain()"
                        [hidden]="!message.Id"
                        [disabled]=" messageForm.invalid">
                    Send Again
                </button>
                <button type="button" class='btn btn-danger  me-2'
                        (click)="delete()"
                        [hidden]="!message.Id"
                        [disabled]="messageForm.invalid">
                    Delete
                </button>
                <button type="submit" class="btn btn-primary"
                        [disabled]="messageForm.invalid">
                    Save
                </button>
            </div>
        </div>
    </form>
</div>
