<tierfilter [config]="filterConfig" [(data)]="values" (fetch)="get()"></tierfilter>
<div class="table-responsive" [hidden]="!entries.length">
    <table class="table">
        <thead>
            <tr>
                <th>Date</th>
                <th>Time</th>
                <th>Level</th>
                <th>Actioned By</th>
                <th>Message</th>
            </tr>
        </thead>
        <tbody>
            <tr *ngFor="let entry of entries">
                <td>{{entry.LoggedAt | transformdate: 'dd-MM-yyyy'}}</td>
                <td>{{entry.LoggedAt | transformdate: 'HH:mm'}}</td>
                <td>
                    <span class="badge" [ngClass]="{
                        'text-bg-info': entry.LevelName === 'INFO',
                        'text-bg-warning': entry.LevelName === 'WARN',
                        'text-bg-danger': entry.LevelName === 'ERROR'
                    }">{{entry.LevelName}}</span>
                </td>
                <td>{{entry.ActionedBy || "System"}}</td>
                <td>{{entry.Message}}</td>
            </tr>
        </tbody>
    </table>
    <div class="d-flex justify-content-center my-3">
        <ngb-pagination [collectionSize]="total" [(page)]="page" [pageSize]="values.amount" [maxSize]="5" [rotate]="true" [ellipses]="false" [boundaryLinks]="true" (pageChange)="get()" />
    </div>
</div>
<div class="alert alert-info" role="alert" [hidden]="entries.length">
    <i class="fa-solid fa-exclamation-circle"></i> 0 results found.
</div>
