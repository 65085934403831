
<div class="row mb-4">
    <div class="col-md-4 text-center"></div>
    <div class="col-md-4 text-center mb-3">
        <h3>{{ viewDate | calendarDate:(view + 'ViewTitle'): 'en' }}</h3>
        <div class="btn-group">
            <button type="button" class="btn btn-primary"
                    mwlCalendarPreviousView
                    [view]="view"
                    [(viewDate)]="viewDate"
                    (click)="activeDayIsOpen=false">
                <i class="fa-solid fa-long-arrow-alt-left fa-2x"></i>
            </button>
            <button type="button" class="btn btn-success"
                    mwlCalendarToday
                    [(viewDate)]="viewDate"
                    (click)="activeDayIsOpen=false">
                <i class="fa-solid fa-calendar fa-2x"></i>
            </button>
            <button type="button" class="btn btn-primary"
                    mwlCalendarNextView
                    [view]="view"
                    [(viewDate)]="viewDate"
                    (click)="activeDayIsOpen=false">
                <i class="fa-solid fa-long-arrow-alt-right fa-2x"></i>
            </button>
        </div>
    </div>
    <div class="col-sm-12 text-center">
        <div class="btn-group">
            <button type="button" class="btn btn-secondary btn-sm" (click)="setView(CalendarView.Month)">
                Month
            </button>
            <button type="button" class="btn btn-secondary btn-sm" (click)="setView(CalendarView.Week)">
                Week
            </button>
        </div>
    </div>
</div>
<div class="mb-4" [ngSwitch]="view">
    <mwl-calendar-month-view
        *ngSwitchCase="'month'"
        [viewDate]="viewDate"
        [events]="events"
        [refresh]="refresh"
        [activeDayIsOpen]="activeDayIsOpen"
        (dayClicked)="dayClicked($event.day)"
        (eventClicked)="eventClicked($event.event)"
        (beforeViewRender)="beforeMonthViewRender($event)">
    </mwl-calendar-month-view>
    <mwl-calendar-week-view
        *ngSwitchCase="'week'"
        [viewDate]="viewDate"
        [events]="events"
        [refresh]="refresh"
        (eventClicked)="eventClicked($event.event)">
    </mwl-calendar-week-view>
</div>
