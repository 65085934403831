import { Component, Inject, Input, EventEmitter, Output } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { SessionModel } from 'src/tier/models';

import { EquipmentResourceTreeSelectorComponent } from './equipmentresourcetreeselector.component'

@Component({
    selector: 'tier-equipment-treeselector-display',
    styleUrl: './equipmentresourcetreedisplay.component.scss',
    template: `
        <div class="hstack">
            <div class="eqtreedisplay-box me-2">
                <div class="eqtreedisplay-item" *ngFor="let equip of session.EquipmentResources | slice:0:5">
                    <span class="eqtreedisplay-label">{{equip.Reference}}</span>
                </div>
                <div class="eqtreedisplay-item" *ngIf="session.EquipmentResources && session.EquipmentResources.length > 5"><span class="eqtreedisplay-label">+{{session.EquipmentResources.length - 5}} more</span></div>
            </div>
            <div class="ms-auto">
                <button role="button" class="btn btn-info" (click)="equipmentResourceSelectModal()"><i class="fa-solid fa-box"></i></button>
            </div>
        </div>`
})
export class EquipmentResourceTreeDisplayComponent {
    @Input() session : SessionModel = {};
    @Output() sessionChange : EventEmitter<SessionModel> = new EventEmitter<SessionModel>();

    constructor(
        @Inject(NgbModal) private modalService : NgbModal,
      ) { }

    public equipmentResourceSelectModal() {
        const modalRef = this.modalService.open(EquipmentResourceTreeSelectorComponent, { size: 'xl' , scrollable: true });

        modalRef.componentInstance.equipment = this.session.EquipmentResources ?? [];

        modalRef.result.then((results : any) => {
            this.session.EquipmentResources = results;
            this.sessionChange.emit(this.session);
        }).catch(() => {});
    }
}
