import { forwardRef, Directive} from '@angular/core';
import { NG_VALIDATORS, Validator, AbstractControl, ValidationErrors } from '@angular/forms';

import { isNorU } from 'src/tier/tier.utils';

@Directive({
  selector: '[URLValidation]',
  providers: [
    {
      provide: NG_VALIDATORS,
      useExisting: forwardRef(() => URLValidationDirective),
      multi: true,
    }
  ]
})
export class URLValidationDirective implements Validator {
    private url : string | null = null;

    onChange: () => void = () => {};
    onTouched: () => void = () => {};

    validate(control: AbstractControl): ValidationErrors | null {
        if(isNorU(control.value))
            return null;

        try {
            new URL(control.value);
            return null;
        } catch (err) {
            return { 'URLValidation': { value: this.url } };
        }
    }

    registerOnValidatorChange(fn: () => void): void {
        this.onChange = fn;
    }
}
