import { Component, OnInit, Inject, Input } from '@angular/core';
import { SessionModel } from '../../models/';
import { Transition } from '@uirouter/angular';

import { TIERAPICalls, TIERToast } from '../../services';
import { http2Error } from 'src/tier/tier.utils';

@Component({
    selector: 'tier-training-sessionsattendence',
    templateUrl: "./sessionsattendence.template.html"
})
export class SessionsAttendenceComponent implements OnInit {
    private id : number = this.trans.params()?.id;

    public session : SessionModel = {};

    constructor(
        @Inject(TIERAPICalls) private apicall : TIERAPICalls,
        @Inject(TIERToast) private alert : TIERToast,
        @Inject(Transition) private trans : Transition) {}

    ngOnInit(): void {
        if(!this.id)
            return;

        this.apicall.get<SessionModel>('api/sessions/%s'.formUri(this.id)).subscribe({
            next: (response : SessionModel) => {
                this.session = response;
            },
            error: (error) => {
                this.alert.error(http2Error(error));
            }
        });
    }

    public print() {
        window.print();
    }
}
