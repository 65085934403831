'use strict'

import { Component, Inject, OnInit, ViewChild, Input, EventEmitter } from '@angular/core';
import { format } from "date-fns";
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { NgForm } from '@angular/forms';

import { DrivingLicenceCategoryModel, DrivingLicencePenaltyPointModel, NoKContactDetailModel, ContactDetailModel, TeammembersModel, DrivingLicenceModel } from '../../models/';
import { TIERAPICalls, TIERToast, TIERProfilePicture, TIERAuth, TIEROptions, TIERDownload, TIERUpload, TIEROrganization, ExceededLimit } from '../../services';
import { TeammembersEntitlementsComponent, TeammembersPenaltyPointsComponent, TeammembersPasswordConfirmComponent } from '../';
import { http2Error, isNorU } from 'src/tier/tier.utils';
import { ContactsModel } from 'src/tier/models/Contacts.model';

@Component({
    selector: 'tier-management-teammembers',
    templateUrl: "./teammembers.template.html",
    styleUrls: [ './teammembers.component.scss' ]
})
export class TeammembersComponent implements OnInit {
    @ViewChild('tmForm') tmForm!: NgForm;

    @Input() state? : string | null = null;

    private teamMemberDefault : TeammembersModel = { Roles: [], InductionDate: format(new Date(), 'dd-MM-yyyy').toString(), ProfilePicture: null, ContactDetail: null, DrivingLicence: null, NextOfKinContactDetail: null };
    public teamMember : TeammembersModel =  { ...this.teamMemberDefault };
    public isReadOnly : boolean = true;
    public profilepic : string = this.pp.getDefaultPic();
    public profilepicData : FormData | undefined | null = null;
    public hideSecurityQuestionAnswer : boolean = true;
    public localPasswordAvaliable : boolean = false;
    public refreshEmitter : EventEmitter<string | number> = new EventEmitter();
    public limit : ExceededLimit | null = null
    public reset : EventEmitter<null> = new EventEmitter<null>();

    public selectedTeammemberId : string | number = -1;

    public groupByFn = (item : any) => item.IsActive;
    public groupValueFn = (_: string, children: any[]) =>
        {
            if(children[0].IsActive == false)
                return "Inactive";

            if(children[0].IsActive === true)
                return "Active";

            return "Unknown";
        }

    constructor(
        @Inject(TIERAPICalls) private apicall : TIERAPICalls,
        @Inject(TIERToast) private alert : TIERToast,
        @Inject(TIERProfilePicture) private pp : TIERProfilePicture,
        @Inject(TIERAuth) private auth : TIERAuth,
        @Inject(TIEROptions) private options : TIEROptions,
        @Inject(NgbModal) private modalService : NgbModal,
        @Inject(TIERDownload) private down : TIERDownload,
        @Inject(TIERUpload) private upload : TIERUpload,
        @Inject(TIEROrganization) private organization : TIEROrganization) {
           this.localPasswordAvaliable = (this.options.get('org-local-auth') === "true");
        };

        public onClear() : void {
            this.clean();
        }

        public ngOnInit(): void {
            this.isReadOnly = this.state !== 'admin';

            this.organization.hasExceededLimit().subscribe({
                next: (res) => {
                    this.limit = res;
                }
            });

            if(this.isReadOnly) {
                let token = this.auth.getTokenInfo();

                if(token)
                    this.get(token.userId);
            }
        }

        private clean() : void {
            this.teamMember = { ...this.teamMemberDefault };
            this.profilepic = this.pp.getDefaultPic();
            this.reset.emit(null);
            this.tmForm.form.markAsPristine();
        }

        public get(id : string | number | null | undefined) : void {
            if(id === -1 || isNorU(id)) {
                this.clean();
                return;
            }

            this.apicall.get<TeammembersModel>('/api/teammembers/%s'.formUri(id)).subscribe({
                next: (response : TeammembersModel) => {
                    this.reset.emit(null);

                    this.teamMember = response;

                    if(this.teamMember.ProfilePicture !== null) {
                        this.getProfilePic(id as string);
                    } else {
                        this.profilepic = this.pp.getDefaultPic();
                    }

                    this.tmForm.form.markAsPristine();
                },
                error: (error) => {
                    this.alert.error(http2Error(error));
                }
            });
        }

        private getProfilePic(id : string) {
            this.pp.get(id).subscribe({
                next: (response : string) => {
                    this.profilepic = response;
                },
                error: (error) => {
                    this.alert.error(http2Error(error));
                }
            });
        }

         public CreateContactIfNull(ref : keyof ContactDetailModel, value : any) : void {
            if(!this.teamMember.ContactDetail)
                this.teamMember.ContactDetail = {};

            this.teamMember.ContactDetail![ref] = value;
         }

         public CreateNokContactIfNull(ref : keyof NoKContactDetailModel, value : any) : void {
            if(!this.teamMember.NextOfKinContactDetail)
                this.teamMember.NextOfKinContactDetail = {};

            this.teamMember.NextOfKinContactDetail![ref] = value;
         }

         public CreateDlIfNull(ref : keyof DrivingLicenceModel, value : any) : void {
            if(!this.teamMember.DrivingLicence)
                this.teamMember.DrivingLicence = {};

            this.teamMember.DrivingLicence![ref] = value;
         }

        public openTeamMemberAddPoints() : void {
            const modalRef = this.modalService.open(TeammembersPenaltyPointsComponent);

            modalRef.result.then((result) => {
                if(isNorU(this.teamMember.DrivingLicence) || isNorU(this.teamMember.DrivingLicence?.DrivingLicencePoints))
                    this.teamMember.DrivingLicence = { ...this.teamMember.DrivingLicence, DrivingLicencePoints: [] };

                this.teamMember.DrivingLicence?.DrivingLicencePoints!.push(result);
                this.tmForm.form.markAsDirty();
            }).catch(() => {});
        }

        public openTeamMemberAddEntitlement() : void {
            const modalRef = this.modalService.open(TeammembersEntitlementsComponent);

            modalRef.result.then((result) => {
                if(isNorU(this.teamMember.DrivingLicence) || isNorU(this.teamMember.DrivingLicence?.DrivingLicenceCategories))
                    this.teamMember.DrivingLicence = { ...this.teamMember.DrivingLicence, DrivingLicenceCategories: [] };

                this.teamMember.DrivingLicence?.DrivingLicenceCategories!.push(result);
                this.tmForm.form.markAsDirty();
            }).catch(() => {});
        }

        public deletePenalty(points : DrivingLicencePenaltyPointModel) : void {
            let index = this.teamMember.DrivingLicence?.DrivingLicencePoints?.indexOf(points);

            if(index === -1)
                return;

            if(this.teamMember.DrivingLicence?.DrivingLicencePoints)
                this.teamMember.DrivingLicence.DrivingLicencePoints.splice(index!, 1);
            this.tmForm.form.markAsDirty();
        }

        public deleteEntitlement(category : DrivingLicenceCategoryModel) : void {
            let index = this.teamMember.DrivingLicence?.DrivingLicenceCategories?.indexOf(category);

            if(index === -1)
                return;

            if(this.teamMember.DrivingLicence?.DrivingLicenceCategories)
                this.teamMember.DrivingLicence.DrivingLicenceCategories.splice(index!, 1);
            this.tmForm.form.markAsDirty();
        }

        public changePassword() {
            const modalRef = this.modalService.open(TeammembersPasswordConfirmComponent);

            modalRef.result.then((result) => {
                this.teamMember.Password = result;
                this.save();
            });
        }

        public ban() : void {
            this.apicall.delete<TeammembersModel>('/api/teammembers/%s'.formUri(this.teamMember?.Id)).subscribe({
                next: (response? : TeammembersModel) => {
                    let model = isNorU(response) ? this.teamMember : response;

                    this.refreshEmitter.emit(model?.Id);
                    this.get(model?.Id!);
                    this.alert.success("Teammember " + (model!.LockoutEnabled ? "disabled." : "enabled."));
                },
                error: (error) => {
                    this.alert.error(http2Error(error));
                }
            });
        }

        public deleteProfilePicture() {
            this.apicall.delete<TeammembersModel>('api/teammembers/%s/deleteprofilepicture'.formUri(this.teamMember?.Id)).subscribe({
                next: (response? : TeammembersModel) => {
                    let model = isNorU(response) ? this.teamMember : response;

                    this.refreshEmitter.emit(model?.Id);
                    this.get(model?.Id);
                    this.alert.success("Teammember profile picture deleted.");
                },
                error: (error) => {
                    this.alert.error(http2Error(error));
                }
            });
        }

        public handleUpload(data : FormData) {
            this.profilepicData = data;
            this.tmForm.form.markAsDirty();
        }

        public downloadProfile() {
            this.down.downloadLinkFromServer('api/teammembers/downloadprofiledata').subscribe({
                next: (link : HTMLAnchorElement) => {
                    link.dispatchEvent(new MouseEvent(`click`, { bubbles: true, cancelable: true, view: window }));
                },
                error: (err) => { this.alert.error(http2Error(err)); }
            });
        }

        public save() : void {
            let request = this.apicall.save<TeammembersModel>('/api/teammembers/%s'.formUri(this.isReadOnly ? null : this.teamMember?.Id), this.teamMember);

            if(this.profilepicData)
                request = this.upload.upload<TeammembersModel>('api/teammembers/%s/uploadprofilepicture', 'Id', this.teamMember.Id, this.profilepicData, request)

            request.subscribe({
                next: (response : TeammembersModel) => {
                    let id = isNorU(response) ? this.teamMember?.Id : response.Id;
                    this.get(id);
                    this.refreshEmitter.emit(id);
                    this.alert.success("Teammember saved.");
                },
                error: (error) => {
                    this.alert.error(http2Error(error));
                }
            });
        }
}
