'use strict'

import { Component, Inject } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

import { DrivingLicencePenaltyPointModel, DrivingLicencePointModel } from '../../models/';

@Component({
    selector: 'tier-management-teammembers-penaltypoints',
    template: `
        <form #ppForm="ngForm" role="form" name="ppForm" (ngSubmit)="confirm()" novalidate autocomplete="none">
            <div class="modal-header">
                <h4 class="modal-title">Add Penalty Point</h4>
            </div>
            <div class="modal-body">
                <div class="row mb-3">
                    <label class="col-sm-3 col-form-label fw-bold">Penalty</label>
                    <div class="col-sm-9">
                        <tierdropdown
                            name="ppdd"
                            source="api/drivinglicencepenaltypoints"
                            placeholder="Select Penalty"
                            (ngModelChange)="setPenaltyPointsId($event)"
                            [(ngModel)]="entry.DrivingLicencePenaltyPoint"
                            [allowNew]=false
                            bindValue="Name"
                            #ppdd="ngModel"
                            required>
                        </tierdropdown>
                        <p [hidden]="ppdd.valid" class="form-text text-danger">Penalty is required.</p>
                    </div>
                </div>
                <div class="row mb-3">
                    <label for="validfrom" class="col-sm-3 col-form-label fw-bold">Valid From</label>
                    <div class="col-sm-9">
                        <tiertimepicker name="validfrom" name="validfrom" [model]="validfrom" #validfrom="ngModel" [(ngModel)]="entry.ValidFrom" required></tiertimepicker>
                        <p [hidden]="validfrom.valid" class="form-text text-danger">Valid from date is required.</p>
                    </div>

                </div>
                <div class="row mb-3">
                    <label for="validto" class="col-sm-3 col-form-label fw-bold">Valid To</label>
                    <div class="col-sm-9">
                        <tiertimepicker name="validto" id="validto" #validto="ngModel" [model]="validto" [(ngModel)]="entry.ValidTo" [DateValidation]="[ { value: validfrom.value, equality: 'morethan', ident: 'validfrom' } ]" required></tiertimepicker>
                        <p [hidden]="validto.valid" class="form-text text-danger">Valid to date is required and must be after valid from date.</p>
                    </div>
                </div>
                <div class="row mb-3">
                    <label for="points" class="col-sm-3 col-form-label fw-bold">Points</label>
                    <div class="col-sm-9">
                        <input type="number" min="1" id="points" name="points" #points="ngModel" [ngClass]="{ 'is-invalid': points.invalid, 'is-valid': points.valid && !points.pristine }" class="form-control" [(ngModel)]="entry.Points" placeholder="Points" required>
                        <p [hidden]="points.valid" class="form-text text-danger">Valid number is required.</p>
                    </div>
                </div>
            </div>
            <div class="modal-footer">
                <button class="btn btn-secondary"
                        type="button"
                        (click)="activeModal.dismiss()">
                    Cancel
                </button>
                <button class="btn btn-primary"
                        type="submit"
                        [disabled]="ppForm.invalid">
                    Confirm
                </button>
            </div>
        </form>`,
})
export class TeammembersPenaltyPointsComponent {

    public entry : DrivingLicencePointModel = {};

    constructor(@Inject(NgbActiveModal) public activeModal : NgbActiveModal) {}

    public setPenaltyPointsId(obj : DrivingLicencePenaltyPointModel) {
        this.entry.DrivingLicencePenaltyPointId = obj.Id;
    }

    public confirm() {
        this.activeModal.close(this.entry);
    }

}
