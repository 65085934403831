'use strict'

import { Component, OnInit } from '@angular/core';
import { ChartData, ChartConfiguration } from 'chart.js';
import { WidgetBase } from '../../Widget.base';
import { http2Error } from 'src/tier/tier.utils';

@Component({
    selector: 'tier-widget-all-incidentattendancewidget',
    template: `
        <tierspinner [name]="getUUID()"></tierspinner>
        <div class="chart-container">
            <canvas baseChart class="chart" [type]="barChartType" [data]="data" [options]="chartOptions"></canvas>
        </div>
        <br/>
        <div class="attendance-widget-text">
            Your annual attendance for {{year || 'N/A'}} is {{attendance || '0%'}}
        </div>`,
    styleUrls: [ '../../_widgets.scss' ]
})
export class IncidentAttendanceWidgetComponent extends WidgetBase implements OnInit {
    static readonly widgetName = 'IncidentAttendanceWidgetComponent';
    public data : ChartData<'bar'> | undefined = undefined;
    public attendance : number | null = null;
    public year : number | null = null;

    public chartOptions : ChartConfiguration['options']= {
        responsive: true,
        maintainAspectRatio: false,
        plugins: {
            legend: {
              display: true,
            },
        }
      };

    ngOnInit(): void {
        this.callAPI<any>('api/dashboard/incidentattendance', this.getUUID()).subscribe({
            next: (response : any) => {
                this.attendance = response.Attendance;
                this.year = response.Year
                this.data = {
                    labels: ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec" ],
                    datasets: response.Datasets as any
                }
                if(this.isEmpty(this.data))
                    this.switchToNoDataWidget();
            },
            error: (error) => {
                this.alert.error(http2Error(error));
            }
        });
    }
}
