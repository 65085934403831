'use strict'

import { Component, OnInit, Inject } from '@angular/core';

import { TIERAPICalls, TIERConfig, TIERAuth, TIERToast, SKIP_ROOT_SPINNER, TIEROrganization } from '../../services';
import { OrganizationModel } from '../../models/';
import { isNorU, http2Error } from 'src/tier/tier.utils';
import { Observable } from 'rxjs';

const sitePrefix : string = '/organizations/';

@Component({
    selector: 'tier-bootstrap',
    templateUrl: "./bootstrap.template.html",
    styleUrls: [ "./bootstrap.component.scss" ]
  })
  export class BootstrapComponent implements OnInit {
    public clientVersion : string = this.config.get("version");

    constructor(
        @Inject(TIERAPICalls) private apicall : TIERAPICalls,
        @Inject(TIERConfig) private config : TIERConfig,
        @Inject(TIERAuth) private auth : TIERAuth,
        @Inject(TIERToast) private alert : TIERToast,
        @Inject(TIEROrganization) private organization : TIEROrganization
      ) {
            this.auth.LoginEvent.subscribe(() => {
                this.loadTheme();
            });

            this.auth.LogoutEvent.subscribe(() => {
                this.loadTheme();
            });
       };

      ngOnInit() : void {
        this.loadTheme();
      };

      public loadTheme() : void {
        if (this.auth.isLoggedIn()) {
            let org : Observable<OrganizationModel> | null = this.organization.get();

            if(org)
                org.subscribe({
                    next: (response : OrganizationModel) => {
                        if(!isNorU(response.SiteCSS))
                            this.addCSS(sitePrefix + response.SiteCSS);
                    },
                    error: (error) => {
                        this.alert.error(http2Error(error));
                    }
                });
        } else {
            this.removeAllStylesheets();
        }
      }

      private addCSS(url : string) : void {
        let styles : StyleSheetList = document.styleSheets;
        for (let i = 0; i < styles.length; i++) {
            if (styles[i].href?.match(url)) {
                styles[i].disabled = false;
                return;
            }
        }

        if (document.createStyleSheet) {
            document.createStyleSheet(url);
        } else {
            document.getElementsByTagName('head')[0].appendChild(this.createLink(url))
        }
      }

      private removeAllStylesheets() : void {
        let styles : StyleSheetList = document.styleSheets;
        for (let i = 0; i < styles.length; i++) {
            if (styles[i].href?.match(sitePrefix)) {
                styles[i].disabled = true;
            }
        }
      }

      private createLink(url : string) : HTMLLinkElement {
        let link = document.createElement('link');

        link.rel = "stylesheet";
        link.type = "text/css";
        link.href = url;

        return link;
      }
  }
