import { Component, Inject, OnInit, Input } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

import { IncidentAttendeeModel, SkillModel } from '../../models';
import { TIERAPICalls, TIERToast } from '../../services';
import { http2Error } from 'src/tier/tier.utils';
import { RespondingSkillModel } from 'src/tier/models/RespondingSkill.model';

@Component({
    selector: 'tier-moduleselector',
    template: `<div class="modal-header">
                <h3 class="modal-title">
                    Choose Training Skills
                </h3>
            </div>
            <div class="modal-body">
                <div>
                    <div *ngFor="let skill of skills" (click)="changeSkill(skill)">
                        <div class="hstack">
                            <div class="me-2">
                                <input type="checkbox" [(ngModel)]="skill.Value">
                            </div>
                            <div>
                                <strong>{{skill.Name}}</strong>
                            </div>
                        </div>
                    </div>
                    <div [hidden]="skills.length" class="alert alert-info" role="alert">
                        <i class="fa-solid fa-exclamation-circle"></i> No applicable skills
                    </div>
                </div>
            </div>
            <div class="modal-footer">
                <div>
                    <button type="button" class="btn btn-secondary me-2" (click)="this.activeModal.dismiss()">
                        Cancel
                    </button>
                    <button type="button" class="btn btn-primary" (click)="confirm()">
                        Confirm
                    </button>
                </div>
            </div>`
})
export class ModuleSelectorComponent implements OnInit {
    @Input() attendee : IncidentAttendeeModel = {};

    public skills : RespondingSkillModel[] = [];
    public choosenSkills : RespondingSkillModel[] = [];

    ngOnInit(): void {
            this.apicall.get<RespondingSkillModel[]>('api/responding/skills/user/%s'.formUri(this.attendee.TeamMemberId)).subscribe({
                next: (response : RespondingSkillModel[]) => {
                    this.skills = response;
                    this.setPrevious();
                },
                error: (error) => {
                    this.alert.error(http2Error(error));
                }
            });
    }

    constructor(
        @Inject(NgbActiveModal) public activeModal : NgbActiveModal,
        @Inject(TIERAPICalls) private apicall : TIERAPICalls,
        @Inject(TIERToast) private alert : TIERToast,
      ) {}

    public confirm() {
        this.activeModal.close(this.unpackRoles(this.choosenSkills))
    }

    public changeSkill(skill : RespondingSkillModel) {
        if (!skill?.Id)
            return;

        skill.Value = !skill.Value;
        if (skill.Value === true) {
            this.choosenSkills.push(skill);
        } else {
            this.choosenSkills.splice(
                this.choosenSkills.map((item) => item.Id).indexOf(skill.Id), 1);
        }
    };

    public unpackRoles(choosen : RespondingSkillModel[]) : SkillModel[] {
        let unpacked : SkillModel[] = [];

        choosen.forEach((entry) => {
            if (entry.Type === "ROLE") {
                entry.SkillModules.forEach((skill : SkillModel ) => {
                    unpacked.push(skill);
                })
            } else {
                unpacked.push(entry);
            }
        });

        return unpacked;
    };

    private setPrevious() {
        if (!this.attendee.SkillModules?.length)
            return;

        this.skills.forEach((entry) => {
            if (entry.Type === "ROLE") {
                if (entry.SkillModules.length > 0) {
                    let count = 0;
                    entry.SkillModules.forEach((skill) => {
                        if (this.attendee.SkillModules!.filter((e) => e.Id === skill.Id))
                            count++;
                    });
                    if (count === entry.SkillModules.length)
                        this.changeSkill(entry);
                } else {
                    if (this.attendee.SkillModules!.filter((e) => e.Id === entry.Id).length > 0)
                        this.changeSkill(entry);
                }
            } else if(entry.Type === "SKILL") {
                if (this.attendee.SkillModules!.filter((e) => e.Id === entry.Id).length > 0)
                    this.changeSkill(entry);
            }
        });
    }
}
