import { Component, OnInit, Inject, EventEmitter } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Transition } from '@uirouter/angular';
import { HttpParams } from '@angular/common/http';

import { TIERAPICalls, TIERToast, TIERAuth, TIERDialog } from '../../services';
import { EquipmentResourceModel, EquipmentResourceListingModel } from '../../models';
import { http2Error, calcUUID } from 'src/tier/tier.utils';
import { EquipmentCheckoutCheckInComponent } from './equipmentcheckoutcheckin.component';

@Component({
    selector: 'tier-equipment-checkout',
    templateUrl: "./equipmentcheckout.template.html"
})
export class EquipmentCheckoutComponent implements OnInit {

    public resources : EquipmentResourceModel[] = [];
    public total : number = 0;
    public page : number = 1;
    public userId : string | null = null;
    public refreshEmitter : EventEmitter<void> = new EventEmitter();

    public resourceSelectionFilterConfig = [
        {
            name: calcUUID(),
            formControl: "search",
            label: "Search Text",
            value: "search",
        },
        {
            name: calcUUID(),
            label: "Allocated To",
            formControl: "dropdown",
            placeHolder: "Select User",
            source: 'api/teammembers',
            value: "teammemberId",
            bindValue: 'FullName'
        },
        {
            name: calcUUID(),
            label: "Categories",
            formControl: "dropdown",
            placeHolder: "Select Category",
            noItemsPlaceHolder: "No category to select",
            source: 'api/equipment/template/category',
            value: "categoryId"
        },
        {
            name: calcUUID(),
            label: "Condition",
            formControl: "dropdown",
            placeHolder: "Select Condition",
            source: 'api/equipment/resources/conditiontypes',
            value: "conditionId"
        },
        {
            name: calcUUID(),
            label: "Template",
            formControl: "dropdown",
            placeHolder: "Select Template",
            source: 'api/equipment/templates',
            httpparams: { skip: 0, amount: 100 },
            isObjectList: true,
            value: "templateId"
        },
        {
            name: calcUUID(),
            label: "Last Checked",
            formControl: "dropdown",
            placeHolder: "Select Last Checked",
            source: [{ Id: "all", Name: "All" }, { Id: "valid", Name: "Valid" }, { Id: "expiring", Name: "Expiring" }, { Id: "expired", Name: "Expired" }, { Id: "notchecked", Name: "Not Checked" }],
            value: "lastChecked"
        },
        {
            name: calcUUID(),
            label: "Expiry",
            formControl: "dropdown",
            placeHolder: "Select Expiry",
            source: [{ Id: "all", Name: "All" }, { Id: "valid", Name: "Valid" }, { Id: "expiring", Name: "Expiring" }, { Id: "expired", Name: "Expired" }, { Id: "noexpiry", Name: "No Expiry" }],
            value: "expiry"
        },
        {
            name: calcUUID(),
            label: "Allocated",
            formControl: "dropdown",
            placeHolder: "Select Allocation",
            source: [{ Id: 0, Name: "All" }, { Id: 1, Name: "Allocated" }, { Id: 2, Name: "Not allocated" }],
            value: "allocated"
        },
        {
            name: calcUUID(),
            formControl: "tickbox",
            label: "Filter Recursively",
            value: "recursive"
        }];

    public filterConfig = [
    {
        name: calcUUID(),
        formControl: "search",
        label: "Search Text",
        value: "search"
    }];

    public values : any = {
        skip: 0,
        amount: 19,
        search: this.trans.params()?.search,
        categoryId: this.trans.params()?.categoryId,
        teammemberId: this.trans.params()?.teammemberId,
        conditionId: this.trans.params()?.conditionId,
        templateId: this.trans.params()?.templateId,
        lastChecked: this.trans.params()?.lastChecked,
        expiry: this.trans.params()?.expiry,
        isActive: true,
        recursive: this.trans.params()?.recursive,
        allocated: this.trans.params()?.allocated
    };

    constructor(
        @Inject(TIERAPICalls) private apicall : TIERAPICalls,
        @Inject(TIERToast) private alert : TIERToast,
        @Inject(TIERAuth) private auth : TIERAuth,
        @Inject(TIERDialog) private dialog : TIERDialog,
        @Inject(NgbModal) private modalService : NgbModal,
        @Inject(Transition) private trans : Transition
    ) {
        this.userId = this.auth.getTokenInfo()?.userId ?? null;
    }

    ngOnInit(): void {
        this.get();
    }

    public get() {
        this.values.skip = (this.page - 1) * this.values.amount;

        this.apicall.get<EquipmentResourceListingModel>("api/equipment/resources/checkout", new HttpParams({'fromObject': { ...this.values }})).subscribe({
            next: (response : EquipmentResourceListingModel) => {
                this.resources = response.ObjectList;
                this.total = response.Total;
            },
            error: (error) => {
                this.alert.error(http2Error(error));
            }
        });
    }

    public onSelection(resource : EquipmentResourceModel) {
        if(resource.AllocatedToId === this.userId)
            this.checkIn(resource);

        if(resource.AllocatedToId === null)
            this.checkOut(resource);

        if(resource.AllocatedToId !== null && resource.AllocatedToId !== this.userId)
            this.dialog.showAlertDialog("Unable to checkout", "This resource cannot be checkout as it is allocated to " + resource.AllocatedTo?.FullName).then(() => {}).catch(() => {});
    }

    private checkIn(resource : EquipmentResourceModel) {
        this.dialog.showConfirmDialog("Checking in resource", "Are you sure you wish to check in this resource?").then(
        () => {
            const modalRef = this.modalService.open(EquipmentCheckoutCheckInComponent);

            modalRef.componentInstance.resource = resource;

            modalRef.result.then((result) => {
                this.apicall.put<void>("api/equipment/resource/checkin/%s".formUri(resource.Id), result).subscribe({
                    next: () => {
                        this.refreshEmitter.emit();
                        this.get();
                    },
                    error: (error) => {
                        this.alert.error(http2Error(error));
                    }
                });
            }).catch(() => {});
        }).catch(() => {});
    }

    public checkOut(resource : EquipmentResourceModel) {
        if(!resource.EquipmentTemplate?.IsAllocatable) {
            this.dialog.showAlertDialog("Unable to checkout", "This resource cannot be checkout as it is unallocatable.").then(
            () => {}).catch(() => {});
            return;
        }

        this.dialog.showConfirmDialog("Checking out resource", "Are you sure you wish to check out this resource?").then(
            () => {
                this.apicall.put<void>("api/equipment/resource/checkout/%s".formUri(resource.Id), undefined).subscribe({
                    next: () => {
                        this.refreshEmitter.emit();
                        this.get();
                    },
                    error: (error) => {
                        this.alert.error(http2Error(error));
                    }
                });
            }).catch(() => {});
    }
}
