import { Component, forwardRef, ViewChild } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR, NG_VALIDATORS, Validator, AbstractControl, ValidationErrors, NgModel } from "@angular/forms";

import { isNorU } from 'src/tier/tier.utils';

@Component({
    selector: 'tierphonenumber',
    template: `
                <div class="input-group">
                    <span class="input-group-text">
                        <span class="flags pe-1">{{UnicodeFlag}}</span>
                            {{CallingCode}}
                        </span>
                    <input type="text" #pnModel="ngModel" class="form-control" pattern="^[1-9]\\d{3}(\\s)?\\d{6}" [(ngModel)]="phonenumber" (ngModelChange)="updateChanges($event)"/>
                </div>
                <div>
                    <p [hidden]="pnModel.valid" class="form-text text-danger">Phone number is invalid.</p>
                </div>`,
    providers: [{
        provide: NG_VALUE_ACCESSOR,
        useExisting: forwardRef(() => TIERPhonenumberComponent),
        multi: true
    },
    {
      provide: NG_VALIDATORS,
      useExisting: forwardRef(() => TIERPhonenumberComponent),
      multi: true,
    }]
})
export class TIERPhonenumberComponent implements ControlValueAccessor, Validator {
    @ViewChild('pnModel') pn!: NgModel;

    public CallingCode = "+44";
    public UnicodeFlag = "🇬🇧";

    public phonenumber : any = null;

    onChange: (_: any) => void = (_: any) => {};
    onTouched: () => void = () => {};

    updateChanges(phoneno : string) : void {
        this.onChange(!isNorU(phoneno) ? this.CallingCode + this.phonenumber : null);
    }

    writeValue(selected : any): void {
        if(!isNorU(selected)) {
            this.phonenumber = selected.replace(/\+44|[^\u0000-\u007F]/gu, "");
        } else {
            this.phonenumber = null;
        }
    }

    registerOnChange(fn: any): void {
        this.onChange = fn;
    }

    registerOnTouched(fn: any): void {
        this.onTouched = fn;
    }

    validate(control: AbstractControl): ValidationErrors | null {
        if(isNorU(control.value))
            return null;

        return this.pn.valid ? null : { pattern: true };
    }
}
