import { Component, OnInit, Inject } from '@angular/core';
import { StateService, Transition } from '@uirouter/angular';

import { TIERAPICalls, TIERToast, TIERDownload, TIERLocalStorage } from '../../services';
import { ReportsModel, ReportMetaDataModel, ReportOptionsModel } from '../../models/';
import { http2Error, isNorU } from 'src/tier/tier.utils';
import { Observable, map } from 'rxjs';

@Component({
    selector: 'tier-reports',
    templateUrl: "./reports.template.html"
})
export class ReportsComponent implements OnInit {

    public Reports : ReportsModel[] = [];
    public SelectedReportId : string | null = null;
    public ReportMetaData : ReportMetaDataModel | null = null;

    public OrderOption : object[] = [
        { Id: true, Name: 'Ascending' },
        { Id: false, Name: 'Descending' }
    ];

    public AmountOption : object[] = [
        { Id: 0, Name: 'All' },
        { Id: 10, Name: '10' },
        { Id: 20, Name: '20' },
        { Id: 50, Name: '50' },
        { Id: 100, Name: '100' }
    ];

    private defaultReportOptions : any = {
        Amount: 20,
        IsAsc: true,
        Page: 1
    };
    public ReportOptions : any = { ...this.defaultReportOptions };

    constructor(
        @Inject(TIERAPICalls) private apicall : TIERAPICalls,
        @Inject(TIERToast) private alert : TIERToast,
        @Inject(TIERLocalStorage) private local : TIERLocalStorage,
        @Inject(TIERDownload) private down : TIERDownload,
        @Inject(StateService) private state : StateService,
        @Inject(Transition) private trans : Transition) {};

    ngOnInit(): void {
        this.getReports().subscribe({
            next: (response : ReportsModel[]) => {
                this.Reports = response;
                this.selectReport();
            },
            error: (error) => {
                this.alert.error(http2Error(error));
            }
        });
    }

    private selectReport() : void {
        let prevReport : any = this.local.get('reporthistory');

        if(!isNorU(this.trans.params()?.report)) {
            this.SelectedReportId = this.trans.params().report;
        } else if(prevReport !== null) {
            this.SelectedReportId = prevReport.page;
        }

        if(!this.isReportValid(this.SelectedReportId)) {
            this.SelectedReportId = null;
            this.local.remove('reporthistory');
            return;
        }

        this.fetchMetaData(this.SelectedReportId).subscribe({
            next: (response : ReportMetaDataModel) => {
                this.ReportMetaData = response;

                if (this.SelectedReportId === prevReport?.page)
                    Object.assign(this.ReportOptions, prevReport.options);
            },
            error: (error) => {
                this.alert.error(http2Error(error));
            }
        })
    }

    private isReportValid(id : string | null) : boolean {
        if(isNorU(id))
            return false;

        if(this.Reports.findIndex((report) => id === report.Id) !== -1)
            return true;

        return false;
    }

    private getReports() : Observable<ReportsModel[]> {
        return this.apicall.get<ReportsModel[]>('api/reports');
    }

    private fetchMetaData(id : any) : Observable<ReportMetaDataModel> {
        this.reset();

        return this.apicall.get<ReportMetaDataModel>('api/reports/%s/metadata'.formUri(id)).pipe(map((metadata) => {
            metadata.Options?.forEach((option : ReportOptionsModel) => {
                if(!isNorU(option.Default))
                    this.ReportOptions[option.Id!] = option.Default;
            })

            if(!isNorU(metadata.Columns) && !isNorU(metadata.Columns![0]))
                this.ReportOptions.Column = metadata.Columns![0].Id;

            return metadata;
        }));
    }

    public getMetaData(id : any) : void {
        this.fetchMetaData(id).subscribe({
            next: (response : ReportMetaDataModel) => {
                this.ReportMetaData = response;
            },
            error: (error) => {
                this.alert.error(http2Error(error));
            }
        });
    }

    private reset() : void {
        this.ReportOptions = { ...this.defaultReportOptions };
    }

    public generate() : void {
        this.local.set('reporthistory', { page: this.SelectedReportId, options: this.ReportOptions });
        this.state.go(this.ReportMetaData?.RouteId!, this.ReportOptions);
    }

    public export() : void {
        this.local.set('reporthistory', { page: this.SelectedReportId, options: this.ReportOptions });
        this.exportExcel(this.SelectedReportId, this.ReportOptions);
    }

    private exportExcel(report : string | null, options : object) : void {
        if(!report)
            return;

        this.down.downloadLinkFromServer('api/reports/' + report + '/export/excel', 'POST',
            undefined,
            {
                IsAsc: this.ReportOptions.IsAsc,
                Column: this.ReportOptions.Column,
                Amount: this.ReportOptions.Amount,
                Page: this.ReportOptions.Page,
                Parameters: options
            }
        ).subscribe({
            next: (link : HTMLAnchorElement) => {
                link.dispatchEvent(new MouseEvent(`click`, { bubbles: true, cancelable: true, view: window }));
            },
            error: (err) => { this.alert.error(http2Error(err)); }
        })
    }
}
