<div class="card mb-2">
    <div class="card-header">
        Query Selection
    </div>
    <div class="card-body">
        <div>
            <form autocomplete="none">
                <div class="row mb-3">
                    <label class="col-sm-2 col-form-label">Roles: </label>
                    <div class="col-sm-10">
                        <tierdropdown name="roledd" source="api/roles" placeholder="Select Role" (ngModelChange)="getModules($event)" (clear)="onClear()" [(ngModel)]="selectedItemId" [allowNew]=false bindId="Id" bindValue="Name"></tierdropdown>
                    </div>
                </div>
            </form>
        </div>
    </div>
</div>
<div [hidden]="!modules.length">
    <br />
    <div class="row justify-content-end">
        <div class="col-sm-4 col-lg-2">
            <input class="form-control"
                    type="text"
                    placeholder="Search..."
                    [(ngModel)]="searchText">
        </div>
    </div>
    <br />
    <div class="table-responsive">
        <table class="table">
            <thead>
                <tr>
                    <th>Module</th>
                    <th>Validity</th>
                    <th>Critical</th>
                    <th>Description</th>
                </tr>
            </thead>
            <tbody>
                <tr *ngFor="let module of modules | search:searchText">
                    <td *ngIf="module.URL"><a href="{{module.URL}}" target="_blank">{{module.Name}}</a></td>
                    <td *ngIf="!module.URL">{{module.Name}}</td>
                    <td>{{module.MonthsValidFor || "None"}}</td>
                    <td>
                        <i class="fa-solid fa-check" *ngIf="module.Critical === true"></i>
                        <i class="fa-solid fa-times" *ngIf="module.Critical === false"></i>
                    </td>
                    <td>{{module.Description}}</td>
                </tr>
            </tbody>
        </table>
    </div>
</div>
<div class="alert alert-info" role="alert" [hidden]="modules.length || !selectedItemId">
    <i class="fa-solid fa-exclamation-circle"></i> 0 results found.
</div>
