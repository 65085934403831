'use strict'

import { Component, Inject, Input } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
    selector: 'tier-training-sessionscomplete-comments',
    template: `
            <div class="modal-header">
                <h4 class="modal-title">Comment</h4>
            </div>
            <div class="modal-body">
                <div class="row">
                    <label for="comment" class="col-sm-3 col-form-label fw-bold">Comment</label>
                    <div class="col-sm-9">
                        <textarea #comment="ngModel" [ngClass]="{ 'is-invalid': commentText === null || commentText.length <= 0, 'is-valid': commentText && commentText.length > 0 }" placeholder="Comment" class="form-control" rows="5" name="comment" id="comment" [(ngModel)]="commentText"></textarea>
                    </div>
                </div>
            </div>
            <div class="modal-footer">
                <button class="btn btn-secondary"
                        type="button"
                        (click)="activeModal.dismiss()">
                    Cancel
                </button>
                <button class="btn btn-primary"
                            type="button"
                            (click)="confirm()"
                            [disabled]="commentText === null || commentText.length <= 0">
                        Confirm
                </button>
            </div>`
})
export class SessionsCompleteCommentsComponent {
    @Input() commentText : string | null = null;

    constructor(@Inject(NgbActiveModal) public activeModal : NgbActiveModal) {}

    public confirm() {
        this.activeModal.close(this.commentText);
    }
}
