<table class="table">
    <tbody>
        <tr>
            <td class="w-50">
                <input type="text" name="label" id="label" class="form-control" placeholder="Label" [(ngModel)]="attribute.Label">
            </td>
            <td class="w-50">
                <tierdropdown
                    id="typedd"
                    name="typedd"
                    source="api/equipment/template/attributetypes"
                    (change)="setModel($event)"
                    placeholder="Select Attribute Type"
                    [(ngModel)]="attribute.EquipmentTemplateAttributeTypeId"
                    bindId="Id"
                    bindValue="Name">
                </tierdropdown>
            </td>
            <td>
                <button class="btn btn-success" (click)="add()" type="button" [disabled]="!attribute.Label?.length || !attribute.EquipmentTemplateAttributeTypeId">
                    <i class="fa-solid fa-plus"></i>
                </button>
            </td>
        </tr>
        <tr *ngFor="let attribute of attributes; index as i">
            <td>
                <input type="text" class="form-control" [(ngModel)]="attribute.Label" required>
            </td>
            <td>
                <input type="text" class="form-control-plaintext" readonly [value]="attribute?.EquipmentTemplateAttributeType?.Name">
            </td>
            <td>
                <button type="button" class="btn btn-danger" (click)="delete(i)">
                    <i class="fa-solid fa-trash"></i>
                </button>
            </td>
        </tr>
    </tbody>
</table>
