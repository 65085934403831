 <div class="modal-header">
    <h3 class="modal-title">Incident Management</h3>
</div>
<div class="modal-body">
    <form role="form" #incidentForm="ngForm" name="incidentForm" novalidate autocomplete="none">

        <div class="alert alert-danger" role="alert" [hidden]="!hasDuplicateAttendees">
            You have enabled single entry mode in settings. This incident currently has duplicate attendees. You will need to delete the duplicate attendees before you can modify this incident.
        </div>

        <div class="card mb-2">
            <div class="card-header">Incident Information</div>
            <div class="card-body">
                <div class="row mb-3">
                    <label for="name" class="col-sm-3 col-form-label fw-bold">Name</label>
                    <div class="col-sm-9">
                        <input type="text" #name="ngModel" name="name" id="name" class="form-control" placeholder="Name" [(ngModel)]="incident.Name" maxlength="50" required [class.is-invalid]="name.invalid">
                        <p [hidden]="name.valid" class="form-text text-danger">Valid name is required.</p>
                    </div>
                </div>
                <div class="row mb-3">
                    <label for="externalref" class="col-sm-3 col-form-label fw-bold" [textContent]="isCare ? 'Patient Id' : 'External Ref'"></label>
                    <div class="col-sm-9">
                        <input type="text" name="externalref" id="externalref" class="form-control" [placeholder]="isCare ? 'Patient Id' : 'External Ref'" [(ngModel)]="incident.ExternalReference" maxlength="50">
                    </div>
                </div>
                <div class="row mb-3">
                    <label for="start" class="col-sm-3 col-form-label fw-bold">Start Date &amp; Time</label>
                    <div class="col-sm-9">
                        <tiertimepicker id="start" name="start" #startDate="ngModel" [withTime]="true" format="d-m-Y H:i" [model]="startDate" [(ngModel)]="incident.Start" (timechanged)="timeHasChanged(startDate, $event)" required></tiertimepicker>
                        <p [hidden]="!startDate.errors?.required" class="form-text text-danger">Start date is required.</p>
                        <p [hidden]="!startDate.errors?.outofbounds" class="form-text text-danger">
                            Start date is out of bounds of attendees
                            <span *ngFor="let error of startDate.errors?.outofbounds; last as l"
                                [textContent]="!l ? error + ', ' : error + ' '">
                            </span>
                             start and end dates.
                        </p>
                    </div>
                </div>
                <div class="row mb-3">
                    <label for="end" class="col-sm-3 col-form-label fw-bold">End Date &amp; Time</label>
                    <div class="col-sm-9">
                        <tiertimepicker id="end" name="end" #endDate="ngModel" [withTime]="true" format="d-m-Y H:i" [model]="endDate" (timechanged)="timeHasChanged(endDate, $event)" [DateValidation]="[ { value: startDate.value, equality: 'morethan', ident: 'incidentend' } ]" ValidationFormat="dd-MM-yyyy HH:mm" [(ngModel)]="incident.End"></tiertimepicker>
                        <p [hidden]="endDate.errors?.DateValidation?.value !== 'incidentend'" class="form-text text-danger">End date must be after start date.</p>
                        <p [hidden]="!endDate.errors?.outofbounds" class="form-text text-danger">
                            End date is out of bounds of attendees
                            <span *ngFor="let error of endDate.errors?.outofbounds; last as l"
                                [textContent]="!l ? error + ', ' : error + ' '">
                            </span>
                             start and end dates.
                        </p>
                    </div>
                </div>
                <div class="row mb-3">
                    <label for="rvlocation" class="col-sm-3 col-form-label fw-bold" [textContent]="isCare ? 'Incident Location' : 'RV Location'"></label>
                    <div class="col-sm-9">
                        <div class="hstack">
                            <div class="col-xs-10 w-100 me-2">
                                <input type="text"
                                        id="rvlocation"
                                        name="rvlocation"
                                        class="form-control"
                                        placeholder="Location Name"
                                        [ngModel]="incident.RvLocation && incident.RvLocation.Name"
                                        (ngModelChange)="CreateVenueIfNull('RvLocation', 'Name', $event)"
                                        [ngbTypeahead]="getNamedLocation">
                            </div>
                            <div class="ms-auto">
                                <tier-mapping-button [location]="incident.RvLocation" permission="auto" [disabled]="!incident.RvLocation?.Name"><i class="fa fa-map"></i></tier-mapping-button>
                            </div>
                        </div>
                        <p [hidden]="!searchFailed" class="form-text text-warning">You are creating a new venue.</p>
                    </div>
                </div>

                <div class="row mb-3">
                    <label class="col-sm-3 col-form-label fw-bold">Incident Location</label>
                    <div class="col-sm-9">
                        <div class="hstack">
                            <div class="w-50 me-1">
                                <input type="text" #inlat="ngModel" name="inlat" id="inlat" class="form-control" placeholder="Lat" [(ngModel)]="incident.ActivityLocation!.Lat" [readonly]="true" required [class.is-invalid]="inlat.invalid">
                            </div>
                            <div class="w-50 me-1">
                                <input type="text" #inlon="ngModel" name="inlon" id="inlon" class="form-control" placeholder="Lon" [(ngModel)]="incident.ActivityLocation!.Lon" readonly required [class.is-invalid]="inlon.invalid">
                            </div>
                            <div>
                                <tier-mapping-button [location]="incident.ActivityLocation" permission="auto"><i class="fa fa-map"></i></tier-mapping-button>
                            </div>
                        </div>
                        <p [hidden]="inlat.valid || inlon.valid" class="form-text text-danger">Incident location is required.</p>
                    </div>
                </div>
                <div class="row mb-3">
                    <label for="incidentcalltype" class="col-sm-3 col-form-label fw-bold" [textContent]="isCare ? 'Incident Type' : 'Call Type'"></label>
                    <div class="col-sm-9">
                        <tiertypedropdown id="incidentcalltype" bindId="Id" name="incidentcalltype" placeholder="Select Incident Call Type" url="api/incidentcalltype" [label]="isCare ? 'Incident Type' : 'Call Type'" [(ngModel)]="incident.IncidentCallTypeId"></tiertypedropdown>
                    </div>
                </div>
                <div class="row mb-3">
                    <label for="responsecalltype" class="col-sm-3 col-form-label fw-bold" [textContent]="isCare ? 'Incident Cause' : 'Response Type'"></label>
                    <div class="col-sm-9">
                        <tiertypedropdown id="responsecalltype" bindId="Id" name="responsecalltype" placeholder="Select Response Call Type" url="api/incidentresponsetype" [label]="isCare ? 'Incident Cause' : 'Response Type'" [(ngModel)]="incident.IncidentResponseTypeId"></tiertypedropdown>
                    </div>
                </div>
                <div class="row mb-3">
                    <label for="operationcalltype" class="col-sm-3 col-form-label fw-bold" [textContent]="isCare ? 'External Referral' : 'Operation Type'"></label>
                    <div class="col-sm-9">
                        <tiertypedropdown id="operationcalltype" bindId="Id" name="operationcalltype" placeholder="Select Operation Call Type" url="api/incidentoperationtype" [label]="isCare ? 'External Referral' : 'Operation Type'" [(ngModel)]="incident.IncidentOperationTypeId"></tiertypedropdown>
                    </div>
                </div>
                <div class="row mb-3">
                    <label for="cause" class="col-sm-3 col-form-label fw-bold">Details</label>
                    <div class="col-sm-9">
                        <textarea placeholder="Details" class="form-control" rows="5" id="cause" name="cause" [(ngModel)]="incident.Details"></textarea>
                    </div>
                </div>
            </div>
        </div>

        <div class="card mb-2">
            <div class="card-header">Incident Responders</div>
            <div class="card-body">
                <tier-incident-attendees [(attendees)]="incident.IncidentAttendees" (attendeesChange)="checkForDuplicates()" [start]="incident.Start" [end]="incident.End"></tier-incident-attendees>
            </div>
        </div>
        <div class="card mb-2">
            <div class="card-header">Incident Subject</div>
            <div class="card-body">
                <tier-incident-subjects [(subjects)]="incident.IncidentSubjects"></tier-incident-subjects>
            </div>
        </div>
        <div class="card">
            <div class="card-header">Weather</div>
            <div class="card-body">
                <tier-weather [location]="incident.ActivityLocation" [(weather)]="incident.CurrentWeather" [ro]="false"></tier-weather>
            </div>
        </div>
    </form>
</div>
   <div class="modal-footer">
        <div>
            <button type="button" class="btn btn-info me-2"
                    (click)="complete()"
                    [hidden]="!incident.Id"
                    [disabled]="incidentForm.invalid || !incident.IsActive">
                Complete
            </button>
            <button type="button" class="btn me-2"
                    (click)="delete()"
                    [disabled]="incidentForm.invalid"
                    [hidden]="!incident.Id"
                    [ngClass]="{'btn-success': incident.IsActive, 'btn-danger': !incident.IsActive}"
                    [textContent]="incident.IsActive ? 'Active' : 'Inactive'">
            </button>
            <button type="button" class="btn btn-secondary me-2"
                    (click)="this.activeModal.dismiss()">
                Cancel
            </button>
            <button type="button" class="btn btn-primary"
                    (click)="save()"
                    [disabled]="incidentForm.invalid">
                Save
            </button>
        </div>
    </div>
