import AbstractProvider, {
    EndpointArgument,
    ParseArgument,
    SearchResult,
    SearchArgument,
  } from './provider';

  import { parse, osGridToLatLong } from 'mt-osgridref' ;

  export interface RawResult {
      label: string;
      x: number;
      y: number;
  }

  export default class osGridRefProvider extends AbstractProvider<
  null,
  RawResult
> {
    endpoint({ query }: EndpointArgument) {
        return '';
    }
    
    parse(result: ParseArgument<null>): SearchResult<RawResult>[] {
      const res: SearchResult<RawResult> = {
        x: 0,
        y: 0,
        label: '',
        bounds: null,
        raw: {
          x: 0,
          y: 0,
          label: ''
        }
      }

      return [res];
    }

    search(options: SearchArgument): Promise<SearchResult<RawResult>[]> {
      const regex : RegExp = /^([STNHOstnho][A-Za-z]\s?)(\d{5}\s?\d{5}|\d{4}\s?\d{4}|\d{3}\s?\d{3}|\d{2}\s?\d{2}|\d{1}\s?\d{1})$/;

      if(!regex.test(options.query))
        return new Promise((resolve) => { resolve([]); });

      const gridref = parse(options.query);
      const latlon = osGridToLatLong(gridref);

      return new Promise((resolve) => { resolve([{
        x: latlon.lon(),
        y: latlon.lat(),
        label: options.query,
        bounds: null, 
        raw: {
          x: latlon.lon(),
          y: latlon.lat(),
          label: options.query,
        }
      }])});
    }
  }
