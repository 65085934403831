import { Injectable, EventEmitter } from '@angular/core';

@Injectable({
    providedIn: 'root'
})
export class TIERMenuSignals
{
    constructor() {}

    public RefreshEvent = new EventEmitter();

    public refresh() : void {
        this.RefreshEvent.emit();
    }
}
