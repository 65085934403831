'use strict'

import { Component, Inject } from '@angular/core';

import { TrainingReportPagenationModel, TrainingReportModel } from '../../models';
import { Reports } from '../../reports';
import { TIERAPICalls } from '../../../services/';
import { http2Error } from 'src/tier/tier.utils';

@Component({
    selector: 'tier-reports-trainingreport',
    templateUrl: './trainingreport.template.html'
})
export class TrainingReportComponent extends Reports {
    public report : TrainingReportModel[] = [];
    public total : number = 0;
    public search : string | undefined = undefined;

    constructor(
        @Inject(TIERAPICalls) private apicall : TIERAPICalls
    ) { super(); };

    public get() {
        this.apicall.post<TrainingReportPagenationModel>('api/reports/TrainingReport',
            this.state2Options()
        ).subscribe({
            next: (response : TrainingReportPagenationModel) => {
                this.report = response.ObjectList;
                this.total = response.Total;
            },
            error: (error) => {
                this.alert.error(http2Error(error));
            }
        });
    }

    public setState(state : boolean) {};
}
