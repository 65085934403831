<div>
    <div class="col-md-8 offset-md-2">
        <div class="alert alert-warning" role="alert" [hidden]="!limit || isReadOnly">
            <i class="fa-solid fa-exclamation-circle"></i> You are currently over your user limit ({{limit?.limit}}) for your current licence agreement.
        </div>
        <div [hidden]="isReadOnly">
            <div class="row">
                <label class="col-sm-3 col-form-label fw-bold">Team Member</label>
                <div class="col-sm-9">
                    <tierdropdown
                        name="tmdd"
                        source="api/teammembers"
                        placeholder="Select Team Member or Create New"
                        newLabel="Create New Team Member"
                        (ngModelChange)="get($event)"
                        (clear)="onClear()"
                        [(ngModel)]="selectedTeammemberId"
                        [httpparams]="{ ShowInactive: true }"
                        [allowNew]=true
                        bindId="Id"
                        bindValue="FullName"
                        [group]="groupByFn"
                        [groupVal]="groupValueFn"
                        [refresh]="refreshEmitter">
                    </tierdropdown>
                </div>
            </div>
            <hr />
        </div>
        <form #tmForm="ngForm" role="form" name="teamMemberForm" (ngSubmit)="save()" novalidate autocomplete="none">
            <div class="row mb-2 p-2">
                <div class="card p-0" [ngClass]="{ 'border-success': !teamMember.IsRemoved, 'border-danger': teamMember.IsRemoved }">
                    <div class="card-header" *ngIf="!teamMember.IsRemoved" [textContent]="'User information for account: ' + (teamMember.Email || 'N/A')"></div>
                    <div class="card-header" *ngIf="teamMember.IsRemoved" [textContent]="'Team Member has been deleted due to account being marked inactive for 90 days'"></div>
                    <div class="card-body">
                        <div class="card p-2 mb-3" [hidden]="!teamMember.Id">
                            <span [hidden]="teamMember.LastLoginDate !== null">Team member has never logged in.</span>
                            <span [hidden]="teamMember.LastLoginDate === null">Team member last logged in at: {{teamMember.LastLoginDate}}</span>
                        </div>
                        <div class="row align-items-center">
                            <div class="col-sm-4 align-items-center text-center">
                                <div class="mb-3">
                                    <img class="img-thumbnail profile-picture" [src]="profilepic">
                                </div>
                                <div class="d-flex justify-content-center">
                                    <div class="col-sm-8 ">
                                    <tieruploadbutton
                                        [hidden]="isReadOnly"
                                        [attachment]="teamMember.ProfilePicture"
                                        (data)="handleUpload($event)"
                                        (del)="deleteProfilePicture()"
                                        uploadTextContent="Upload Profile Picture"
                                        deleteTextContent="Delete Profile Picture"
                                        [allowedTypes]="['Images']"
                                        [reset]="reset">
                                    </tieruploadbutton>
                                    </div>
                                </div>
                            </div>
                            <div class="col-sm-8">
                                <div class="col-md-12 mb-3">
                                    <label for="fullname" class="col-form-label fw-bold">Full Name</label>
                                    <input type="text" id="fullname" #fullname="ngModel" name="fullname" class="form-control" placeholder="Full Name" [ngClass]="{ 'is-invalid': fullname.invalid, 'is-valid': fullname.valid && !fullname.pristine }" [(ngModel)]="teamMember.FullName" required>
                                    <p [hidden]="fullname.valid" class="form-text text-danger">Valid full name is required.</p>
                                </div>
                                <div class="col-md-12 mb-3">
                                    <label for="emailaddress" class="col-form-label fw-bold">Email Address</label>
                                    <input type="email" id="emailaddress" #emailaddress="ngModel" name="emailaddress" class="form-control" placeholder="Email Address" [ngClass]="{ 'is-invalid': emailaddress.invalid, 'is-valid': emailaddress.valid && !emailaddress.pristine }" [(ngModel)]="teamMember.Email" email required>
                                    <p [hidden]="emailaddress.valid" class="form-text text-danger">Valid email address is required.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="row p-2">
                <ul ngbNav #nav="ngbNav" class="nav-tabs" [destroyOnHide]="false">
                    <li [ngbNavItem]="1">
                        <button ngbNavLink>Details</button>
                        <ng-template ngbNavContent>
                            <div class="row mb-3">
                                <label for="dob" class="col-sm-3 col-form-label fw-bold">Date Of Birth</label>
                                <div class="col-sm-9">
                                    <tiertimepicker name="dateofbirth" [(ngModel)]="teamMember.DateOfBirth"></tiertimepicker>
                                </div>
                            </div>
                             <div class="row mb-3">
                                <label for="dbscertnumber" class="col-sm-3 col-form-label fw-bold">DBS Certificate Number</label>
                                <div class="col-sm-9">
                                    <input type="text" name="dbscertnumber" #dbscertnumber="ngModel" [readonly]="isReadOnly" maxlength="15" class="form-control" placeholder="DBS Certificate Number" [ngClass]="{ 'is-invalid': dbscertnumber.invalid, 'is-valid': dbscertnumber.valid && !dbscertnumber.pristine }" [(ngModel)]="teamMember.DBSCertificateNumber">
                                    <p [hidden]="dbscertnumber.valid" class="form-text text-danger">DBS Certificate Number has to be below 15 characters</p>
                                </div>
                            </div>
                            <div class="row mb-3">
                                <label for="securityq" class="col-sm-3 col-form-label fw-bold">Security Question</label>
                                <div class="col-sm-9">
                                    <input type="text" name="securityq" class="form-control" placeholder="Security Question" [(ngModel)]="teamMember.SecurityQuestion">
                                </div>
                            </div>
                            <div class="row mb-3">
                                <label for="securitya" class="col-sm-3 col-form-label fw-bold">Security Answer</label>
                                <div class="col-sm-9">
                                    <div class="input-group">
                                        <input [attr.type]="hideSecurityQuestionAnswer ? 'password' : 'text'" name="securitya" class="form-control" placeholder="Security Answer" [(ngModel)]="teamMember.SecurityAnswer">
                                        <div class="input-group-addon btn btn-primary" (click)="hideSecurityQuestionAnswer = !hideSecurityQuestionAnswer"><i class="fa-solid fa-eye"></i></div>
                                    </div>
                                </div>
                            </div>
                            <div class="row mb-3">
                                <label class="col-sm-3 col-form-label fw-bold">Dietary Requirement</label>
                                <div class="col-sm-9">
                                    <tierdropdown
                                        name="dietdd"
                                        source="api/dietaryrequirement"
                                        placeholder="Select Dietary Requirement"
                                        [(ngModel)]="teamMember.DietaryRequirementId"
                                        [allowNew]=false
                                        bindId="Id"
                                        bindValue="Name">
                                    </tierdropdown>
                                </div>
                            </div>
                            <div class="row mb-3">
                                <label for="options" class="col-sm-3 pt-0 col-form-label fw-bold">Options</label>
                                <div class="col-sm-9">
                                    <div class="form-check">
                                        <input class="form-check-input" name="AllowEmail" type="checkbox" [(ngModel)]="teamMember.AllowEmail">
                                        <label class="form-check-label">
                                            Receive Email Notifications?
                                        </label>
                                    </div>
                                </div>
                            </div>
                        </ng-template>
                    </li>
                <li [ngbNavItem]="2">
                    <button ngbNavLink>Roles</button>
                    <ng-template ngbNavContent>
                        <div class="row mb-3">
                            <label class="col-sm-3 col-form-label fw-bold">Team Member Roles</label>
                            <div class="col-sm-9">
                                <tier-roleselector name="rselect" [(ngModel)]="teamMember.Roles" [readonly]="isReadOnly"></tier-roleselector>
                            </div>
                        </div>
                    </ng-template>
                </li>
                <li [ngbNavItem]="3">
                    <button ngbNavLink>Milestone Dates</button>
                    <ng-template ngbNavContent>
                        <div class="row mb-3">
                            <label for="inductiondate" class="col-sm-3 col-form-label fw-bold">Induction Date</label>
                            <div class="col-sm-9">
                                <tiertimepicker name="inductiondate" #inductiondate="ngModel" [model]="inductiondate" [(ngModel)]="teamMember.InductionDate" [readonly]="isReadOnly" required></tiertimepicker>
                                <p [hidden]="inductiondate.valid" class="form-text text-danger">Valid induction date is required.</p>
                            </div>
                        </div>
                        <div class="row mb-3">
                            <label for="traineedate" class="col-sm-3 col-form-label fw-bold">Trainee Date</label>
                            <div class="col-sm-9">
                                <tiertimepicker name="traineedate" [(ngModel)]="teamMember.TraineeDate" [readonly]="isReadOnly"></tiertimepicker>
                            </div>
                        </div>
                        <div class="row mb-3">
                            <label for="probationdate" class="col-sm-3 col-form-label fw-bold">Probation Date</label>
                            <div class="col-sm-9">
                                <tiertimepicker name="probationdate" [(ngModel)]="teamMember.ProbationDate" [readonly]="isReadOnly"></tiertimepicker>
                            </div>
                        </div>
                        <div class="row mb-3">
                            <label for="fullmemberdate" class="col-sm-3 col-form-label fw-bold">Full Member Date</label>
                            <div class="col-sm-9">
                                <tiertimepicker name="fullmemberdate" [(ngModel)]="teamMember.FullMemberDate" [readonly]="isReadOnly"></tiertimepicker>
                            </div>
                        </div>
                        <div class="row mb-3">
                            <label for="leavedate" class="col-sm-3 col-form-label fw-bold">Leave Date</label>
                            <div class="col-sm-9">
                                <tiertimepicker name="leavedate" [(ngModel)]="teamMember.LeaveDate" [readonly]="isReadOnly"></tiertimepicker>
                            </div>
                        </div>
                        <hr />
                        <div class="row mb-3">
                            <label for="dbscheck" class="col-sm-3 col-form-label fw-bold">DBS Check</label>
                            <div class="col-sm-9">
                                <tiertimepicker name="dbscheck" [(ngModel)]="teamMember.DBSCheck" [readonly]="isReadOnly"></tiertimepicker>
                            </div>
                        </div>
                    </ng-template>
                </li>
                <li [ngbNavItem]="4">
                    <button ngbNavLink>Contact Information</button>
                    <ng-template ngbNavContent>
                        <div class="row mb-3">
                            <label for="homenumber" class="col-sm-3 col-form-label fw-bold">Home Phone Number</label>
                            <div class="col-sm-9">
                                <tierphonenumber name="homenumber" [ngModel]="teamMember.ContactDetail && teamMember.ContactDetail.HomeNumber" (ngModelChange)="CreateContactIfNull('HomeNumber', $event)"></tierphonenumber>
                            </div>
                        </div>
                        <div class="row mb-3">
                            <label for="mobilenumber" class="col-sm-3 col-form-label fw-bold">Mobile Phone Number</label>
                            <div class="col-sm-9">
                                <tierphonenumber name="mobilenumber" [ngModel]="teamMember.ContactDetail && teamMember.ContactDetail.MobileNumber" (ngModelChange)="CreateContactIfNull('MobileNumber', $event)"></tierphonenumber>
                            </div>
                        </div>
                        <hr />
                        <div class="row mb-3">
                            <label for="homeAddressLine1" class="col-sm-3 col-form-label fw-bold">Home Address</label>
                            <div class="col-sm-9">
                                <input type="text" name="homeAddressLine1" class="form-control" placeholder="Address Line 1" [ngModel]="teamMember.ContactDetail && teamMember.ContactDetail.HomeAddressLine1"  (ngModelChange)="CreateContactIfNull('HomeAddressLine1', $event)">
                            </div>
                        </div>
                        <div class="row mb-3">
                            <div class="col-sm-9 offset-sm-3">
                                <input type="text" name="homeAddressLine2" class="form-control" placeholder="Address Line 2" [ngModel]="teamMember.ContactDetail && teamMember.ContactDetail.HomeAddressLine2" (ngModelChange)="CreateContactIfNull('HomeAddressLine2', $event)">
                            </div>
                        </div>
                        <div class="row mb-3">
                            <div class="col-sm-9 offset-sm-3">
                                <input type="text" name="homeAddressLine3" class="form-control" placeholder="Address Line 3" [ngModel]="teamMember.ContactDetail && teamMember.ContactDetail.HomeAddressLine3" (ngModelChange)="CreateContactIfNull('HomeAddressLine3', $event)">
                            </div>
                        </div>
                        <div class="row mb-3">
                            <div class="col-sm-4 offset-sm-3">
                                <input type="text" name="homeAddressTown" class="form-control" placeholder="Town" [ngModel]="teamMember.ContactDetail && teamMember.ContactDetail.HomeAddressTown" (ngModelChange)="CreateContactIfNull('HomeAddressTown', $event)">
                            </div>
                        </div>
                        <div class="row mb-3">
                            <div class="col-sm-4 offset-sm-3">
                                <input type="text" name="homeAddressCounty" class="form-control" placeholder="County" [ngModel]="teamMember.ContactDetail && teamMember.ContactDetail.HomeAddressCounty" (ngModelChange)="CreateContactIfNull('HomeAddressCounty', $event)">
                            </div>
                        </div>
                        <div class="row mb-3">
                            <div class="col-sm-3 offset-sm-3">
                                <input type="text" name="homeAddressPostcode" class="form-control" placeholder="Postcode" [ngModel]="teamMember.ContactDetail && teamMember.ContactDetail.HomeAddressPostcode" (ngModelChange)="CreateContactIfNull('HomeAddressPostcode', $event)">
                            </div>
                        </div>
                        <hr />
                        <div class="row mb-3">
                            <label for="workAddressLine1" class="col-sm-3 col-form-label fw-bold">Work Address</label>
                            <div class="col-sm-9">
                                <input type="text" name="workAddressLine1" class="form-control" placeholder="Address Line 1" [ngModel]="teamMember.ContactDetail && teamMember.ContactDetail.WorkAddressLine1" (ngModelChange)="CreateContactIfNull('WorkAddressLine1', $event)">
                            </div>
                        </div>
                        <div class="row mb-3">
                            <div class="col-sm-9 offset-sm-3">
                                <input type="text" name="workAddressLine2" class="form-control" placeholder="Address Line 2" [ngModel]="teamMember.ContactDetail && teamMember.ContactDetail.WorkAddressLine2" (ngModelChange)="CreateContactIfNull('WorkAddressLine2', $event)">
                            </div>
                        </div>
                        <div class="row mb-3">
                            <div class="col-sm-9 offset-sm-3">
                                <input type="text" name="workAddressLine3" class="form-control" placeholder="Address Line 3" [ngModel]="teamMember.ContactDetail && teamMember.ContactDetail.WorkAddressLine3" (ngModelChange)="CreateContactIfNull('WorkAddressLine3', $event)">
                            </div>
                        </div>
                        <div class="row mb-3">
                            <div class="col-sm-4 offset-sm-3">
                                <input type="text" name="workAddressTown" class="form-control" placeholder="Town" [ngModel]="teamMember.ContactDetail && teamMember.ContactDetail.WorkAddressTown" (ngModelChange)="CreateContactIfNull('WorkAddressTown', $event)">
                            </div>
                        </div>
                        <div class="row mb-3">
                            <div class="col-sm-4 offset-sm-3">
                                <input type="text" name="workAddressCounty" class="form-control" placeholder="County" [ngModel]="teamMember.ContactDetail && teamMember.ContactDetail.WorkAddressCounty" (ngModelChange)="CreateContactIfNull('WorkAddressCounty', $event)">
                            </div>
                        </div>
                        <div class="row mb-3">
                            <div class="col-sm-3 offset-sm-3">
                                <input type="text" name="workAddressPostcode" class="form-control" placeholder="Postcode" [ngModel]="teamMember.ContactDetail && teamMember.ContactDetail.WorkAddressPostcode" (ngModelChange)="CreateContactIfNull('WorkAddressPostcode', $event)">
                            </div>
                        </div>
                        <hr />
                        <div ng-hide="isCare">
                            <div class="row mb-3">
                                <label for="teamcallsign" class="col-sm-3 col-form-label fw-bold">Team Callsign</label>
                                <div class="col-sm-9">
                                    <input type="text" name="teamcallsign" class="form-control" placeholder="Team Callsign" [ngModel]="teamMember.ContactDetail && teamMember.ContactDetail.TeamCallsign" (ngModelChange)="CreateContactIfNull('TeamCallsign', $event)">
                                </div>
                            </div>
                            <div class="row mb-3">
                                <label for="airwavecallsign" class="col-sm-3 col-form-label fw-bold">Airwave Callsign</label>
                                <div class="col-sm-9">
                                    <input type="text" name="airwavecallsign" class="form-control" placeholder="Airwave Callsign" [ngModel]="teamMember.ContactDetail && teamMember.ContactDetail.AirwaveCallsign" (ngModelChange)="CreateContactIfNull('AirwaveCallsign', $event)">
                                </div>
                            </div>
                        </div>
                    </ng-template>
                </li>
                <li [ngbNavItem]="5">
                    <button ngbNavLink>Licence Information</button>
                    <ng-template ngbNavContent>
                        <div class="row mb-3">
                            <label for="isfulllicence" class="col-sm-3 col-form-label fw-bold pt-0">Licence Type</label>
                            <div class="col-sm-9">
                                <div class="form-check">
                                    <input class="form-check-input" [disabled]="isReadOnly" type="radio" name="Islicencefull" [value]=true [ngModel]="teamMember.DrivingLicence && teamMember.DrivingLicence.IsFullLicence"  (ngModelChange)="CreateDlIfNull('IsFullLicence', $event)">
                                    <label class="form-check-label" for="Islicencefull">
                                        Full
                                    </label>
                                </div>
                                <div class="form-check">
                                    <input class="form-check-input" [disabled]="isReadOnly" type="radio" name="Islicencefull" [value]=false [ngModel]="teamMember.DrivingLicence && teamMember.DrivingLicence.IsFullLicence" (ngModelChange)="CreateDlIfNull('IsFullLicence', $event)">
                                    <label class="form-check-label" for="Islicencefull">
                                        Provisional
                                    </label>
                                </div>
                            </div>
                        </div>
                        <br />
                        <div class="row mb-3">
                            <label for="licencevalidfrom" class="col-sm-3 col-form-label fw-bold">Licence Valid From</label>
                            <div class="col-sm-9">
                                <tiertimepicker name="licencevalidfrom" #issuedate="ngModel" [readonly]="isReadOnly" [ngModel]="teamMember.DrivingLicence && teamMember.DrivingLicence.IssueDate" (ngModelChange)="CreateDlIfNull('IssueDate', $event)"></tiertimepicker>
                            </div>
                        </div>
                        <div class="row mb-3">
                            <label for="licencevalidto" class="col-sm-3 col-form-label fw-bold">Licence Valid To</label>
                            <div class="col-sm-9">
                                <tiertimepicker name="licencevalidto" #expirydate="ngModel" [readonly]="isReadOnly" [model]="expirydate" [DateValidation]="[ { value: issuedate.value, equality: 'morethan', ident: 'issuedate' } ]" [ngModel]="teamMember.DrivingLicence && teamMember.DrivingLicence.ExpiryDate" (ngModelChange)="CreateDlIfNull('ExpiryDate', $event)"></tiertimepicker>
                                <p [hidden]="expirydate.valid" class="form-text text-danger">Date cannot be below issue date.</p>
                            </div>
                        </div>
                        <div class="row mb-3">
                            <label for="licenceissueauthority" class="col-sm-3 col-form-label fw-bold">Issuing Authority</label>
                            <div class="col-sm-9">
                                <input type="text" name="licenceissueauthority" class="form-control" [ngClass]="{ 'ro' : isReadOnly }" [readonly]="isReadOnly" placeholder="Issuing Authority" [ngModel]="teamMember.DrivingLicence && teamMember.DrivingLicence.IssuingAuthority" (ngModelChange)="CreateDlIfNull('IssuingAuthority', $event)">
                            </div>
                        </div>
                        <div class="row mb-3">
                            <label for="licencenumber" class="col-sm-3 col-form-label fw-bold">Licence Number</label>
                            <div class="col-sm-9">
                                <input type="text" name="licencenumber" class="form-control" [ngClass]="{ 'ro' : isReadOnly }" [readonly]="isReadOnly" placeholder="Licence Number" [ngModel]="teamMember.DrivingLicence && teamMember.DrivingLicence.LicenceNumber" (ngModelChange)="CreateDlIfNull('LicenceNumber', $event)">
                            </div>
                        </div>
                        <hr />
                        <div class="row mb-3">
                            <label for="categorytable" class="col-sm-3 col-form-label fw-bold">Entitlements</label>
                            <div class="col-sm-9">
                                <table class="table table-hover" name="categorytable" *ngIf="teamMember.DrivingLicence?.DrivingLicenceCategories?.length">
                                    <tr>
                                        <th>Entitlement</th>
                                        <th>From</th>
                                        <th>To</th>
                                        <th>Codes</th>
                                        <th class="text-center" [hidden]="isReadOnly">Remove</th>
                                    </tr>
                                    <tr *ngFor="let category of teamMember.DrivingLicence?.DrivingLicenceCategories">
                                        <td>
                                            <span>
                                                {{category.DrivingLicenceCategoryEntitlement?.Name}}
                                                <i ng-if="category.DrivingLicenceCategoryEntitlement.Icon !== null" class="fa-solid {{category.DrivingLicenceCategoryEntitlement?.Icon}}"></i>
                                            </span>
                                        </td>
                                        <td>
                                            {{category.ValidFrom | transformdate: 'dd-MM-yyyy'}}
                                        </td>
                                        <td>
                                            {{category.ValidTo | transformdate: 'dd-MM-yyyy'}}
                                        </td>
                                        <td>
                                            {{category.InformationCodes || 'None'}}
                                        </td>
                                        <td class="text-center">
                                            <i class="fa-solid fa-times" (click)="deleteEntitlement(category)" [hidden]="isReadOnly"></i>
                                        </td>
                                    </tr>
                                </table>
                                <button type="button" class="btn btn-primary btn-xs" (click)="openTeamMemberAddEntitlement()" [disabled]="isReadOnly">Add New Entitlement</button>
                            </div>
                        </div>
                        <hr />
                        <div class="row mb-3">
                            <label for="penaltytable" class="col-sm-3 col-form-label fw-bold">Penalty Points</label>
                            <div class="col-sm-9">
                                <table class="table table-hover" name="penaltytable" *ngIf="teamMember.DrivingLicence?.DrivingLicencePoints?.length">
                                    <tr>
                                        <th>Penalty</th>
                                        <th>From</th>
                                        <th>To</th>
                                        <th>Points</th>
                                        <th class="text-center" [hidden]="isReadOnly">Remove</th>
                                    </tr>
                                    <tr *ngFor="let points of teamMember.DrivingLicence?.DrivingLicencePoints">
                                        <td>
                                            <span>{{points.DrivingLicencePenaltyPoint?.Name}}</span>
                                        </td>
                                        <td>
                                            {{points.ValidFrom | transformdate: 'dd-MM-yyyy'}}
                                        </td>
                                        <td>
                                            {{points.ValidTo | transformdate: 'dd-MM-yyyy'}}
                                        </td>
                                        <td>
                                            {{points.Points}}
                                        </td>
                                        <td class="text-center">
                                            <i class="fa-solid fa-times" (click)="deletePenalty(points)" [hidden]="isReadOnly"></i>
                                        </td>
                                    </tr>
                                </table>
                                <button type="button" class="btn btn-primary btn-xs" (click)="openTeamMemberAddPoints()" [disabled]="isReadOnly">Add New Points</button>
                            </div>
                        </div>
                    </ng-template>
                </li>
                <li [ngbNavItem]="6">
                    <button ngbNavLink>Emergency Contact Information</button>
                    <ng-template ngbNavContent>
                        <div class="row mb-3">
                            <label for="nokfullname" class="col-sm-3 col-form-label fw-bold">Full Name</label>
                            <div class="col-sm-6">
                                <input type="text" name="nokfullname" class="form-control" placeholder="Full Name" [ngModel]="teamMember.NextOfKinContactDetail && teamMember.NextOfKinContactDetail.FullName" (ngModelChange)="CreateNokContactIfNull('FullName', $event)">
                            </div>
                        </div>
                        <div class="row mb-3">
                            <label for="nokrelationship" class="col-sm-3 col-form-label fw-bold">Relationship</label>
                            <div class="col-sm-6">
                                <input type="text" name="nokrelationship" class="form-control" placeholder="Relationship" [ngModel]="teamMember.NextOfKinContactDetail && teamMember.NextOfKinContactDetail.Relationship" (ngModelChange)="CreateNokContactIfNull('Relationship', $event)">
                            </div>
                        </div>
                        <div class="row mb-3">
                            <label for="nokemailaddress" class="col-sm-3 col-form-label fw-bold">Email Address</label>
                            <div class="col-sm-6">
                                <input type="email" name="nokemailaddress" class="form-control" placeholder="Email Address" [ngModel]="teamMember.NextOfKinContactDetail && teamMember.NextOfKinContactDetail.EmailAddress" (ngModelChange)="CreateNokContactIfNull('EmailAddress', $event)">
                            </div>
                        </div>
                        <div class="row mb-3">
                            <label for="nokhomenumber" class="col-sm-3 col-form-label fw-bold">Home Phone Number</label>
                            <div class="col-sm-6">
                                <tierphonenumber name="nokhomenumber" placeholder="Home Phone Number" [ngModel]="teamMember.NextOfKinContactDetail && teamMember.NextOfKinContactDetail.HomeNumber" (ngModelChange)="CreateNokContactIfNull('HomeNumber', $event)"></tierphonenumber>
                            </div>
                        </div>
                        <div class="row mb-3">
                            <label for="nokmobilenumber" class="col-sm-3 col-form-label fw-bold">Mobile Phone Number</label>
                            <div class="col-sm-6">
                                <tierphonenumber name="nokmobilenumber" placeholder="Mobile Phone Number" [ngModel]="teamMember.NextOfKinContactDetail && teamMember.NextOfKinContactDetail.MobileNumber" (ngModelChange)="CreateNokContactIfNull('MobileNumber', $event)"></tierphonenumber>
                            </div>
                        </div>
                        <hr />
                        <div class="row mb-3">
                            <label for="nokHomeAddressLine1" class="col-sm-3 col-form-label fw-bold">Home Address</label>
                            <div class="col-sm-9">
                                <input type="text" name="nokHomeAddressLine1" class="form-control" placeholder="Address Line 1" [ngModel]="teamMember.NextOfKinContactDetail && teamMember.NextOfKinContactDetail.HomeAddressLine1" (ngModelChange)="CreateNokContactIfNull('HomeAddressLine1', $event)">
                            </div>
                        </div>
                        <div class="row mb-3">
                            <div class="col-sm-9 offset-sm-3">
                                <input type="text" name="nokHomeAddressLine2" class="form-control" placeholder="Address Line 2" [ngModel]="teamMember.NextOfKinContactDetail && teamMember.NextOfKinContactDetail.HomeAddressLine2" (ngModelChange)="CreateNokContactIfNull('HomeAddressLine2', $event)">
                            </div>
                        </div>
                        <div class="row mb-3">
                            <div class="col-sm-9 offset-sm-3">
                                <input type="text" name="nokHomeAddressLine3" class="form-control" placeholder="Address Line 3" [ngModel]="teamMember.NextOfKinContactDetail && teamMember.NextOfKinContactDetail.HomeAddressLine3" (ngModelChange)="CreateNokContactIfNull('HomeAddressLine3', $event)">
                            </div>
                        </div>
                        <div class="row mb-3">
                            <div class="col-sm-4 offset-sm-3">
                                <input type="text" name="nokHomeAddressTown" class="form-control" placeholder="Town" [ngModel]="teamMember.NextOfKinContactDetail && teamMember.NextOfKinContactDetail.HomeAddressTown" (ngModelChange)="CreateNokContactIfNull('HomeAddressTown', $event)">
                            </div>
                        </div>
                        <div class="row mb-3">
                            <div class="col-sm-4 offset-sm-3">
                                <input type="text" name="nokHomeAddressCounty" class="form-control" placeholder="County" [ngModel]="teamMember.NextOfKinContactDetail && teamMember.NextOfKinContactDetail.HomeAddressCounty" (ngModelChange)="CreateNokContactIfNull('HomeAddressCounty', $event)">
                            </div>
                        </div>
                        <div class="row mb-3">
                            <div class="col-sm-3 offset-sm-3">
                                <input type="text" name="nokHomeAddressPostcode" class="form-control" placeholder="Postcode" [ngModel]="teamMember.NextOfKinContactDetail && teamMember.NextOfKinContactDetail.HomeAddressPostcode" (ngModelChange)="CreateNokContactIfNull('HomeAddressPostcode', $event)">
                            </div>
                        </div>
                        <hr />
                        <div class="row mb-3">
                            <label for="nokWorkAddressLine1" class="col-sm-3 col-form-label fw-bold">Work Address</label>
                            <div class="col-sm-6">
                                <input type="text" name="nokWorkAddressLine1" class="form-control" placeholder="Address Line 1" [ngModel]="teamMember.NextOfKinContactDetail && teamMember.NextOfKinContactDetail.WorkAddressLine1" (ngModelChange)="CreateNokContactIfNull('WorkAddressLine1', $event)">
                            </div>
                        </div>
                        <div class="row mb-3">
                            <div class="col-sm-9 offset-sm-3">
                                <input type="text" name="nokWorkAddressLine2" class="form-control" placeholder="Address Line 2" [ngModel]="teamMember.NextOfKinContactDetail && teamMember.NextOfKinContactDetail.WorkAddressLine2" (ngModelChange)="CreateNokContactIfNull('WorkAddressLine2', $event)">
                            </div>
                        </div>
                        <div class="row mb-3">
                            <div class="col-sm-9 offset-sm-3">
                                <input type="text" name="nokWorkAddressLine3" class="form-control" placeholder="Address Line 3" [ngModel]="teamMember.NextOfKinContactDetail && teamMember.NextOfKinContactDetail.WorkAddressLine3" (ngModelChange)="CreateNokContactIfNull('WorkAddressLine3', $event)">
                            </div>
                        </div>
                        <div class="row mb-3">
                            <div class="col-sm-4 offset-sm-3">
                                <input type="text" name="nokWorkAddressTown" class="form-control" placeholder="Town" [ngModel]="teamMember.NextOfKinContactDetail && teamMember.NextOfKinContactDetail.WorkAddressTown" (ngModelChange)="CreateNokContactIfNull('WorkAddressTown', $event)">
                            </div>
                        </div>
                        <div class="row mb-3">
                            <div class="col-sm-4 offset-sm-3">
                                <input type="text" name="nokWorkAddressCounty" class="form-control" placeholder="County" [ngModel]="teamMember.NextOfKinContactDetail && teamMember.NextOfKinContactDetail.WorkAddressCounty" (ngModelChange)="CreateNokContactIfNull('WorkAddressCounty', $event)">
                            </div>
                        </div>
                        <div class="row mb-3">
                            <div class="col-sm-3 offset-sm-3">
                                <input type="text" name="nokWorkAddressPostcode" class="form-control" placeholder="Postcode" [ngModel]="teamMember.NextOfKinContactDetail && teamMember.NextOfKinContactDetail.WorkAddressPostcode" (ngModelChange)="CreateNokContactIfNull('WorkAddressPostcode', $event)">
                            </div>
                        </div>
                    </ng-template>
                </li>
            </ul>
        </div>
        <div [ngbNavOutlet]="nav" class="row mt-3"></div>
        <div class="row mb-4">
            <div class="text-end pe-2">
                <div>
                    <button type="button" class="btn me-2 btn-info" [hidden]="!teamMember.Id || !localPasswordAvaliable" [disabled]="tmForm.invalid" (click)="changePassword()">
                        Change Password
                    </button>
                    <button type="button" class="btn me-2 btn-secondary" [hidden]="!isReadOnly" (click)="downloadProfile()">
                        Download Profile Data
                    </button>
                    <button type="button" class="btn me-2"
                            [hidden]="!teamMember.Id || isReadOnly"
                            [ngClass]="{ 'btn-success': !teamMember.LockoutEnabled, 'btn-danger': teamMember.LockoutEnabled }"
                            [textContent]="teamMember.LockoutEnabled === false ? 'Active' : 'Inactive'"
                            [disabled]="tmForm.invalid"
                            (click)="ban()">
                    </button>
                    <button type="submit" class="btn btn-primary" [disabled]="tmForm.pristine || tmForm.invalid">
                        Save
                    </button>
                </div>
            </div>
        </div>
    </form>
</div>
