'use strict';

import { Injectable, Inject } from '@angular/core';
import { Observable } from 'rxjs';
import { map, shareReplay } from 'rxjs/operators';
import { HttpContext } from '@angular/common/http';

import { TIERAPICalls, TIERAuth, SKIP_ROOT_SPINNER } from './';
import { OrganizationModel } from '../models';
import { isNorU } from 'src/tier/tier.utils';

export interface ExceededLimit {
    limit : number,
    count : number
}

@Injectable({
    providedIn: 'root'
})
export class TIEROrganization
{
    private myOrganization : OrganizationModel | null = null;

    constructor(
        @Inject(TIERAPICalls) private apicall : TIERAPICalls,
        @Inject(TIERAuth) private auth : TIERAuth
    ) { }

    private fetch() : Observable<OrganizationModel> | null {
        let tokenInfo = this.auth.getTokenInfo();

        if(isNorU(tokenInfo)) {
            console.error("Unable to get token information.");
            return null;
        }

        return this.apicall.get<OrganizationModel>('/api/organizations/%s'.formUri(tokenInfo!.organizationId), undefined, undefined, new HttpContext().set(SKIP_ROOT_SPINNER, true))
            .pipe<OrganizationModel>(shareReplay(1))
            .pipe(map((res) => this.myOrganization = res));
    }

    public get() : Observable<OrganizationModel> | null {
        if(this.myOrganization)
            return new Observable(obs => {
                obs.next(this.myOrganization!);
            });

        return this.fetch();
    }

    public hasExceededLimit() : Observable<ExceededLimit> {
        return new Observable(obs => {
            let fetching : Observable<OrganizationModel> | null = this.get();

            if(!fetching)
                return obs.error("Unable to fetch org data");

            fetching!.subscribe({
                next: (result : OrganizationModel) => {
                    if(result.ActiveMembers > result.Limit) {
                        return obs.next({ limit: result.Limit, count: result.ActiveMembers });
                    } else {
                        return obs.next();
                    }
                },
                error: (error) => {
                    obs.error(error);
                }
            })
        });
    }
}
