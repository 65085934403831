import { Component, EventEmitter, Output } from '@angular/core';

@Component({
    selector: 'TIERExtendButtons',
    template: `
    <div>
        <button type="button" class="btn btn-secondary" (click)="extend.emit()">Expand All</button> /
        <button type="button" class="btn btn-secondary" (click)="collapse.emit()">Collapse All</button>
    </div>`
})
export class TIERExtendButtonsComponent {
    @Output() extend : EventEmitter<void> = new EventEmitter();
    @Output() collapse : EventEmitter<void> = new EventEmitter();
}
