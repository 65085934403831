'use strict';

import { Injectable, Inject } from '@angular/core';
import { HttpParams, HttpClient, HttpHeaders, HttpContext, HttpContextToken } from '@angular/common/http';
import { Observable } from 'rxjs';

import { TIERHelpers } from './';

export const SKIP_ROOT_SPINNER = new HttpContextToken<boolean>(() => false)

@Injectable({
    providedIn: 'root'
})
export class TIERAPICalls
{
    constructor(
        @Inject(TIERHelpers) private helpers : TIERHelpers,
        @Inject(HttpClient) private http : HttpClient) {}

    public get<T = object>(url : string, params : HttpParams = new HttpParams(), headers : HttpHeaders = new HttpHeaders(), context: HttpContext = new HttpContext()) : Observable<T> {
        return this.http.request<T>('GET', this.helpers.as(url), { 'responseType': 'json', params, headers, 'context': context });
    }

    public post<T = object>(url : string, body : object | string, params : HttpParams = new HttpParams(), headers : HttpHeaders = new HttpHeaders(), context: HttpContext = new HttpContext()) : Observable<T>  {
        return this.http.request<T>('POST', this.helpers.as(url), { 'responseType': 'json', 'body': body, params, headers, 'context': context });
    }

    public put<T = object>(url : string, body : T | string, params : HttpParams = new HttpParams(), headers : HttpHeaders = new HttpHeaders(), context: HttpContext = new HttpContext()) : Observable<object>  {
        return this.http.request('PUT', this.helpers.as(url), { 'responseType':'json', 'body': body, params, headers, 'context': context });
    }

    public delete<T = object>(url : string, params : HttpParams = new HttpParams(), headers : HttpHeaders = new HttpHeaders(), context: HttpContext = new HttpContext()) : Observable<T>  {
        return this.http.request<T>('DELETE', this.helpers.as(url), { 'responseType':'json', params, headers, 'context': context });
    }

    public save<T = object>(url : string, body : any, params : HttpParams = new HttpParams(), headers : HttpHeaders = new HttpHeaders(), context: HttpContext = new HttpContext()) : Observable<T>  {
        let method = 'POST';

        if(typeof body.Id !== 'undefined' && body.Id !== 0 && body.Id !== null)
            method = 'PUT';

        return this.http.request<T>(method, this.helpers.as(url), { 'responseType':'json', 'body': body, params, headers, 'context': context });
    }
}
