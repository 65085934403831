<div class="p-2">
    <ul ngbNav #nav="ngbNav" class="nav-tabs" [destroyOnHide]="false">
        <li [ngbNavItem]="1">
            <button ngbNavLink>Organization Resources</button>
            <ng-template ngbNavContent>
                <tier-equipment-resources
                    viewLink="account@equipmentcheckout"
                    [checkout]="true"
                    (itemChoosen)="onSelection($event)"
                    [refresh]="refreshEmitter"
                    [filterConfig]="resourceSelectionFilterConfig">
                </tier-equipment-resources>
            </ng-template>
        </li>
        <li [ngbNavItem]="2">
            <button ngbNavLink>My Allocated Resources</button>
            <ng-template ngbNavContent>
                <div>
                    <tierfilter class="mb-3" [isPageFilter]="true" [config]="filterConfig" [(data)]="values" (fetch)="get()"></tierfilter>
                </div>
                <div *ngIf="resources?.length">
                    <div class="card mb-3">
                        <div class="card-header">
                            My Allocated Resources
                        </div>
                        <div class="card-body">
                            <div class="row">
                                <div class="col-md-6 col-lg-4 col-xxl-3 mb-2" *ngFor="let resource of resources">
                                    <div class="card border-success" (click)="onSelection(resource)">
                                        <div class="card-body d-flex">
                                            <div class="hstack">
                                                <div class="ps-4" [ngClass]="{ 'unallocatable': (!resource.EquipmentTemplate?.IsAllocatable || !resource.IsAllowedToCheckout || (resource.AllocatedToId !== userId && resource.AllocatedToId !== null)) && values.checkout }">
                                                    <h1 class="m-0 p-0">
                                                        <span class="fa-stack">
                                                            <i class="fa-solid fa-hard-hat text-danger" *ngIf="resource.EquipmentTemplate?.IsContainer === false"></i>
                                                            <i class="fa-solid fa-box text-danger" *ngIf="resource.EquipmentTemplate?.IsContainer === true"></i>
                                                        </span>
                                                    </h1>
                                                    <span class="cost-font" *ngIf="resource.Cost && !values.checkout">£{{resource.Cost}}</span>
                                                </div>
                                                <div class="ps-4 text-primary">
                                                    <b>Template: </b> {{resource.EquipmentTemplate?.Name}}<br>

                                                    <b>Reference:</b><span class="text-break"> {{resource.Reference}}</span><br>
                                                    <b>Checked Due: </b> {{(resource.CheckDueDate | transformdate: 'dd-MM-yyyy') || 'Not Checked'}}<br>
                                                    <b>Expiry Date: </b> {{(resource.ExpiryDate | transformdate: 'dd-MM-yyyy') || 'No Expiry'}}<br>
                                                    <b>Condition: </b>
                                                    <span class="font-bold" [ngClass]="{ 'text-success': resource.EquipmentResourceConditionType?.IsDefault, 'text-danger': !resource.EquipmentResourceConditionType?.IsDefault }">
                                                        {{resource.EquipmentResourceConditionType?.Name}}
                                                    </span>
                                                </div>
                                            </div>
                                        </div>
                                        <div>
                                            <div class="card-footer text-primary">
                                                <span><i class="fa-solid fa-info-circle"></i> Allocated on {{resource.AllocatedOn | transformdate: 'dd-MM-yyyy HH:mm'}}</span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="d-flex justify-content-center my-3" [hidden]="!resources.length">
                        <ngb-pagination [collectionSize]="total" [(page)]="page" [pageSize]="values.amount" [maxSize]="15" [rotate]="true" [ellipses]="false" [boundaryLinks]="true" (pageChange)="get()" />
                    </div>

                </div>
                <div class="alert alert-info" role="alert" [hidden]="resources.length">
                    <i class="fa-solid fa-exclamation-circle"></i> 0 results found.
                </div>
            </ng-template>
        </li>
    </ul>
    <div [ngbNavOutlet]="nav" class="row mt-3"></div>
</div>
