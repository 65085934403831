'use strict'

import { Component, OnInit, Inject } from '@angular/core';
import { HttpParams } from '@angular/common/http';
import { format, subMonths } from "date-fns";

import { TIERAPICalls, TIERToast } from '../../services';
import { AuditLogModel, AuditLogEntryModel } from 'src/tier/models/';
import { http2Error, calcUUID } from 'src/tier/tier.utils';

@Component({
    selector: 'tier-management-auditlog',
    templateUrl: "./auditlog.template.html"
})
export class AuditLogComponent implements OnInit {

    public entries : AuditLogEntryModel[] = [];
    public total : number = 0;
    public page : number = 1;

    public filterConfig = [
        {
            name: calcUUID(),
            formControl: "search",
            label: "Search Text",
            value: "search"
        },
        {
            name: calcUUID(),
            label: "Level",
            formControl: "dropdown",
            placeHolder: "Select Level",
            noItemsPlaceHolder: "No Levels to select",
            source: [{ Id: "all", Name: "All" }, { Id: "info", Name: "Info" }, { Id: "warn", Name: "Warn" }, { Id: "error", Name: "Error" }],
            value: "level"
        },
        {
            name: calcUUID(),
            label: "Date",
            equality: 'dateEquality',
            formControl: "date",
            format: "dd-MM-yyyy",
            value: "date"
        }
    ];

    public values = {
        skip: 0,
        amount: 30,
        dateEquality: 'morethan',
        date: format(subMonths(new Date(), 1), 'dd-MM-yyyy'),
        level: "all",
        search: ''
    };

  constructor(
        @Inject(TIERAPICalls) private apicall : TIERAPICalls,
        @Inject(TIERToast) private alert : TIERToast
      ) { }

    public get() : void {
        this.values.skip = (this.page - 1) * this.values.amount;

        this.apicall.get<AuditLogModel>('api/auditlog', new HttpParams({'fromObject': { ...this.values }})).subscribe({
            next: (response : AuditLogModel) => {
                this.entries = response.ObjectList;
                this.total = response.Total;
            },
            error: (error) => {
                this.alert.error(http2Error(error));
            }
        });
    }

    ngOnInit(): void {
        this.get();
    }
}
