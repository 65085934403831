import { Component, OnInit, Inject } from '@angular/core';
import { StateService, Transition } from '@uirouter/angular';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Observable, forkJoin } from 'rxjs';
import { addMonths, format } from "date-fns";

import { TIERAPICalls, TIERToast, TIERDialog } from '../../services';
import { EquipmentResourceModel, EquipmentTemplateModel } from '../../models';
import { http2Error, isNorU } from 'src/tier/tier.utils';
import { EquipmentResource } from './equipmentresource';

@Component({
    selector: 'tier-equipment-resourceadd',
    templateUrl: "./equipmentresource.template.html"
})
export class EquipmentResourceAddComponent extends EquipmentResource implements OnInit {
    private templateId = this.trans.params()?.templateId;
    private parentId = this.trans.params()?.parentId;

    public resource : EquipmentResourceModel = { 'EquipmentResourceParentId': this.parentId, 'EquipmentTemplateId': this.templateId, 'EquipmentResourceProperties': [], IsActive: true };

    constructor(
        @Inject(TIERAPICalls) protected apicall : TIERAPICalls,
        @Inject(TIERToast) protected alert : TIERToast,
        @Inject(StateService) protected state : StateService,
        @Inject(TIERDialog) protected dialog : TIERDialog,
        @Inject(Transition) protected trans : Transition,
        @Inject(NgbModal) protected modalService : NgbModal
    ) { super(apicall, alert, dialog, modalService); }

    ngOnInit(): void {
        let request : Observable<any>[] = [ this.apicall.get<EquipmentTemplateModel>("api/equipment/template/%s".formUri(this.templateId)) ]

        if(!isNorU(this.parentId))
            request.push(this.apicall.get<EquipmentResourceModel>("api/equipment/resource/%s".formUri(this.parentId)));

        forkJoin(request).subscribe({
            next: (response : any) => {
                this.resource.EquipmentTemplate = response[0];
                this.resource.EquipmentResourceParent = response[1] ?? null;

                this.resource.Cost = this.resource?.EquipmentTemplate?.DefaultCost ?? null
                this.resource.Weight = this.resource?.EquipmentTemplate?.DefaultWeight ?? null;

                if(!isNorU(this.resource?.EquipmentTemplate?.ExpiryPeriodInMonths))
                    this.resource.ExpiryDate = format(addMonths(new Date(), this.resource.EquipmentTemplate?.ExpiryPeriodInMonths!), 'dd-MM-yyyy');
            },
            error: (error) => {
                this.alert.error(http2Error(error));
            }
        })
    }

    public cancel() {
        this.state.go('equipment@resources@view', { 'parentId': this.resource.EquipmentResourceParentId });
    }
}
