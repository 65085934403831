'use strict';

import { Injectable, Inject } from '@angular/core';
import { TIERConfig } from './';

@Injectable({
    providedIn: 'root'
})
export class TIERHelpers
{
    constructor(@Inject(TIERConfig) private config : TIERConfig) {}

    public as(url : string) : string {
        return (this.config.get("apiUrl") + url).replace(/([^:]\/)\/+/ug, "$1");
    };
}
