import { Component, Inject } from '@angular/core';

import { ModuleTimelineReportPagenationModel, ModuleTimelineReportModel } from '../../models';
import { Reports } from '../../reports';
import { TIERAPICalls } from '../../../services/';
import { http2Error } from 'src/tier/tier.utils';

@Component({
    selector: 'tier-reports-moduletimelinereport',
    templateUrl: './moduletimelinereport.template.html'
})
export class ModuleTimelineReportComponent extends Reports {
    public report : ModuleTimelineReportModel[] = [];
    public total : number = 0;
    public search : string | undefined = undefined;

    constructor(
        @Inject(TIERAPICalls) private apicall : TIERAPICalls
    ) { super(); };

    public get() {
        this.apicall.post<ModuleTimelineReportPagenationModel>('api/reports/ModuleTimelineReport',
            this.state2Options()
        ).subscribe({
            next: (response : ModuleTimelineReportPagenationModel) => {
                this.report = response.ObjectList;
                this.total = response.Total;
            },
            error: (error) => {
                this.alert.error(http2Error(error));
            }
        });
    }

    public setState(state : boolean) {
        this.report.forEach((record) => {
            record.Collapse = state;
        })
    }
}
