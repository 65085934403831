import { Component, Input } from '@angular/core';
import { Size } from 'ngx-spinner';

@Component({
    selector: 'tierspinner',
    template: `<ngx-spinner [name]="name" [fullScreen]="fullscreen" [type]="type" [size]="size"><ng-content></ng-content></ngx-spinner>`,
    styleUrls: [ './TIERSpinner.component.scss' ]
})
export class TIERSpinnerComponent {
    @Input() name : string = '';
    @Input() fullscreen: boolean = false;
    @Input() type : string = "timer";
    @Input() size : Size = "medium";
}
