import { Component, Inject } from '@angular/core';

import { EquipmentUsagePagenationModel, EquipmentUsageReportModel, EquipmentUsageReportActivityModel } from '../../models';
import { Reports } from '../../reports';
import { TIERAPICalls } from '../../../services/';
import { http2Error, isNorU } from 'src/tier/tier.utils';

@Component({
    selector: 'tier-reports-equipmentusagereport',
    templateUrl: './equipmentusagereport.template.html'
})
export class EquipmentUsageReportComponent extends Reports {
    public total : number = 0;
    public search : string = '';
    public report : EquipmentUsageReportModel[] = [];

    constructor(
        @Inject(TIERAPICalls) private apicall : TIERAPICalls
    ) { super(); };

    public get() {
        this.apicall.post<EquipmentUsagePagenationModel>('api/reports/EquipmentUsageReport',
            this.state2Options()
        ).subscribe({
            next: (response : EquipmentUsagePagenationModel) => {
                this.report = response.ObjectList;
                this.total = response.Total;
            },
            error: (error) => {
                this.alert.error(http2Error(error));
            }
        });
    }

    public setState(state : boolean) {
        this.report.forEach((record : EquipmentUsageReportModel) => { record.IsCollapsed = state });
    }
}
