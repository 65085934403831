import { Component, Inject, OnInit } from '@angular/core';
import { HttpParams } from '@angular/common/http';
import { Transition } from '@uirouter/angular';

import { TIERAPICalls, TIERToast } from '../../services';
import { SkillModel, SkillListingModel } from '../../models/';
import { http2Error, calcUUID } from 'src/tier/tier.utils';

@Component({
    selector: 'tier-modules',
    templateUrl: "./modules.template.html",
    styleUrls: [ './modules.component.scss' ]
})
export class ModulesComponent implements OnInit {
    public modules : SkillModel[] = [];
    public total : number = 0;
    public page : number = 1;

    public filterConfig = [
        {
            name: calcUUID(),
            formControl: "search",
            label: "Search Text",
            value: "search"
        },
        {
            name: calcUUID(),
            formControl: "tickbox",
            label: "Show Inactive entries",
            value: "showInactive"
        },
        {
            name: calcUUID(),
            formControl: "tickbox",
            label: "Show Critical Only",
            value: "showCriticalOnly"
        }
    ];

    public values = {
        skip: 0,
        amount: 19,
        search: this.trans.params()?.search,
        showInactive: this.trans.params()?.showInactive,
        showCriticalOnly: this.trans.params()?.showCriticalOnly,
    };

    constructor(
        @Inject(TIERAPICalls) private apicall : TIERAPICalls,
        @Inject(TIERToast) private alert : TIERToast,
        @Inject(Transition) private trans : Transition) { };

    ngOnInit(): void {
        this.get();
    }

    public get() : void {
        this.values.skip = (this.page - 1) * this.values.amount;

        this.apicall.get<SkillListingModel>('api/modules/', new HttpParams({'fromObject': { ...this.values }})).subscribe({
            next: (response : SkillListingModel) => {
                this.modules = response.ObjectList;
                this.total = response.Total;
            },
            error: (error) => {
                this.alert.error(http2Error(error));
            }
        });
    }
}
