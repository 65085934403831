import { Component, OnInit, Inject, Input } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

import { TIERToast } from '../../../services';
import { AttachmentModel } from 'src/tier/models';

@Component({
    selector: 'tierupload',
    template: `
        <div class="modal-header">
            <h3 class="modal-title">Upload File</h3>
        </div>
        <div class="modal-body" id="modal-body">
            <div class="dropzone">
                <input type="file" #fileDropRef id="fileDropRef" (drop)="drop($event)" (change)="selected($event)"/>
                <svg xmlns="http://www.w3.org/2000/svg" width="63" height="64" viewBox="0 0 63 64">
                    <g fill="#3B454F" fill-rule="nonzero">
                        <path
                            d="M42.656 15.135a1.953 1.953 0 0 1-1.391-.578L31.5 4.795l-9.765 9.762a1.97 1.97 0 1 1-2.785-2.785L30.106.616a1.97 1.97 0 0 1 2.785 0l11.157 11.156a1.97 1.97 0 0 1-1.392 3.363z" />
                        <path
                            d="M31.5 36.791a1.97 1.97 0 0 1-1.969-1.969V2.01a1.97 1.97 0 0 1 3.938 0v32.812a1.97 1.97 0 0 1-1.969 1.969z" />
                        <path
                            d="M55.781 63.041H7.22A7.225 7.225 0 0 1 0 55.822V41.385a4.599 4.599 0 0 1 4.594-4.594h7.234a4.567 4.567 0 0 1 4.402 3.276l2.814 9.382a.658.658 0 0 0 .628.467h23.656a.658.658 0 0 0 .628-.467l2.814-9.385a4.572 4.572 0 0 1 4.402-3.273h7.234A4.599 4.599 0 0 1 63 41.385v14.437a7.225 7.225 0 0 1-7.219 7.219zM4.594 40.729a.656.656 0 0 0-.657.656v14.437a3.286 3.286 0 0 0 3.282 3.282H55.78a3.286 3.286 0 0 0 3.282-3.282V41.385a.656.656 0 0 0-.657-.656h-7.234a.65.65 0 0 0-.628.467L47.73 50.58a4.628 4.628 0 0 1-4.402 3.274H19.672a4.567 4.567 0 0 1-4.402-3.276l-2.814-9.382a.65.65 0 0 0-.628-.467H4.594z" />
                    </g>
                </svg>

                <h3>Drag and drop file here</h3>
                <h3>or</h3>
                <label class="mb-3" for="fileDropRef">Browse for file</label>
                <p class="fw-bold">Allowed Types: <span *ngFor="let type of allowedTypes; last as l">{{type}}<span *ngIf="!l">, </span></span></p>
            </div>
        </div>
        <div class="modal-footer">
            <button class="btn btn-secondary"
                    type="button"
                    (click)="cancel()"
                    ngbAutofocus>
                Cancel
            </button>
        </div>`,
        styleUrls: [ './TIERUpload.component.scss' ]
})
export class TIERUploadComponent {
    @Input() attachment : AttachmentModel | null = null;
    @Input() allowedTypes : string[] = [];

    constructor(@Inject(NgbActiveModal) public activeModal : NgbActiveModal,
                @Inject(TIERToast) private alert : TIERToast)
        {}

    public selected(event : any) : void {
        this.handle(event.target.files);
    }

    public drop(event : any) : void {
        event.preventDefault();
        event.stopPropagation();

        this.handle(event.dataTransfer.files);
    }

    private handle(files : FileList) : void {
        const file:File = files[0];
        const formData = new FormData();

        if(file) {
            formData.append('file', file);
            this.activeModal.close(formData);
        } else {
            this.alert.error("Unable to upload file");
        }
    }

    public cancel() : void {
        this.activeModal.dismiss();
    }
}
