<div class="d-print-none mb-3 d-flex justify-content-end">
    <TIERExtendButtons class="me-3" (extend)="extendAll()" (collapse)="collapseAll()"></TIERExtendButtons>
    <div>
        <div class="form-group">
            <input type="text" class="form-control"
                placeholder="Search..."
                [(ngModel)]="search"
                minlength="2"
                maxlength="20">
        </div>
    </div>
</div>
<div *ngIf="report.length">
    <div *ngFor="let r of report | search: search" class="card mb-3">
        <div class="card-body">
            <div class="mb-3" *ngIf="params.Type === 'page'">
                <div class="hstack">
                    <div class="me-3">
                        <img class="user-report-pic img-thumbnail" [src]="r.ProfilePictureURL">
                    </div>
                    <div class="w-100">
                        <div>
                            <h1 class="top-right-tm-text-fields">{{r.FullName}}</h1><br />
                            <strong class="top-right-tm-text-fields">Home Phone No:</strong> {{r.HomeNo || 'N/A'}} <br />
                            <strong class="top-right-tm-text-fields">Mobile Phone No:</strong> {{r.MobileNo || 'N/A'}} <br />
                            <strong class="top-right-tm-text-fields">Team Callsign:</strong> {{r.TeamCallsign || 'N/A'}} <br />
                            <strong class="top-right-tm-text-fields">Airwave Callsign:</strong> {{r.AirwaveCallsign || 'N/A'}} <br />
                            <strong class="top-right-tm-text-fields">Incident Attendence:</strong>
                            <ngb-progressbar class="progress-striped" [value]="r.Attendence" [striped]="true" type="success"><b>{{(r?.Attendence | number : '1.0-2' ) || 0.00}}%</b></ngb-progressbar><br />
                        </div>
                        <hr />
                    </div>
                </div>
            </div>
            <div *ngIf="params.Type === 'tab'">
                <div class="table-responsive">
                    <table class="table">
                        <thead>
                            <tr>
                                <th>Fullname</th>
                                <th>Home Number</th>
                                <th>Mobile Number</th>
                                <th>Team Callsign</th>
                                <th>Airwave Callsign</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td>{{r.FullName}}</td>
                                <td>{{r.HomeNo || 'N/A'}}</td>
                                <td>{{r.MobileNo || 'N/A'}}</td>
                                <td>{{r.TeamCallsign || 'N/A'}}</td>
                                <td>{{r.AirwaveCallsign || 'N/A'}}</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
            <tier-reportpane title="Roles" *ngIf="r.Roles?.length" [isHidden]="r.RolesCollapse">
                <div *ngFor="let role of r.Roles">
                    <div class="card text-bg-info mb-2">
                        <div class="card-body fw-bold">
                            {{role.Name}}
                        </div>
                    </div>
                    <table class="table" *ngIf="role.Skills?.length">
                        <thead TIERCustomTableHeader [format]="[
                                        { id: 'Name', name: 'Module' },
                                        { id: 'ExpiryDate', name: 'Expiry' },
                                        { id: 'AssessmentBased', name: 'Type' },
                                        { id: 'Status', name: 'Passed?' },
                                        { id: 'IsValid', name: 'Valid?' } ]"
                            [(table)]="role.Skills">
                        </thead>
                        <tbody>
                            <tr *ngFor="let skill of role.Skills" [ngClass]="{ 'table-danger': !skill.IsValid, 'table-success': skill.IsValid }">
                                <td>{{skill.Name}}</td>
                                <td>{{(skill.ExpiryDate | transformdate: 'dd-MM-yyyy') || 'N/A'}}</td>
                                <td *ngIf="skill.AssessmentBased === true"><span class="badge text-bg-primary">Assessment</span></td>
                                <td *ngIf="skill.AssessmentBased === false"><span class="badge text-bg-primary">Attendence</span></td>
                                <td *ngIf="skill.Status === true && skill.AssessmentBased === true"><i class="fa-solid fa-check"></i></td>
                                <td *ngIf="skill.Status === false && skill.AssessmentBased === true"><i class="fa-solid fa-times"></i></td>
                                <td *ngIf="skill.AssessmentBased === false">N/A</td>
                                <td *ngIf="skill.IsValid === true"><i class="fa-solid fa-check"></i></td>
                                <td *ngIf="skill.IsValid === false"><i class="fa-solid fa-times"></i></td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </tier-reportpane>
            <tier-reportpane title="Allocated Equipment" *ngIf="r.AllocatedEquipment?.length" [isHidden]="r.AllocatedEquipmentCollapse">
                <table class="table">
                    <thead TIERCustomTableHeader [format]="[
                                        { id: 'EquipmentTemplate.Name', name: 'Equipment' },
                                        { id: 'Reference', name: 'Reference' },
                                        { id: 'CheckDueDate', name: 'Check Due' },
                                        { id: 'CheckedBy.FullName', name: 'Last Checked By' },
                                        { id: 'ExpiryDate', name: 'Expires On' },
                                        { id: 'ResourceExpired', name: 'Valid?' } ]"
                        [(table)]="r.AllocatedEquipment">
                    </thead>
                    <tbody>
                        <tr *ngFor="let allocated of r.AllocatedEquipment" ng-class="{
                                'danger': allocated.ResourceExpired === true || allocated.CheckDueExpired === true || allocated.CheckDueDate === null,
                                'success': allocated.ResourceExpired == false && allocated.CheckDueExpired === false && allocated.CheckDueDate !== null }">
                            <td>{{allocated.EquipmentTemplate.Name}}</td>
                            <td>{{allocated.Reference || 'N/A'}}</td>
                            <td>{{(allocated.CheckDueDate | transformdate: 'dd-MM-yyyy') || 'Not Checked'}}</td>
                            <td>{{allocated.CheckedBy.FullName || "Not Checked"}}</td>
                            <td>{{(allocated.ExpiryDate | transformdate: 'dd-MM-yyyy') || 'None'}}</td>
                            <td *ngIf="allocated.ResourceExpired == false && allocated.CheckDueExpired === false && allocated.CheckDueDate !== null"><i class="fa-solid fa-check"></i></td>
                            <td *ngIf="allocated.ResourceExpired === true || allocated.CheckDueExpired === true || allocated.CheckDueDate === null"><i class="fa-solid fa-times"></i></td>
                        </tr>
                    </tbody>
                </table>
            </tier-reportpane>
            <tier-reportpane title="Incidents" *ngIf="r.Incidents?.length" [isHidden]="r.IncidentsCollapse">
                <table class="table">
                    <thead TIERCustomTableHeader [format]="[
                                        { id: 'Name', name: 'Name' },
                                        { id: 'ExternalRef', name: 'Reference' },
                                        { id: 'Minutes', name: 'Duration' } ]"
                        [(table)]="r.Incidents">
                    </thead>
                    <tbody>
                        <tr *ngFor="let incident of r.Incidents">
                            <td>{{incident.Name}}</td>
                            <td>{{incident.ExternalRef || 'N/A'}}</td>
                            <td>{{incident.Minutes}}</td>
                        </tr>
                    </tbody>
                </table>
            </tier-reportpane>
        </div>
    </div>
    <div class="d-flex justify-content-center my-3 d-print-none" *ngIf="params.Amount">
        <ngb-pagination [collectionSize]="total" [(page)]="params.Page" [pageSize]="params.Amount" [maxSize]="5" [rotate]="true" [ellipses]="false" [boundaryLinks]="true" (pageChange)="reload()" />
    </div>
</div>
<div class="alert alert-warning" role="alert" [hidden]="report.length">
    <i class="fa-solid fa-exclamation-circle"></i> 0 results found.
</div>

<TIERReportButtons (print)="print()" (export)="export('TeammembersReport')"></TIERReportButtons>
