'use strict';

import { Injectable, Inject } from '@angular/core';
import { TIERAPICalls } from './';
import {
    Observable,
    switchMap
} from 'rxjs';
import { isNorU } from 'src/tier/tier.utils';

@Injectable()
export class TIERUpload
{
    constructor(@Inject(TIERAPICalls) private apicall : TIERAPICalls) { }

    public upload<T>(url : string, key : keyof T, id : any, form : FormData, precurse : Observable<T> | null = null) : Observable<object> {
        if(!precurse)
            return this.apicall.post(url.formUri(id), form);

        return precurse.pipe(switchMap(result => this.apicall.post(url.formUri(isNorU(result) ? id : result[key]), form)));
    }
}
