import { Component, Input, OnInit, forwardRef } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR, Validator, AbstractControl, ValidationErrors, NG_VALIDATORS, Validators } from "@angular/forms";

import { isNorU } from 'src/tier/tier.utils';

interface TimeSpan {
    match : string,
    text : string,
    year : number
    month : number
    week : number,
    day : number,
    value : number | null
}

@Component({
    selector: 'tiertimespan',
    template: `
        <div class="hstack">
            <div *ngFor="let entry of entries" class="me-2 w-100">
                <div class="input-group">
                    <input type="number" name="{{entry.match}}" class="form-control" [(ngModel)]="entry.value" min="1" max="99" (change)="updateChanges()" \>
                    <span class="input-group-text">{{entry.text}}</span>
                </div>
            </div>
            <button class="btn btn-danger" type="button" (click)="clear()"><i class="fa-solid fa-trash"></i></button>
        </div>`,
    providers: [{
        provide: NG_VALUE_ACCESSOR,
        useExisting: forwardRef(() => TIERTimespanComponent),
        multi: true
    },
    {
      provide: NG_VALIDATORS,
      useExisting: forwardRef(() => TIERTimespanComponent),
      multi: true,
    }]
})
export class TIERTimespanComponent implements ControlValueAccessor, OnInit, Validator {

    @Input() options : string[] = [];
    @Input() format : keyof Pick<TimeSpan, "month" | "week" | "day" | "year"> = "month";

    public entries : TimeSpan[] = [];

    private timespans : TimeSpan[] = [
        { 'match': 'year', 'text': 'years', 'year': 1, 'month': 12, 'week': 52, 'day': 365, 'value': null },
        { 'match': 'month', 'text': 'months', 'year': 12, 'month': 1, 'week': 4, 'day': 31, 'value': null },
        { 'match': 'week', 'text': 'weeks', 'year': 52, 'month': 4, 'week': 1, 'day': 7, 'value': null },
        { 'match': 'day', 'text': 'days', 'year': 365, 'month': 31, 'week': 7, 'day': 1, 'value': null }
    ];

    onChange: (_: any) => void = (_: any) => {};
    onTouched: () => void = () => {};

    ngOnInit(): void {
        let formatIndex = this.timespans.findIndex((element) => {
            return element.match === this.format;
        });

        if(formatIndex === -1) {
            console.log("Format not allowed");
            return;
        }

        if(this.options.findIndex((element) => {
            return element === this.format;
        }) === -1) {
            console.log("Format not in options");
            return;
        }

        this.timespans.forEach((value, index) => {
            if(this.options.findIndex((element) => {
                return element === value.match;
            }) !== -1) {
                if(formatIndex >= index)
                    this.entries.push(value);
            }
        });
    }

    private setTimespan(amount : number | null) : void {
        if(isNorU(amount)) {
            this.clear();
            return;
        }

        this.entries.forEach((entry) => {
            let base = entry[this.format] as number;
            entry.value = null;

            let wn = parseInt((amount! / base).toString());
            if(wn > 0) {
                entry.value = wn;
                amount! -= wn * base;
            }
        });
    }

    public clear() : void {
        this.entries.forEach((entry) => {
            entry.value = null;
        });

        this.onChange(null);
    }

    updateChanges() : void {
        let amount : number = 0;
        let valid : boolean = false;

        for(let i = 0; i < this.entries.length; i++) {
            let entry = this.entries[i];

            if(!isFinite(entry.value ?? 0) && entry.value !== null)
                break;

            if(entry.value && entry[this.format])
                amount += entry.value * entry[this.format];

            if(entry.match === this.format)
                valid = true;
        }

        if(valid)
            this.onChange(amount);
    }

    validate(control: AbstractControl): ValidationErrors | null {
        let isValid : any = {};
        this.entries.forEach((element) => {
            if((element.value ?? 0) <= 0 && element.value !== null)
                isValid[element.text] = element.value;
        });

        if(Object.keys(isValid).length > 0)
            return { 'tiertimespan': isValid }

        return null;
    }

    writeValue(amount : number): void {
        this.setTimespan(amount);
    }

    registerOnChange(fn: any): void {
        this.onChange = fn;
    }

    registerOnTouched(fn: any): void {
        this.onTouched = fn;
    }
}
