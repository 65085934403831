import { Component, OnInit, Inject, Input } from '@angular/core';
import { HttpErrorResponse, HttpParams } from '@angular/common/http';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

import { TIERAPICalls, TIERToast, TIERAuth, TIERUpload, TIERDownload, TIEROptions } from '../../services';
import { AssessmentModel } from 'src/tier/models/';
import { http2Error, calcUUID } from 'src/tier/tier.utils';

import { EditAssessmentsComponent, editAssessment } from './';
import { Observable, finalize } from 'rxjs';

@Component({
    selector: 'tier-training-assessments',
    templateUrl: "./assessments.template.html"
})
export class AssessmentsComponent implements OnInit {

    @Input() state! : string;

    public selectedTeammemberId : string | null = null;
    public assessments : AssessmentModel[] = [];
    public userState : boolean = true;
    public searchText : string | undefined = undefined;
    public allowAttachment : boolean = false;

    public filterConfig = [
        {
            name: calcUUID(),
            label: "Roles",
            formControl: "dropdown",
            placeHolder: "Select Role",
            source: 'api/roles',
            value: "roleId"
        },
        {
            name: calcUUID(),
            label: "Type",
            formControl: "dropdown",
            placeHolder: "Select Type",
            source: [{ Id: "All", Name: "All" }, { Id: "Assessment", Name: "Assessment" }, { Id: "Attendence", Name: "Attendence" }],
            value: "type"
        },
        {
            name: calcUUID(),
            label: "Date Obtained",
            equality: 'assessedEquality',
            formControl: "date",
            format: "dd-MM-yyyy",
            value: "assessed"
        },
        {
            name: calcUUID(),
            label: "Date Expires",
            equality: 'expiredEquality',
            formControl: "date",
            format: "dd-MM-yyyy",
            value: "expired"
        },
        {
            name: calcUUID(),
            label: "Show Only Critical Skills",
            formControl: "tickbox",
            value: "critical"
        }
    ];

    public values = {
        roleId: '',
        critical: false,
        type: "All",
        assessed: '',
        expired: '',
        assessedEquality: 'equal',
        expiredEquality: 'equal'
    };

    constructor(
        @Inject(TIERAPICalls) private apicall : TIERAPICalls,
        @Inject(TIERToast) private alert : TIERToast,
        @Inject(TIERAuth) private auth : TIERAuth,
        @Inject(TIERUpload) private upload : TIERUpload,
        @Inject(TIERDownload) private down : TIERDownload,
        @Inject(NgbModal) private modalService : NgbModal,
        @Inject(TIEROptions) private options : TIEROptions
    ) { }

    ngOnInit(): void {
        this.userState = (this.state === 'user');
        this.allowAttachment = this.options.get('org-training-allow-attachment') === 'true';

        if(this.userState) {
            let token = this.auth.getTokenInfo();

            if(token) {
                this.selectedTeammemberId = token.userId;
                this.get(this.selectedTeammemberId);
            }
        }
    }

    public get(id : string | null) {
        if(!id)
            return;

        this.apicall.get<AssessmentModel[]>('api/assessment/%s'.formUri(id), new HttpParams({'fromObject': { ...this.values }})).subscribe({
            next: (response : AssessmentModel[]) => {
                this.assessments = response;
            },
            error: (error) => {
                this.alert.error(http2Error(error));
            }
        });
    }

    private save(assessment : AssessmentModel) : Observable<AssessmentModel> {
        return this.apicall.save<AssessmentModel>('api/assessment/%s'.formUri(assessment.Id), assessment);
    }

    private delete(assessment : AssessmentModel) : Observable<AssessmentModel> {
        return this.apicall.delete<AssessmentModel>('api/assessment/%s'.formUri(assessment.Id));
    }

    public edit(assessment : AssessmentModel) {
        const modalRef = this.modalService.open(EditAssessmentsComponent);

        modalRef.componentInstance.assessment = { ...assessment };
        modalRef.componentInstance.attachmentOnly = (this.userState && assessment?.Id !== null);

        modalRef.result.then((result : editAssessment) => {
            let obs : any = this.userState && assessment?.Id ? null : this.save(result.assessment);

            if(result.delete && obs) {
                obs = this.delete(result.assessment);
            } else {
                if(result.file?.has('file'))
                    obs = this.upload.upload<AssessmentModel>('api/assessment/%s/uploadattachment', 'Id', result.assessment.Id, result.file, obs)
            }

            if(obs) {
                obs.pipe(finalize(() => {
                    this.get(this.selectedTeammemberId);
                })).subscribe({
                    next: () => { if(!result.quiet) this.alert.success(result.delete ? "Assessment deleted." : "Assessment saved."); },
                    error: (err : HttpErrorResponse) => { this.alert.error(http2Error(err)); }
                });
            }
        }).catch(() => {});
    }

    public download(id : number) {
        this.down.downloadLinkFromServer("api/assessment/%s/downloadattachment".formUri(id)).subscribe({
            next: (link : HTMLAnchorElement) => {
                link.dispatchEvent(new MouseEvent(`click`, { bubbles: true, cancelable: true, view: window }));
            },
            error: (err) => { this.alert.error(http2Error(err)); }
        })
    }
}
