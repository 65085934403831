'use strict'

import { Component, Inject, Input } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Observable } from 'rxjs';

import { ScheduleModel } from 'src/tier/models';
import { TIERAPICalls, TIERToast } from '../../services';
import { http2Error } from 'src/tier/tier.utils';

@Component({
    selector: 'tier-training-schedule-details',
    templateUrl: './scheduledetails.template.html'
})
export class ScheduleDetailsComponent {
    @Input() session : ScheduleModel = {};

    constructor(
        @Inject(NgbActiveModal) public activeModal : NgbActiveModal,
        @Inject(TIERAPICalls) private apicall : TIERAPICalls,
        @Inject(TIERToast) private alert : TIERToast
    ) { }

    public attend(id : number | null | undefined, isAttending : boolean = false) {
        let call : Observable<object> = this.apicall.put<object | null>('api/schedule/%s/attend'.formUri(id), null);

        if(isAttending)
            call = this.apicall.delete<object>('api/schedule/%s/attend'.formUri(id));

        call.subscribe({
            next: () => {
                this.activeModal.close();
            },
            error: (error) => {
                this.alert.error(http2Error(error));
            }
        });
    }
}
