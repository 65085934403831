'use strict'

import { Component, OnInit } from '@angular/core';
import { ChartData, ChartConfiguration } from 'chart.js';
import { WidgetBase } from '../../Widget.base';
import { http2Error } from 'src/tier/tier.utils';

@Component({
    selector: 'tier-widget-teamadmin-incidentpertypewidget',
    template: `
        <tierspinner [name]="getUUID()"></tierspinner>
        <div class="dropdown-container">
            <div class="d-flex flex-row mb-2">
                <label class="col-form-label mx-2 fw-bold">Type</label>
                <div class="flex-fill pe-2">
                    <tierdropdown [source]="typeSelection" [(ngModel)]="selectedTypeSelection" (ngModelChange)="onChange($event)" [allowNew]=false bindId="id"></tierdropdown>
                </div>
            </div>
        </div>
        <div class="chart-container-with-dropdown">
            <canvas baseChart class="chart" [type]="barChartType" [data]="data" [options]="chartOptions"></canvas>
        </div>`,
        styleUrls: [ '../../_widgets.scss' ]
})
export class IncidentPerTypeWidgetComponent extends WidgetBase implements OnInit {
    static readonly widgetName = 'IncidentPerTypeWidgetComponent';
    public data : ChartData<'bar'> | undefined = undefined;
    public attendance : number | null = null;
    public year : number | null = null;
    public selectedTypeSelection : number | string | null = null;

    public typeSelection = [
        { id: 'call', name: 'Call' },
        { id: 'response', name: 'Response' },
        { id: 'operation', name: 'Operation' }
    ];

    public chartOptions : ChartConfiguration['options']= {
        responsive: true,
        maintainAspectRatio: false,
        plugins: {
            legend: {
              display: false,
            },
        }
      };

    ngOnInit(): void {
        this.selectedTypeSelection = this.typeSelection[0].id;
        this.fetch(this.typeSelection[0].id);
    }

    private fetch(selected : number | string) : void {
        this.callAPI<any | null>('api/dashboard/incidentpertype/%s'.formUri(selected), this.getUUID()).subscribe({
            next: (response : any | null) => {
                this.data = response;

                if(this.isEmpty(this.data))
                    this.switchToNoDataWidget();
            },
            error: (error) => {
                this.alert.error(http2Error(error));
            }
        });
    }

    public onChange(selected : number | string) : void {
        this.fetch(selected);
    }
}
