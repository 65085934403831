'use strict'

import { Component, OnInit, Inject } from '@angular/core';
import { TIERAPICalls, TIERToast } from '../../services';
import { NOKContactModel } from '../../models';
import { http2Error } from '../../tier.utils';

@Component({
    selector: 'tier-management-emergencycontacts',
    templateUrl: "./emergencycontacts.template.html"
  })
  export class EmergencyContactsComponent implements OnInit {
    public contacts : any = null;
    public searchText : string | undefined = undefined;

    constructor(
        @Inject(TIERAPICalls) private apicall : TIERAPICalls,
        @Inject(TIERToast) private alert : TIERToast
    ) { };

    ngOnInit() : void {
        this.fetch();
    };

    private fetch() : void {
        this.apicall.get<NOKContactModel[]>('api/NOKContacts/').subscribe({
            next: (response) => {
                this.contacts = response;
            },
            error: (error) => {
              this.alert.error(http2Error(error));
            }
          })
    };
  }
