<form class="col-md-8 offset-md-2 mt-3" role="form" #templateModify="ngForm" name="templateModify" (ngSubmit)="save(template.Id)" novalidate autocomplete="none">
    <div>
        <div class="row mb-3">
            <label for="name" class="col-sm-3 col-form-label fw-bold">Name</label>
            <div class="col-sm-9">
                <input type="text" #name="ngModel" id="name" name="name" class="form-control" [class.is-invalid]="name.invalid" [(ngModel)]="template.Name" required>
                <p [hidden]="name.valid" class="form-text text-danger">A name is required.</p>
            </div>
        </div>
        <div class="row mb-3">
            <label for="supplier" class="col-sm-3 col-form-label fw-bold">Supplier</label>
            <div class="col-sm-9">
                <input type="text" id="supplier" name="supplier" class="form-control" [(ngModel)]="template.Supplier">
            </div>
        </div>
        <div class="row mb-3">
            <label for="suppliercontactname" class="col-sm-3 col-form-label fw-bold">Supplier Contact Name</label>
            <div class="col-sm-9">
                <input type="text" id="suppliercontactname" name="suppliercontactname" class="form-control" [(ngModel)]="template.SupplierContactName">
            </div>
        </div>
        <div class="row mb-3">
            <label for="suppliercontactemail" class="col-sm-3 col-form-label fw-bold">Supplier Contact Email</label>
            <div class="col-sm-9">
                <input type="text" id="suppliercontactemail" name="suppliercontactemail" class="form-control" [(ngModel)]="template.SupplierContactEmail">
            </div>
        </div>
        <div class="row mb-3">
            <label for="dcost" class="col-sm-3 col-form-label fw-bold">Default Cost</label>
            <div class="col-sm-9">
                <div class="input-group">
                    <div class="input-group-text">£</div>
                    <input type="number" id="dcost" #dcost="ngModel" name="dcost" class="form-control" [class.is-invalid]="dcost.invalid" [(ngModel)]="template.DefaultCost" min="0.01" max="999999">
                </div>
                <p class="form-text text-danger" [hidden]="dcost.valid">Cost cannot be 0 or under and cannot be over 999999.</p>
            </div>
        </div>
        <div class="row mb-3">
            <label for="dweight" class="col-sm-3 col-form-label fw-bold">Default Weight</label>
            <div class="col-sm-9">
                <div class="input-group">
                    <input type="number" id="dweight" name="dweight" #dweight="ngModel" class="form-control" [class.is-invalid]="dweight.invalid" [(ngModel)]="template.DefaultWeight" min="0.01" max="999999">
                    <div class="input-group-text">KG</div>
                </div>
                <p class="form-text text-danger" [hidden]="dweight.valid">Weight cannot be 0 or under and cannot be over 999999.</p>
            </div>
        </div>
        <div class="row mb-3">
            <label for="expiryperiod" class="col-sm-3 col-form-label fw-bold">Default Expiry Period</label>
            <div class="col-sm-9">
                <tiertimespan name="expiryperiod" [(ngModel)]="template.ExpiryPeriodInMonths" [options]="['month', 'year']" format="month"></tiertimespan>
            </div>
        </div>
        <div class="row mb-3">
            <label for="checkperiod" class="col-sm-3 col-form-label fw-bold">Default Check Period</label>
            <div class="col-sm-9">
                <tiertimespan name="checkperiod" [(ngModel)]="template.CheckPeriodInMonths" [options]="['month', 'year']" format="month"></tiertimespan>
            </div>
        </div>
        <div class="row mb-3">
            <label for="roledd" class="col-sm-3 col-form-label fw-bold">Allowed Roles</label>
            <div class="col-sm-9">
                <p class="form-text mb-0 ms-1"><i class="fa-solid fa-exclamation-circle"></i> Leave blank for resources to be allocated to any team member</p>
                <tierdropdown
                    id="roledd"
                    name="roledd"
                    source="api/roles"
                    placeholder="Select Role"
                    [(ngModel)]="template.AllowedRoles"
                    [multi]=true
                    bindValue="Name">
                </tierdropdown>
            </div>
        </div>
        <div class="row mb-3">
            <label for="categories" class="col-sm-3 col-form-label fw-bold">Categories</label>
            <div class="col-sm-9">
                <tiertypedropdown
                    id="categories"
                    name="categories"
                    [multi]="true"
                    placeholder="Select Categories"
                    deleteId="Id"
                    url="api/equipment/template/category"
                    label="Categories"
                    [(ngModel)]="template.EquipmentTemplateCategories">
                </tiertypedropdown>
            </div>
        </div>
        <div class="row mb-3">
            <label for="equipment" class="col-sm-3 col-form-label fw-bold">Attributes</label>
            <div class="col-sm-9">
                <tier-equipmentattributes name="equipment" [(ngModel)]="template.EquipmentTemplateAttributes"></tier-equipmentattributes>
            </div>
        </div>

        <div class="row mb-3">
            <div class="col-sm-9 offset-sm-3">
                <div class="form-check">
                    <input class="form-check-input" name="contain" type="checkbox" [(ngModel)]="template.IsContainer">
                    <label class="form-check-label">
                        Template can contain resources?
                    </label>
                </div>
            </div>
        </div>
        <div class="row mb-3">
            <div class="col-sm-9 offset-sm-3">
                <div class="form-check">
                    <input class="form-check-input" name="alloc" type="checkbox" [(ngModel)]="template.IsAllocatable">
                    <label class="form-check-label">
                        Template is Allocatable?
                    </label>
                </div>
            </div>
        </div>
        <div class="row mb-3">
            <div class="col-sm-9 offset-sm-3">
                <div class="form-check">
                    <input class="form-check-input" name="recursivecheck" type="checkbox" [(ngModel)]="template.IsAllowedRecursiveCheck">
                    <label class="form-check-label">
                        Template can be recursively checked?
                    </label>
                </div>
            </div>
        </div>
    </div>
    <div class="row">
        <div class="offset-sm-3 text-end col-sm-9 mb-3">
            <button type="button" class="btn me-2"
                (click)="save(template.Id, true)"
                [ngClass]="{'btn-success': template.IsActive, 'btn-danger': !template.IsActive}"
                [textContent]="template.IsActive ? 'Active' : 'Inactive'"
                [disabled]="templateModify.invalid || !template.Id">
            </button>
            <button class="btn btn-secondary me-2"
                    type="button"
                    (click)="cancel()">
                Cancel
            </button>
            <button type="submit" class="btn btn-primary"
                    [disabled]="templateModify.invalid || templateModify.pristine">
                Save
            </button>
        </div>
    </div>
</form>

