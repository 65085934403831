<div>
    <tierfilter class="mb-3" [isPageFilter]="true" [config]="filterConfig" [(data)]="values" (fetch)="get()"></tierfilter>
</div>

<div class="card">
    <div class="card-header">
        Skill Modules
    </div>
    <div class="card-body">
        <div class="row">
            <div class="col-md-6 col-lg-4 col-xxl-3 mb-2">
                <a class="link-underline link-underline-opacity-0" uiSref="training@modules@add">
                    <div class="card border-info">
                        <div class="card-body d-flex">
                            <div class="hstack">
                                <div class="ps-4">
                                    <h1 class="m-0 p-0"><i class="fa-solid fa-plus text-success"></i></h1>
                                </div>
                                <div class="ps-4">
                                    <h2 class="m-0 p-0 text-primary">New Module</h2>
                                </div>
                            </div>
                        </div>
                    </div>
                </a>
            </div>
            <div class="col-md-6 col-lg-4 col-xxl-3 mb-2" *ngFor="let module of modules">
                <a class="link-underline link-underline-opacity-0" uiSref="training@modules@edit" [uiParams]="{ moduleId: module.Id }">
                    <div class="card" [ngClass]="{ 'border-danger': !module.IsActive, 'border-success': module.IsActive }">
                        <div class="card-body d-flex">
                            <div class="hstack">
                                <div class="">
                                    <div class="ps-4">
                                        <h1 class="m-0 p-0">
                                            <i class="fa-solid fa-box text-danger"></i>
                                        </h1>
                                    </div>
                                </div>
                                <div class="ps-4 text-primary">
                                    <div>
                                        <b>Name: </b>{{module.Name}}
                                    </div>
                                    <div>
                                        <b>Valid For: </b> {{module.MonthsValidFor}} Months<br>
                                        <b>Frequency: </b> {{module.Frequency}} Months<br>
                                        <b>Priority: </b> {{module.Priority}}<br>
                                        <b>Critical: </b> <i class="fa-solid ms-1" [ngClass]="{ 'fa-check':  module.Critical, 'fa-times': !module.Critical }"></i><br>
                                        <b>Assessment Based: </b> <i class="fa-solid ms-1" [ngClass]="{ 'fa-check':  module.AssessmentBasedFlag, 'fa-times': !module.AssessmentBasedFlag}"></i><br>
                                        <span *ngIf="module.Roles!.length > 0"><b>Roles: </b><span *ngFor="let r of module.Roles; last as l">
                                            {{r.Name}}<span *ngIf="!l">,</span>
                                        </span><br></span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </a>
            </div>
        </div>
    </div>
</div>
<div class="d-flex justify-content-center my-3" *ngIf="modules?.length">
    <ngb-pagination [collectionSize]="total" [(page)]="page" [pageSize]="values.amount" [maxSize]="15" [rotate]="true" [ellipses]="false" [boundaryLinks]="true" (pageChange)="get()" />
</div>
