'use strict'

import { Component, Inject, NgZone } from '@angular/core';
import { TIERConfig, TIERAuth, TIERToast, LoginInformation } from '../../services';
import { StateService } from '@uirouter/angular';
import { http2Error } from '../../tier.utils';

@Component({
    selector: 'tier-login',
    templateUrl: "./login.template.html",
    styleUrl: './login.component.scss'
  })
  export class LoginComponent {
    public loginInfo : LoginInformation = {
        email: "",
        password: "",
        remember: false
    };

    constructor(
        @Inject(TIERConfig) private config : TIERConfig,
        @Inject(TIERAuth) private auth: TIERAuth,
        @Inject(NgZone) private ngZone : NgZone,
        @Inject(TIERToast) private alert : TIERToast,
        @Inject(StateService) private state : StateService
    ) {
        window.authCallBack = (fragment : any) => {
            this.ngZone.run(() => {
              this.auth.obtainAccessToken({
                  provider: fragment.provider,
                  userName: fragment.external_user_name,
                  clientid: this.config.get('clientId'),
                  externalAccessToken: fragment.external_access_token
              }).subscribe({
                next: () => {
                    this.state.go('dashboard');
                  },
                  error: (error) => {
                    this.alert.error(http2Error(error));
                  }
              });
            });
          }
    }

    public login() : void  {
        this.auth.login(this.loginInfo).subscribe({
            next: () => {
                this.state.go('dashboard');
            },
            error: () => {
                this.alert.warning("Authentication failed.");
            }
        });
    }

    public authExternalProvider(provider : string) : void  {
      let redirectUri : string = location.protocol + '//' + location.host + '/authcomplete.html';
      let externalProviderUrl : string = this.config.get('apiUrl') + "api/teammembers/ExternalLogin?provider=" +
          provider +
          "&response_type=token&client_id=" + this.config.get('clientId') +
          "&redirect_uri=" + redirectUri;

        window.open(externalProviderUrl, "Authenticate Account", "location=0,status=0,width=600,height=750");
    }
  }

