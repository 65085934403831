import { HttpParams } from '@angular/common/http';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

import { http2Error, calcUUID, isNorU } from 'src/tier/tier.utils';
import { TIERAPICalls, TIERToast, TIERDialog } from '../../services';
import { EquipmentResourceModel } from 'src/tier/models';
import { EquipmentResourceCheckComponent } from './equipmentresourcecheck.component';

export abstract class EquipmentResource {
    abstract resource : EquipmentResourceModel;

    constructor(
        protected apicall : TIERAPICalls,
        protected alert : TIERToast,
        protected dialog : TIERDialog,
        protected modalService : NgbModal
    ) { }

    protected abstract cancel() : void;

    public generateReference() {
        if(!isNorU(this.resource?.Reference)) {
            this.
            dialog.
            showConfirmDialog("Generating Reference", "Are you sure you wish to create a new reference while there is currently a reference entered?").
            then(() => {
                this.resource.Reference = calcUUID();
            }).catch(() => {});
        } else {
            this.resource.Reference = calcUUID();
        }
    }

    public save() {
        this.apicall.save<EquipmentResourceModel>('api/equipment/resource/%s'.formUri(this.resource.Id), this.resource).subscribe({
            next: () => {
                this.cancel();
                if(isNorU(this.resource.Id)) {
                    this.alert.success("Equipment resource added.")
                } else {
                    this.alert.success("Equipment resource edited.")
                }
            },
            error: (error) => {
                this.alert.error(http2Error(error));
            }
        });
    }

    private _delete(id : number | undefined) : void {
        this.apicall.delete<EquipmentResourceModel>('api/equipment/resource/%s'.formUri(id)).subscribe({
            next: () => {
                this.cancel();
            },
            error: (error) => {
                this.alert.error(http2Error(error));
            }
        });
    }

    public delete() : void {
        if(this.resource.IsActive) {
            this.dialog.
            showConfirmDialog("Making resource inactive.", "Making this resource inactive will make all its children inactive, are you sure you wish to do this.").
                then(() => {
                    this._delete(this.resource.Id);
                }).catch(() => {});
        } else {
            this._delete(this.resource.Id);
        }
    }

    public openCheckModal() {
        const modalRef = this.modalService.open(EquipmentResourceCheckComponent);

        modalRef.componentInstance.resource = this.resource;

        modalRef.result.then((result : any) => {
                this.resource.CheckDueDate = result.checkDueDate;
                this.resource.CheckedById = result.teamMember.Id;
                this.resource.CheckedBy = result.teamMember;
                this.resource.SelectedChildren = result.children;
        }).catch(() => {});
    }
}
