import { Component, forwardRef } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from "@angular/forms";

import { EquipmentTemplateAttributeModel, EquipmentTemplateAttributeTypeModel } from '../../models';

@Component({
    selector: 'tier-equipmentattributes',
    templateUrl: './equipmentattributes.template.html',
    providers: [{
        provide: NG_VALUE_ACCESSOR,
        useExisting: forwardRef(() => EquipmentAttributesComponent),
        multi: true
    }]
})
export class EquipmentAttributesComponent implements ControlValueAccessor {
    public attribute : EquipmentTemplateAttributeModel = {};
    public attributes : EquipmentTemplateAttributeModel[] = [];

    onChange: (_: any) => void = (_: any) => {};
    onTouched: () => void = () => {};

    public add() {
        this.attributes.push(this.attribute);
        this.attribute = {};
        this.onChange(this.attributes);
    }

    public delete(index : number) {
        this.attributes.splice(index, 1);
        this.onChange(this.attributes);
    };

    writeValue(attributes : EquipmentTemplateAttributeModel[]): void {
        this.attributes = attributes ?? [];
    }

    public setModel(event : EquipmentTemplateAttributeTypeModel) {
        this.attribute.EquipmentTemplateAttributeType = event;
    }

    registerOnChange(fn: any): void {
        this.onChange = fn;
    }

    registerOnTouched(fn: any): void {
        this.onTouched = fn;
    }
}
