<div class="row mb-3" *ngFor="let attribute of attributes">
    <label class="col-sm-3 col-form-label fw-bold" [ngClass]="{ 'pt-0': attribute.EquipmentTemplateAttributeType?.Name === 'Boolean' }" [textContent]="attribute?.Label"></label>
    <div class="col-sm-9">
        <input class="form-control" type="text" *ngIf="attribute?.EquipmentTemplateAttributeType?.Name === 'Text'" [ngModel]="getIndexValue(attribute.Id!)" (ngModelChange)="propertyChange($event, attribute)">
        <input class="form-control" type="number" *ngIf="attribute?.EquipmentTemplateAttributeType?.Name === 'Number'" [ngModel]="getIndexValue(attribute.Id!)" (ngModelChange)="propertyChange($event, attribute)">
        <div class="form-check" *ngIf="attribute?.EquipmentTemplateAttributeType?.Name === 'Boolean'">
            <input class="form-check-input" type="checkbox" ConvertToTrueOrFalse [ngModel]="getIndexValue(attribute.Id!)" (ngModelChange)="propertyChange($event, attribute)">
        </div>
        <tiertimepicker *ngIf="attribute.EquipmentTemplateAttributeType?.Name == 'Date'" [ngModel]="getIndexValue(attribute.Id!)" (ngModelChange)="propertyChange($event, attribute)"></tiertimepicker>
    </div>
</div>
