<div>
    <tierfilter class="mb-3" [config]="filterConfig" [(data)]="values" (fetch)="get()"></tierfilter>
</div>

<div class="hstack mb-3">
    <div class="ms-auto">
        <button type="button" class="btn btn-info" (click)="new()">New Incident</button>
    </div>
</div>

<div [hidden]="!incidents.length">

    <div class="mb-4">
        <div class="mb-1 fw-bold">
            Colour Key:
        </div>
        <div class="hstack ms-4">
            <div class="me-2 hstack">
                <div class="text-bg-info rounded-2 p-2 me-2">
                </div>
                <div>
                    <span>Concluded incident</span>
                </div>
            </div>
        </div>
    </div>

    <div>
        <table class="table">
            <thead>
                <tr>
                    <th></th>
                    <th *ngFor="let header of headers" (click)="changeOrder(header)" [ngClass]="{'text-center': header.centered, 'cursor-link' : header.orderable }">
                        {{header.name}}
                        <i class="fa-solid" [ngClass]="
                        {
                            'fa-caret-down': values.column === header.name && values.direction === 'asc',
                            'fa-caret-up': values.column === header.name && values.direction === 'desc',
                            'cursor-link' : header.orderable === true
                        }">
                        </i>
                    </th>
                </tr>
            </thead>
            <tbody>
                <ng-container *ngFor="let incident of incidents">
                    <tr [ngClass]="{ 'table-info': incident.HasConclusion }">
                        <td class="text-center">
                            <div class="mb-1">
                                <button type="button" class="btn btn-primary btn-sm button-column" (click)="edit(incident.Id)">
                                    <i class="fa-solid fa-pencil-alt"></i>
                                </button>
                            </div>
                            <div class="mb-1">
                                <button type="button" class="btn btn-primary btn-sm button-column" (click)="openMessageModal(incident.Id)">
                                    <i class="fa-solid fa-comment"></i>
                                </button>
                            </div>
                            <div class="mb-1" [hidden]="!incident.HasConclusion">
                                <button type="button" class="btn btn-primary btn-sm button-column" (click)="print(incident.Id)">
                                    <i class="fa-solid fa-print"></i>
                                </button>
                            </div>
                            <div class="mb-1">
                                <button type="button" class="btn btn-info btn-sm button-column" [disabled]="!incident.ActualMessageCount" (click)="incident.ToggleMessage = !incident.ToggleMessage">
                                    <i class="fa-solid" [ngClass]="{'fa-arrow-down': !incident.ToggleMessage, 'fa-arrow-up': incident.ToggleMessage }"></i>
                                </button>
                            </div>
                        </td>
                        <td>
                            <tier-telltime [from]="incident.Start" [to]="incident.End"></tier-telltime>
                        </td>
                        <td>
                            <span *ngIf="incident.HasConclusion">Concluded </span>Incident <b>"{{incident.Name}}"</b> has the following details <b>{{incident.Details || 'N/A'}}</b>.
                            The incident is at Longitude <b>{{incident.ActivityLocation?.Lon}}</b> and Latitude <b>{{incident.ActivityLocation?.Lat}}</b>
                            <span *ngIf="!noTrackTimeOption"> and has <b>{{incident.IncidentAttendeesCount}}</b> team members currently responding. </span>
                            <span *ngIf="noTrackTimeOption"> and <b>{{incident.TotalIncidentAttendeesCount}}</b> team members have responded. </span>
                            This incident has <b>{{incident.ActualMessageCount}}</b> messages.
                            <span *ngIf="incident.HasConclusion">The conclusion information is <b>"{{incident.Conclusion}}"</b></span>
                        </td>
                        <td>{{incident.ExternalReference || "N/A"}}</td>
                        <td class="text-center">
                            <i class="fa-solid" [ngClass]="{ 'fa-check': incident.IsActive , 'fa-times': !incident.IsActive  }"></i>
                        </td>
                    </tr>
                    <tr [hidden]="!incident.ToggleMessage">
                        <td colspan="5">
                            <table class="w-100">
                                <tr *ngFor="let message of incident.DefaultTwoMessages">
                                    <td>
                                        <div class="card mb-2">
                                            <div class="card-header">
                                                <div class="hstack">
                                                    <div class="hstack">
                                                        <div class="me-1">
                                                            <i class="fa-solid fa-star star" [ngClass]="{ 'goldstar': message.Favourite }"></i>
                                                        </div>
                                                        <div><b>Posted On:</b> {{message.TimeStamp}}</div>
                                                    </div>
                                                    <div class="ms-auto"><b>Posted By:</b> {{message.TeamMemberFullName}}</div>
                                                </div>
                                            </div>
                                            <div class="card-body">{{message.Message}}</div>
                                            <div class="card-footer" *ngIf="message.HasSentSMS">
                                                <i class="fa-solid fa-check me-2 text-success"></i>SMS has been sent.
                                            </div>
                                        </div>
                                    </td>
                                </tr>
                            </table>
                        </td>
                    </tr>
                </ng-container>
            </tbody>
        </table>
    </div>

    <div class="d-flex justify-content-center my-3">
        <ngb-pagination [collectionSize]="total" [(page)]="page" [pageSize]="values.amount" [maxSize]="15" [rotate]="true" [ellipses]="false" [boundaryLinks]="true" (pageChange)="get()" />
    </div>

</div>

<div class="alert alert-info" role="alert" [hidden]="incidents.length">
    <i class="fa-solid fa-exclamation-circle"></i> 0 results found.
</div>
