'use strict'

import { Component, OnInit } from '@angular/core';
import { ChartData, ChartConfiguration } from 'chart.js';
import { WidgetBase } from '../../Widget.base';
import { http2Error } from 'src/tier/tier.utils';

@Component({
    selector: 'tier-widget-all-timespenttrainingwidget',
    template: `
    <tierspinner [name]="getUUID()"></tierspinner>
    <div class="chart-container">
        <canvas baseChart class="chart" [type]="doughnutChartType" [data]="data" [options]="chartOptions"></canvas>
    </div>`,
    styleUrls: [ '../../_widgets.scss' ]
})
export class TimeSpentTrainingWidgetComponent extends WidgetBase implements OnInit {
  static readonly widgetName = 'TimeSpentTrainingWidgetComponent';
    public data : ChartData<'doughnut'> | undefined = undefined;

    public chartOptions : ChartConfiguration['options']= {
      responsive: true,
      maintainAspectRatio: false,
    };

    ngOnInit(): void {
        this.callAPI<object | null>('api/dashboard/timespenttraining', this.getUUID()).subscribe({
            next: (response : object | null) => {
              this.data = {
                labels: ["Attended", "Faciliated"],
                datasets: response as any
              }
              if(this.isEmpty(this.data))
                this.switchToNoDataWidget();

              if(this.options.get('org-widget-show-organizational') === "true" && this.data.labels)
                this.data.labels.push("Not Attended");
            },
            error: (error) => {
                this.alert.error(http2Error(error));
            }
        });
    }
}
