import { Component, Input, forwardRef, OnChanges, SimpleChanges, EventEmitter, Output } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR, NgModel } from "@angular/forms";

import { isNorU } from 'src/tier/tier.utils';

@Component({
    selector: 'tiertimepicker',
    template: `
                <div class="input-group">
                    <span class="input-group-text">
                        <i class="fa fa-calendar"></i>
                    </span>
                    <input type="text" class="form-control" [ngClass]="{ 'is-invalid': model?.invalid, 'is-valid': model?.valid && !model?.pristine }" mwlFlatpickr [noCalendar]="timeOnly" [(ngModel)]="entry" [enableTime]="timeOnly || withTime" [dateFormat]="format" [clickOpens]="!readonly" [time24hr]="true" [altFormat]="altFormat" [altInput]="altInput" (flatpickrChange)="updateChanges($event)" />
                </div>`,
    providers: [{
        provide: NG_VALUE_ACCESSOR,
        useExisting: forwardRef(() => TIERTimepickerComponent),
        multi: true
    }]
})
export class TIERTimepickerComponent implements ControlValueAccessor, OnChanges {
    @Input() timeOnly : boolean = false;
    @Input() withTime : boolean = false;
    @Input() format : string = "d-m-Y";
    @Input() altFormat : any = null;
    @Input() altInput : boolean = false;
    @Input() readonly : boolean = false;
    @Input() model : NgModel | null = null;

    @Output() timechanged : EventEmitter<string> = new EventEmitter();

    public entry : string | null = null;

    onChange: (_: any) => void = (_: any) => {};
    onTouched: () => void = () => {};

    ngOnChanges(changes: SimpleChanges): void {
        if(!isNorU(changes['model']?.currentValue))
            this.model === changes['model'].currentValue;
    }

    updateChanges(event : any) : void {
        let change = isNorU(event.dateString) ? null : event.dateString;

        this.onChange(change);
        this.timechanged.emit(change);
    }

    writeValue(selected : string): void {
        this.entry = selected;
    }

    registerOnChange(fn: any): void {
        this.onChange = fn;
    }

    registerOnTouched(fn: any): void {
        this.onTouched = fn;
    }
}
