<div class="row justify-content-end">
    <div class="col-sm-4 col-lg-2">
        <input class="form-control"
                type="text"
                placeholder="Search..."
                [(ngModel)]="searchText">
    </div>
</div>
<br />
<div class="table-responsive">
    <table class="table noktable">
        <thead>
            <tr>
                <th>Team Member</th>
                <th>Contact Name</th>
                <th>Relationship</th>
                <th>Phone No</th>
                <th>Mobile No</th>
            </tr>
        </thead>
        <tbody>
            <tr *ngFor="let contact of contacts | search: searchText">
                <td>{{contact.TeamMemberName || 'N/A'}}</td>
                <td *ngIf="contact.NOKEmailAddress"><a href="mailto:{{contact.NOKEmailAddress}}">{{contact.NOKName}}</a></td>
                <td *ngIf="!contact.NOKEmailAddress">{{contact.NOKName || 'N/A'}}</td>
                <td>{{contact.NOKRelationship || 'N/A'}}</td>
                <td *ngIf="contact.NOKPhoneNumber"><a href="tel:{{contact.NOKPhoneNumber}}">{{contact.NOKPhoneNumber}}</a></td>
                <td *ngIf="!contact.NOKPhoneNumber">N/A</td>
                <td *ngIf="contact.NOKMobileNumber"><a href="tel:{{contact.NOKMobileNumber}}">{{contact.NOKMobileNumber}}</a></td>
                <td *ngIf="!contact.NOKMobileNumber">N/A</td>
            </tr>
        </tbody>
    </table>
</div>
