import { Component, Inject } from '@angular/core';

import { EquipmentReportPagenationModel, EquipmentReportModel } from '../../models';
import { Reports } from '../../reports';
import { TIERAPICalls } from '../../../services/';
import { http2Error, isNorU } from 'src/tier/tier.utils';

@Component({
    selector: 'tier-reports-equipmentreport',
    templateUrl: './equipmentreport.template.html'
})
export class EquipmentReportComponent extends Reports {
    public rawReport : EquipmentReportModel[] = [];
    public report : EquipmentReportModel[] = [];
    public total : number = 0;
    public search : string | undefined = undefined;

    constructor(
        @Inject(TIERAPICalls) private apicall : TIERAPICalls
    ) { super(); };

    public get() {
        this.apicall.post<EquipmentReportPagenationModel>('api/reports/EquipmentReport',
            this.state2Options()
        ).subscribe({
            next: (response : EquipmentReportPagenationModel) => {
                this.rawReport = response.ObjectList;
                this.refresh(response.ObjectList);
                this.total = response.Total;
            },
            error: (error) => {
                this.alert.error(http2Error(error));
            }
        });
    }

    public setState(state : boolean) {
        this.report.forEach((equipment) => {
            if(!equipment.ParentId)
                this.collapse([equipment.Id]);
        })
    };

    public refresh(equipment : EquipmentReportModel[]) {
        this.report = this.flatten(equipment);
    }

    public totalCost() : number {
        let total = 0;

        this.report.forEach((equipment) => {
            if(!equipment.Level)
                total += equipment.Total;
        })

        return total;
    }

    private addFlatPack(pancake : EquipmentReportModel[], equipment : EquipmentReportModel[], level : number) {
        if(level === null)
            return;

        equipment.forEach((value) => {
            value.Level = level;
            pancake.push(value);

            if(!isNorU(value.Children))
                this.addFlatPack(pancake, value.Children, level+1);
        })
    };

    private flatten(data : EquipmentReportModel[]) : EquipmentReportModel[] {
        let pancake : EquipmentReportModel[] = [];

        this.addFlatPack(pancake, data, 0);
        return pancake;
    };

    public collapse(ids : number[]) {
        let root = this.report.find((r) => r.Id === ids[0]);

        if(!root?.IsContainer)
            return;

        root.IsClosed = !root.IsClosed;

        this.collapseTree(ids).forEach((value) => {
            value.IsCollapsed = root.IsClosed;

            if(value.IsContainer)
                value.IsClosed = root.IsClosed;
        })
    };

    private collapseTree(ids : number[]) : EquipmentReportModel[] {
        let data : EquipmentReportModel[] = [];

        ids.forEach((value) => {
            let filtered = this.report.filter((r) => r.ParentId === value);
            data = data.concat(filtered);

            if(filtered.length)
                data = data.concat(this.collapseTree(filtered.map(x => x.Id)));
        })

        return data;
    };
}
