<div class="d-print-none mb-3 d-flex justify-content-end">
    <TIERExtendButtons class="me-3" (extend)="extendAll()" (collapse)="collapseAll()"></TIERExtendButtons>
    <div>
        <div class="form-group">
            <input type="text" class="form-control"
                placeholder="Search..."
                [(ngModel)]="search"
                minlength="2"
                maxlength="20">
        </div>
    </div>
</div>
<div *ngIf="report.length">
    <div *ngFor="let r of report">
        <tier-reportpane title="{{r.Name}}" [isHidden]="r.Collapse" *ngIf="r.Skills">
        <table class="table">
            <thead TIERCustomTableHeader [format]="[
                                    { id: 'Name', name: 'Module' },
                                    { id: 'IsCritical', name: 'Critical' },
                                    { id: 'Obtained', name: 'Assess Date' },
                                    { id: 'Expires', name: 'Expiry Date' },
                                    { id: 'AssessmentBased', name: 'Type' },
                                    { id: 'IsPassed', name: 'Passed' },
                                    { id: 'IsValid', name: 'Valid' },
                                    { id: 'Comments', name: 'Comments' } ]"
                   [(table)]="r.Skills">
            </thead>
            <tbody>
                @for (sk of r.Skills | search: search; track sk) {
                    <tr [ngClass]="{ 'table-danger': sk.IsCurrentValid === false, 'table-success': sk.IsCurrentValid === true }">
                        <td>{{sk.Name}}</td>
                        <td *ngIf="sk.IsCritical === true"><i class="fa-solid fa-check"></i></td>
                        <td *ngIf="sk.IsCritical === false"><i class="fa-solid fa-times"></i></td>
                        <td>{{(sk.Current.AssessDate | transformdate: 'dd-MM-yyyy') || 'N/A'}}</td>
                        <td>{{(sk.Current.ExpiryDate | transformdate: 'dd-MM-yyyy') || 'N/A'}}</td>
                        <td *ngIf="sk.AssessmentBased === true"><span class="badge text-bg-primary">Assessment</span></td>
                        <td *ngIf="sk.AssessmentBased === false"><span class="badge text-bg-primary">Attendence</span></td>
                        <td *ngIf="sk.Current.Status === true && sk.AssessmentBased === true"><i class="fa-solid fa-check"></i></td>
                        <td *ngIf="(sk.Current.Status === false || sk.Current.Status === null) && sk.AssessmentBased === true"><i class="fa-solid fa-times"></i></td>
                        <td *ngIf="sk.AssessmentBased === false">N/A</td>
                        <td *ngIf="sk.IsCurrentValid === true"><i class="fa-solid fa-check"></i></td>
                        <td *ngIf="sk.IsCurrentValid === false || sk.IsCurrentValid === null"><i class="fa-solid fa-times"></i></td>
                        <td>{{sk.Current.Comments || 'None'}}</td>
                    </tr>
                    <tr *ngFor="let pre of sk.Previous">
                        <td>
                            <span class="fa-rotate-90" style="display: inline-block;">
                                <i class="fa-solid fa-level-down-alt fa-flip-vertical"></i>
                            </span>
                        </td>
                        <td></td>
                        <td>{{pre.AssessDate | transformdate: 'dd-MM-yyyy'}}</td>
                        <td>{{pre.ExpiryDate | transformdate: 'dd-MM-yyyy'}}</td>
                        <td></td>
                        <td *ngIf="pre.Status === true && sk.AssessmentBased === true"><i class="fa-solid fa-check"></i></td>
                        <td *ngIf="(pre.Status === false || pre.Status === null) && sk.AssessmentBased === true"><i class="fa-solid fa-times"></i></td>
                        <td *ngIf="sk.AssessmentBased === false">N/A</td>
                        <td></td>
                        <td>{{pre.Comments || 'None'}}</td>
                    </tr>
                }
            </tbody>
        </table>
    </tier-reportpane>
    </div>
    <div class="d-flex justify-content-center my-3 d-print-none" *ngIf="params.Amount">
        <ngb-pagination [collectionSize]="total" [(page)]="params.Page" [pageSize]="params.Amount" [maxSize]="5" [rotate]="true" [ellipses]="false" [boundaryLinks]="true" (pageChange)="reload()" />
    </div>
</div>
<div class="alert alert-warning" role="alert" [hidden]="report.length">
    <i class="fa-solid fa-exclamation-circle"></i> 0 results found.
</div>

<TIERReportButtons (print)="print()" (export)="export('ModuleTimelineReport')"></TIERReportButtons>
