import { Component, Input, forwardRef, Output, EventEmitter } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from "@angular/forms";
import { sub, add, parse, format } from "date-fns";
import type { Duration } from "date-fns";

import { isNorU } from 'src/tier/tier.utils';

@Component({
    selector: 'tierfilterdate',
    template: `
        <div class="row">
            <label for="search" class="col-sm-3 col-form-label fw-bold" [textContent]="label"></label>
            <div class="col-sm-9">
                <div class="hstack mb-2">
                    <tierdropdown
                        class="me-2"
                        [source]="equalityDropdown"
                        placeholder="Choose Equality"
                        [(ngModel)]="equality"
                        (ngModelChange)="equalityChange.emit($event)"
                        bindId="Id"
                        bindValue="Name"
                        [clearable]="false">
                    </tierdropdown>
                    <tiertimepicker class="w-100" [(ngModel)]="filterDate" (ngModelChange)="updateChanges($event)"></tiertimepicker>
                </div>
                <div class="row">
                    <div class="hstack">
                        <div class="text-nowrap">
                            <button class="btn btn-secondary me-1 btn-sm" type="button" (click)="modDate('<','D')">< D</button>
                            <button class="btn btn-secondary me-1 btn-sm" type="button" (click)="modDate('<','M')">< M</button>
                            <button class="btn btn-secondary me-1 btn-sm" type="button" (click)="modDate('<','Y')">< Y</button>
                        </div>
                        <div class="ms-auto text-nowrap">
                            <button class="btn btn-secondary me-1 btn-sm" type="button" (click)="modDate('>','Y')">Y ></button>
                            <button class="btn btn-secondary me-1 btn-sm" type="button" (click)="modDate('>','M')">M ></button>
                            <button class="btn btn-secondary me-1 btn-sm" type="button" (click)="modDate('>','D')">D ></button>
                        </div>
                    </div>
                </div>
            </div>
        </div>`,
    providers: [{
        provide: NG_VALUE_ACCESSOR,
        useExisting: forwardRef(() => TIERFilterDateComponent),
        multi: true
    }]
})
export class TIERFilterDateComponent implements ControlValueAccessor{
    @Input() label : string | null = null;
    @Input() equality : string = 'lessthan'
    @Output() equalityChange : EventEmitter<string> = new EventEmitter<string>();

    public filterDate : string | null = null;

    public equalityDropdown = [
        { Id: 'lessthan', Name: '<', prettyName: 'below' },
        { Id: 'equal', Name: '=', prettyName: 'equal' },
        { Id: 'morethan', Name: '>', prettyName: 'forward of' }
    ];

    onChange: (_: any) => void = (_: any) => {};
    onTouched: () => void = () => {};

    updateChanges(date : string) : void {
        this.onChange(date);
    }

    writeValue(date : string): void {
        if(isNorU(date)) {
            this.filterDate = null;
            return;
        }

        this.filterDate = date;
    }

    registerOnChange(fn: any): void {
        this.onChange = fn;
    }

    registerOnTouched(fn: any): void {
        this.onTouched = fn;
    }

    public modDate(direction : string, type : string) {
        let diFn : Function = sub;

        if(direction === '>')
            diFn = add;

        let d = null;
        try {
            d = parse(this.filterDate ?? '', 'dd-MM-yyyy', new Date());
        } catch(error) {
            console.log("Unable to parse timepicker date");
            return;
        }

        let duration : Duration = {};
        switch(type) {
            case 'D':
                duration.days = 1;
                break;
            case 'M':
                duration.months = 1;
                break;
            case 'Y':
                duration.years = 1;
                break;
            default:
                console.log("Type unknown");
        }

        this.filterDate = format(diFn(d, duration), 'dd-MM-yyyy').toString();
        this.updateChanges(this.filterDate);
    }
}
