<div class="d-print-none mb-3 d-flex justify-content-end">
    <TIERExtendButtons class="me-3" (extend)="extendAll()" (collapse)="collapseAll()"></TIERExtendButtons>
    <div>
        <div class="form-group">
            <input type="text" class="form-control"
                placeholder="Search..."
                [(ngModel)]="search"
                minlength="2"
                maxlength="20">
        </div>
    </div>
</div>
<div *ngIf="report.length > 0">
    <div *ngFor="let at of report | search: search">
        <tier-reportpane [title]="at.EquipmentName" [isHidden]="at.IsCollapsed">
        <table class="table caption-top" *ngIf="at.IncidentActivities.length > 0">
            <caption>Incidents</caption>
            <thead TIERCustomTableHeader [format]="[
                        { id: 'TeammemberName', name: 'Teammember' }, { id: 'Seconds', name: 'Seconds' }]"
                [(table)]="at.IncidentActivities">
            </thead>
            <tbody>
                <tr *ngFor="let ac of at.IncidentActivities">
                    <td>{{ac.TeammemberName}}</td>
                    <td>{{ac.SecondsTextual}}</td>
                </tr>
            </tbody>
            <tfoot>
            <tr>
                <td class="text-end fw-bold">Total: </td>
                <td>{{at.IncidentTotalTextual}}</td>
            </tr>
            </tfoot>
        </table>
        <table class="table caption-top" *ngIf="at.SessionActivities.length > 0">
            <caption>Sessions</caption>
            <thead TIERCustomTableHeader [format]="[
                        { id: 'TeammemberName', name: 'Teammember' }, { id: 'Seconds', name: 'Seconds' }]"
                [(table)]="at.SessionActivities">
            </thead>
            <tbody>
                <tr *ngFor="let ac of at.SessionActivities">
                    <td>{{ac.TeammemberName}}</td>
                    <td>{{ac.SecondsTextual}}</td>
                </tr>
            </tbody>
            <tfoot>
            <tr>
                <td class="text-end fw-bold">Total: </td>
                <td>{{at.SessionTotalTextual}}</td>
            </tr>
            </tfoot>
        </table>
        <div class="text-end" *ngIf="at.SessionActivities.length > 0 && at.IncidentActivities.length > 0"><span class="fw-bold">Equipment Total: </span>{{at.GlobalTotalTextual}}</div>
        <div class="alert alert-warning mb-0" role="alert" *ngIf="at.SessionActivities.length <= 0 && at.IncidentActivities.length <= 0">
            <i class="fa-solid fa-exclamation-circle"></i> 0 results found.
        </div>
        </tier-reportpane>
    </div>
    <div class="d-flex justify-content-center my-3 d-print-none" *ngIf="params.Amount">
        <ngb-pagination [collectionSize]="total" [(page)]="params.Page" [pageSize]="params.Amount" [maxSize]="5" [rotate]="true" [ellipses]="false" [boundaryLinks]="true" (pageChange)="reload()" />
    </div>
</div>
<TIERReportButtons (print)="print()" (export)="export('EquipmentUsageReport')"></TIERReportButtons>
