import { Component, Input, forwardRef } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from "@angular/forms";

import { isNorU } from 'src/tier/tier.utils';

@Component({
    selector: 'tierfiltersearch',
    template: `
        <div class="row">
            <label for="search" class="col-sm-3 col-form-label fw-bold" [textContent]="label"></label>
            <div class="col-sm-9">
                <div class="input-group">
                    <input type="text" id="search" minlength="3" #search="ngModel" class="form-control" [(ngModel)]="searchText" (ngModelChange)="updateChanges($event)" placeholder="Search...">
                    <button class="btn btn-danger" type="button" (click)="trash()"><i class="fa-solid fa-trash"></i></button>
                </div>
                <p [hidden]="search.valid || search.pristine" class="form-text text-danger">Search text needs to be more than 3 characters.</p>
            </div>
        </div>`,
    providers: [{
        provide: NG_VALUE_ACCESSOR,
        useExisting: forwardRef(() => TIERFilterSearchComponent),
        multi: true
    }]
})
export class TIERFilterSearchComponent implements ControlValueAccessor {
    @Input() label : string | null = null;

    public searchText : string | undefined = undefined;

    onChange: (_: any) => void = (_: any) => {};
    onTouched: () => void = () => {};

    public trash() : void {
        this.updateChanges(this.searchText = '');
    }

    updateChanges(text : string) : void {
        this.onChange(text);
    }

    writeValue(text : any): void {
        if(isNorU(text)) {
            this.searchText = '';
            return;
        }

        this.searchText = text;
    }

    registerOnChange(fn: any): void {
        this.onChange = fn;
    }

    registerOnTouched(fn: any): void {
        this.onTouched = fn;
    }
}
