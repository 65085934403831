import { Component, Input } from '@angular/core';

@Component({
    selector: 'tiermenuchild',
    template: `
    <a *ngIf="entry.isRoot === true && entry.children === null" class="nav-link root-menu-entry" [uiSref]="entry.ident" uiSrefActive="active">
      <i *ngIf="entry.icon" class="fa-solid fa-lg {{entry.icon}} root-menu-icon "></i> <span class="root-menu-text">{{entry.name}}</span>
    </a>
    <a *ngIf="entry.isRoot === false && entry.children === null" class="dropdown-item child-menu-entry" [uiSref]="entry.ident" uiSrefActive="active">
      <i *ngIf="entry.icon" class="fa-solid fa-lg {{entry.icon}} child-menu-icon d-flex justify-content-center"></i> <span class="child-menu-text">{{entry.name}}</span>
    </a>
    <li *ngIf="entry.children !== null && entry.children.length > 0" class="nav-item" ngbDropdown display="dynamic">
        <a class="nav-link" ngbDropdownToggle role="button">
            <i class="fa-solid {{entry.icon}} fa-lg root-menu-icon"></i> <span class="root-menu-text">{{entry.name}}</span>
        </a>
        <div class="dropdown-menu" ngbDropdownMenu>
            <tiermenuchild *ngFor="let menu of entry.children" [entry]="menu"></tiermenuchild>
        </div>
    </li>`,
    styleUrls: [ "./TIERMenu.component.scss" ]
})
export class TIERMenuChildComponent {
  @Input() entry : any | null = null;

  constructor() {};
}
