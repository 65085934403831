'use strict'

import { Component, OnInit, Inject } from '@angular/core';
import { HttpParams } from '@angular/common/http';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { format } from "date-fns";

import { TIERAPICalls, TIERToast, TIERAuth, TIEROptions, TIERConfig, TIERDialog } from '../../services';
import { IncidentListingModel, IncidentEntryModel, IncidentAttendeeModel } from '../../models/';
import { http2Error, calcUUID } from 'src/tier/tier.utils';
import { ModuleSelectorComponent, EquipmentResourceTreeSelectorComponent } from '../';

@Component({
    selector: 'tier-responding',
    templateUrl: "./responding.template.html"
})
export class RespondingComponent implements OnInit {
    public incidents : IncidentEntryModel[] = [];
    public total : number = 0;
    public page : number = 1;
    public noTrackTimeOption : boolean = false;
    public googleLink : string = '';
    public nowString : string = format(new Date(), "dd-MM-yyyy HH:mm");

    public filterConfig = [
        {
            name: calcUUID(),
            formControl: "search",
            label: "Search Text",
            value: "searchText"
        }
    ];

    public values = {
        skip: 0,
        amount: 10,
        searchText: '',
        column: 'Date',
        direction: 'desc',
        showConcluded: false,
        showUnconcluded: true
    };

    constructor(
        @Inject(TIERAPICalls) private apicall : TIERAPICalls,
        @Inject(TIERToast) private alert : TIERToast,
        @Inject(TIEROptions) private options : TIEROptions,
        @Inject(TIERConfig) private config : TIERConfig,
        @Inject(TIERDialog) private dialog : TIERDialog,
        @Inject(NgbModal) private modalService : NgbModal,
        @Inject(TIERAuth) private auth : TIERAuth
    ) {
        this.noTrackTimeOption = this.options.get('org-incident-no-track-time') === "true";
        this.googleLink = this.config.get('googleMapLink');
    }

    ngOnInit(): void {
        this.get();
    }

    public get() : void {
        this.apicall.get<IncidentListingModel>('api/incidents', new HttpParams({'fromObject': { ...this.values }})).subscribe({
            next: (response : IncidentListingModel) => {
                this.incidents = response.ObjectList;
                this.total = response.Total;
            },
            error: (error) => {
                this.alert.error(http2Error(error));
            }
        });
    }

    private openSkillModal(incident : IncidentAttendeeModel) : NgbModalRef {
        const modalRef = this.modalService.open(ModuleSelectorComponent);
        modalRef.componentInstance.attendee = incident;
        return modalRef;
    }

    private openEquipmentModal(incident : IncidentAttendeeModel | null) : NgbModalRef | null {
        let userId = this.auth.getTokenInfo()?.userId

        if(!userId) {
            this.alert.error("Unable to get token information");
            return null;
        }

        const modalRef = this.modalService.open(EquipmentResourceTreeSelectorComponent, { size: 'xl', scrollable: true });

        modalRef.componentInstance.equipment = incident?.EquipmentResources ?? [];
        modalRef.componentInstance.enableButtons = false;
        modalRef.componentInstance.showAllocated = false;
        modalRef.componentInstance.httpparams = { flat: true, 'teammemberId': userId };

        return modalRef;
    }

    public close(incident : IncidentEntryModel) {
        this.apicall.put<IncidentAttendeeModel>('api/incidents/%s/attendee/%s'.formUri(incident.Id, incident.TeamMemberIsCurrentlyAttending.Id),
            { ...incident.TeamMemberIsCurrentlyAttending, End: format(new Date(), 'dd-MM-yyyy HH:mm') }
        ).subscribe({
            next: () => {
                this.alert.success("You are no longer set as responding.")
                this.get();
            },
            error: (error) => {
                this.alert.error(http2Error(error));
            }
        });
    }

    public editSkills(incident : IncidentEntryModel) {
        this.openSkillModal(incident.TeamMemberIsCurrentlyAttending).result.then((skResult) => {
            this.apicall.put<IncidentAttendeeModel>('api/incidents/%s/attendee/%s'.formUri(incident.Id, incident.TeamMemberIsCurrentlyAttending.Id),
                { ...incident.TeamMemberIsCurrentlyAttending, SkillModules: skResult }
            ).subscribe({
                next: () => {
                    this.alert.success("You have successfully edited your skills for the incident response.")
                    this.get();
                },
                error: (error) => {
                    this.alert.error(http2Error(error));
                }
            });
        }).catch(() => {});
    }

    public editEquipment(incident : IncidentEntryModel) {
        this.openEquipmentModal(incident.TeamMemberIsCurrentlyAttending)?.result.then((eqResult) => {
            this.apicall.put<IncidentAttendeeModel>('api/incidents/%s/attendee/%s'.formUri(incident.Id, incident.TeamMemberIsCurrentlyAttending.Id),
                { ...incident.TeamMemberIsCurrentlyAttending, EquipmentResources: eqResult }
            ).subscribe({
                next: () => {
                    this.alert.success("You have successfully edited your equipment for the incident response.")
                    this.get();
                },
                error: (error) => {
                    this.alert.error(http2Error(error));
                }
            });
        }).catch(() => {});
    }

    private _respond(incident: IncidentEntryModel) : void {
        this.openSkillModal({ 'TeamMemberId': this.auth.getTokenInfo()?.userId }).result.then((skresult) => {
            this.openEquipmentModal(null)?.result.then((eqResult) => {
                this.apicall.post<IncidentAttendeeModel>('api/incidents/%s/attendee'.formUri(incident.Id),
                    {
                        Start: format(new Date(), 'dd-MM-yyyy HH:mm'),
                        SkillModules: skresult,
                        EquipmentResources: eqResult
                    }
                ).subscribe({
                    next: () => {
                        this.alert.success("You are now set as responding.")
                        this.get();
                    },
                    error: (error) => {
                        this.alert.error(http2Error(error));
                    }
                });
            }).catch(() => {});
        }).catch(() => {});
    }

    public respond(incident : IncidentEntryModel) : void {
        if (incident.TeamMemberHasPreviouslyAttended) {
            this.dialog.showConfirmDialog("Confirm Attending", "You have previously responded to this incident. Are you sure you wish to respond to this incident again?").then(
            () => {
                this._respond(incident);
            }).catch(() => {});
        } else {
            this._respond(incident);
        }
    }
}
