import { Component, OnInit, Inject } from '@angular/core';
import { StateService, Transition } from '@uirouter/angular';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

import { TIERAPICalls, TIERToast, TIERDialog } from '../../services';
import { EquipmentResourceModel } from '../../models';
import { http2Error } from 'src/tier/tier.utils';
import { EquipmentResource } from './equipmentresource';

@Component({
    selector: 'tier-equipment-resourceedit',
    templateUrl: "./equipmentresource.template.html"
})
export class EquipmentResourceEditComponent extends EquipmentResource implements OnInit {
    private id : number = this.trans.params()?.id;

    public resource : EquipmentResourceModel = { 'EquipmentResourceParentId': this.trans.params()?.parentId, 'EquipmentTemplateId': this.trans.params()?.templateId, 'EquipmentResourceProperties': [], IsActive: true };

    constructor(
        @Inject(TIERAPICalls) protected apicall : TIERAPICalls,
        @Inject(TIERToast) protected alert : TIERToast,
        @Inject(StateService) protected state : StateService,
        @Inject(TIERDialog) protected dialog : TIERDialog,
        @Inject(Transition) protected trans : Transition,
        @Inject(NgbModal) protected modalService : NgbModal
    ) { super(apicall, alert, dialog, modalService); }

    ngOnInit(): void {
        if(!this.id)
            return;

        this.apicall.get<EquipmentResourceModel>("api/equipment/resource/%s".formUri(this.id)).subscribe({
            next: (response : EquipmentResourceModel) => {
                this.resource = response;
            },
            error: (error) => {
                this.alert.error(http2Error(error));
            }
        });
    }

    public cancel() {
        this.state.go('equipment@resources@view', { 'parentId': this.resource.EquipmentTemplate?.IsContainer === true ? this.resource.Id : this.resource.EquipmentResourceParentId });
    }
}
