<div class="hstack" *ngIf="!ro">
    <div class="ms-auto">
        <button type="button" class="btn btn-primary" (click)="fillCurrentWeather()" [disabled]="!location?.Lon || !location?.Lat">Fill With Current Weather</button>
    </div>
</div>
<form #weatherform="ngForm" name="weatherform" class="mt-3">
    <div class="text-center">
        <div class="row mb-3">
            <div class="col-sm-3">
                <i *ngIf="weather?.VisibilityIconCSS" class="wi" [ngClass]="weather?.VisibilityIconCSS"></i>
                <i *ngIf="!weather?.VisibilityIconCSS || !weather?.Categorical" class="wi wi-na"></i>
            </div>
            <div class="col-sm-6">
                <input placeholder="Visibility Text" name="visibility" class="form-control" type="text" [ngModel]="weather && weather.Visibility" (ngModelChange)="CreateObjIfNull('Visibility', $event)" [readOnly]="ro">
            </div>
        </div>
        <div class="row mb-3">
            <div class="col-sm-3">
                <i class="wi wi-thermometer"></i>
            </div>
            <div class="col-sm-6">
                <div>
                    <input placeholder="Temperature" class="form-control" name="temperature" type="number" [ngModel]="weather && weather.Temperature" (ngModelChange)="CreateObjIfNull('Temperature', $event)" [readOnly]="ro">
                </div>
            </div>
            <div class="col-sm-3">
                <i class="wi wi-celsius"></i>
            </div>
        </div>
        <div class="row mb-3">
            <div class="col-sm-3">
                <i class="wi wi-wind" [ngClass]="weather?.WindIconCSS"></i>
            </div>
            <div class="hstack col-sm-6">
                <div class="me-3 w-50">
                    <input placeholder="Degrees" class="form-control" type="number" name="winddegrees" [ngModel]="weather && weather.WindDegrees" max="360" min="0" (ngModelChange)="CreateObjIfNull('WindDegrees', $event)" [readOnly]="ro">
                </div>
                <div class="w-50">
                    <input placeholder="Mps" class="form-control" type="number" name="windmps" [ngModel]="weather && weather.WindMetersPerSecond" (ngModelChange)="CreateObjIfNull('WindMetersPerSecond', $event)" [readOnly]="ro">
                </div>
            </div>
            <div class="col-sm-3">
                <span class="suffix"> deg</span> / <span class="suffix"> mps</span>
            </div>
        </div>
        <div class="row mb-3">
            <div class="col-sm-3">
                <i class="wi wi-cloud"></i>
            </div>
            <div class="col-sm-6">
                <input placeholder="Cloud Coverage %" class="form-control" type="number" name="cloudcoverage" [ngModel]="weather && weather.CloudCoverage" max="100" min="0" (ngModelChange)="CreateObjIfNull('CloudCoverage', $event)" [readOnly]="ro">
            </div>
            <div class="col-sm-3">
                <span class="suffix">%</span>
            </div>
        </div>
        <div class="row mb-3">
            <div class="col-sm-3">
                <i class="wi wi-humidity"></i>
            </div>
            <div class="col-sm-6">
                <input placeholder="Humidity" class="form-control" type="number" name="humidity" [ngModel]="weather && weather.Humidity" max="100" min="0" (ngModelChange)="CreateObjIfNull('Humidity', $event)" [readOnly]="ro">
            </div>
            <div class="col-sm-3">
                <span class="suffix">%</span>
            </div>
        </div>
        <div class="row mb-3">
            <div class="col-sm-3">
                <i class="wi wi-sunrise"></i>
            </div>
            <div class="col-sm-6">
                <input placeholder="Ultra violet index" class="form-control" type="number" name="uvindex" [ngModel]="weather && weather.UltraVioletIndex" (ngModelChange)="CreateObjIfNull('UltraVioletIndex', $event)" [readOnly]="ro">
            </div>
            <div class="col-sm-3">
                <span class="suffix">UV Index</span>
            </div>
        </div>
        <div class="row mb-3">
            <div class="col-sm-3">
                <i class="wi wi-barometer"></i>
            </div>
            <div class="col-sm-6">
                <input placeholder="Pressure" class="form-control" type="number" name="pressure" [ngModel]="weather && weather.Pressure" (ngModelChange)="CreateObjIfNull('Pressure', $event)" [readOnly]="ro">
            </div>
            <div class="col-sm-3">
                <span class="suffix">hPa</span>
            </div>
        </div>
    </div>
</form>
