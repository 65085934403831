import { Component, EventEmitter, Inject, Input, Output } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

import { IncidentSubjectModel } from '../../models/';
import { IncidentSubjectsModalComponent } from '.';

@Component({
    selector: 'tier-incident-subjects',
    template: `
        <div class="hstack mb-2">
            <button type="button" class="btn btn-primary ms-auto" (click)="add()">
                <i class="fa-solid fa-plus"></i>
            </button>
        </div>

        <div class="alert alert-success mb-0" role="alert" *ngIf="subjects?.length! <= 0">
            <i class="fa-solid fa-exclamation-circle"></i> 0 incident subjects.
        </div>

        <table class="table" *ngIf="subjects?.length! > 0">
            <thead>
                <tr>
                    <th>Subject</th>
                    <th>Type</th>
                    <th>Injuries</th>
                    <th></th>
                </tr>
            </thead>
            <tbody>
                <tr *ngFor="let subject of subjects; index as i">
                    <td>{{subject.FirstName || 'Anonymous'}}</td>
                    <td>{{subject.IncidentSubjectType?.Name || 'N/A'}}</td>
                    <td>{{(subject.SubjectInjuries?.length! + subject.IncidentGlobalInjuries?.length!) || 0}}</td>
                    <td class="minwidth">
                        <div class="hstack">
                            <button type="button" class="btn btn-primary btn-sm me-1" (click)="add(i)">
                                <i class="fa-solid fa-edit"></i>
                            </button>
                            <button type="button" class="btn btn-danger btn-sm" (click)="delete(i)">
                                <i class="fa-solid fa-trash"></i>
                            </button>
                        </div>
                    </td>
                </tr>
            </tbody>
        </table>`
})
export class IncidentSubjectsComponent {
    @Input() subjects : IncidentSubjectModel[] | undefined | null = [];
    @Output() subjectsChange : EventEmitter<IncidentSubjectModel[]> = new EventEmitter<IncidentSubjectModel[]>();

    constructor(@Inject(NgbModal) private modalService : NgbModal) {}

    public add(index : number | null = null) {
        const modalRef = this.modalService.open(IncidentSubjectsModalComponent, { size: 'lg' });
        modalRef.componentInstance.subject = index !== null && this.subjects ? this.subjects[index] : {};

        modalRef.result.then((result : any) => {
            if(this.subjects && index !== null) {
                this.subjects[index] = result;
            } else {
                if(!this.subjects)
                    this.subjects = [];

                this.subjects.push(result);
            }
            this.subjectsChange.emit(this.subjects);
        }).catch(() => {});
    }

    public delete(index : number) {
        if(this.subjects) {
            this.subjects.splice(index, 1);
            this.subjectsChange.emit(this.subjects);
        }
    }
}
