'use strict'

import { Component, OnInit } from '@angular/core';
import { WidgetBase } from '../../Widget.base';
import { http2Error } from 'src/tier/tier.utils';

@Component({
    selector: 'tier-widget-trainingadmin-noncriticalexpiredmoduleswidget',
    template: `<tierspinner [name]="getUUID()"></tierspinner>
    <div>
        <table [hidden]="mainTable" class="table table-borderless table-hover table-condensed">
            <thead>
                <tr>
                    <th>Module</th>
                    <th>Count</th>
                </tr>
            </thead>
            <tbody>
                <tr *ngFor="let module of modules">
                    <td><a class="link-primary link-offset-2 link-underline-opacity-25 link-underline-opacity-100-hover" role="button" (click)="showChild(module.Id)">{{module.Name}}</a></td>
                    <td>{{module.Count}}</td>
                </tr>
            </tbody>
        </table>
        <div [hidden]="!show[module.Id]" *ngFor="let module of modules" (click)="showChild(0)">
            <div class="widgetchildheader">
                <span>{{module.Name}}</span>
            </div>
            <table class="table table-condensed">
                <thead>
                    <tr>
                        <th>Team Member</th>
                        <th>Date Expires</th>
                    </tr>
                </thead>
                <tbody>
                    <tr *ngFor="let teamMember of module.TeamMembers">
                        <td>{{teamMember.Name}}</td>
                        <td>{{teamMember.DateLimit | transformdate: 'dd-MM-yyyy'}}</td>
                    </tr>
                </tbody>
            </table>
        </div>
    </div>`,
    styleUrls: [ '../../_widgets.scss' ]
})
export class NonCriticalExpiredModulesWidgetComponent extends WidgetBase implements OnInit {
    static readonly widgetName = 'NonCriticalExpiredModulesWidgetComponent';
    public modules : any[] = [];
    public show : boolean[] = [];
    public mainTable : boolean = false;

    ngOnInit(): void {
        this.callAPI<any[]>('api/dashboard/noncriticalexpiredmodules', this.getUUID()).subscribe({
            next: (response : any[]) => {
              this.modules = response;

              if(this.modules.length <= 0)
                this.switchToNoDataWidget();
            },
            error: (error) => {
                this.alert.error(http2Error(error));
            }
        });
    }

    public showChild(id : number) : void {
        this.mainTable = !this.mainTable;

        if (id !== 0) {
            this.show[id] = true;
        } else {
            this.show = [];
        }
    }
}
