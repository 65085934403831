'use strict';

import { Injectable, Inject } from '@angular/core';
import { Observable, lastValueFrom, throwError } from 'rxjs';
import { map, shareReplay, switchMap, catchError } from 'rxjs/operators';
import { HttpErrorResponse } from '@angular/common/http';

import { TIERAPICalls } from './';
import { OptionModel } from '../models';
import { http2Error } from '../tier.utils';

@Injectable({
    providedIn: 'root'
})
export class TIEROptions
{
    private options : OptionModel | null = null;

    constructor(
        @Inject(TIERAPICalls) private apicall : TIERAPICalls
    ) { }

    private fetch() : Observable<OptionModel> {
        return this.apicall.get<OptionModel>('/api/options')
            .pipe<OptionModel>(shareReplay(1))
            .pipe(
                map((res) => this.options = res),
                catchError((error : HttpErrorResponse) => {
                    return throwError(() => {
                        let err = http2Error(error);
                        return !err ? "Fetching options has been rejected due to authentication" : err;
                    })
                })
            );
    }

    public resolve() : Promise<OptionModel> {
        if(this.options)
            return new Promise((resolver) => {
                resolver(this.options!);
            })

        return lastValueFrom(this.fetch());
    }

    public exists(key : keyof OptionModel) : boolean {
        if(this.options)
            if ((key in this.options))
                return true;

        return false;
    }

    public get(key : keyof OptionModel) : string | undefined {
        if(!this.exists(key))
            return undefined;

        return this.options![key];
    }

    public getAll() : OptionModel {
        return { ...this.options };
    }

    private save(options : OptionModel | null) : Observable<object> | null {
        if(!options)
            return null;

        return this.apicall.put('/api/options', options);
    }

    public saveAndReload(options : OptionModel | null = null) : Observable<object> | null {
        return this.save(options ? options : this.options)?.pipe(switchMap(() => this.fetch())) ?? null
    }
}
