import { Component, OnInit, Inject, Input } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

import { TIERAPICalls, TIERToast } from 'src/tier/services';
import { AssessmentModel } from 'src/tier/models/';
import { http2Error } from 'src/tier/tier.utils';

export interface editAssessment {
    delete: boolean,
    file: FormData | null,
    assessment: AssessmentModel,
    quiet: boolean
}

@Component({
    selector: 'tier-training-edit-assessments',
    template: `
        <div class="modal-header">
            <h3 class="modal-title" id="modal-title">Assess Module</h3>
        </div>
        <form #assessmentForm="ngForm" role="form" name="assessmentForm" novalidate autocomplete="none" (ngSubmit)="confirm()">
            <div class="modal-body" id="modal-body">
                <div [hidden]="attachmentOnly">
                    <div class="row mb-3">
                        <label for="date" class="col-sm-3 col-form-label fw-bold">Date</label>
                        <div class="col-sm-9">
                            <tiertimepicker #date="ngModel" [model]="date" [(ngModel)]="assessment.AssessDate" name="date" id="date" required></tiertimepicker>
                            <p [hidden]="date.valid" class="form-text text-danger">Valid date is required.</p>
                        </div>
                    </div>
                    <div class="row mb-3">
                        <label for="comments" class="col-sm-3 col-form-label fw-bold">Comments</label>
                        <div class="col-sm-9">
                            <textarea placeholder="Comments" class="form-control" rows="5" id="comments" name="comments" [(ngModel)]="assessment.Comments"></textarea>
                        </div>
                    </div>
                    <div class="row mb-3" *ngIf="assessment.Type">
                        <label for="status" class="col-sm-3 col-form-label fw-bold pt-0">Status</label>
                        <div class="col-sm-9">
                            <div class="form-check">
                                <input class="form-check-input" type="radio" name="status" [value]=true [(ngModel)]="assessment.Status">
                                <label class="form-check-label" for="status">
                                    Pass
                                </label>
                            </div>
                            <div class="form-check">
                                <input class="form-check-input" type="radio" name="status" [value]=false [(ngModel)]="assessment.Status">
                                <label class="form-check-label" for="status">
                                    Fail
                                </label>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="row mb-3">
                    <label for="attachment" class="col-sm-3 col-form-label fw-bold">Attachment</label>
                    <div class="col-sm-9">
                        <tieruploadbutton [allowedTypes]="['Images', 'Documents']" [attachment]="assessment.Attachment" (data)="handleUpload($event)" (del)="deleteAttachment()" [deleteAllowed]="!attachmentOnly"></tieruploadbutton>
                    </div>
                </div>
            </div>
            <div class="modal-footer">
                <button *ngIf="assessment?.Id && !attachmentOnly"
                        class="btn btn-danger"
                        type="button"
                        (click)="deleteAssessment()"
                        ngbAutofocus>
                    Delete Assessment
                </button>
                <button class="btn btn-secondary"
                        type="button"
                        (click)="cancel()"
                        ngbAutofocus>
                    Cancel
                </button>
                <button *ngIf="!attachmentOnly || (attachmentOnly && !assessment?.Attachment)"
                        class="btn btn-primary"
                        type="submit"
                        [disabled]="assessmentForm.invalid">
                    Confirm
                </button>
            </div>
        </form>`
})
export class EditAssessmentsComponent implements OnInit {

    @Input() assessment : AssessmentModel = {};
    @Input() attachmentOnly : boolean = true;

    private fileData : FormData = new FormData();

    constructor(@Inject(NgbActiveModal) public activeModal : NgbActiveModal,
                @Inject(TIERAPICalls) private apicall : TIERAPICalls,
                @Inject(TIERToast) private alert : TIERToast)
        {}

    ngOnInit(): void {
        if(!this.assessment?.Type)
            this.assessment.Status = true;
    }

    public confirm() {
        this.activeModal.close({ quiet: false, delete: false, file: this.fileData, assessment: this.assessment } as editAssessment);
    }

    public cancel() {
        this.activeModal.dismiss();
    }

    public handleUpload(data : FormData) {
        this.fileData = data;
    }

    public deleteAttachment() {
       this.apicall.delete('api/assessment/%s/deleteattachment'.formUri(this.assessment.Id)).subscribe({
            next: () => {
                this.activeModal.close({ quiet: true, delete: false, file: null, assessment: this.assessment } as editAssessment);
                this.alert.success("Attachment deleted.");
            },
            error: (error) => { this.alert.error(http2Error(error)); }
        });
    }

    public deleteAssessment() {
        this.activeModal.close({ quiet: false, delete: true, file: null, assessment: this.assessment });
    }
}
