import { Component,  Input, EventEmitter, Output, OnChanges, SimpleChanges } from '@angular/core';

import { isNorU } from 'src/tier/tier.utils';

@Component({
    selector: '[TIERCustomTableHeader]',
    template: `
        <tr>
            <th [ngClass]="{ 'cursor-link': true }" *ngFor="let head of format" (click)="sortPressed(head)">
                {{head.name}}
                <i class="fa-solid" [ngClass]="{ 'fa-caret-down': field === head.id && toggle === true, 'fa-caret-up': field === head.id && toggle === false }"></i>
            </th>
        </tr>`
})
export class TIERCustomTableHeaderComponent implements OnChanges {
    @Input() format : { id: string, name : string }[] = [];

    @Input() table : any[] = [];
    @Output() tableChange : EventEmitter<any[]> = new EventEmitter();

    public toggle : boolean = false;
    public field : string | null = null;

    constructor() {}

    ngOnChanges(changes: SimpleChanges): void {
        if(!isNorU(changes['table']?.currentValue) && !changes['table'].firstChange)
            this.table = changes['table'].currentValue;

        this.sortPressed(this.format[0]);
    }

    public sortPressed(entry : { id: string, name : string }) {
        if(this.field !== entry.id)
            this.toggle = false;

        this.field = entry.id;

        let table = this.sort(entry.id, this.table);
        this.tableChange.emit(table);
    };

    private getValue<T>(obj : T, id : string) : any {
        let parts = id.split('.');
        let ref : any = obj;

        for (let i = 0; i < parts.length; i++) {
            ref = ref[(parts[i] as keyof T)];
        }

        return ref;
    };

    private sort(id : string, model : any[]) {
        this.toggle = !this.toggle;

        model.sort((a, b) => {
            let va : any = this.getValue<any>(a, id);
            let vb : any = this.getValue<any>(b, id);

            if (typeof va !== typeof vb) {
                console.log("Comparison via same type only in sort");
                return;
            }

            switch (typeof va) {
                case 'string':
                    return this.toggle ?
                        va.toLowerCase().localeCompare(vb.toLowerCase()) :
                        vb.toLowerCase().localeCompare(va.toLowerCase());
                case 'number':
                case 'boolean':
                    return this.toggle ?
                        +va - vb :
                        vb - +va;
                case 'object':
                    return this.toggle ?
                        va > vb :
                        vb > va;
                default:
                    console.log("Unable to sort type: " + typeof va);
            }
            return 0;
        });

        return model;
    };
}
