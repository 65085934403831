<div>
    <h3>TIER Terms of Service</h3>
    <h4><b>1.</b> Your Relationship with TIER.</h4>
    <p><b>1.1</b> Your use TIER services and web sites (referred to collectively as the "Services" in this document) is subject to the terms of a legal agreement between you and TIER. "TIER" means Caradox Limited, whose Registered Office is The Welsh Mill, Park Hill Drive, Frome, United Kingdom, BA11 2LE and whose Company Registration Number is 12387641. This document explains how the agreement is made up, and sets out some of the terms of that agreement.</p>
    <p><b>1.2</b> Unless otherwise agreed in writing by TIER, your agreement with TIER will always include, at a minimum, the terms and conditions set out in this document. These are referred to below as the "Universal Terms". Your agreement with TIER will also include the terms of any Legal Notices applicable to the Services, such as TIER's Privacy Policy and Code of Conduct (which can be found at <a href="https://sartier.co.uk">https://sartier.co.uk</a>), as well as those terms which apply to a specific Service. All of these are referred to as the "Additional Terms". Where Additional Terms apply to a Service, these will be accessible for you to read either within, or through your use of, that Service. The Universal Terms, together with the Additional Terms, form a legally binding agreement between you and TIER in relation to your use of the Services. It is important that you take the time to read them carefully. Collectively, this legal agreement is referred to below as the "Terms".</p>
    <p><b>1.3</b> If there is any contradiction between what the Additional Terms say and what the Universal Terms say, then the Additional Terms shall take precedence in relation to that Service.</p>
    <h4><b>2.</b> Accepting the Terms.</h4>
    <p><b>2.1</b> In order to use the Services, you must first agree to the Terms. You may not use the Services if you do not accept the Terms.</p>
    <p><b>2.2</b> You can accept the Terms by:</p>
    <p><b>(a)</b> clicking to accept or agree to the Terms, where this option is made available to you by TIER in the user interface for any Service, or as part of the download of any software included in the Services; or</p>
    <p><b>(b)</b> by actually using the Services. In this case, you understand and agree that TIER will treat your use of the Services as acceptance of the Terms from that point onwards.</p>
    <h4><b>3.</b> Requirements for Registration or Use of Services.</h4>
    <p><b>3.1</b> The Services are intended and offered only for lawful use by individuals or organisations with the legal capacity and authority under applicable law to enter into a contract for such services. TIER DOES NOT OFFER SERVICES TO MINORS OR WHERE PROHIBITED BY LAW. By registering for and/or by using the Services, you represent and warrant that you have the legal capacity and authority to enter into a binding agreement to adhere to these Terms, and that you will use the Services only in accordance with these Terms and with all applicable laws. If an individual is registering or using the Services on behalf of an entity or organisation, that individual warrants, represents, and covenants to TIER that such individual is duly authorised to agree to these Terms on behalf of the organisation and to bind the organisation to them. If an individual uses Services that are provided by any third party including any person, entity or organisation, the individual using the Services, by virtue of such use, agrees that any information the individual provides to TIER (including but not limited to the individual's files and data) will be accessible to, and may be viewed, changed, or deleted by the person, entity or organisation that provided the Services to the individual.</p>
    <p><b>3.2</b> You agree to provide accurate and complete information when you register for the Services and you agree to keep such information accurate and complete during the entire time that you use the Services.</p>
    <p><b>3.3</b> TIER may ask you from time to time to establish a user name or password to access or use the Services. You are solely responsible for any consequences arising in whole or in part out of your failure to maintain the confidentiality of your username and/or password.</p>
    <h4><b>4.</b> Modifications to the Terms.</h4>
    <p><b>4.1</b> TIER may change the Terms at any time, without prior notice to you, and in its sole discretion. The new or modified Terms will be effective immediately after we publish them on our website at <a href="https://sartier.co.uk">https://sartier.co.uk</a>. Therefore, we encourage you to check the date of our terms and conditions whenever you visit this website to check if they have been updated. You must review this agreement on a regular basis to keep yourself apprised of any changes.</p>
    <p><b>4.2</b> If you do not agree to be bound by the Terms as published by TIER from time to time, your sole and exclusive remedy is to immediately stop all use of the Services and cancel the Services as provided in Section 4.3. Your continued use of the Services following the posting of modifications will constitute your acceptance of the revised terms and conditions.</p>
    <p><b>4.3</b> If you wish to cancel the Services after a change in the Terms, you must do so in writing or by email within thirty (30) days after your next use of the Service following the change in the Terms.</p>
    <h4><b>5.</b> Acceptable Use of the Services - Fair Use Policy</h4>
    <p><b>5.1</b> TIER SHALL HAVE THE ABSOLUTE AND UNILATERAL RIGHT IN ITS SOLE DISCRETION TO DENY USE OF AND ACCESS TO ALL OR ANY PORTION OF THE SERVICES TO USERS WHO ARE DEEMED BY TIER TO BE USING THE SERVICES IN A MANNER NOT REASONABLY INTENDED BY TIER OR IN VIOLATION OF LAW, INCLUDING BUT NOT LIMITED TO SUSPENDING OR TERMINATING A USER'S ACCOUNT WITH TIER.</p>
    <p><b>5.2</b> The Services are designed to serve the needs of particular types of users, such as small organisations. If you have registered for a Service that is inappropriate for your actual usage, TIER may require you to switch to an appropriate Service which may result in your having to pay TIER additional fees for use of the appropriate Service, or to terminate your Services and refund, on a prorated basis, fees you may have paid on the unused portion of your subscription.</p>
    <p><b>5.3</b> TIER may, in its sole discretion and from time to time, establish or amend general operating practices to maximize the operation and availability of Services and to prevent abuses. As part of these practices, TIER reserves the right to monitor its systems to identify excessive consumption of network resources and to take such technical and other remedies as we deem appropriate. Your consumption of Services may be deemed excessive if, within any month, your usage greatly exceeds the average level of monthly usage of TIER's customers, generally. In the event you are deemed to have violated this policy, we reserve the right to offer an alternative pricing plan or Service that will permit you to continue to use the Services. Although violations of this policy have been infrequent, we reserve the right to terminate or suspend your right to use the Services, without prior notice in the event of a violation of this policy.</p>
    <h4><b>6.</b> Software License.</h4>
    <p><b>6.1</b> Subject to the Terms, TIER, hereby grants you a limited, personal, non-sublicensable, non-transferable, nonexclusive license to use the software provided to you by TIER as part of the Services (the "Software") on a single computer or multiple mobile devices (as the case may be) that are operated by you. The Software may be used only to access Services, and must be used in accordance with any instructions provided to you by TIER. The term "Software" also includes any documentation (whether included in the download or accessible online), and any Updates provided by TIER pursuant to Section 8 below.</p>
    <p><b>6.2</b> Software is licensed, not sold to you, and is licensed only for your personal use. You may not sell, assign, rent, lease, distribute, export, act as an intermediary or provider, or otherwise grant rights to third parties with regard to all or any part of the Software. You may not modify, reverse-engineer, decompile, disassemble, or attempt to discover the source code, structure or sequence, or otherwise hack, the Software, and have no rights to create derivate works from the Software.</p>
    <p><b>6.3</b> You agree that the Software may be incorporated into, and may include, software and other technology owned and controlled by third parties. Any such third party software or technology that is incorporated into the Software falls under the scope of the Terms. Any other third party software or technology that may be distributed together with the Software will be subject to you accepting a license agreement with that third party. You should consult the license agreement accompanying the applicable software application or contact the licensor of that product to determine whether use of the product in this fashion is permitted without an additional license.</p>
    <p><b>7. Your Conduct.</b> As a condition of your use of the Services, you agree that you will comply with TIER's Code of Conduct, and will not use the Services for any unlawful purpose. Without limiting the foregoing, you agree that you will not use the Services: (i) to infringe the intellectual property rights or proprietary rights, or rights of publicity or privacy, of any third party; (ii) to violate any applicable law, statute, ordinance or regulation; (iii) to disseminate any content in any form or format that is harmful, threatening, abusive, harassing, tortuous, defamatory, vulgar, obscene, libellous, or otherwise objectionable under applicable laws or community standards; or (iv) to disseminate any software viruses or any other computer code, files or programs that may interrupt, destroy or limit the functionality of any computer software or hardware or telecommunications equipment. You are solely responsible for all Content (as defined below) that you upload, post, email, transmit, or otherwise disseminate using, or in connection with, the Services, and You will be solely responsible for any damage to any party resulting there from.</p>
    <p><b>8. Modifications to the Services; Support and Updates.</b> TIER reserves the right to modify, add, or remove features or functions to or from the Services, or to provide programming fixes, enhancements, updates and upgrades, to the Software (collectively "Updates"). The Software which you use may automatically download and install Updates from time to time from TIER. You agree that TIER has no obligation to provide you with Updates, or make available to you any subsequent versions of the Software. You also agree that you may have to enter into a renewed version of the Terms if you want to download, install or use an Update. Your license to an existing version of the Software may, at TIER's discretion, expire when new versions of Software are released. You acknowledge and agree that TIER, in its sole discretion, may modify, discontinue or suspend your ability to use any version of the Software or any Service, and/or disable any Software you may already have accessed or installed without any notice to you, for the repair, improvement, and/or upgrade of the underlying technology, or for any other reason.</p>
    <h4><b>9.</b> Administrator and Team Members</h4>
    <p><b>9.1</b> Certain Services operate under an "Administrator and Team Members" hierarchy. Administrators are those team members who are authorised to perform administrative functions, and to manage the accounts of other end users within the team. In the creation of a new TIER end user account, the new subscriber is the default Administrator. All Administrators are responsible for billing issues related to their Team members, and Administrators can at their sole discretion terminate a team member's account.</p>
    <p><b>9.2</b> You acknowledge and agree that where TIER enters into these Terms with an entity or organisation, or where an entity or organisation makes the Services available to you, the contracting entity or organisation giving you access may grant multiple individuals rights related to the management and use of the Services and your Content without any notice to you. These rights may enable one or more persons to: view, access or change your Content; determine who can access the account, Content and account Information, including your personally identifiable information; determine who is responsible financially for the account; and other similar actions.</p>
    <h4><b>10.</b> Cancellation Of Services.</h4>
    <p><b>10.1</b> Without limiting other remedies, TIER may limit, suspend, or terminate its legal agreement with you, terminate your use of Software, prohibit access to TIER Website and delete your user account, effective immediately, if (i) TIER believes that you are in breach of the Terms, creating problems, possible legal liabilities, infringing someone else's intellectual property rights, engaging in fraudulent, immoral or illegal activities, or for other similar reasons, (ii) if TIER is required to do so by law (for example, where the provision of the Services to you is, or becomes, unlawful), (iii) if TIER is transitioning to no longer providing the Services to users in the country in which you are resident or from which you use the Service, or (iv) the provision of Services to you is no longer, in TIER's opinion, no longer commercially viable. TIER shall effect such termination by providing notice to you to the email address you have provided, and/or by preventing your access to your TIER user account.</p>
    <p><b>10.2</b> You may cancel your user account upon notice to TIER at any time. To cancel your service, send an email to <a href="mailto:support@sartier.co.uk">support&#64;sartier.co.uk</a>.</p>
    <p><b>10.3</b> Upon cancellation by TIER or at your direction, you may request a file of your data. You must make such request at the notification of cancellation to receive such file within thirty (30) days of termination. Otherwise, ANY DATA YOU HAVE STORED ON TIER'S SYSTEMS MAY NOT BE RETRIEVED, and TIER shall have no obligation to maintain any data stored in your account or to forward any data to you or any third party. You agree that TIER may retain (but shall have no obligation to retain) your Content for a period after your account has been terminated, expired, or otherwise lapsed, as part of TIER's marketing to you of the opportunity to purchase, renew, or extend your account.</p>
    <p>If you cancel a team account, all team member accounts will be terminated as well. Upon cancelling your account, you will receive a FINAL termination notice stating that your data will be deleted from our system.</p>
    <p>If you have a question while cancelling your account, you must give our email staff three (3) working days to respond to your message. Cancellation requests should be sent via email to <a href="mailto:support@sartier.co.uk">support&#64;sartier.co.uk</a>.</p>
    <p><b>10.4</b> Except as expressly provided in Section 10.5, when users cancel their accounts, they will not be entitled to any refunds of fees paid.</p>
    <p><b>10.5</b> An annual subscriber who wishes to cancel and request a refund may do so within thirty (30) days of the date on which the user created the account. Provided that the cancellation is received by TIER within the stated period, the user will be entitled to a refund equal to eighty percent (80%) of the annual fee paid. An annual subscriber who cancels as provided in Section 4.3 and requests a refund, will be entitled to a pro-rata refund for the unused portion of any prepaid fees, as of the date TIER receives the notice, provided that the change in Terms materially adversely effects the rights or obligations of the user, or the functions or features of the Services,</p>
    <p><b>10.6</b> Upon termination of your legal agreement with TIER and the cancellation of your account (a) you acknowledge and agree that all licenses and rights to use the Services and Software shall terminate, and (b) you will cease any and all use of the Software, and (c) you will remove the Software from all hard drives, networks and other storage media and destroy all copies of the Software in your possession or under your control. Following termination TIER reserves the right to retain data required for account settlement or billing purposes. The provisions of the following sections shall survive such termination, expiration, or cancellation, and you agree to remain bound by those terms.</p>
    <p><b>10.7</b> TIER will not be liable for any damages caused by the termination of your agreement with TIER, for any reason.</p>
    <h4><b>11.</b> Payment of Subscription Fees; Automatic Renewal, Suspension of Service</h4>
    <p><b>11.1</b> The fees applicable to the Services are available at <a href="https://sartier.co.uk">https://sartier.co.uk</a>. TIER reserves the right to change the fees or applicable charges and to institute new charges at any time, upon thirty (30) prior notice to you which will be sent by email to the address you provided to us.</p>
    <p><b>11.2</b> Unless you specifically inform TIER by email, no later than thirty (30) days prior to the conclusion of the term of your subscription, whether monthly or yearly, TIER will automatically renew the service for the same term.</p>
    <p><b>11.3</b> If payment is not received by end of a given monthly period of thirty (30) days, your account will be temporarily suspended until all outstanding payments have been processed by TIER. TIER will remind you of the suspended state of your account every seven (7) days. Seven (7) days before the termination date, TIER will send a FINAL REMINDER to you that your suspended account will be terminated and your DATA DELETED if you do not settle your outstanding balance.</p>
    <p><b>11.4</b> It is solely your responsibility to settle the outstanding balances of your account in a timely manner and maintain updated billing information. If payment is not made within ninety (90) days, your account will be terminated and all Content will be DELETED.</p>
    <h4><b>12.</b> Intellectual Property Rights</h4>
    <p><b>12.1</b> TIER shall retain all title, ownership rights, and intellectual property rights in and to the Services and Software, and any copies or portions thereof. The Services and Software are protected by patent, copyright and other intellectual property laws. All trademarks, service marks or other similar items appearing in the Services are the property of their respective owners, including TIER. You agree not to take any action to jeopardize, limit, or interfere in any manner with TIER's (or its licensors') ownership of or rights with respect to the Services or Software. Except for the rights specifically granted to you in the Terms, all rights in and to the Services and Software are reserved, and no implied licenses are granted by TIER. You will maintain and not remove or obscure any proprietary notices on the Software, and will reproduce such notices exactly on all permitted copies of the Software.</p>
    <p><b>12.2</b> To the extent that the Services provide TIER users an opportunity to store and exchange information, materials, data, files, programs, ideas and opinions ("Content"), you hereby represent and warrant that you have all necessary rights in and to all Content you provide and all information contained therein. By registering to use the Services, you understand and acknowledge that TIER and its contractors retain an irrevocable, royalty-free, worldwide license to use, copy, and display such Content for the sole purpose of providing to you the Services for which you have registered. You continue to retain all ownership rights in any Content you provide and shall remain solely responsible for your conduct, your User Content, and any material or information transmitted to other Users for interaction with other Users. TIER does not claim any ownership rights in any of your Content.</p>
    <p><b>12.3</b> If you have designated a person (whether by email, by registering such person with TIER, by granting such person access to your username and password) to have access to your Content, you hereby authorise TIER to give such designated person access to your Content, including without limitation in the event of your death or incapacity.</p>
    <p><b>13. Indemnity.</b> You agree that TIER shall have no liability whatsoever for any use you make of the Services or Software. You agree to indemnify, defend and hold TIER harmless from and against any and all claims, damages, liabilities, and costs and fees (including reasonable attorneys' fees), in connection with or arising out of your (a) violation or breach of any term of the Terms or any applicable law or regulation, whether or not referenced herein, or (b) violation of any rights of any third party, (c) use or misuse of the Services or Software, or (d) any upload, download or dissemination or your Content by means of the Services.</p>
    <p><b>14. WARRANTY DISCLAIMER.</b> THE SERVICES AND SOFTWARE ARE PROVIDED "AS IS", WITHOUT WARRANTY OF ANY KIND, AND TIER HEREBY DISCLAIMS ALL EXPRESS OR IMPLIED WARRANTIES, INCLUDING WITHOUT LIMITATION WARRANTIES OF MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE, QUALITY, PERFORMANCE, ACCURACY, RELIABILITY, AND NON-INFRINGEMENT. TIER MAKES NO WARRANTY THAT THE SERVICES OR SOFTWARE WILL BE AVAILABLE, ACCESSIBLE, UNINTERRUPTED, TIMELY, SECURE, ERROR-FREE OR OTHERWISE MEET YOUR EXPECTATIONS. THIS DISCLAIMER OF WARRANTY CONSTITUTES AN ESSENTIAL PART OF THE TERMS. AS SOME JURISDICTIONS DO NOT ALLOW SOME OF THE EXCLUSIONS OR LIMITATIONS ABOVE, THEY MAY NOT APPLY TO YOU.</p>
    <p><b>15. LIMITATION OF LIABILITY.</b> IN NO EVENT SHALL TIER OR ITS AFFILIATES, SUPPLIERS OR DISTRIBUTORS (OR ANY OF THE FOREGOING ENTITIES' OFFICERS, DIRECTORS, EMPLOYEES, OR AGENTS) BE LIABLE FOR ANY INDIRECT DAMAGES, INCLUDING, BY WAY OF ILLUSTRATION AND NOT LIMITATION, LOST DATA, LOST PROFITS, LOST BUSINESS OR LOST OPPORTUNITY, OR ANY INDIRECT, SPECIAL, INCIDENTAL, CONSEQUENTIAL OR EXEMPLARY DAMAGES, INCLUDING LEGAL FEES, ARISING OUT OF THE DOWNLOAD, USE, OR INABILITY TO USE THE SERVICES OR SOFTWARE. IN NO EVENT WILL THE MAXIMUM CUMULATIVE LIABILITY OF TIER UNDER THE TERMS, EXCEED THE FEES YOU'VE PAID TO TIER, OR IF NONE PAID, 10 POUNDS STERLING. YOUR ONLY RIGHT OR REMEDY WITH RESPECT TO ANY PROBLEMS OR DISSATISFACTION WITH THE SOFTWARE OR SERVICE IS TO DEINSTALL THE SOFTWARE AND CEASE USE OF THE SERVICES. SOME STATES OR JURISDICTIONS DO NOT ALLOW THE EXCLUSION OR LIMITATION OF INCIDENTAL OR CONSEQUENTIAL DAMAGES, SO THE ABOVE LIMITATION AND EXCLUSION MAY NOT APPLY TO YOU.</p>
    <p><b>16. Governing Law.</b> The sole and exclusive jurisdiction and venue for actions arising under the Terms shall be the English courts; you hereby agree to service of process in accordance with the rules of such courts. The United Nations Convention on Contracts for the Sale of Goods does not apply to the Terms.</p>
    <h4><b>17.</b> Miscellaneous.</h4>
    <p><b>17.1 Entire Agreement.</b> The Terms set forth the entire understanding and agreement between you and TIER with respect to the subject matter hereof. If any provision or provisions hereof shall be held to be invalid, illegal, or unenforceable, the validity, legality, and enforceability of the remaining provisions shall not be in any way affected thereby.</p>
    <p><b>17.2 Third Party Fees.</b> You are responsible for fees associated with the operation of your mobile device and personal computer, such as messaging charges and airtime, and the costs of Internet access.</p>
    <p><b>17.3 Waivers.</b> TIER's failure to act with respect to a breach of the Terms by you or others does not constitute a waiver and shall not limit TIER's rights with respect to such breach or any subsequent breaches.</p>
    <p><b>17.4 No Assignment by You.</b> Your agreement with TIER is personal to you and may not be assigned or transferred for any reason whatsoever.</p>
    <p><b>17.5 Assignment by TIER.</b> TIER expressly reserves the right to assign its agreement with you and to delegate any of its obligations hereunder.</p>
    <p><b>17.6 Language.</b> The original English version of the Terms may have been translated into other languages. In the event of inconsistency or discrepancy between the English version and any other language version of the Terms, the English language version shall prevail.</p>
    <p><b>17.7 Electronic Communications and Signatures.</b> You hereby agree to the use of electronic communication in order to enter into agreements and place orders, and to the electronic delivery of notices, policies and records of transactions initiated or completed through the Software. Furthermore, you hereby waive any rights or requirements under any laws or regulations in any jurisdiction which require an original (non-electronic) signature or delivery or retention of non-electronic records, to the extent permitted under applicable mandatory law.</p>
    <p>If you need assistance with the Software, including uninstallation, You can e-mail TIER at <a href="mailto:support@sartier.co.uk">support&#64;sartier.co.uk</a></p>
</div>
