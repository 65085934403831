'use strict'

import { Component, OnInit, Inject, ViewChild } from '@angular/core';
import { GridsterConfig, GridsterItem } from 'angular-gridster2';
import { TIERAPICalls, TIERToast } from '../../services';
import { WidgetLibrary } from '../../shared/decorators/WidgetsLibrary.decorator';
import { TIERMaximizeDirective } from '../../directives';
import { forkJoin } from 'rxjs';

import { calcUUID, http2Error, isNorU } from '../../tier.utils';
import { WidgetInstanceModel, WidgetModel, WidgetCategoryModel } from '../../models'

interface GridItemData {
  Id: number,
  name: string,
  WidgetId: number,
  X: number,
  Y: number,
  ColumnSize: number,
  RowSize: number,
  DefaultColumnSize: number,
  DefaultRowSize: number,
  Title: string,
  Component: string,
  inputs: any,
  UUID: string
};

@Component({
  selector: 'tier-dashboard',
  templateUrl: "./dashboard.template.html",
  styleUrls: [ "./dashboard.component.scss" ]
})
export class DashboardComponent implements OnInit {
  @ViewChild('MaximizeFrame') tierMaximize!: TIERMaximizeDirective;

  private timeoutId : NodeJS.Timeout | null = null;
  public widgets : WidgetCategoryModel[] = [];
  public dashboard : GridsterItem[] = [];
  public sideMenuToggle : boolean = false;
  public refreshTimes : any[] = [{ id: 10, name: '10 Mins' }, { id: 15, name: '15 Mins' }, { id: 30, name: '30 Mins' }];
  public selectedRefreshTime : number | null = null;

  public gridster2Options : GridsterConfig =
  {
    gridType: 'fit',
    margin: 10,
    defaultItemCols: 2,
    defaultItemRows: 2,
    maxItemCols: 4,
    maxItemRows: 6,
    outerMargin: true,
    swap: true,
    disableWarnings: true,
    keepFixedHeightInMobile: true,
    fixedRowHeight: 500,
    pushItems: true,
    pushResizeItems: true,
    draggable: {
        enabled: false,
        dragHandleClass: 'grid-handle',
        ignoreContent: true
    },
    resizable: {
        enabled: false
    }
  };

  public layout = {
    isEditing: false,
    buttonText: 'Edit Layout'
  };

  constructor(
    @Inject(TIERAPICalls) private apicall : TIERAPICalls,
    @Inject(TIERToast) private alert : TIERToast
  ) { };

  ngOnInit() : void {
    this.fetchAll();
  };

  private fetchAll() : void {
    this.dashboard = [];

    forkJoin([this.apicall.get<WidgetInstanceModel[]>('api/widgetinstances/'), this.apicall.get<WidgetCategoryModel[]>('api/widgets/')]).subscribe({
      next: (response) => {
        this.addFromServer(response[0] as GridItemData[]);
        this.widgets = response[1];
      },
      error: (error) => {
        this.alert.error(http2Error(error));
      }
    });
  };

  private addFromServer(widgets : GridItemData[]) : void {
    widgets.forEach((widget) => {
      this.dashboard.push({
        id: widget.Id,
        name: widget.Component,
        widgetId: widget.WidgetId,
        x: widget.X,
        y: widget.Y,
        cols: widget.ColumnSize,
        rows: widget.RowSize,
        title: widget.Title,
        component: WidgetLibrary.Get(widget.Component) ?? WidgetLibrary.Get('NotFoundWidgetComponent'),
        UUID: calcUUID()
      });
    })
  };

  public addWidgetInstance(widget : GridItemData) : void {
    this.dashboard.push({
      id: null,
      name: widget.Component,
      widgetId: widget.Id,
      x: widget.X,
      y: widget.Y,
      cols: widget.ColumnSize,
      rows: widget.RowSize,
      title: widget.Title,
      component: WidgetLibrary.Get(widget.Component),
      UUID: calcUUID()
    });
  };

  public addWidgetItem(widget : WidgetModel) : void {
    this.dashboard.push({
      id: null,
      name: widget.Component,
      widgetId: widget.Id,
      x: 0,
      y: 0,
      cols: widget.DefaultColumnSize,
      rows: widget.DefaultRowSize,
      title: widget.Title,
      component: WidgetLibrary.Get(widget.Component),
      UUID: calcUUID()
    });
  };

  public alreadyDeployed(widget : WidgetModel) : boolean {
    if (this.dashboard.filter((e) => { return e.name === widget.Component }).length > 0) {
        return true;
    }
    return false;
  };

  public changeLayout(forceNoSave : boolean) : void {
    this.sideMenuToggle = false;
    this.layout.isEditing = !this.layout.isEditing;
    this.layout.buttonText = this.layout.isEditing ? 'Save Layout' : 'Edit Layout';

    if(this.gridster2Options.draggable)
        this.gridster2Options.draggable.enabled = this.layout.isEditing;

    if(this.gridster2Options.resizable)
        this.gridster2Options.resizable.enabled = this.layout.isEditing;

    if(this.gridster2Options.api?.optionsChanged)
        this.gridster2Options.api.optionsChanged();

    if (!this.layout.isEditing && !forceNoSave)
        this.saveDashboard();
  };

  public saveDashboard() : void {
    this.apicall.put<WidgetInstanceModel[]>('api/widgetinstances/', this.argumentRequest(this.dashboard)).subscribe({
      next: () => {
        this.alert.success("Dashboard layout saved.");
      },
      error: (error) => {
        this.alert.error(http2Error(error));
      }
    });
  };

  private argumentRequest(data : GridsterItem[]) : WidgetInstanceModel[] {
    let augmentedData : WidgetInstanceModel[] = [];

    data.forEach((value) => {
      augmentedData.push({
        Id: value.id,
        X: value.x,
        Y: value.y,
        ColumnSize: value.cols,
        RowSize: value.rows,
        WidgetId: value.widgetId
      });
    })

    return augmentedData;
  };

  public cancel() : void {
    this.changeLayout(true);
    this.fetchAll();
  };

  public toggleWidgetMenu() : void {
    this.sideMenuToggle = !this.sideMenuToggle;
  };

  public removeAllWidgetOfType(widget : WidgetModel) : void {
    let removeIndex = this.dashboard.map((item) => { return item.widgetId; }).indexOf(widget.Id);
    this.dashboard.splice(removeIndex, 1);
  };

  public delWidget(widget : GridsterItem) : void {
    let removeIndex = this.dashboard.map(function (item) { return item.UUID; }).indexOf(widget.UUID);
    this.dashboard.splice(removeIndex, 1);
  };

  public toggleFullscreen() : void {
    this.tierMaximize.toggle();
  };

  public setRefresh(selected : number | string) : void {
    if(isNorU(selected))
      return;

    this.alert.success("Dashboard will refresh every " + selected + " minutes.");
    this.timeoutId = setTimeout(() => {
      this.fetchAll();
    }, +selected * 60000);
  }

  public clearRefresh() : void {
    if(this.timeoutId === null)
      return;

    this.alert.success("Dashboard refresh cancelled.");
    clearTimeout(this.timeoutId);
  }
}
