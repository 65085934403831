import { Component, Output, Inject, Input, EventEmitter, OnInit } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

import { AttachmentModel } from 'src/tier/models';
import { TIERUploadComponent } from './';

@Component({
    selector: 'tieruploadbutton',
    template: `
        <div>
            <button *ngIf="!attachment && !fdata" (click)="open()" type="button" class="btn" [ngClass]="class" [textContent]="uploadTextContent"></button>
            <button *ngIf="attachment && deleteAllowed" (click)="delete()" type="button" class="btn btn-danger" [textContent]="deleteTextContent"></button>
            <div *ngIf="fdata" class="hstack">
                <div>
                    Filename: {{fileName()}}
                </div>
                <div class="ms-auto">
                    <button type="button" class="btn btn-danger btn-sm ms-2" (click)="clear()">X</button>
                </div>
            </div>
            <div class="pt-2" *ngIf="attachment && !deleteAllowed">{{attachment.FileName}}</div>
        </div>`
})
export class TIERUploadButtonComponent implements OnInit {
    @Input() attachment : AttachmentModel | undefined | null = null;

    @Input() class : string = "btn-primary";
    @Input() uploadTextContent : string = "Upload Attachment";
    @Input() deleteTextContent : string = "Delete Attachment";
    @Input() allowedTypes : string[] = [];
    @Input() deleteAllowed : boolean = true;
    @Input() reset : EventEmitter<null> = new EventEmitter<null>();

    @Output() data : EventEmitter<FormData> = new EventEmitter<FormData>();
    @Output() del : EventEmitter<any> = new EventEmitter();

    public fdata : FormData | null = null;
    public fileName = () => (this.fdata?.get('file') as File)?.name;

    constructor(@Inject(NgbModal) private modalService : NgbModal)
    {}

    ngOnInit(): void {
        this.reset.subscribe({
            next: () => this.clear()
        })
    }

    public delete() : void {
        this.del.emit();
    }

    public clear() : void {
        this.fdata = null;
        this.data.emit(undefined);
    }

    public open() : void {
        const modalRef = this.modalService.open(TIERUploadComponent);

        modalRef.componentInstance.attachment = { ...this.attachment };
        modalRef.componentInstance.allowedTypes = this.allowedTypes;

        modalRef.result.then((result : FormData) => {
            this.fdata = result;
            this.data.emit(result);
        }).catch(() => {});
    }
}
