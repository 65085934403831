import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { StateService, Transition } from '@uirouter/angular';
import { NgForm } from '@angular/forms';

import { TIERAPICalls, TIERToast } from '../../services';
import { SkillModel } from '../../models';
import { http2Error } from 'src/tier/tier.utils';

@Component({
    selector: 'tier-module',
    templateUrl: "./module.template.html"
})
export class ModuleComponent implements OnInit {
    @ViewChild('moduleForm') moduleForm!: NgForm;

    private moduleId : number = this.trans.params()?.moduleId;
    public module : SkillModel = {};

    constructor(
        @Inject(TIERAPICalls) private apicall : TIERAPICalls,
        @Inject(TIERToast) private alert : TIERToast,
        @Inject(StateService) private state : StateService,
        @Inject(Transition) private trans : Transition) { };

    ngOnInit(): void {
        if(!this.moduleId)
            return;

        this.get(this.moduleId);
    }

    public get(id : number) : void {
        this.apicall.get<SkillModel>('api/modules/%s'.formUri(id)).subscribe({
            next: (response : SkillModel) => {
                this.module = response;
                this.moduleForm.form.markAsPristine();
            },
            error: (error) => {
                this.alert.error(http2Error(error));
            }
        });
    }

    public cancel() {
        this.state.go('training@modules@view');
    }

    public save() {
        this.apicall.save<SkillModel>('api/modules/%s'.formUri(this.module?.Id), this.module).subscribe({
            next: () => {
                this.alert.success("Module saved.");
                this.state.go('training@modules@view');
            },
            error: (error) => {
                this.alert.error(http2Error(error));
            }
        });
    }

    public delete() {
        this.module.IsActive = !this.module.IsActive;
        this.save();
    }
}
