'use strict'

import { Pipe, PipeTransform } from '@angular/core';
import { parse, isValid, intervalToDuration, formatDuration } from "date-fns";
import { isNorU } from 'src/tier/tier.utils';

@Pipe({name: 'durationdate'})
export class DurationDatePipe implements PipeTransform {
    transform(start: string | null | undefined, end: string | null | undefined) : any {
        if(isNorU(end))
            return "Ongoing";

        if (isNorU(start))
            return "Unknown";

        let s, e = null;
        try {
            s = parse(start!, 'dd-MM-yyyy HH:mm', new Date());
            e = parse(end!, 'dd-MM-yyyy HH:mm', new Date());
        } catch {
            return "Unknown";
        }

        if (!isValid(s) || !isValid(e))
            return "Unknown";

        let inter = intervalToDuration({ 'start' : s!, 'end' : e! });

        if(!Object.keys(inter)?.length)
            return "0 minute";

        return formatDuration(inter);
    }
}
