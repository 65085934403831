import { Input, forwardRef, Directive, OnChanges, SimpleChanges } from '@angular/core';
import { NG_VALIDATORS, Validator, AbstractControl, ValidationErrors } from '@angular/forms';

import { isNorU } from 'src/tier/tier.utils';

@Directive({
  selector: '[DuplicateField]',
  providers: [
    {
      provide: NG_VALIDATORS,
      useExisting: forwardRef(() => DuplicateFieldValidationDirective),
      multi: true,
    },
  ]
})
export class DuplicateFieldValidationDirective implements Validator, OnChanges {
    @Input('DuplicateField') dupe : string | null | undefined = null;

    onChange: () => void = () => {};

    ngOnChanges(changes: SimpleChanges): void {
        if(!isNorU(changes['dupe']?.currentValue) && !changes['dupe'].isFirstChange())
            this.dupe = changes['dupe'].currentValue;

        this.onChange();
    }

    validate(control: AbstractControl): ValidationErrors | null {
        if(this.dupe !== control.value)
            return { invalid: true }

        return null;
    }

    registerOnValidatorChange(fn: () => void): void {
        this.onChange = fn;
    }
}
