<form role="form" #settingsForm="ngForm" (ngSubmit)="save()" novalidate autocomplete="none">
    <div class="card mb-3">
        <div class="card-header">Locale Defaults</div>
        <div class="card-body">
            <div class="row mb-3">
                <label class="col-sm-3 col-form-label fw-bold">Country</label>
                <div class="col-sm-9">
                    <tierdropdown
                        name="nationaldd"
                        source="api/nationalities"
                        [(ngModel)]="options['org-locale-nationality']"
                        [allowNew]=false
                        bindId="Id"
                        bindValue="Country"
                        placeholder="Select Nationality"
                        [isNumber]="true">
                    </tierdropdown>
                </div>
            </div>
                <div class="row">
                    <label class="col-sm-3 col-form-label fw-bold">Location</label>
                    <div class="col-sm-9">
                        <div class="hstack">
                            <input type="text" readonly name="locationlat" class="form-control me-2" [(ngModel)]="options['org-location-lat']" />
                            <input type="text" readonly name="locationlon" class="form-control me-2" [(ngModel)]="options['org-location-lon']" />
                            <tierdropdown
                                name="locationzoom"
                                class="me-2"
                                [source]="[ 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18 ]"
                                [(ngModel)]="options['org-location-zoom']"
                                [allowNew]=false
                                placeholder="Select Zoom Level"
                                [isNumber]="true">
                            </tierdropdown>
                            <tier-mapping-button [location]="location" (locationChange)="locationToOptions($event)" permission="auto" [zoom]="options['org-location-zoom']"><i class="fa fa-map"></i></tier-mapping-button>
                        </div>
                    </div>
                </div>
        </div>
    </div>
    <div class="card mb-3">
        <div class="card-header">Date and Time Defaults</div>
        <div class="card-body">
            <div class="row mb-3">
                <label class="col-sm-3 col-form-label fw-bold">Session start time</label>
                <div class="col-sm-9">
                    <tiertimepicker name="sessionstarttime" [timeOnly]="true" [(ngModel)]="options['org-session-starttime']" format="H:i"></tiertimepicker>
                </div>
            </div>
            <div class="row mb-3">
                <label class="col-sm-3 col-form-label fw-bold">Session end time</label>
                <div class="col-sm-9">
                    <tiertimepicker name="sessionendtime" [timeOnly]="true" [(ngModel)]="options['org-session-endtime']" format="H:i"></tiertimepicker>
                </div>
            </div>
            <div class="row">
                <label class="col-sm-3 col-form-label fw-bold">Training day</label>
                <div class="col-sm-9">
                    <tierdropdown
                        name="weekdd"
                        [source]="daysOfWeek"
                        placeholder="Select Role"
                        [(ngModel)]="options['org-training-day']"
                        [allowNew]=false
                        bindId="id"
                        bindValue="name"
                        [isNumber]="true">
                    </tierdropdown>
                </div>
            </div>
        </div>
    </div>
    <div class="card mb-3">
        <div class="card-header">Authentication</div>
        <div class="card-body">
            <div class="form-check mb-2">
                <input class="form-check-input" name="googleauth" type="checkbox" ConvertToTrueOrFalse [(ngModel)]="options['org-3rdparty-google-auth']">
                <label class="form-check-label">
                    Allow Google 3rd party authentication at login?
                </label>
            </div>
            <div class="form-check mb-2">
                <input class="form-check-input" name="microsoftauth" type="checkbox" ConvertToTrueOrFalse [(ngModel)]="options['org-3rdparty-microsoft-auth']">
                <label class="form-check-label">
                    Allow Microsoft 3rd party authentication at login?
                </label>
            </div>
            <div class="form-check">
                <input class="form-check-input" name="localauth" type="checkbox" ConvertToTrueOrFalse [(ngModel)]="options['org-local-auth']">
                <label class="form-check-label">
                    Allow local Authentication at login?
                </label>
            </div>
        </div>
    </div>
    <div class="card mb-3">
        <div class="card-header">Equipment</div>
        <div class="card-body">
            <div class="row mb-2">
                <label class="col-sm-3 col-form-label fw-bold">Set condition of equipment on session completion</label>
                <div class="col-sm-9">
                    <tierdropdown
                        name="sessionequipmentcondition"
                        [source]="conditions"
                        placeholder="Select Condition"
                        [(ngModel)]="options['org-equipment-session-complete']"
                        [httpparams]="{ allowDefault: true }"
                        [allowNew]=false
                        bindId="Id"
                        bindValue="Name"
                        [isNumber]="true">
                    </tierdropdown>
                </div>
            </div>
            <div class="row">
                <label class="col-sm-3 col-form-label fw-bold">Set condition of equipment on incident completion</label>
                <div class="col-sm-9">
                    <tierdropdown
                        name="incidentequipmentcondition"
                        [source]="conditions"
                        placeholder="Select Condition"
                        [(ngModel)]="options['org-equipment-incident-complete']"
                        [httpparams]="{ allowDefault: true }"
                        [allowNew]=false
                        bindId="Id"
                        bindValue="Name"
                        [isNumber]="true">
                    </tierdropdown>
                </div>
            </div>
        </div>
    </div>
    <div class="card mb-3">
        <div class="card-header">Assessment</div>
        <div class="card-body">
            <div class="form-check mb-2">
                <input class="form-check-input" name="assessedoncomplete" type="checkbox" ConvertToTrueOrFalse [(ngModel)]="options['org-maintainer-assessed-on-complete']">
                <label class="form-check-label">
                    Set marked skills as passed for faciliators on session completion?
                </label>
            </div>
            <div class="form-check">
                <input class="form-check-input" name="allowattachment" type="checkbox" ConvertToTrueOrFalse [(ngModel)]="options['org-training-allow-attachment']">
                <label class="form-check-label">
                    Allow team members to upload attachments via training page?
                </label>
            </div>
        </div>
    </div>
    <div class="card mb-3">
        <div class="card-header">Incidents</div>
        <div class="card-body">
            <div class="card mb-3">
                <div class="card-header">
                    Incident Attendence
                </div>
                <div class="card-body">
                    <div class="form-check mb-2">
                        <input class="form-check-input" name="notracktime" type="radio" [(ngModel)]="options['org-incident-no-track-time']" [value]="'false'">
                        <label class="form-check-label">
                            Register incident attendee date and time of attendence, allowing for multiple entries.
                        </label>
                    </div>
                    <div class="form-check">
                        <input class="form-check-input" name="notracktime" type="radio" [(ngModel)]="options['org-incident-no-track-time']" [value]="'true'">
                        <label class="form-check-label">
                            Register incident attendee attendence only, allowing for only single entries.
                        </label>
                    </div>
                </div>
            </div>
            <div class="row mb-3">
                <label class="col-sm-3 col-form-label fw-bold">Training Skill Limit</label>
                <div class="col-sm-9">
                    <input type="number" name="trainingskilllimimt" class="form-control" [(ngModel)]="options['org-incident-skill-limit']" min="1" />
                </div>
            </div>
            <div class="form-check mb-2">
                <input class="form-check-input" name="rolemodules" type="checkbox" ConvertToTrueOrFalse [(ngModel)]="options['org-incident-roles-modules']">
                <label class="form-check-label">
                    Use skill modules not roles in responding skill selector?
                </label>
            </div>
            <div class="form-check">
                <input class="form-check-input" name="rolesinvalid" type="checkbox" ConvertToTrueOrFalse [(ngModel)]="options['org-incident-roles-invalid']">
                <label class="form-check-label">
                    Allow team member to respond using invalid roles?
                </label>
            </div>
        </div>
    </div>
    <div class="card mb-3">
        <div class="card-header">Widget Globals</div>
        <div class="card-body">
            <div class="form-check">
                <input class="form-check-input" name="showorg" type="checkbox" ConvertToTrueOrFalse [(ngModel)]="options['org-widget-show-organizational']">
                <label class="form-check-label">
                    Show organizational totals on widgets?
                </label>
            </div>
        </div>
    </div>
    <div class="card">
        <div class="card-header">Twilio Integration</div>
        <div class="card-body">
            <div class="row mb-3">
                <label class="col-sm-3 col-form-label fw-bold">Account SID</label>
                <div class="col-sm-9">
                    <input type="text" name="twiliosid" class="form-control" [(ngModel)]="options['org-twilio-auth-sid']" />
                </div>
            </div>
            <div class="row mb-3">
                <label class="col-sm-3 col-form-label fw-bold">Auth Token</label>
                <div class="col-sm-9">
                    <input type="text" name="twiliosauthtoken" class="form-control" [(ngModel)]="options['org-twilio-auth-token']" />
                </div>
            </div>
            <div class="row mb-3"  *ngIf="twilioPhoneNumbers.length > 0">
                <label class="col-sm-3 col-form-label fw-bold">Phone Numbers</label>
                <div class="col-sm-9">
                    <ul>
                        <li *ngFor="let phoneNumber of twilioPhoneNumbers">{{phoneNumber.phone_number}}</li>
                    </ul>
                </div>
            </div>
            <div class="row mb-3">
                <label class="col-sm-3 col-form-label fw-bold">Username</label>
                <div class="col-sm-9">
                    <input type="text" name="twiliousername" class="form-control" [(ngModel)]="options['org-twilio-basicauth-username']" (ngModelChange)="smsHook()"/>
                </div>
            </div>
            <div class="row mb-3">
                <label class="col-sm-3 col-form-label fw-bold">Password</label>
                <div class="col-sm-9">
                    <div class="input-group">
                        <input type="text" name="twiliopassword" minlength="3" class="form-control" [(ngModel)]="options['org-twilio-basicauth-password']" readonly>
                        <button class="btn btn-secondary" type="button" (click)="genToken()"><i class="fa-solid fa-sync"></i></button>
                    </div>
                </div>
            </div>
            <div class="row mb-3">
                <label class="col-sm-3 col-form-label fw-bold">SMS Hook</label>
                <div class="col-sm-9">
                    <div class="input-group">
                        <input type="text" name="twiliosmshook" class="form-control ro" [(ngModel)]="smsHookField" readonly/>
                        <button class="btn btn-info" type="button"><i class="fa-solid fa-copy"></i></button>
                    </div>
                </div>
            </div>
            <div class="row mb-3">
                <label class="col-sm-3 col-form-label fw-bold">Allowed CLI numbers to recieve from</label>
                <div class="col-sm-9">
                    <input type="text" name="twilioallowednumbers" class="form-control" [(ngModel)]="options['org-twilio-allowed-numbers']" />
                </div>
            </div>
            <div class="form-check mb-2">
                <input class="form-check-input" name="twilioallowwork" type="checkbox" ConvertToTrueOrFalse [(ngModel)]="options['org-twilio-allow-worknumbers']">
                <label class="form-check-label">
                    Allow messages from team members mobile phone numbers?
                </label>
            </div>
            <div class="form-check">
                <input class="form-check-input" name="twiliosmsresponse" type="checkbox" ConvertToTrueOrFalse [(ngModel)]="options['org-twilio-sms-response']">
                <label class="form-check-label">
                    Respond to messages sent to twilio?
                </label>
            </div>
        </div>
    </div>
    <br />
    <div class="hstack mb-3">
        <div class="ms-auto">
            <button type="submit" class="btn btn-primary"
                    [disabled]="settingsForm.invalid || settingsForm.pristine">
                Save
            </button>
        </div>
    </div>
</form>
