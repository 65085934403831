'use strict'

import { Pipe, PipeTransform } from '@angular/core';

@Pipe({name: 'filterbyobjectprop'})
export class FilterByObjectPropertyPipe implements PipeTransform {
    transform(value: any, fun : Function) : any {
        if (!value || !fun)
            return value;

         return value.filter(fun);
    }
}
