import { Component, OnInit, Inject, Input } from '@angular/core';
import { HttpParams } from '@angular/common/http';

import { TIERAPICalls, TIERToast } from '../../services';
import { EquipmentTemplateModel, EquipmentTemplateListingModel } from '../../models';
import { http2Error, calcUUID } from 'src/tier/tier.utils';

@Component({
    selector: 'tier-equipment-templates',
    templateUrl: "./equipmenttemplates.template.html",
    styleUrls: [ './equipmenttemplates.component.scss' ]
})
export class EquipmentTemplatesComponent implements OnInit {
    @Input() newLink : string | null = null;

    @Input() cancelLink : string | null = null;
    @Input() cancelOpts : Object | null = null;

    @Input({ required: true }) selectLink! : string;
    @Input() selectOpts : Object | null = null;

    public templates : EquipmentTemplateModel[] = [];
    public total : number = 0;
    public page : number = 1;

    public getParams = (template : EquipmentTemplateModel) => {
        return { ...this.selectOpts, templateId: template.Id };
    };

    public filterConfig = [
        {
            name: calcUUID(),
            formControl: "search",
            label: "Search Text",
            value: "search"
        },
        {
            name: calcUUID(),
            formControl: "tickbox",
            label: "Show Inactive entries",
            value: "isActive"
        }
    ];

    public values = {
        skip: 0,
        amount: 19,
        search: '',
        isActive: false
    };

    constructor(
        @Inject(TIERAPICalls) private apicall : TIERAPICalls,
        @Inject(TIERToast) private alert : TIERToast
    ) { }

    ngOnInit(): void {
        this.get();
    }

    public get() {
        this.values.skip = (this.page - 1) * this.values.amount;

        this.apicall.get<EquipmentTemplateListingModel>('api/equipment/templates', new HttpParams({'fromObject': { ...this.values }})).subscribe({
            next: (response : EquipmentTemplateListingModel) => {
                this.templates = response.ObjectList;
                this.total = response.Total;
            },
            error: (error) => {
                this.alert.error(http2Error(error));
            }
        });
    }
}
