import { Component, OnInit, Inject } from '@angular/core';
import { HttpParams } from '@angular/common/http';
import { Transition } from '@uirouter/angular';

import { TIERAPICalls, TIERToast } from '../../services';
import { EquipmentResourceHistoryModel, EquipmentResourceHistoryListingModel } from '../../models';
import { http2Error } from 'src/tier/tier.utils';

@Component({
    selector: 'tier-equipment-resourcehistory',
    templateUrl: "./equipmentresourcehistory.template.html"
})
export class EquipmentResourceHistoryComponent implements OnInit {
    private id : number = this.trans.params()?.id;

    public history : EquipmentResourceHistoryModel[] = [];
    public total : number = 0;
    public page : number = 1;

    public values = {
        skip: 0,
        amount: 50
    };

    constructor(
        @Inject(TIERAPICalls) private apicall : TIERAPICalls,
        @Inject(TIERToast) private alert : TIERToast,
        @Inject(Transition) private trans : Transition
    ) { }

    ngOnInit(): void {
        this.get();
    }

    public get() {
        if(!this.id)
            return;

        this.values.skip = (this.page - 1) * this.values.amount;

        this.apicall.get<EquipmentResourceHistoryListingModel>('api/equipment/resource/history/%s'.formUri(this.id), new HttpParams({'fromObject': { ...this.values }})).subscribe({
            next: (response : EquipmentResourceHistoryListingModel) => {
                this.history = response.ObjectList;
                this.total = response.Total;
            },
            error: (error) => {
                this.alert.error(http2Error(error));
            }
        });
    }
}
