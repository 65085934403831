'use strict'

import { Component, OnInit } from '@angular/core';
import { WidgetBase } from '../../Widget.base';
import { http2Error } from 'src/tier/tier.utils';

@Component({
    selector: 'tier-widget-trainingadmin-teamfailedmoduleswidget',
    template: `
    <tierspinner [name]="getUUID()"></tierspinner>
    <table class="table table-borderless table-hover table-condensed">
        <thead>
            <tr>
                <th>Name</th>
                <th>Skill</th>
                <th>Assessed</th>
                <th>Next</th>
            </tr>
        </thead>
        <tbody>
            <tr *ngFor="let module of modules">
                <td>{{module.FullName}}</td>
                <td [ngClass]="{'danger' : module.IsCritical === true}">{{module.SkillName}}</td>
                <td>{{module.AssessDate | transformdate: 'dd-MM-yyyy'}}</td>
                <td [ngClass]="{'danger' : module.NextSession === null}">{{(module.NextSession | transformdate: 'dd-MM-yyyy') || 'None'}}</td>
            </tr>
        </tbody>
    </table>`,
    styleUrls: [ '../../_widgets.scss' ]
})
export class TeamFailedModulesWidgetComponent extends WidgetBase implements OnInit {
    static readonly widgetName = 'TeamFailedModulesWidgetComponent';
    public modules : any[] = [];

    ngOnInit(): void {
        this.callAPI<any[]>('api/dashboard/teamfailedmodules', this.getUUID()).subscribe({
            next: (response : any[]) => {
                this.modules = response;

                if(this.modules.length <= 0)
                    this.switchToNoDataWidget();
            },
            error: (error) => {
                this.alert.error(http2Error(error));
            }
        });
    }
}
