<div class="table-responsive" [hidden]="!history.length">
    <table class="table table-striped">
        <thead>
            <tr>
                <th>Date</th>
                <th>Time</th>
                <th>Commited By</th>
                <th>Action</th>
                <th>Message</th>
            </tr>
        </thead>
        <tbody>
            <tr *ngFor="let entry of history">
                <td>{{entry.Date | transformdate: 'dd-MM-yyyy'}}</td>
                <td>{{entry.Date | transformdate: 'HH:mm'}}</td>
                <td>{{entry.CommittedBy.FullName}}</td>
                <td><div class="badge text-bg-info">{{entry.Type}}</div></td>
                <td>
                    <b>Equipment resource has been {{entry.Type | lowercase}} with the following details:</b><br><br>
                    <ul>
                        <li *ngFor="let json of entry.Json | transformjson" [textContent]="'(' + json.State + ') ' + json.Name + ' => ' + json.Current"></li>
                    </ul>
                </td>
            </tr>
        </tbody>
    </table>
    <div class="d-flex justify-content-center my-3" [hidden]="!history.length">
        <ngb-pagination [collectionSize]="total" [(page)]="page" [pageSize]="values.amount" [maxSize]="15" [rotate]="true" [ellipses]="false" [boundaryLinks]="true" (pageChange)="get()" />
    </div>
</div>
<div class="alert alert-info" role="alert" [hidden]="history.length">
    <i class="fa-solid fa-exclamation-circle"></i> 0 results found.
</div>
