'use strict'

import { Component, OnInit } from '@angular/core';
import { WidgetBase } from '../../Widget.base';
import { http2Error } from 'src/tier/tier.utils';

@Component({
    selector: 'tier-widget-all-myexpiringmoduleswidget',
    template: `
    <tierspinner [name]="getUUID()"></tierspinner>
    <table class="table table-borderless table-hover table-condensed">
        <thead>
            <tr>
                <th>Module</th>
                <th>Critical</th>
                <th>Expires</th>
                <th>Next</th>
            </tr>
        </thead>
        <tbody>
            <tr *ngFor="let module of modules">
                <td>{{module.Module}}</td>
                <td>
                    <i class="fa fa-check" *ngIf="module.Critical === true"></i>
                    <i class="fa fa-times" *ngIf="module.Critical === false"></i>
                </td>
                <td>{{module.Expires | transformdate: 'dd-MM-yyyy'}}</td>
                <td>{{module.NextSession | transformdate: 'dd-MM-yyyy'}}</td>
            </tr>
        </tbody>
    </table>`,
    styleUrls: [ '../../_widgets.scss' ]
})
export class MyExpiringModulesWidgetComponent extends WidgetBase implements OnInit {
    static readonly widgetName = 'MyExpiringModulesWidgetComponent';
    public modules : any[] = [];

    ngOnInit(): void {
        this.callAPI<any[]>('api/dashboard/myexpiringmodules', this.getUUID()).subscribe({
            next: (response : any[]) => {
              this.modules = response;
              if(this.modules.length <= 0) {
                this.switchToNoDataWidget();
              }
            },
            error: (error) => {
                this.alert.error(http2Error(error));
            }
        });
    }
}
