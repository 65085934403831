<div>
    <div class="row" id="ReportsContainer" style="visibility: hidden">
        <div class="col-md-3 mb-3">
            <div class="card">
                <div class="card-header eq-panel-heading">
                    <div class="eq-card-title eq-card-first-item">Reports</div>
                    <div class="btn-group dropdown" ngbDropdown>
                        <button class="btn btn-default btn-sm"
                                type="button" ngbDropdownToggle>
                            <i class="fa-solid fa-cog"></i>
                        </button>
                        <div ngbDropdownMenu>
                            <button type="button" ngbDropdownItem class="eq-dropdown-item" id="NewReportButton" href="javascript:void(0)">New report</button>
                            <button type="button" ngbDropdownItem class="eq-dropdown-item" id="SaveReportButton" href="javascript:void(0)">Save as...</button>
                            <div class="dropdown-divider"></div>
                            <button type="button" ngbDropdownItem class="eq-dropdown-item" id="RemoveReportButton" href="javascript:void(0)">Remove report</button>
                        </div>
                    </div>
                </div>
                <div class="card-body eq-panel-body">
                    <div id="ReportList" class="card-body">
                    </div>
                </div>
            </div>
        </div>

        <div class="col-md-9 mb-2">
            <div id="ReportPanel" class="card">
                <div class="card-header eq-panel-heading">
                    <div id="ReportTitle" class="eq-card-title float-left">Report title</div>
                </div>
                <div class="card-body">
                    <div class="columns-block mb-3">
                        <div class="block-caption">Columns:</div>
                        <div id="ColumnsBar"></div>
                    </div>
                    <div class="conditions-block mb-3">
                        <div class="block-caption">Conditions:</div>
                        <div id="QueryPanel" class="query-panel"></div>
                    </div>
                    <div class="result-block">
                        <div class="block-caption">Result:</div>
                        <div id="ResultFacetsPanel"></div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="row" id="NoReportPanel" style="visibility:hidden;">
        <div class="col-md-12">
            <h3 class="text-center">
                No reports are defined. <a id="FirstReportButton" href="javascript:document['ReportView'].newReport()">Create a first one</a> to start.
            </h3>
        </div>
    </div>
</div>

