
import { Component, Input, Inject, EventEmitter, Output } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

import { VenueModel } from 'src/tier/models';
import { TIERMappingModalComponent } from './';

@Component({
    selector: 'tier-mapping-button',
    template: `<button class="btn" [ngClass]="classes" (click)="showMappingModal()" type="button" [disabled]="disabled"><ng-content></ng-content></button>`,
})
export class TIERMappingButtonComponent {
    @Input() disabled : boolean = false;
    @Input() classes : string[] = [ "btn-info" ];
    @Input() zoom : number | string | undefined = undefined;

    @Input() location : VenueModel | null | undefined = null;
    @Output() locationChange : EventEmitter<VenueModel | null | undefined> = new EventEmitter<VenueModel | null | undefined>();
    @Input() permission : string = 'r';

    constructor(@Inject(NgbModal) private modalService : NgbModal)
        {}

    public showMappingModal() {
        const modalRef = this.modalService.open(TIERMappingModalComponent, { size: 'xl' });

        modalRef.componentInstance.location = this.location;
        modalRef.componentInstance.permission = this.permission;
        modalRef.componentInstance.zoom = this.zoom;

        modalRef.result.then(() => {
            this.locationChange.emit(this.location);
        }).catch(() => {});
    }
}
