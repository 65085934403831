import { Component, Inject, OnInit, Input, ViewChild, OnChanges, SimpleChanges } from '@angular/core';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { NgModel } from '@angular/forms';

import { IncidentAttendeeModel, TeammembersModel } from '../../models';
import { ModuleSelectorComponent } from '../';
import { EquipmentResourceTreeSelectorComponent } from '../equipmentresourcetreeselector';
import { TIEROptions } from '../../services';
import { isNorU, clone } from 'src/tier/tier.utils';

@Component({
    selector: 'tier-incident-attendeesmodal',
    template: `
            <div class="modal-header">
                <h3 class="modal-title">Add Attendee</h3>
            </div>
            <div class="modal-body">
                <form name="attendeeAddForm" #attendeeAddForm="ngForm" novalidate autocomplete="none">
                    <div class="row mb-3">
                        <label for="attendee" class="col-sm-3 col-form-label fw-bold">Attendee</label>
                        <div class="col-sm-9">
                            <tierdropdown
                                name="atdd"
                                id="attendee"
                                #atdd="ngModel"
                                source="api/teammembers"
                                placeholder="Select Team Member"
                                [(ngModel)]="selectedAttendee.TeamMember"
                                [allowNew]="false"
                                bindValue="FullName"
                                [multi]="multi"
                                (change)="dropdownChanged($event)"
                                required>
                            </tierdropdown>
                            <p [hidden]="!atdd.errors?.required" class="form-text text-danger">Attendee is required.</p>
                            <p [hidden]="!atdd.errors?.duplicate" class="form-text text-danger">
                                You cannot add team members
                                <span *ngFor="let name of errorMembers" [textContent]="name + ' '"></span>
                                <span *ngIf="!noTrackTimeOption">as they are still marked as attending.</span>
                                <span *ngIf="noTrackTimeOption">as duplicates are not allowed with single entry setting enabled.</span>
                            </p>
                        </div>
                    </div>
                    <div class="row mb-3" *ngIf="!multi">
                        <label for="skills" class="col-sm-3 col-form-label fw-bold">Training Skills</label>
                        <div class="col-sm-9">
                            <button type="button" class="btn btn-secondary btn-block w-100 btn-sm" (click)="amend(selectedAttendee)" [disabled]="!selectedAttendee.TeamMemberId">Amend Skills</button>
                        </div>
                    </div>
                    <div *ngIf="!noTrackTimeOption">
                        <div class="row mb-3">
                            <label for="attendstart" class="col-sm-3 col-form-label fw-bold">Start Date &amp; Time</label>
                            <div class="col-sm-9">
                                <tiertimepicker #attendstart="ngModel" id="attendstart" name="attendstart" [(ngModel)]="selectedAttendee.Start" format="d-m-Y H:i" [withTime]="true" [model]="attendstart" [DateValidation]="[ { value: end, equality: 'lessthan', ident: 'incidentend' }, { value: start, equality: 'morethan', ident: 'incidentstart' } ]" ValidationFormat="dd-MM-yyyy HH:mm" required></tiertimepicker>
                                <p [hidden]="!attendstart.errors?.required" class="form-text text-danger">Start date and time is required.</p>
                                <p [hidden]="attendstart.errors?.DateValidation?.value !== 'incidentend'" class="form-text text-danger">Start date cannot be after incident end date.</p>
                                <p [hidden]="attendstart.errors?.DateValidation?.value !== 'incidentstart'" class="form-text text-danger">Start date cannot be before incident start date.</p>
                            </div>
                        </div>
                        <div class="row mb-3">
                            <label for="attendend" class="col-sm-3 col-form-label fw-bold">End Date &amp; Time</label>
                            <div class="col-sm-9">
                                <tiertimepicker #attendend="ngModel" id="attendend" name="attendend" [(ngModel)]="selectedAttendee.End" [withTime]="true" format="d-m-Y H:i" [model]="attendend" [DateValidation]="[ { value: end, equality: 'lessthan', ident: 'incidentend' }, { value: start, equality: 'morethan', ident: 'incidentstart' }, { value: attendstart.value, equality: 'morethan', ident: 'attendee' }]" ValidationFormat="dd-MM-yyyy HH:mm"></tiertimepicker>
                                <p [hidden]="attendend.errors?.DateValidation?.value !== 'attendee'" class="form-text text-danger">End date cannot be before start date.</p>
                                <p [hidden]="attendend.errors?.DateValidation?.value !== 'incidentend'" class="form-text text-danger">End date cannot be after incident end date.</p>
                                <p [hidden]="attendend.errors?.DateValidation?.value !== 'incidentstart'" class="form-text text-danger">End date cannot be before incident start date.</p>
                            </div>
                        </div>
                    </div>
                    <div class="row mb-3" *ngIf="!multi">
                        <label for="skills" class="col-sm-3 col-form-label fw-bold">Equipment</label>
                        <div class="col-sm-9">
                            <button type="button" class="btn btn-secondary btn-block w-100 btn-sm" (click)="equipment(selectedAttendee)" [disabled]="!selectedAttendee.TeamMemberId">Amend Equipment</button>
                        </div>
                    </div>
                </form>
            </div>
            <div class="modal-footer">
                <button class="btn btn-secondary"
                        type="button"
                        (click)="this.activeModal.dismiss()">
                    Cancel
                </button>
                <button class="btn btn-primary"
                        type="button"
                        (click)="confirm()"
                        [disabled]="attendeeAddForm.invalid">
                    Confirm
                </button>
            </div>`
})
export class IncidentAttendeesModalComponent implements OnInit, OnChanges {
    @ViewChild('atdd') atdd!: NgModel;

    @Input() attendees : IncidentAttendeeModel[] | null | undefined = [];
    @Input() multi : boolean = false;
    @Input({ required : true }) index! : number;

    @Input() start : string | undefined | null = null;
    @Input() end : string | undefined | null = null;

    public entries : IncidentAttendeeModel[] = [];
    public selectedAttendee : any = null;
    public noTrackTimeOption : boolean = false;
    public errorMembers : string[] = [];

    constructor(
        @Inject(NgbActiveModal) public activeModal : NgbActiveModal,
        @Inject(TIEROptions) private options : TIEROptions,
        @Inject(NgbModal) private modalService : NgbModal
      ) {
            this.noTrackTimeOption = this.options.get('org-incident-no-track-time') === "true";
        }

    ngOnInit() : void {
        this.entries = clone(this.attendees);

        if(!isNorU(this.index) && !isNorU(this.entries[this.index])) {
            this.selectedAttendee = this.entries[this.index];
        } else {
             this.selectedAttendee = [];
        }
    }

    ngOnChanges(changes: SimpleChanges): void {
        if(!changes['start']?.isFirstChange())
            this.start = changes['start']?.currentValue;

        if(!changes['end']?.isFirstChange())
            this.end = changes['end']?.currentValue;
    }

    public confirm() : void {
        if(Array.isArray(this.selectedAttendee.TeamMember)) {
            this.selectedAttendee.TeamMember.forEach((attend : TeammembersModel) => this.entries.push({
                TeamMemberId: attend.Id,
                TeamMember: attend,
                Start: this.selectedAttendee.Start,
                End: this.selectedAttendee.End,
                SkillModules: [],
                EquipmentResources : []
            }));
        } else {
            this.entries[this.index] = {
                TeamMemberId: this.selectedAttendee.TeamMember.Id,
                TeamMember: this.selectedAttendee.TeamMember,
                Start: this.selectedAttendee.Start,
                End: this.selectedAttendee.End,
                SkillModules: this.selectedAttendee.SkillModules ?? [],
                EquipmentResources : this.selectedAttendee.EquipmentResources ?? []
            }
        }

        this.activeModal.close(this.entries);
    }

    public equipment(incidentAttendee : IncidentAttendeeModel) {
        const modalRef = this.modalService.open(EquipmentResourceTreeSelectorComponent, { size: 'xl', scrollable: true });

        modalRef.componentInstance.equipment = incidentAttendee.EquipmentResources ?? [];

        modalRef.result.then((result : any) => {
            this.selectedAttendee.EquipmentResources = result;
        }).catch(() => {});
    }

    public amend(attendee : IncidentAttendeeModel | null = null) : void {
        const modalRef = this.modalService.open(ModuleSelectorComponent);

        modalRef.componentInstance.attendee = attendee ?? {};

        modalRef.result.then((result : any) => {
            this.selectedAttendee.SkillModules = result;
        }).catch(() => {});
    }

    public dropdownChanged(change : TeammembersModel[] | TeammembersModel) {
        this.errorMembers = [];

        let pushToError = (entry : IncidentAttendeeModel, tm : TeammembersModel) => {
            if(entry.TeamMemberId === tm.Id && (this.noTrackTimeOption || isNorU(entry.End)))
                this.errorMembers.push(tm.FullName!);
        };

        if(this.attendees)
            this.attendees.forEach((entry) => {
                if(Array.isArray(change)) {
                    change.forEach((tm) => {
                        pushToError(entry, tm);
                    })
                } else {
                    pushToError(entry, change);
                }
            });

        if(this.errorMembers.length > 0) {
            this.atdd.control.setErrors({ ...this.atdd.control.errors || {}, 'duplicate': true });
        } else {
            if(this.atdd.control.hasError('duplicate'))
                this.atdd.control.setErrors({'duplicate': null});
        }
    }
}
