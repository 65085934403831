'use strict';

import { Injectable, Inject } from '@angular/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { StateService } from '@uirouter/angular';
import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import {
    BehaviorSubject,
    catchError,
    filter,
    finalize,
    Observable,
    switchMap,
    take,
    throwError
  } from 'rxjs';

import { TIERAuth, SKIP_ROOT_SPINNER } from './';
import { isNorU } from '../tier.utils';

@Injectable()
export class TIERAuthInterceptor implements HttpInterceptor
{
    private refreshTokenInProgress: boolean = false;
    private refreshTokenSubject: BehaviorSubject<any> = new BehaviorSubject<any>(null);

    private requestCount = 0;

    constructor(@Inject(TIERAuth) private auth : TIERAuth,
                @Inject(StateService) private state : StateService,
                @Inject(NgxSpinnerService) private spinner : NgxSpinnerService) {}

    intercept(req: HttpRequest<any>, next: HttpHandler) : Observable<HttpEvent<unknown>> {
        let SKIP_SPINNER = req.context.get(SKIP_ROOT_SPINNER) === true;
        let authReq = this.addBearer(req);

        if(!SKIP_SPINNER)
            this.showLoader();

        return next.handle(authReq).pipe(
            finalize(() => {
                if(!SKIP_SPINNER)
                    this.hideLoader();
            }),
            catchError((error: HttpErrorResponse) => {
                if (error instanceof HttpErrorResponse && error.status === 401)
                    return this.refreshToken(authReq, next);

                return throwError(() => error);
            })
      );
    }

    private handleError() : void {
        this.refreshTokenInProgress = false;

        if(this.auth.getToken().isAuth)
            this.auth.logout();

        if(!this.state.is('login'))
            this.state.go('login');
    }

    private showLoader(): void {
        if(this.requestCount === 0)
            this.spinner.show("root");
        this.requestCount++;
    }
    private hideLoader(): void {
        this.requestCount--;
        if(this.requestCount === 0)
            this.spinner.hide("root");
    }

    private addBearer(req: HttpRequest<any>, token : string | null = this.auth.getToken()?.token) : HttpRequest<any> {
        return isNorU(token) ? req : req.clone({
            headers: req.headers.set('Authorization', 'Bearer ' + token)
        });
      }

    private refreshToken(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        if (!this.refreshTokenInProgress) {
            this.refreshTokenInProgress = true;
            this.refreshTokenSubject.next(null);

            let tokenInfo = this.auth.getToken();

            if (tokenInfo.isAuth === true && !isNorU(tokenInfo.refreshToken)) {
                return this.auth.refreshToken().pipe(
                    switchMap((response) => {
                        this.refreshTokenInProgress = false;
                        this.refreshTokenSubject.next(response.access_token);

                        return next.handle(this.addBearer(req, response.access_token));
                    }),
                    catchError((error) => {
                        this.handleError();
                        return throwError(() => error);
                    })
                );
            } else {
                this.handleError();
                return throwError(() => new HttpErrorResponse({ url: req.url, status: 401, error: Error("Refreshing token not present") }))
            }
        }

        return this.refreshTokenSubject.pipe(
            filter(token => token !== null),
            take(1),
            switchMap((token) => next.handle(this.addBearer(req, token)))
        );
    }
}
