<div>
    <tierfilter class="mb-3" [isPageFilter]="true" [config]="filterConfig" [(data)]="values" (fetch)="get()"></tierfilter>
</div>
<div [hidden]="(resources.children.length <= 0 && !newLink && !resources.parent)">
    <div  class="card mb-3" [hidden]="moveStack.stack.length <= 0">
        <div class="card-header">
            Selected...
        </div>
        <div class="card-body">
            <div class="row col-sm-12">
                <div *ngFor="let move of moveStack.stack" class="p-2 me-2 mb-2 col-sm-2 badge text-bg-secondary">({{move.template}}) {{move.ref}}</div>
            </div>
        </div>
        <div class="card-footer">
            <div class="hstack">
                <div class="ms-auto">
                    <button type="button" class="btn btn-secondary me-2" (click)="clearMoveStack()">Clear</button>
                    <button type="button" class="btn btn-primary" (click)="commitMove()">Move</button>
                </div>
            </div>
        </div>
    </div>
    <div class="card mb-3">
        <div class="card-header">
            Resources
        </div>
        <div class="card-body">
            <div class="col-xs-12 card bg-light mb-3" *ngIf="resources.parent">
                <div class="hstack">
                    <div class="ps-4 d-none d-md-inline">
                        <h1 class="m-0 p-0">
                            <i class="fa-solid fa-box text-danger"></i>
                        </h1>
                    </div>
                    <div class="ps-4 my-3 flex-fill">
                        <div class="row text-md-end">
                            <label for="template" class="col-sm-12 col-md-3 col-form-label fw-bold">Template</label>
                            <div class="col-sm-12 col-md-9">
                                <input id="template" type="text" readonly class="form-control-plaintext" [value]="resources.parent.EquipmentTemplate.Name">
                            </div>
                        </div>
                        <div class="row text-md-end">
                            <label for="reference" class="col-sm-12 col-md-3 col-form-label fw-bold">Reference</label>
                            <div class="col-sm-12 col-md-9">
                                <input id="reference" type="text" readonly class="form-control-plaintext" [value]="resources.parent.Reference">
                            </div>
                        </div>
                        <div class="row text-md-end">
                            <label for="checkdue" class="col-sm-12 col-md-3 col-form-label fw-bold">Check Due</label>
                            <div class="col-sm-12 col-md-9">
                                <input id="checkdue" type="text" readonly class="form-control-plaintext" [value]="(resources.parent.CheckDueDate | transformdate: 'dd-MM-yyyy') || 'Not Checked'">
                            </div>
                        </div>
                        <div class="row text-md-end">
                            <label for="expirydue" class="col-sm-12 col-md-3 col-form-label fw-bold">Expiry Date</label>
                            <div class="col-sm-12 col-md-9">
                                <input id="expirydue" type="text" readonly class="form-control-plaintext" [value]="(resources.parent.ExpiryDate | transformdate: 'dd-MM-yyyy') || 'No Expiry'">
                            </div>
                        </div>
                        <div class="row text-md-end">
                            <label for="condition" class="col-sm-12 col-md-3 col-form-label fw-bold">Condition</label>
                            <div class="col-sm-12 col-md-9">
                                <input id="condition" type="text" readonly class="form-control-plaintext" [ngClass]="{ 'text-success': resources.parent?.EquipmentResourceConditionType?.IsDefault, 'text-danger': !resources.parent?.EquipmentResourceConditionType?.IsDefault }" [value]="resources.parent?.EquipmentResourceConditionType?.Name">
                            </div>
                        </div>
                        <div class="row text-md-end">
                            <label for="cost" class="col-sm-12 col-md-3 col-form-label fw-bold">Cost</label>
                            <div class="col-sm-12 col-md-9">
                                <input id="cost" type="text" readonly class="form-control-plaintext" [value]="'£' + resources.parent.Cost || 0">
                            </div>
                        </div>
                    </div>
                    <div class="ms-auto m-3 align-self-end">
                        <button
                            class="btn btn-primary"
                            (click)="whenItemChoosen(resources.parent)"
                            ng-disabled="(!$ctrl.resources.parent.IsAllowedToCheckout || !$ctrl.resources.parent.EquipmentTemplate.IsAllocatable) && $ctrl.checkout">
                                <span *ngIf="!checkout">Edit Container</span>
                                <span
                                    *ngIf="checkout"
                                    [textContent]="(resources.parent.AllocatedToId !== null && resources.parent.AllocatedToId === userId) ? containerCheckInText : contrainerCheckOutText">
                                </span>
                        </button>
                    </div>
                </div>

            </div>

            <div class="row">
                <div class="col-md-6 col-lg-4 col-xxl-3 mb-2" [hidden]="!resources.parent">
                    <a class="link-underline link-underline-opacity-0" [uiSref]="viewLink" [uiParams]="{ 'parentId': resources.parent?.EquipmentResourceParentId }">
                        <div class="card border-info">
                            <div class="card-body d-flex">
                                <div class="hstack">
                                    <div class="ps-4">
                                        <h1 class="m-0 p-0">
                                            <i class="fa-solid fa-arrow-circle-left"></i>
                                        </h1>
                                    </div>
                                    <div class="ps-4">
                                        <h2 class="m-0 p-0 text-primary">
                                            Go Back
                                        </h2>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </a>
                </div>

                <div class="col-md-6 col-lg-4 col-xxl-3 mb-2" *ngIf="newLink">
                    <a class="link-underline link-underline-opacity-0" [uiSref]="newLink" [uiParams]="{ 'parentId': resources.parent?.Id }">
                        <div class="card border-info">
                            <div class="card-body d-flex">
                                <div class="hstack">
                                    <div class="ps-4">
                                        <h1 class="m-0 p-0"><i class="fa-solid fa-plus text-success"></i></h1>
                                    </div>
                                    <div class="ps-4">
                                        <h2 class="m-0 p-0 text-primary">New Resource</h2>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </a>
                </div>

                <div class="col-md-6 col-lg-4 col-xxl-3 mb-2" *ngFor="let resource of resources.children">
                    <div class="card"
                        [ngClass]="{ 'border-danger': !resource.IsActive, 'border-success': resource.IsActive, 'selected': resource.Selected }"
                        (click)="resource?.EquipmentTemplate?.IsContainer ? whenContainerChoosen(resource) : whenItemChoosen(resource)"
                        (contextmenu)="whenLongPressed($event, resource)" (longpress)="whenLongPressed($event, resource)">

                        <div class="card-body d-flex">
                            <div class="hstack">
                                <div class="text-center">
                                    <h1 class="p-0">
                                        <span class="fa-stack">
                                            <i class="fa-solid fa-hard-hat text-danger fa-stack-1x" *ngIf="resource.EquipmentTemplate.IsContainer === false"></i>
                                            <i class="fa-solid fa-box text-danger fa-stack-1x" *ngIf="resource.EquipmentTemplate.IsContainer === true"></i>
                                            <i class="fa-solid fa-ban fa-stack-2x text-danger" *ngIf="!resource.IsAllowedToCheckout && checkout"></i>
                                        </span>
                                    </h1>
                                    <h4 class="cost-font" *ngIf="resource.Cost && !values.checkout">£{{resource.Cost}}</h4>
                                </div>
                                <div class="ps-4 text-primary text-wrap">
                                    <b>Template: </b> {{resource.EquipmentTemplate.Name}}<br>

                                    <b>Reference:</b><span class="text-break"> {{resource.Reference}}</span><br>
                                    <b>Checked Due: </b> {{(resource.CheckDueDate | transformdate: 'dd-MM-yyyy') || 'Not Checked'}}<br>
                                    <b>Expiry Date: </b> {{(resource.ExpiryDate | transformdate: 'dd-MM-yyyy') || 'No Expiry'}}<br>
                                    <b>Condition: </b>
                                    <span class="font-bold" [ngClass]="{ 'text-success': resource.EquipmentResourceConditionType?.IsDefault, 'text-danger': !resource.EquipmentResourceConditionType?.IsDefault }">
                                        {{resource.EquipmentResourceConditionType?.Name}}
                                    </span>
                                </div>
                            </div>
                        </div>
                        <div>
                            <div class="card-footer text-primary">
                                <span *ngFor="let foot of genFooter(resource)"><i class="fa-solid" [ngClass]="foot.icon"></i> {{foot.message}}<br /></span>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
        </div>
    </div>
    <div class="d-flex justify-content-center my-3" *ngIf="resources?.children?.length">
        <ngb-pagination [collectionSize]="total" [(page)]="page" [pageSize]="values.amount" [maxSize]="15" [rotate]="true" [ellipses]="false" [boundaryLinks]="true" (pageChange)="get()" />
    </div>
</div>
<div class="alert alert-info" role="alert" [hidden]="resources.children.length || newLink">
    <i class="fa-solid fa-exclamation-circle"></i> 0 results found.
</div>
