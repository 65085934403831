/// <reference types="@angular/localize" />

import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import { enableProdMode } from '@angular/core';
import { TierModule } from './tier/tier.module';
import { environment } from './environments/environment';

if (environment.production)
    enableProdMode();

platformBrowserDynamic().bootstrapModule(TierModule);
