'use strict';

import { Injectable, Inject, EventEmitter } from '@angular/core';
import { HttpContext } from '@angular/common/http';
import { TIERAPICalls, TIERToast, SKIP_ROOT_SPINNER } from './';

import { MessageModel } from '../models';
import { http2Error } from 'src/tier/tier.utils';

@Injectable({
    providedIn: 'root'
})
export class TIERMessages
{
    public RefreshEvent = new EventEmitter();
    public Messages : Array<MessageModel> = [];

    constructor(@Inject(TIERAPICalls) private apicall : TIERAPICalls,
                @Inject(TIERToast) private alert : TIERToast) {

        this.RefreshEvent.subscribe({
            next: () => {
                this.Fetch();
            }
        })
    }

    public Fetch() : void {
        this.Clear();

        this.apicall.get<Array<MessageModel>>('/api/messages/getunread', undefined, undefined, new HttpContext().set(SKIP_ROOT_SPINNER, true)).subscribe({
            next: (response : Array<MessageModel>) => {
                this.Messages = response;
            },
            error: (error) => {
                this.alert.error(http2Error(error));
            }
        });
    }

    public Clear() : void {
        this.Messages = [];
    }

    public DeleteMessage(id : number) : void {
        let index = this.Messages.findIndex((x) => {
            if (x.Id === id) return true;
            return false;
        });

        if (index === -1)
            return;

        this.Messages.splice(index, 1);
    }
}
