'use strict'

import { Component, Inject } from '@angular/core';

import { UserReportPagenationModel, UserReportModel } from '../../models';
import { Reports } from '../../reports';
import { TIERAPICalls, TIERProfilePicture } from '../../../services/';
import { http2Error } from 'src/tier/tier.utils';

@Component({
    selector: 'tier-reports-userreport',
    templateUrl: './userreport.template.html',
    styleUrl: './userreport.component.scss'
})
export class UserReportComponent extends Reports {
    public report : UserReportModel[] = [];
    public total : number = 0;
    public search : string | undefined = undefined;

    constructor(
        @Inject(TIERAPICalls) private apicall : TIERAPICalls,
        @Inject(TIERProfilePicture) private pp : TIERProfilePicture,
    ) { super(); };

    public get() {
        this.apicall.post<UserReportPagenationModel>('api/reports/TeammembersReport',
            this.state2Options()
        ).subscribe({
            next: (response : UserReportPagenationModel) => {
                this.report = response.ObjectList;
                this.total = response.Total;

                this.report.forEach((user) => {
                    if(user.ProfilePicture !== null) {
                        this.pp.get(user.Id).subscribe({
                            next: (response) => {
                                user.ProfilePictureURL = response;
                            }
                        })
                    } else {
                        user.ProfilePictureURL = this.pp.getDefaultPic();
                    }
                });
            },
            error: (error) => {
                this.alert.error(http2Error(error));
            }
        });
    }

    public setState(state : boolean) {
        this.report.forEach((user) => {
            user.IncidentsCollapse = user.RolesCollapse = user.AllocatedEquipmentCollapse = state;
        })
    };
}
