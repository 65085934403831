'use strict'

import { Component, Input, ViewEncapsulation } from '@angular/core';
import { SkillModel } from 'src/tier/models';

@Component({
    selector: 'tier-skilltooltip',
    template: `
        <ng-template #skilltip>
            <div class="card">
                <div class="card-header">
                    <h3 class="card-title">{{skill?.Name}}</h3>
                </div>
                <div class="card-body">
                    <span *ngIf="skill?.Description !== ''">{{skill?.Description}}<br><br></span>
                    <span *ngIf="skill?.AssessmentBasedFlag" class="badge text-bg-primary me-1">Assessment</span>
                    <span *ngIf="!skill?.AssessmentBasedFlag" class="badge text-bg-primary me-1">Attendence</span>
                    <span *ngIf="skill?.Critical" class="badge text-bg-danger">Critical</span>
                    <span *ngIf="skill?.URL !== null && skill?.URL !== ''">
                        <br><br>For more info click <a [href]="skill?.URL" target="_blank">Here</a>
                    </span>
                </div>
            </div>
        </ng-template>
        <span type="button" class="badge text-bg-success" tooltipClass="tier-tooltip" triggers="manual" #skilltt="ngbTooltip" [ngbTooltip]="skilltip" [autoClose]="'outside'" (click)="skilltt.open()">{{skill?.Name}}</span>`,
        styleUrls: [ './TIERSkillTooltip.component.scss' ],
        encapsulation: ViewEncapsulation.None,
})
export class TIERSkillTooltipComponent {
    @Input() skill : SkillModel | null = null;
}
