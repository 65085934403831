'use strict';

import { Injectable } from '@angular/core';

@Injectable({
    providedIn: 'root'
})
export class TIERLocalStorage
{
    private prefix = "";

    private px(key : string) : string {
        return this.prefix + "." + key;
    }

    public setPrefix(prefix : string) : void {
        this.prefix = prefix;
    }

    public getPrefix() : string {
        return this.prefix;
    }

    public get(key : string, store : Storage = localStorage) : string | object | null {
       let response = store.getItem(this.px(key));

        if(response === null)
            return null;

        try {
            return JSON.parse(response as string);
        } catch (error) {
            return response as string;
        }
    }

    public set(key : string, value : string | object, store : Storage = localStorage) {
        let result : string;

        if(typeof value === 'object') {
            result = JSON.stringify(value);
        } else {
            result = value;
        }

        store.setItem(this.px(key), result);
    }

    public remove(key : string, store : Storage = localStorage) : void {
        store.removeItem(this.px(key));
    }

    public clear(store : Storage = localStorage) : void {
        store.clear();
    }
}
