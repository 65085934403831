import { Component, Inject } from '@angular/core';
import { TransitionService } from '@uirouter/angular';

import { isNorU } from 'src/tier/tier.utils';

@Component({
    selector: 'tiertitle',
    template: `<div class="container-fluid">
                    <nav class="breadcrumb" style="--bs-breadcrumb-divider: '>';" class="py-1">
                        <ol class="breadcrumb mx-auto px-2 py-2 m-0 fw-bold">
                            <li class="breadcrumb-item" *ngFor="let label of labels">
                                {{label}}
                            </li>
                        </ol>
                    </nav>
                </div>`,
    styleUrls: [ './TIERTitle.component.scss' ]
})
export class TIERTitleComponent {
    public labels : Array<string> = [];

    constructor(
        @Inject(TransitionService) private trans : TransitionService
    ) {
        this.trans.onSuccess({}, (transition) => {
            this.labels = transition?.$to()?.data?.label?.split("/") ?? 'No Label';
        });
    }
}
