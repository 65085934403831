<div *ngFor="let r of report">
    <div class="container-fluid my-3">
        <h1>Incident - {{r.Name}}</h1>
    </div>
    <tier-reportpane title="Incident Information" [isHidden]="r.Collapse">
        <div class="row mb-3">
            <label for="name" class="col-sm-3 col-form-label fw-bold">Name</label>
            <div class="col-sm-9">
                <input id="name" type="text" readonly class="form-control-plaintext" [value]="r.Name">
            </div>
        </div>
        <div class="row mb-3">
            <label for="externalref" class="col-sm-3 col-form-label fw-bold">External Ref</label>
            <div class="col-sm-9">
                <input id="externalref" type="text" readonly class="form-control-plaintext" [value]="r.ExternalRef || 'N/A'">
            </div>
        </div>
        <div class="row mb-3">
            <label for="start" class="col-sm-3 col-form-label fw-bold">Start Date + Time</label>
            <div class="col-sm-9">
                <input id="start" type="text" readonly class="form-control-plaintext" [value]="r.Start || 'N/A'">
            </div>
        </div>
        <div class="row mb-3">
            <label for="end" class="col-sm-3 col-form-label fw-bold">End Date + Time</label>
            <div class="col-sm-9">
                <input id="end" type="text" readonly class="form-control-plaintext" [value]="r.End || 'N/A'">
            </div>
        </div>
        <div class="row mb-3">
            <label for="rvlocation" class="col-sm-3 col-form-label fw-bold">RV Location</label>
            <div class="col-sm-9">
                <input id="rvlocation" type="text" readonly class="form-control-plaintext" [value]="r.RvLocationName || 'N/A'">
            </div>
        </div>
        <div class="row mb-3">
            <label for="incidentlocation" class="col-sm-3 col-form-label fw-bold">Incident Location</label>
            <div class="col-sm-9">
                <input id="incidentlocation" type="text" readonly class="form-control-plaintext" [value]="r.ActivityLocationLat + ' / ' + r.ActivityLocationLon + ' - ' + r.ActivityLocationOsGridRef">
            </div>
        </div>
        <div class="row mb-3">
            <label for="calltype" class="col-sm-3 col-form-label fw-bold">Call Type</label>
            <div class="col-sm-9">
                <input id="calltype" type="text" readonly class="form-control-plaintext" [value]="r.CallTypeName || 'N/A'">
            </div>
        </div>
       <div class="row mb-3">
            <label for="responsetype" class="col-sm-3 col-form-label fw-bold">Response Type</label>
            <div class="col-sm-9">
                <input id="responsetype" type="text" readonly class="form-control-plaintext" [value]="r.ResponseTypeName || 'N/A'">
            </div>
        </div>
       <div class="row mb-3">
            <label for="operationtype" class="col-sm-3 col-form-label fw-bold">Operation Type</label>
            <div class="col-sm-9">
                <input id="operationtype" type="text" readonly class="form-control-plaintext" [value]="r.OperationTypeName || 'N/A'">
            </div>
        </div>
       <div class="row mb-3">
            <label for="details" class="col-sm-3 col-form-label fw-bold">Details</label>
            <div class="col-sm-9">
                <input id="details" type="text" readonly class="form-control-plaintext" [value]="r.Details || 'N/A'">
            </div>
        </div>
       <div class="row mb-3" *ngIf="r.HasConclusion">
            <label for="conclusion" class="col-sm-3 col-form-label fw-bold">Conclusion</label>
            <div class="col-sm-9">
                <input id="conclusion" type="text" readonly class="form-control-plaintext" [value]="r.Conclusion">
            </div>
        </div>
    </tier-reportpane>
    <div *ngIf="r.IncidentAttendees">
        <tier-reportpane title="Attendee Information" [isHidden]="r.AttendeeCollapse">
            <p class="form-control-plaintext" *ngFor="let attendee of r.IncidentAttendees">
                {{attendee.TeamMemberName}} - {{attendee.Start | transformdate: 'dd-MM-yyyy HH:mm' }} => {{attendee.End | transformdate: 'dd-MM-yyyy HH:mm' }} ({{ attendee.Start | durationdate: attendee.End }})
            </p>
        </tier-reportpane>
    </div>
    <div *ngIf="r.IncidentSubjects">
        <tier-reportpane title="Subject Information" [isHidden]="r.SubjectsCollapse">
            <div *ngFor="let subject of r.IncidentSubjects">
                <h2 *ngIf="subject.FirstName || subject.LastName">Subject {{subject.FirstName + ' ' + subject.LastName}}</h2>
                <h2 *ngIf="!subject.FirstName && !subject.LastName">Subject Anonymous</h2>
                <hr />
                <div class="row mb-3">
                    <label for="subjecttype" class="col-sm-3 col-form-label fw-bold">Subject Type</label>
                    <div class="col-sm-9">
                        <input id="subjecttype" type="text" readonly class="form-control-plaintext" [value]="subject.IncidentSubjectTypeName || 'N/A'">
                    </div>
                </div>
                <div class="row mb-3">
                    <label for="dateofbirth" class="col-sm-3 col-form-label fw-bold">Date Of Birth</label>
                    <div class="col-sm-9">
                        <input id="dateofbirth" type="text" readonly class="form-control-plaintext" [value]="(subject.DateOfBirth | transformdate: 'dd-MM-yyyy') || 'N/A'">
                    </div>
                </div>
                <div class="row mb-3">
                    <label for="age" class="col-sm-3 col-form-label fw-bold">Age</label>
                    <div class="col-sm-9">
                        <input id="age" type="text" readonly class="form-control-plaintext" [value]="dob2age(subject.DateOfBirth) || 'Unknown'">
                    </div>
                </div>
                <div class="row mb-3">
                    <label for="sex" class="col-sm-3 col-form-label fw-bold">Sex</label>
                    <div class="col-sm-9">
                        <input id="sex" type="text" readonly class="form-control-plaintext" [value]="subject.Sex || 'N/A'">
                    </div>
                </div>
                <div class="row mb-3">
                    <label for="nationality" class="col-sm-3 col-form-label fw-bold">Nationality</label>
                    <div class="col-sm-9">
                        <input id="nationality" type="text" readonly class="form-control-plaintext" [value]="subject.NationalityName || 'N/A'">
                    </div>
                </div>
                <div class="row mb-3">
                    <label for="city" class="col-sm-3 col-form-label fw-bold">City</label>
                    <div class="col-sm-9">
                        <input id="city" type="text" readonly class="form-control-plaintext" [value]="subject.City || 'N/A'">
                    </div>
                </div>
                <div class="row mb-3">
                    <label for="notes" class="col-sm-3 col-form-label fw-bold">Notes</label>
                    <div class="col-sm-9">
                        <input id="notes" type="text" readonly class="form-control-plaintext" [value]="subject.Notes || 'N/A'">
                    </div>
                </div>
                <div class="row mb-3" *ngIf="subject.GlobalInjuries?.length">
                    <label class="col-sm-3 col-form-label fw-bold">Global Injuries</label>
                    <div class="col-sm-9">
                        <input *ngFor="let globalInjury of subject.GlobalInjuries" type="text" readonly class="form-control-plaintext" [value]="globalInjury.Name">
                    </div>
                </div>
                <div class="row mb-3" *ngIf="subject.SpecificInjuries?.length">
                    <label class="col-sm-3 col-form-label fw-bold">Specific Injuries</label>
                    <div class="col-sm-9">
                        <input *ngFor="let injury of subject.SpecificInjuries" type="text" readonly class="form-control-plaintext" [value]="injury.BodyPart + ' - ' + injury.Description">
                    </div>
                </div>
            </div>
        </tier-reportpane>
    </div>
    <div *ngIf="r.CurrentWeather">
        <tier-reportpane title="Weather" [isHidden]="r.WeatherCollapse">
            <tier-weather [(weather)]="r.CurrentWeather" [ro]="true"></tier-weather>
        </tier-reportpane>
    </div>
    <div *ngIf="r.Messages">
        <tier-reportpane title="Messages" [isHidden]="r.MessagesCollapse">
            <div class="card mb-2" *ngFor="let message of r.Messages">
                <div class="card-header">
                    <div class="hstack">
                        <div class="hstack">
                            <div class="me-1">
                                <i class="fa-solid fa-star star" [ngClass]="{ 'goldstar': message.Important }"></i>
                            </div>
                            <div><b>Posted On:</b> {{message.TimeStamp}}</div>
                        </div>
                        <div class="ms-auto"><b>Posted By:</b> {{message.TeamMemberFullName}}</div>
                    </div>
                </div>
                <div class="card-body">{{message.Message}}</div>
            </div>
        </tier-reportpane>
    </div>
</div>

<div class="alert alert-warning" role="alert" [hidden]="report.length">
    <i class="fa-solid fa-exclamation-circle"></i> 0 results found.
</div>

<TIERReportButtons (print)="print()" (export)="export('IncidentInformationReport')"></TIERReportButtons>
