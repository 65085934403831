import { Component, Inject, Input } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { EquipmentResourceModel } from 'src/tier/models';

@Component({
    selector: 'tier-account-equipmentcheckout-checkin',
    template: `
        <div class="modal-header">
            <h3 class="modal-title">Check in resource</h3>
        </div>
        <div class="modal-body">
            <div class="row mb-3">
                <label for="condition" class="col-sm-3 col-form-label fw-bold">Condition</label>
                <div class="col-sm-9">
                    <tierdropdown
                        id="condition"
                        name="condition"
                        source="api/equipment/resources/conditiontypes"
                        [httpparams]="{ allowDefault: true }"
                        placeholder="Select Condition Type"
                        [(ngModel)]="resource.EquipmentResourceConditionTypeId"
                        [allowNew]=false
                        bindId="Id"
                        bindValue="Name">
                    </tierdropdown>
                </div>
            </div>
            <div class="row mb-3">
                <label for="conditioncomments" class="col-sm-3 col-form-label fw-bold">Comments</label>
                <div class="col-sm-9">
                    <textarea class="form-control" rows="3" name="conditioncomments" id="conditioncomments" [(ngModel)]="resource.ConditionComments"></textarea>
                </div>
            </div>
        </div>
        <div class="modal-footer">
            <div>
                <button type="button" class="btn btn-secondary me-2"
                        (click)="this.activeModal.dismiss()">
                    Cancel
                </button>
                <button type="button" class="btn btn-primary"
                        (click)="confirm()">
                    Confirm
                </button>
            </div>
        </div>`
})
export class EquipmentCheckoutCheckInComponent {
    @Input() resource : EquipmentResourceModel = {};

    constructor(@Inject(NgbActiveModal) public activeModal : NgbActiveModal) {}

    public confirm() {
        if(!this.resource?.EquipmentTemplate?.IsAllocatable)
            return;

        this.activeModal.close({ 'ConditionTypeId': this.resource.EquipmentResourceConditionTypeId, 'ConditionComments': this.resource.ConditionComments });
    }
}
