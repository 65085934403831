import { Component, Input, forwardRef } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from "@angular/forms";

@Component({
    selector: 'tierfiltertickbox',
    template: `
        <div class="row">
            <label for="search" class="col-sm-3 col-form-label fw-bold" [textContent]="label"></label>
            <div class="col-sm-9">
                <div class="form-check pt-2">
                    <input class="form-check-input" type="checkbox" [(ngModel)]="filterTick" (ngModelChange)="updateChanges($event)">
                    <label class="form-check-label">
                        {{description}}
                    </label>
                </div>
            </div>
        </div>`,
    providers: [{
        provide: NG_VALUE_ACCESSOR,
        useExisting: forwardRef(() => TIERFilterTickboxComponent),
        multi: true
    }]
})
export class TIERFilterTickboxComponent implements ControlValueAccessor {
    @Input() label : string | null = null;
    @Input() description : string | null = null;

    public filterTick : boolean = false;

    onChange: (_: any) => void = (_: any) => {};
    onTouched: () => void = () => {};

    updateChanges(tick : boolean) : void {
        this.onChange(tick);
    }

    writeValue(tick : boolean): void {
        this.filterTick = tick;
    }

    registerOnChange(fn: any): void {
        this.onChange = fn;
    }

    registerOnTouched(fn: any): void {
        this.onTouched = fn;
    }
}
