import { Component } from '@angular/core';
import { WidgetBase } from '../../Widget.base';

@Component({
    selector: 'tier-widget-nodatawidget',
    template: `
        <div class="systemwidget">
            <i class="fa-solid fa-chart-pie systemwidgeticon"></i>
            <b class="systemwidgettext">Dataset is empty.</b>
        </div>`,
    styleUrls: [ '../../_widgets.scss' ]
})
export class NoDataWidgetComponent extends WidgetBase {
    static readonly widgetName = 'NoDataWidgetComponent';
 }
