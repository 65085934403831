'use strict'

import { Component, Inject, OnInit, Input } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { format } from "date-fns";

import { IncidentMessageModel, RoleModel } from 'src/tier/models';
import { TIERAPICalls, TIERToast } from '../../services';
import { isNorU, http2Error } from 'src/tier/tier.utils';

@Component({
    selector: 'tier-incidentmessages',
    template: `<div class="modal-header">
        <h3 class="modal-title">Incident Messages</h3>
    </div>
    <div class="modal-body">
        <div class="mb-3">
            <div class="card mb-3" *ngFor="let message of incidentMessages">
                <div class="card-header">
                    <div class="hstack">
                        <div class="hstack">
                            <div class="me-1" (click)="toggleImportant(message)">
                                <i class="fa-solid fa-star star" [ngClass]="{ 'goldstar': message.Favourite }"></i>
                            </div>
                            <div><b>Posted On:</b> {{message.TimeStamp}}</div>
                        </div>
                        <div class="ms-auto"><b>Posted By:</b> {{message.TeamMemberFullName}}</div>
                    </div>
                </div>
                <div class="card-body">{{message.Message}}</div>
                <div class="card-footer" *ngIf="message.HasSentSMS">
                    <i class="fa-solid fa-check me-2 text-success"></i>SMS has been sent.
                </div>
            </div>
        </div>
        <div class="form-group">
            <form #incidentMessageForm="ngForm" name="incidentMessageForm" novalidate autocomplete="none">
                <div class="mb-3">
                    <label class="form-label" for="newmessage">New Message</label>
                    <textarea id="newmessage" name="newmessage" class="form-control" rows="3" [(ngModel)]="message" required></textarea>
                </div>
                <div class="hstack mb-2">
                    <div class="me-3">
                        Send SMS message to team members in role:
                    </div>
                    <div class="ms-auto">
                        <tierdropdown name="roles" [multi]="true" source="api/roles" [allowNew]="false" bindValue="Name" [(ngModel)]="roles"></tierdropdown>
                    </div>
                </div>
                <div class="hstack">
                    <div class="ms-auto">
                        <button type="button" class="btn btn-primary" (click)="add()" [disabled]="incidentMessageForm.invalid">Confirm</button>
                    </div>
                </div>
            </form>
        </div>
    </div>
    <div class="modal-footer">
        <div>
            <button type="button" class="btn btn-secondary"
                    (click)="this.activeModal.dismiss()">
                Cancel
            </button>
        </div>
    </div>`,
    styles: `.goldstar {
        color: gold;
    }`
})
export class IncidentMessagesComponent implements OnInit {
    @Input() id : number | null = null;

    public incidentMessages : IncidentMessageModel[] = [];
    public message : string | null = null;
    public roles : RoleModel[] | null = null;

    constructor(
        @Inject(TIERAPICalls) private apicall : TIERAPICalls,
        @Inject(TIERToast) private alert : TIERToast,
        @Inject(NgbActiveModal) public activeModal : NgbActiveModal) {}

    ngOnInit(): void {
        this.get();
    }

    private get() {
        if(isNorU(this.id))
            return;

        this.message = null;
        this.roles = null;

        this.apicall.get<IncidentMessageModel[]>('api/incidentmessages/%s'.formUri(this.id)).subscribe({
            next: (response : IncidentMessageModel[]) => {
                this.incidentMessages = response;
            },
            error: (error) => {
                this.alert.error(http2Error(error));
            }
        });
    }

    public toggleImportant(message : IncidentMessageModel) {
        message.Favourite = !message.Favourite;

        this.apicall.put<IncidentMessageModel>('api/incidentmessages/%s/message/%s/togglefavourite'.formUri(this.id, message.Id), message).subscribe({
            next: () => {
                this.get();
            },
            error: (error) => {
                this.alert.error(http2Error(error));
            }
        });
    }

    public add() {
        this.apicall.post<IncidentMessageModel[]>('api/incidentmessages/%s'.formUri(this.id), { TimeStamp: format(new Date(), 'dd-MM-yyyy HH:mm'), Message: this.message, Roles: this.roles }).subscribe({
            next: () => {
                this.get();
            },
            error: (error) => {
                this.alert.error(http2Error(error));
            }
        });
    }
}
