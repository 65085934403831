'use strict';

import { Injectable, Inject } from '@angular/core';
import { HttpErrorResponse, HttpParams } from '@angular/common/http';
import { isNorU } from 'src/tier/tier.utils';
import {
    map,
    Observable,
    throwError,
    catchError
  } from 'rxjs';

import { TIERDownload, TIERAuth, TIERConfig, blobDownload, TIERHelpers } from './';

@Injectable({
    providedIn: 'root'
})
export class TIERProfilePicture
{
    constructor(
        @Inject(TIERDownload) private dl : TIERDownload,
        @Inject(TIERAuth) private auth : TIERAuth,
        @Inject(TIERConfig) private config: TIERConfig,
        @Inject(TIERHelpers) private helpers : TIERHelpers)
        { }

    public getMine() : Observable<string> {
        let tokeninfo = this.auth.getTokenInfo();

        if(isNorU(tokeninfo?.avatarURL))
            return this.getObjectURL(this.getDefaultPic());

        return this.getObjectURL(this.helpers.as(tokeninfo!.avatarURL));
    }

    public getDefaultPic() : string {
        return this.config.get("defaultUserIcon");
    }

    private getObjectURL(url : string) : Observable<string> {
         return this.dl.download(url, 'GET', new HttpParams()).pipe(
            map((bl : blobDownload) => {
                if(!bl.blob)
                    throw Error("Blob not present");

                return window.URL.createObjectURL(bl.blob);
            }),
            catchError((error: HttpErrorResponse) => { return error.status !== 404 ? throwError(() => error) : this.getObjectURL(this.getDefaultPic()); })
        );
    }

    public get(id : string | null) : Observable<string> {
        return this.getObjectURL(this.helpers.as(this.config.get("profilePictureURLTemplate").formUri(id)));
    }
}
