'use strict'

import { Component, Inject, EventEmitter, OnInit, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';

import { TIERAPICalls, TIERToast } from '../../services';
import { RoleModel, ClaimModel } from '../../models/';
import { isNorU, http2Error } from 'src/tier/tier.utils';

@Component({
    selector: 'tier-management-roles',
    templateUrl: "./roles.template.html"
})
export class RolesComponent implements OnInit {
    @ViewChild('roleForm') roleForm!: NgForm;

    private roleDefault : RoleModel = { IncidentFlag: false, OperationalFlag: false, Name: null, IsActive: true, Claims: [], Skills: [] };

    public selectedRoleId : string | number = -1;
    public role : RoleModel = { ...this.roleDefault };
    public claims : ClaimModel[] = [];

    public refreshEmitter : EventEmitter<string | number> = new EventEmitter();

    constructor(
        @Inject(TIERAPICalls) private apicall : TIERAPICalls,
        @Inject(TIERToast) private alert : TIERToast
    ) { };

    ngOnInit(): void {
        this.apicall.get<ClaimModel[]>('api/claims/').subscribe({
            next: (claims) => {
                this.claims = claims;
            },
            error: (err) => {
                this.alert.error(http2Error(err));
            },
        });
    }

    public fetchRole(id : number | string) : void {
        if(id === -1) {
            this.clean();
            return;
        }

        this.apicall.get<RoleModel>('/api/roles/%s'.formUri(id)).subscribe({
            next: (response : RoleModel) => {
                this.role = response;
                this.fillClaimsFromRole();
                this.roleForm.form.markAsPristine();
            },
            error: (error) => {
                this.alert.error(http2Error(error));
            }
        });
    }

    public resetSkill() : void {
        this.role.Skills = [];
    }

    private fillClaimsFromRole() : void {
        this.claims.forEach((claim) => {
            if(this.role.Claims.findIndex(x => x.Id === claim.Id) !== -1) {
                claim.Checked = true;
            } else {
                claim.Checked = false;
            }
        });
    };

    public claimClicked(claim : ClaimModel) : void {
        let idx = this.role.Claims.findIndex(x => x.Id === claim.Id);

        if (idx > -1) {
            this.role.Claims.splice(idx, 1);
        } else {
            this.role.Claims.push(claim);
        }
    }

    private clean() : void {
        this.claims.forEach(claim => claim.Checked = false);
        this.role = { ...this.roleDefault };
        this.roleForm.form.markAsPristine();
    }

    public delete() : void {
        this.role.IsActive = !this.role.IsActive;
        this.save();
    }

    public save() : void {
        this.apicall.save<RoleModel>('api/roles/%s'.formUri(this.role.Id), this.role).subscribe({
            next: (response?) => { this.refreshEmitter.emit(isNorU(response) ? this.role.Id : response?.Id); this.alert.success("Role saved"); },
            error: (err) => { this.alert.error(http2Error(err)); }
        });
     }
}
