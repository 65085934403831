import { Component, Inject, OnInit, Input } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { parse, isValid, differenceInYears, subYears, startOfMonth, format } from "date-fns";

import { IncidentSubjectModel, IncidentSubjectInjuriesModel } from '../../models';
import { TIERConfig, TIERDialog, TIEROptions } from '../../services';
import { isNorU, clone } from 'src/tier/tier.utils';

@Component({
    selector: 'tier-incident-subjectsmodal',
    templateUrl: './incidentsubjectsmodal.template.html',
    styleUrl: './incidentsubjectsmodal.component.scss'
})
export class IncidentSubjectsModalComponent implements OnInit {
    @Input() subject : IncidentSubjectModel = {};

    public isCare : boolean = false;
    public entry : IncidentSubjectModel = {};
    public bodyPartInjuries : { [key: string]: IncidentSubjectInjuriesModel } = {};

    public bodyPart : { bodyPartId?: string, bodyPartName?: string, description?: string } = {};
    public bodyPartLength = () => Object.keys(this.bodyPartInjuries)?.length;

    public hideBodyPartWindow : boolean = true;

    constructor(@Inject(NgbActiveModal) public activeModal : NgbActiveModal,
                @Inject(TIERConfig) private config : TIERConfig,
                @Inject(TIERDialog) private dialog : TIERDialog,
                @Inject(TIEROptions) private options : TIEROptions)
        {
            this.isCare = this.config.get('variant') === 'care';
        }

    ngOnInit(): void {
        this.entry = clone(this.subject);
        this.parseSubjectInjuries();

        if(isNorU(this.entry.NationalityId))
            this.entry.NationalityId = +this.options.get('org-locale-nationality')!;

        if(!isNorU(this.entry.DateOfBirth))
            this.dob2age(this.entry.DateOfBirth!);
    }

    public dob2age(event : string) : void {
        let eventDate : Date | null = null;

        try {
            eventDate = parse(event, 'dd-MM-yyyy HH:mm', new Date());
        } catch(e) {
            this.entry.Age = null;
        }

        if (!isValid(eventDate))
            return;

        this.entry.Age = differenceInYears(new Date(), eventDate!);
    };

    public age2dob() : void {
        if (!Number.isFinite(this.entry.Age))
            return;

        this.entry.DateOfBirth = format(startOfMonth(subYears(new Date(), this.entry.Age!)), 'dd-MM-yyyy HH:mm');
    };

    public bindId(event : any) {
        this.entry.IncidentSubjectTypeId = event.Id;
    }

    private getBodyPartId(element : any) : HTMLElement | null {
        if (isNorU(element.id)) {
            if (isNorU(element.parentNode))
                return null;

            return this.getBodyPartId(element.parentNode);
        }

        return document.getElementById(element.id);
    };

    public bodyPartClicked(event : any) : void {
        if (this.entry.NoInjuries)
            return;

        let bodyPart = this.getBodyPartId(event.target);
        const bodyParts = [
            'fronthead',
            'frontleftarm',
            'frontrightarm',
            'frontleftleg',
            'frontrightleg',
            'chest',
            'backhead',
            'backleftarm',
            'backrightarm',
            'backleftleg',
            'backrightleg',
            'back'
        ];

        if (isNorU(bodyPart?.id))
            return;

        bodyParts.forEach((entry) => {
            if(bodyPart?.id === entry)
                this.showBodyPartWindow(bodyPart.id, bodyPart.getAttribute("name"));
        });
    };

    public closeBodyPartWindow() : void {
        this.hideBodyPartWindow = true;
        this.bodyPart = {};
    }

    private showBodyPartWindow(part : string, name : string | null) : void {
        this.bodyPart.bodyPartId = part;
        this.bodyPart.bodyPartName = name ?? 'unknown';
        this.bodyPart.description = this.bodyPartInjuries[part]?.InjuryDescription;
        this.hideBodyPartWindow = false;
    };

    public removeSubjectInjury() : void {
        this.dialog.showConfirmDialog("Deleting subject injury", "Are you sure you wish to delete this subjects injury?").then(() => {
            delete this.bodyPartInjuries[this.bodyPart.bodyPartId!];
            this.closeBodyPartWindow();
		}).catch(() => {});
    }

    public confirmSubjectInjury() : void {
        this.bodyPartInjuries[this.bodyPart.bodyPartId!] = { BodyPart: this.bodyPart.bodyPartId, InjuryDescription: this.bodyPart.description };
        this.closeBodyPartWindow();
    }

    private parseSubjectInjuries() {
        if(!isNorU(this.entry.SubjectInjuries))
            this.entry.SubjectInjuries!.forEach((subjectInjury) => this.bodyPartInjuries[subjectInjury.BodyPart!] = subjectInjury);
    }

    private parseBodyPartInjuries() {
        this.entry.SubjectInjuries = [];

        Object.keys(this.bodyPartInjuries).forEach((bodyPart) => {
            this.entry.SubjectInjuries!.push(this.bodyPartInjuries[bodyPart]);
        });
    }

    public confirm() : void {
        this.parseBodyPartInjuries();
        this.activeModal.close(this.entry);
    }

    public noInjuriesClicked(toggle : boolean) {
        if(toggle) {
            this.dialog.showConfirmDialog("Marking no injuries", "Marking this subject as no injuries will remove all injuries previously entered. Are you sure?").then(() => {
                this.bodyPartInjuries = {};
                this.entry.IncidentGlobalInjuries = [];
            }, () => {
                this.entry.NoInjuries = false;
            }).catch(() => {});
        }
    }
}
