    <div class="modal-header">
        <h3 class="modal-title">Information for {{session.Title}} event</h3>
    </div>
    <div class="modal-body">
        <div class="alert alert-warning" role="alert" [hidden]="!session.IsFaciliator">
            You are a faciliator for this event and therefore cannot attend.
        </div>

        <div class="row mb-3">
            <label for="title" class="col-sm-3 col-form-label fw-bold">Title</label>
            <div class="col-sm-9">
                <input type="text" readonly class="form-control-plaintext" id="title" [value]="session.Title">
            </div>
        </div>
        <div class="row mb-3">
            <label for="trainingcontent" class="col-sm-3 col-form-label fw-bold">Content</label>
            <div class="col-sm-9">
                <input type="text" readonly class="form-control-plaintext" id="trainingcontent" [value]="session.TrainingContent || 'N/A'">
            </div>
        </div>
        <div class="row mb-3">
            <label for="startdate" class="col-sm-3 col-form-label fw-bold">Start Date</label>
            <div class="col-sm-9">
                <input type="text" readonly class="form-control-plaintext" id="startdate" [value]="session.StartDate">
            </div>
        </div>
        <div class="row mb-3">
            <label for="enddate" class="col-sm-3 col-form-label fw-bold">End Date</label>
            <div class="col-sm-9">
                <input type="text" readonly class="form-control-plaintext" id="enddate" [value]="session.EndDate">
            </div>
        </div>
        <div class="row mb-3">
            <label class="col-sm-3 col-form-label fw-bold">Modules</label>
            <div class="col-sm-9 pt-2">
                <div *ngFor="let skillModule of session.SkillModules">
                    <tier-skilltooltip [skill]="skillModule"></tier-skilltooltip>
                </div>
                <span class="badge text-bg-secondary" *ngIf="session.SkillModules?.length === 0">
                    No Modules
                </span>
            </div>
        </div>
        <div class="row mb-3">
            <label class="col-sm-3 col-form-label fw-bold">Facilitators</label>
            <div class="col-sm-9 pt-2">
                <div *ngFor="let facilitator of session.Facilitators">
                        <span class="badge text-bg-info">{{facilitator}}</span>
                </div>
                <span *ngIf="!session?.Facilitators?.length" class="badge text-bg-danger">No one</span>
            </div>
        </div>
        <div class="row mb-3">
            <label class="col-sm-3 col-form-label fw-bold">Location</label>
            <div class="col-sm-9 pt-2">
                <tier-mapping-button [location]="session.Venue" permission="r" [disabled]="!session.Venue?.Name" [classes]="['btn-sm', 'btn-info']">
                    <i class="fa-solid fa-map"></i> {{session.Venue?.Name || "N/A"}}
                </tier-mapping-button>
            </div>
        </div>
        <div class="row mb-3">
            <label class="col-sm-3 col-form-label fw-bold">Attendence</label>
            <div class="col-sm-9 pt-2">
                <tier-teammembersbadge [teammembers]="session.Attendees" bindname="FullName"></tier-teammembersbadge>
            </div>
        </div>
    </div>
    <div class="modal-footer">
        <button type="button" class="btn"
                [ngClass]="{ 'btn-info': !session.IsAttending, 'btn-danger': session.IsAttending }"
                (click)="attend(session.Id, session.IsAttending)"
                [disabled]="session.IsFaciliator"
                [textContent]="session.IsAttending ? 'Unattend' : 'Attend'">
        </button>
        <button type="button"
                class="btn btn-warning"
                (click)="this.activeModal.dismiss()">
            Close
        </button>
    </div>
