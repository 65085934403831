'use strict'

import { Component, Inject } from '@angular/core';
import { StateService } from '@uirouter/angular';
import { TIERLocalStorage } from 'src/tier/services';

@Component({
    selector: 'tier-training-schedule',
    template: `
    <div>
        <div class="hstack mb-4">
            <div>
                <b class="me-3">Select view: </b>
                <div class="btn-group" role="group">
                    <button type="button" class="btn btn-default" (click)="viewChange('list')"><i class="fa-solid fa-list-ul"></i></button>
                    <button type="button" class="btn btn-default" (click)="viewChange('calendar')"><i class="fa-solid fa-calendar"></i></button>
                </div>
            </div>
        </div>
    </div>
    <ui-view></ui-view>`
})
export class ScheduleComponent {
    constructor(@Inject(TIERLocalStorage) private ls : TIERLocalStorage,
                @Inject(StateService) private state : StateService
    ) {
        switch(ls.get('schedule')) {
            case 'list':
                state.go('training@schedule.list');
                break;
            case 'calendar':
                state.go('training@schedule.calendar');
                break;
            default:
                state.go('training@schedule.list');
        }
    }

    public viewChange(type : string) {
        this.ls.set('schedule', type);
        this.state.go('training@schedule.' + type);
    }
 }
