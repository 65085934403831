'use strict'

import { Component, Input } from '@angular/core';

@Component({
    selector: 'tier-telltime',
    template: `
        <div class="tellTimeContent">
            <div>
                <span class="tellTimeLargeTime" [textContent]="from | transformdate : 'dd/MM/yy'"></span><br />
                <span class="tellTimeSmallInfo" [textContent]="from | transformdate : 'HH:mm'"></span><br />
            </div>
            <div [hidden]="to === null">
                <div class="tellTimeSeperator">
                    <span>To</span>
                </div>
                <div>
                    <span class="tellTimeLargeTime" [textContent]="to | transformdate : 'dd/MM/yy'"></span><br />
                    <span class="tellTimeSmallInfo" [textContent]="to | transformdate : 'HH:mm'"></span><br />
                </div>
            </div>
        </div>`,
    styleUrls: [ './TIERTelltime.component.scss' ]
})
export class TIERTelltimeComponent {
    @Input() from : string | null | undefined = null;
    @Input() to : string | null | undefined = null;
}
