import { forwardRef, Directive, Inject, ElementRef, Renderer2, HostListener } from '@angular/core';
import { NG_VALUE_ACCESSOR, ControlValueAccessor } from '@angular/forms';
import { isNorU } from 'src/tier/tier.utils';

@Directive({
  selector: '[ConvertToTrueOrFalse]',
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => ConvertToTrueOrFalseDirective),
      multi: true
    }
  ]
})
export class ConvertToTrueOrFalseDirective implements ControlValueAccessor {

    constructor(@Inject(ElementRef) private elementRef: ElementRef,
                @Inject(Renderer2) private renderer: Renderer2) {}

    onChange: (_: any) => void = (_: any) => {};
    onTouched: () => void = () => {};

    @HostListener('change', ['$event'])
    onHostChange(ev : any) {
        this.onChange(ev.target.checked);
    }

    writeValue(value : any): void {
        if(isNorU(value))
            return;

        if(value.toLowerCase() === "true") {
            this.renderer.setProperty(this.elementRef.nativeElement, 'checked', true);
        } else {
            this.renderer.setProperty(this.elementRef.nativeElement, 'checked', false);
        }
    }

    registerOnChange(fn: any): void {
        this.onChange = fn;
    }

    registerOnTouched(fn: any): void {
        this.onTouched = fn;
    }
}
